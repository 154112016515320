import axios from 'src/utils/axios';
import { isProduction, isStaging } from 'src/config';
import { setSession } from 'src/redux/slices/auth';
import { waitms } from 'src/utils/wait';
import _toLower from 'lodash/toLower';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const getDomainBasedUrl = (prodDomain, stagingDomain, devDomain) => {
  if (isProduction) {
    return window.location.hostname.includes('careitapp.com')
      ? `https://${prodDomain}.careitapp.com`
      : `https://${prodDomain}.careit.com`;
  } else if (isStaging) {
    return window.location.hostname.includes('careitapp.com')
      ? `https://${stagingDomain}.careitapp.com`
      : `https://${stagingDomain}.careit.com`;
  } else {
    return `http://${devDomain}`;
  }
};

export const BACKEND_ORIGIN = getDomainBasedUrl(
  'api',
  'staging-api',
  'localhost:3001'
);
export const SOCKET_ORIGIN = getDomainBasedUrl(
  'api',
  'staging-api',
  'localhost:3001'
).replace('http', 'ws');
//export const BUCKET_NAME = getDomainBasedUrl('assets', 'staging-assets', 'dev-assets');

/**
 * Constructs an http(s) address from an API route
 * (used internally by `fetchAPI`)
 * @param {string} route
 * @returns string
 */
export function getRoute(route) {
  return BACKEND_ORIGIN.concat(route);
}

export const METHODS = {
  delete: 'delete',
  get: 'get',
  post: 'post',
  put: 'put',
};

/**
 * Arbitrarily fetches the backend API.
 * @param {string} route
 * @param {string} method
 * @param {Object} data
 * @param {Object} opts
 * @param {boolean} opts.noAuthRefresh Will not attempt to use a refresh token on a 401.
 * @returns Promise<Response>
 */
export async function fetchAPI(
  route,
  method,
  data,
  { noAuthRefresh = false } = {}
) {
  try {
    const doFetch = () =>
      window.fetch(getRoute(route), {
        mode: 'cors',
        method,
        headers: {
          ...(window.localStorage.getItem('token')
            ? {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
              }
            : {}),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: data ? JSON.stringify(data) : undefined,
        redirect: 'follow',
      });
    const res = await doFetch();

    // Display refresh button to reload the dashboard if there is a version mismatch
    if (
      parseInt(res.headers.get('x-dashboard-version')) >
        parseInt(process.env.REACT_APP_VERSION) &&
      res.status === 200 &&
      localStorage.getItem('latest-version') !=
        res.headers.get('x-dashboard-version')
    ) {
      localStorage.setItem(
        'latest-version',
        parseInt(res.headers.get('x-dashboard-version'))
      );
      window.versionEvent = new CustomEvent('latest-version', {
        detail: { latestVersion: res.headers.get('x-dashboard-version') },
      });
      window.dispatchEvent(window.versionEvent);
    } else if (
      !(
        parseInt(res.headers.get('x-dashboard-version')) >
        parseInt(process.env.REACT_APP_VERSION)
      )
    ) {
      localStorage.setItem(
        'latest-version',
        parseInt(process.env.REACT_APP_VERSION)
      );
      window.versionEvent = new CustomEvent('latest-version', {
        detail: { latestVersion: process.env.REACT_APP_VERSION },
      });
      window.dispatchEvent(window.versionEvent);
    }

    let resData = {};
    try {
      resData = await res.clone().json();
    } catch (err) {
      resData = {};
    }

    // If we get a 401, try to use our refresh token
    if (res.status === 401 && !noAuthRefresh) {
      // Check for the refresh token
      const refreshToken = localStorage.getItem('refresh-token');
      if (!refreshToken) {
        return res;
      }
      const { exp } = parseJwt(refreshToken);
      const expDate = new Date(exp * 1000);
      //if refresh token expired or is invalid, logout
      if (
        expDate.getTime() < new Date().getTime() ||
        _toLower(resData?.message) === 'invalid token'
      ) {
        window.modalEvent = new CustomEvent('token-expired', {
          detail: { isExpired: true },
        });
        window.dispatchEvent(window.modalEvent);
        return;
      }

      // We should first try the global "using refresh token" lock
      const startLock = new Date();
      /* eslint-disable-next-line no-constant-condition */
      while (true) {
        /* eslint-disable-next-line */
        if (localStorage.getItem('refresh-token-lock') == 1) {
          await waitms(250);

          const newRefreshToken = localStorage.getItem('refresh-token');
          if (newRefreshToken !== refreshToken) {
            // Looks like another promise managed to refresh the token!
            // Skip to the re-request.
            break;
          }

          // timeout the lock (1s)
          const now = new Date();
          if (now.valueOf() - startLock.valueOf() > 1000) {
            console.error('Timeout');
            return res;
          }
        } else {
          localStorage.setItem('refresh-token-lock', 1);

          try {
            // Use the refresh token
            const { useRefreshToken } = await import('src/backend/auth');
            const newTokens = await useRefreshToken(refreshToken);
            setSession(newTokens.accessToken, newTokens.refreshToken, {
              noRevoke: true,
            });
          } catch (err) {
            // We cannot renew the session. Logout and return the original request result.
            console.error(err);
            setSession(null, null, { noRevoke: true });

            localStorage.setItem('refresh-token-lock', 0);
            return res;
          }

          // We can retry the request now
          localStorage.setItem('refresh-token-lock', 0);
          break;
        }
      }

      // Now re-run the request
      return doFetch();
    }

    return res;
  } catch (err) {
    // If the request is cancelled due to page reload, we get "NetworkError" as error message,
    // To differentiate between cancelled request and actual network we use window.navigator.onLine
    if (err.message.includes('NetworkError') && window.navigator.onLine) {
      return {};
    } else {
      throw err;
    }
  }
}

/*
 * Verify a response is not erroneous.
 */
export async function assertErrors(res, allowNon200 = false) {
  if (res.status !== 200) {
    let body;
    try {
      body = await res.json();
    } catch {
      throw new Error(res.statusText);
    }

    if (body.error) {
      throw new Error(body.message);
    } else {
      if (allowNon200) return;
      throw new Error(res.statusText);
    }
  }
}

export async function parseBody(res, skipErrorCheck = false) {
  if (res.status === undefined) {
    // res.status is undefined for requests that were cancelled due to reload.
    return {};
  }

  const body = await res.json();
  if (!skipErrorCheck) {
    if (body.error) throw new Error(body.message || body.errorObj.message);
    if (res.status !== 200) throw new Error(res.statusText);
  }

  return body;
}

/**
 * Arbitrarily fetches the backend API.
 * @param {string} route
 * @param {string} method
 * @param {Object} data
 * @returns Promise<Response>
 */
export async function uploadFileAPI(route, method, data) {
  const headers = {
    ...(window.localStorage.getItem('token')
      ? { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      : {}),
    Accept: '*/*',
    'Content-Type': 'multipart/form-data; boundary=CUSTOM',
    'Access-Control-Allow-Origin': '*',
  };
  return await axios.post(`${route}`, data, {
    headers: headers,
  });
}
