import { format, formatDistanceToNow } from 'src/utils/date-fns';

// ----------------------------------------------------------------------

export function fMonth(date) {
  return format(date, 'MMMM yyyy');
}

export function fDate(date) {
  return format(date, 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(date, 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(date, 'dd/MM/yyyy p');
}

export function fToNow(date) {
  return formatDistanceToNow(date, {
    addSuffix: true,
  });
}
