import { Box, Drawer, IconButton } from '@mui/material';
import Iconify from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';

import { useTheme, styled } from '@mui/material/styles';
import { useState } from 'react';
import useChatClient from 'src/hooks/useChatClient';
import useResponsive from 'src/hooks/useResponsive';

import ChatRoomAttachments from './ChatRoomAttachments';
import ChatRoomSingle from './ChatRoomSingle';
import ChatRoomGroup from './ChatRoomGroup';
import ChatRoomDetails from './ChatRoomDetails';

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => (
  <IconButton disableRipple {...props} />
))(({ theme }) => ({
  right: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  top: theme.spacing(1),
  boxShadow: theme.customShadows.z8,
  backgroundColor: theme.palette.background.paper,
  border: `solid 1px ${theme.palette.divider}`,
  borderRight: 0,
  borderRadius: `12px 0 0 12px`,
  transition: theme.transitions.create('all'),
  '&:hover': {
    backgroundColor: theme.palette.background.neutral,
  },
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 240;

export default function ChatRoom() {
  const { conversationParticipants, activeConversation: conversation } =
    useChatClient();
  const participants =
    conversation && conversationParticipants[conversation.sid];
  if (!conversation || !participants) {
    return null;
  }

  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const isGroup = participants.length > 1;

  const [openNav, setOpenNav] = useState(false);
  const [openInfo, setOpenInfo] = useState(true);
  const [openAttachments, setOpenAttachments] = useState(true);
  const [openDetails, setOpenDetails] = useState(true);
  const [openParticipants, setOpenParticipants] = useState(true);

  const onCloseNav = () => {
    setOpenNav(false);
  };

  const renderContent = (
    <>
      {isGroup ? (
        <ChatRoomGroup
          participants={participants}
          isCollapse={openParticipants}
          onCollapse={() => setOpenParticipants(!openParticipants)}
        />
      ) : (
        <ChatRoomSingle
          participant={participants[0]}
          isCollapse={openInfo}
          onCollapse={() => setOpenInfo(!openInfo)}
        />
      )}

      <Box>
        <ChatRoomDetails
          conversation={conversation}
          isCollapse={openDetails}
          onCollapse={() => setOpenDetails(!openDetails)}
        />
      </Box>

      <Box display="none">
        <ChatRoomAttachments
          conversation={conversation}
          isCollapse={openAttachments}
          onCollapse={() => setOpenAttachments(!openAttachments)}
        />
      </Box>
    </>
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledToggleButton
        onClick={() => setOpenNav(!openNav)}
        sx={openNav && isDesktop ? { right: NAV_WIDTH } : {}}
      >
        <Iconify
          width={16}
          icon={openNav ? arrowIosForwardFill : arrowIosBackFill}
        />
      </StyledToggleButton>

      {isDesktop ? (
        <Drawer
          open={openNav}
          anchor="right"
          variant="persistent"
          PaperProps={{
            sx: {
              width: 1,
              position: 'static',
            },
          }}
          sx={{
            height: 1,
            width: NAV_WIDTH,
            transition: theme.transitions.create('width'),
            ...(!openNav && {
              width: 0,
            }),
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
