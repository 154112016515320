// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function LogoIcon({ ...other }) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/careit_icon.svg"
      height={40}
      {...other}
    />
  );
}
