import useAuth from '../hooks/useAuth';
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import { BUCKET_NAME, S3_BASE_URL } from 'src/config';

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user?.image ? `${S3_BASE_URL}/${BUCKET_NAME}/${user.image}` : ''}
      alt={user.name}
      color={user.image ? 'default' : createAvatar(user.name).color}
      {...other}
    >
      {createAvatar(user.name).name}
    </MAvatar>
  );
}
