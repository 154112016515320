import { fetchAPI, METHODS, parseBody } from './common';
import * as Sentry from '@sentry/react';

/**
 * @typedef UploadConfig
 * @property {string} folder
 * @property {string} filetype

/**
 * Uploads an image to an S3 bucket, returns it's url.
 * @param {File} file 
 * @param {UploadConfig} config 
 */
export async function upload(file, config, path = 'donation') {
  try {
    // get signed url
    const res = await fetchAPI('/image/signed-url', METHODS.post, config);
    let { url, fileName } = await parseBody(res);

    // upload image
    const blobData = new Blob([await file.arrayBuffer()], { type: file.type });
    const uploadRes = await fetch(url, { method: METHODS.put, body: blobData });
    if (uploadRes.status !== 200) {
      Sentry.captureException(
        new Error(
          `Error while uploading file to s3; Status: ${uploadRes.status}; Status text: ${uploadRes.statusText}`
        )
      );
    }

    return `${path}/${fileName}`;
  } catch (err) {
    Sentry.captureException(
      new Error(`Error while uploading file to s3; Message: ${err.message}`)
    );
  }
}
