import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { org } from 'src/backend';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from './useAuth';

// ----------------------------------------------------------------------

/**
 * @typedef UseBusiness
 * @property {Business[]} business
 * @property {boolean} isLoading
 */

/**
 * Encapsulates business data state management & logic, returning that logic's
 * state.
 * @returns {UseBusiness}
 */
export default function useBusiness({
  history,
  user,
  isNonProfit,
  allOrgs,
  fetchOnMount = true,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    user: { is_executive },
  } = useAuth();

  const [cachedOrgs, setCachedOrgs] = useState(null);
  const [orgsInCountry, setOrgsInCountry] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [matchingOrgLoading, setMatchingOrgLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [NPOTypes, setNPOTypes] = useState([]);
  const [postOrgLoading, setPostOrgLoading] = useState(false);
  const [updateOrgLoading, setUpdateOrgLoading] = useState(false);
  const [nearbyNPOs, setNearbyNPOs] = useState([]);
  const [nearbyDonors, setNearbyDonors] = useState([]);

  useEffect(() => {
    if (user && user.province && fetchOnMount) {
      setIsLoading(true);
      org
        .getOrgsByProvince({
          province: user.province,
          isNonProfit,
        })
        .then((business) => {
          setCachedOrgs(business);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          enqueueSnackbar(
            'An error occurred while trying to fetch the businesses. Please try again later.',
            { variant: 'error' }
          );
        });
      org
        .getOrgsByCountry({
          country: user.country,
          isNonProfit,
          allOrgs,
        })
        .then((business) => {
          setOrgsInCountry(business);
        })
        .catch(() => {
          enqueueSnackbar(
            'An error occurred while trying to fetch the businesses. Please try again later.',
            { variant: 'error' }
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, user, isNonProfit]);

  const searchExistingOrgs = (data) => {
    setMatchingOrgLoading(true);
    org
      .searchExistingOrgs(data)
      .then((data) => {
        if (data.isBusinessAvailable) {
          enqueueSnackbar('A business already exists in that location.', {
            variant: 'warning',
          });

          if (is_executive) {
            history.push(
              PATH_DASHBOARD.executive.viewOrg(data.business.team_id)
            );
          } else {
            history.push(PATH_DASHBOARD.viewOrg(data.business.team_id));
          }
        }

        setMatchingOrgLoading(false);
      })
      .catch(() => {
        setMatchingOrgLoading(false);
        enqueueSnackbar(
          'An error occurred while trying to fetch the businesses. Please try again later.',
          { variant: 'error' }
        );
      });
  };

  const postOrgData = async (values) => {
    setPostOrgLoading(true);
    return org
      .postOrg(values)
      .then((data) => {
        setPostOrgLoading(false);
        enqueueSnackbar('Organization is created successfully', {
          variant: 'success',
        });

        if (is_executive) {
          history.push(PATH_DASHBOARD.executive.viewOrg(data.team_id));
        } else {
          history.push(PATH_DASHBOARD.viewOrg(data.team_id));
        }
      })
      .catch(() => {
        setPostOrgLoading(false);
        enqueueSnackbar(
          'An error occurred while trying to submit the business data. Please try again later.',
          { variant: 'error' }
        );
      });
  };

  const updateOrgData = (values) => {
    setUpdateOrgLoading(true);
    org
      .updateBusiness(values)
      .then(() => {
        setUpdateOrgLoading(false);
        enqueueSnackbar('Business is updated successfully', {
          variant: 'success',
        });
        if (history) {
          history.push(
            isNonProfit ? PATH_DASHBOARD.npo : PATH_DASHBOARD.business
          );
        }
      })
      .catch(() => {
        setUpdateOrgLoading(false);
        enqueueSnackbar(
          'An error occurred while trying to submit the business data. Please try again later.',
          { variant: 'error' }
        );
      });
  };

  useEffect(() => {
    if (fetchOnMount) {
      if (isNonProfit) {
        org
          .getNPOTypes()
          .then((data) => {
            setNPOTypes(data.NPOTypes);
          })
          .catch(() => {
            enqueueSnackbar(
              'An error occurred while trying to fetch the NPO types. Please try again later.',
              { variant: 'error' }
            );
          });
      } else {
        org
          .getBusinessTypes()
          .then((data) => {
            setBusinessTypes(data.businessTypes);
          })
          .catch(() => {
            enqueueSnackbar(
              'An error occurred while trying to fetch the business types. Please try again later.',
              { variant: 'error' }
            );
          });
      }
    }
  }, [enqueueSnackbar, isNonProfit]);

  const getOrgById = ({ id }) => {
    org
      .getOrgById({ id })
      .then((data) => setSelectedOrg(data))
      .catch(() => {
        enqueueSnackbar(
          'An error occurred while trying to fetch the organization. Please try again later.',
          { variant: 'error' }
        );
      });
  };

  const getNearByNPOs = ({ lat, lng, includeSelf = false }) => {
    org
      .getNearByNPOs({ lat, lng, includeSelf })
      .then((data) => {
        setNearbyNPOs(data);
      })
      .catch(() => {
        enqueueSnackbar(
          'An error occurred while trying to fetch the nearby organizations. Please try again later.',
          { variant: 'error' }
        );
      });
  };

  const getNearByDonors = ({ lat, lng }) => {
    org
      .getNearByDonors({ lat, lng })
      .then((data) => {
        setNearbyDonors(data);
      })
      .catch(() => {
        enqueueSnackbar(
          'An error occurred while trying to fetch the nearby organizations. Please try again later.',
          { variant: 'error' }
        );
      });
  };

  return {
    orgs: cachedOrgs ?? [],
    searchExistingOrgs,
    isLoading,
    matchingOrgLoading,
    businessTypes,
    postOrgData,
    postOrgLoading,
    updateOrgData,
    updateOrgLoading,
    getOrgById,
    selectedOrg,
    NPOTypes,
    getNearByNPOs,
    nearbyNPOs,
    getNearByDonors,
    nearbyDonors,
    orgsInCountry,
  };
}
