/**
 * @typedev PageState
 * @property {number} page
 * @property {number} pageSize
 */

/**
 * Pagination mixin for the `TableView component`.
 * See `src/views/Donations/Donations.js` for an example of it's usage
 * @param {PageState} pageState
 * @param {(PageState) => any} setPageState
 * @param {number} count
 */
export function withPagination(pageState, setPageState, count, invalidate) {
  return {
    pagination: true,
    paginationMode: 'server',
    ...pageState,
    onPageChange: (page) => {
      setPageState((prev) => ({ ...prev, page }));
      invalidate();
    },
    onPageSizeChange: (pageSize) => {
      setPageState((prev) => ({ ...prev, pageSize }));
      invalidate();
    },
    rowsPerPageOptions: [25, 50, 100],
    rowCount: count,
  };
}
