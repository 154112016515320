import { useMemo } from 'react';
import { Box, MenuItem, TextField } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { determineDefaultUnit } from 'careit';
import { useTranslation } from 'react-i18next';

export const WEIGHT_UNITS = [
  {
    value: 'lbs',
    label: 'lbs',
    default: (user) =>
      determineDefaultUnit({
        account_type: user.account_type,
        country: user.country,
        isCorporate: user.is_corporate,
      }),
  },
  {
    value: 'kgs',
    label: 'kgs',
  },
  {
    value: 'meals',
    label: 'meals',
  },
];

export const TEMP_UNITS = [
  {
    value: 'f',
    label: '°F',
    default: (user) =>
      determineDefaultUnit({
        account_type: user.account_type,
        country: user.country,
        isCorporate: user.is_corporate,
      }),
  },
  {
    value: 'c',
    label: '°C',
  },
];

export const DISTANCE_UNITS = [
  {
    value: 'mi',
    label: 'miles',
    default: (user) =>
      determineDefaultUnit({
        account_type: user.account_type,
        country: user.country,
        isCorporate: user.is_corporate,
      }),
  },
  {
    value: 'km',
    label: 'km',
  },
];

/**
 * Get the default unit for a given user.
 *
 * The default unit is the first to satisfy the `default` check or not have a default check property.o
 *
 * This is used for localization
 */
export function getDefaultUnit(units, user) {
  return units.find((unit) =>
    typeof unit.default === 'function' ? unit.default(user) : true
  );
}

export const useMeasureFieldUnits = () => {
  const { t } = useTranslation('translation');
  const { user } = useAuth();
  const WEIGHT_UNITS = [
    {
      value: 'lbs',
      label: t('common.lbs'),
      default: (user) =>
        determineDefaultUnit({
          account_type: user.account_type,
          country: user.country,
          isCorporate: user.is_corporate,
        }),
    },
    {
      value: 'kgs',
      label: t('common.kgs'),
    },
    {
      value: 'meals',
      label: t('common.meals'),
    },
  ];

  const TEMP_UNITS = [
    {
      value: 'f',
      label: t('common.f'),
      default: (user) =>
        determineDefaultUnit({
          account_type: user.account_type,
          country: user.country,
          isCorporate: user.is_corporate,
        }),
    },
    {
      value: 'c',
      label: t('common.c'),
    },
  ];

  const DISTANCE_UNITS = [
    {
      value: 'mi',
      label: t('common.mi'),
      default: (user) =>
        determineDefaultUnit({
          account_type: user.account_type,
          country: user.country,
          isCorporate: user.is_corporate,
        }),
    },
    {
      value: 'km',
      label: t('common.km'),
    },
  ];

  const getDefaultUnit = (units) => {
    return units.find((unit) => {
      if (typeof unit.default === 'function') {
        return unit.default(user);
      }
      return true;
    });
  };

  return {
    WEIGHT_UNITS,
    TEMP_UNITS,
    DISTANCE_UNITS,

    defaultWeightUnit: getDefaultUnit(WEIGHT_UNITS),
    defaultTemperatureUnit: getDefaultUnit(TEMP_UNITS),
    defaultDistanceUnit: getDefaultUnit(DISTANCE_UNITS),
    getDefaultUnit,
  };
};

/**
 * A form field specialized for measures (weight, temperature).
 *
 * This field allows the user to configure both a weight value and
 * a unit. The value of this component takes the form (in ts syntax):
 *
 * ```
 * type MeasureFieldState<T> = {
 *   value: number,
 *   unit: T,
 * };
 *
 * // where T is any of:
 * type Weight = 'lbs' | 'kgs' | 'meals';
 * type Temperature = 'c' | f';
 * ```
 *
 * # Example
 *
 * ```
 *  function MyComponent() {
 *    const [state, setState] = React.useState({
 *      value: '',
 *      unit: 'lbs',
 *    });
 *
 *    return <MeasureField value={state} onChange={setState} units={WEIGHT_UNITS}/>
 *  }
 * ```
 */
export default function MeasureField({
  label,
  value,
  name,
  onChange,
  units,
  disabled,
  error,
  helperText,
  ...props
}) {
  const { t } = useTranslation('translation');
  const { user } = useAuth();
  const { getDefaultUnit } = useMeasureFieldUnits();
  const DEFAULT_UNIT = useMemo(
    () => getDefaultUnit(units, user)?.value,
    [units, user]
  );

  const handleChange = (prop) => (event) => {
    let inputValue = event.target.value;
    if (inputValue === '.') {
      inputValue = `0.`;
    }
    onChange({
      value: value?.value ?? 0,
      unit: Number.isFinite(parseFloat(value?.value))
        ? value?.unit ?? DEFAULT_UNIT
        : DEFAULT_UNIT,
      [prop]: inputValue,
    });
  };

  // display an error if `value.value` is not a valid number
  const internalError = useMemo(() => {
    if (['', null, undefined].includes(value?.value)) {
      return null;
    }

    // Regex matches '10', '3.221' but not 'a', '1.3e+2' or '1.2.3'.
    if (new RegExp(/^-?[0-9]+(\.[0-9]+)?$/).test(value?.value)) {
      return null;
    }

    return t('donations.must-be-a-number');
  }, [value?.value]);

  return (
    <Box {...props} sx={{ display: 'flex', ...(props.sx ?? {}) }}>
      <TextField
        name={name}
        label={label}
        value={value?.value ?? ''}
        onChange={handleChange('value')}
        sx={{
          flex: 1,
          '& > div': { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
        }}
        error={error || internalError}
        inputProps={{ inputMode: 'decimal' }}
        size="small"
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
        helperText={helperText}
      />
      <TextField
        select
        value={
          Number.isFinite(parseFloat(value?.value))
            ? value?.unit ?? DEFAULT_UNIT
            : DEFAULT_UNIT
        }
        onChange={handleChange('unit')}
        sx={{
          '& > div': { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
        }}
        error={error || internalError}
        size="small"
        onSelect={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
      >
        {units.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
