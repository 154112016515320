import { getLocaleUnit } from 'careit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from './hooks/useAuth';

/**
 * Prefer `useFoodTypes` which handles localization.
 * @deprecated
 */
export const FOOD_TYPES = [
  { title: 'Dairy', value: 'DAIRY', key: 'dairy' },
  { title: 'Meat', value: 'MEAT', key: 'meat' },
  { title: 'Produce', value: 'PRODUCE', key: 'produce' },
  { title: 'Prepared', value: 'PREPARED', key: 'prepared' },
  { title: 'Non-Perishable', value: 'NON-PERISHABLE', key: 'non_perishable' },
  { title: 'Bakery', value: 'BAKED', key: 'baked' },
  { title: 'Beverages', value: 'BEVERAGES', key: 'beverages' },
  { title: 'Non-Food', value: 'NON-FOOD', key: 'non_food' },
  { title: 'Frozen', value: 'FROZEN', key: 'frozen' },
  { title: 'Compost', value: 'COMPOST', key: 'compost' },
  { title: 'Unusable', value: 'UNUSABLE', key: 'unusable' },
  { title: 'Animal Feed', value: 'ANIMAL-FEED', key: 'animal_feed' },
];

export function useFoodTypes() {
  const { t, i18n } = useTranslation('translation');

  return useMemo(
    () => [
      { title: t('data.food-types.dairy'), value: 'DAIRY', key: 'dairy' },
      { title: t('data.food-types.meat'), value: 'MEAT', key: 'meat' },
      { title: t('data.food-types.produce'), value: 'PRODUCE', key: 'produce' },
      {
        title: t('data.food-types.prepared'),
        value: 'PREPARED',
        key: 'prepared',
      },
      {
        title: t('data.food-types.non-perishable'),
        value: 'NON-PERISHABLE',
        key: 'non_perishable',
      },
      { title: t('data.food-types.baked'), value: 'BAKED', key: 'baked' },
      {
        title: t('data.food-types.beverages'),
        value: 'BEVERAGES',
        key: 'beverages',
      },
      {
        title: t('data.food-types.non-food'),
        value: 'NON-FOOD',
        key: 'non_food',
      },
      { title: t('data.food-types.frozen'), value: 'FROZEN', key: 'frozen' },
      { title: t('data.food-types.compost'), value: 'COMPOST', key: 'compost' },
      {
        title: t('data.food-types.unusable'),
        value: 'UNUSABLE',
        key: 'unusable',
      },
      {
        title: t('data.food-types.animal-feed'),
        value: 'ANIMAL-FEED',
        key: 'animal_feed',
      },
    ],
    [i18n.language]
  );
}

export const roles = {
  admin: 'ADMIN',
  staff: 'STAFF',
  driver: 'DRIVER',
};

export const RESCUE_STATUSES = {
  ASSIGNED: 'ASSIGNED',
  CANCELLED: 'CANCELLED',
  DELIVERY_IN_PROGRESS: 'DELIVERY_IN_PROGRESS',
  INCOMPLETE: 'INCOMPLETE',
  PICKUP_IN_PROGRESS: 'PICKUP_IN_PROGRESS',
  STARTED: 'STARTED',
  WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
  WAITING_FOR_REASSIGN: 'WAITING_FOR_REASSIGN',
};

export const DONATION_STATUSES = {
  OPEN: 'OPEN',
  RESERVED: 'RESERVED',
  IN_PROGRESS: 'IN-PROGRESS',
  RESCUED: 'RESCUED',
  CANCELLED: 'CANCELLED',
};

export const adornment = {
  canada: {
    title: `What's a Business Number`,
    description: `A business number is a 9 digit number that is assigned
    to businesses for tax identification. We use this to
    distinguish between business identities`,
  },
  usa: {
    title: `What's an EIN?`,
    description: `An EIN is a 9 digit number that the IRS assigns to a
      business for tax identification. We use this to
      distinguish between business identities.`,
  },
};

export const VEHICLES = [
  { value: 'NO_VEHICLE', label: 'No Vehicle' },
  { value: 'CAR', label: 'Car' },
  { value: 'VAN', label: 'Van' },
  { value: 'TRUCK', label: 'Truck' },
];

export const DEFAULT_FILTER_MODEL = {
  items: [],
  linkOperator: 'and',
};

export const ONBOARDING_REQUIRED_ERORR =
  'You must complete required onboarding items before rescuing from this store.';

export const ALREADY_CLAIMED_ERROR =
  'This donation is already claimed by someone else.';

export const useGetVehicleLabels = () => {
  const { t } = useTranslation('translation');
  const { user } = useAuth();
  const { weight: unit } = getLocaleUnit(user);

  const carLabel = `${t('donations.car')} (${
    unit === 'lbs' ? '20-200 lbs' : '10-100 kgs'
  })`;
  const vanLabel = `${t('donations.van')} (${
    unit === 'lbs' ? '201-2000 lbs' : '101-1000 kgs'
  })`;
  const truckLabel = `${t('donations.truck')} (${
    unit === 'lbs' ? '2000+ lbs' : '1000+ kgs'
  })`;
  return [
    {
      value: 0,
      title: carLabel,
      label: carLabel,
      vehicle: 'CAR',
    },
    {
      value: 1,
      label: vanLabel,
      title: vanLabel,
      vehicle: 'VAN',
    },
    {
      value: 2,
      label: truckLabel,
      title: truckLabel,
      vehicle: 'TRUCK',
    },
  ];
};
