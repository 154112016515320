import { assertErrors, fetchAPI, METHODS, parseBody } from './common';
import _get from 'lodash/get';
import qs from 'qs';

/**
 * @typedef Donation
 * @property {string} id
 * @property {Date} start_date
 * @property {Date} end_date
 * @property {string} title
 * @property {string} description
 * @property {number} lat
 * @property {number} lng
 * @property {string} address
 * @property {string} place
 * @property {string} vehicle_id
 * @property {string} delivery_option_id
 * @property {string} status_id
 * @property {string} user_id
 * @property {boolean} is_rescue_complete
 * @property {Date} rescue_date
 * @property {Date} created
 * @property {boolean} is_deleted
 */

/**
 * Gets all donations associated with the account/team.
 * @returns Promise<Donation[]>
 */
export async function get({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/donation/all/get?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );
  const { donations } = await parseBody(res);
  return donations;
}

/**
 * Gets all donations associated with the account/team from the past week (LIMIT 100).
 * @returns Promise<Donation[]>
 */
export async function getRecent() {
  const res = await fetchAPI('/donation/recent', METHODS.get);
  const { donations } = await parseBody(res);
  return donations;
}

/**
 * Gets a donation by it' `id`.
 * @returns Promise<Donation>
 */
export async function getById(id) {
  const res = await fetchAPI(`/donation/${id}`, METHODS.get);
  await assertErrors(res);
  const [donation] = await parseBody(res);
  return donation;
}

/**
 * @typedef Paginated<T>
 * @property {T[]} results
 * @property {number} count
 */

/**
 * @typedef PageState
 * @property {number} step
 * @property {number} offset
 */

/**
 * Get's all public donations which need to be rescued.
 * @param {PageState} pageState
 * @returns {Promise<Paginated<T>>}
 */
export async function getAvailable(pageState) {
  const res = await fetchAPI(
    `/donation/available?${qs.stringify(pageState)}`,
    METHODS.get
  );
  const { donations, count } = await parseBody(res);
  return { results: donations, count };
}

/**
 * Gets all donations assigned to the current user.
 * @returns Promise<Donation[]>
 */
export async function allMine({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/donation/self/pagination/get?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );
  const donations = await parseBody(res);
  return donations;
}

/**
 * @typedef NewDonation
 * @property {number} vehicleSize 0, 1, 2 => "CAR", "VAN", "TRUCK"
 * @property {number} deliveryOption 0, 1, 2 => "SELF-DELIVER", "RECEIVER-PICKUP", "PAID-PREMIUM-PICKUP"
 * @property {string[]} selectedFoodTypes ["PREPARED" | "PRODUCE" | "NON-PERISHABLE"]
 * @property {Date} startTime
 * @property {Date} endTime
 * @property {string} title
 * @property {string} description
 * @property {number} location.lat
 * @property {number} location.lng
 * @property {string} location.formattedAddress
 * @property {string} location.suiteNumber
 * @property {string} location.city
 * @property {string} location.province
 * @property {string} location.country
 * @property {string} location.zipCode
 * @property {string} location.placeId
 * @property {string} location.place
 * @property {string} location.timeZoneId
 * @property {string[]} images
 */

/**
 * Creates a new donation.
 * @param {NewDonation} donation
 */
export async function create(donation) {
  const res = await fetchAPI('/donation', METHODS.post, donation);
  await parseBody(res);
}

/**
 * @typedef RecurringDonation
 * @property {string} donationId
 * @property {string} occurrenceType "recurring" or "onetime"
 * @property {Bool} isRecurring
 * @property {string} interval "Daily", "Weekly", "Bi-Weekly"
 * @property {Date} startDate YYYY-MM-DD
 * @property {Date} endDate YYYY-MM-DD
 * @property {Time} startTime HH:mm:ss
 * @property {Time} endTime HH:mm:ss
 * @property {Bool} isDST
 * @property {number} vehicleSize 0, 1, 2 => "CAR", "VAN", "TRUCK"
 * @property {number} deliveryOption 0, 1, 2 => "SELF-DELIVER", "RECEIVER-PICKUP", "PAID-PREMIUM-PICKUP"
 * @property {string[]} selectedFoodTypes ["PREPARED" | "PRODUCE" | "NON-PERISHABLE"]
 * @property {string} title
 * @property {string} description
 * @property {string} reservedByTeam
 * @property {string} driverId
 * @property {number} pickup_address.lat
 * @property {number} pickup_address.lng
 * @property {string} pickup_address.formattedAddress
 * @property {string} pickup_address.suiteNumber
 * @property {string} pickup_address.city
 * @property {string} pickup_address.province
 * @property {string} pickup_address.country
 * @property {string} pickup_address.zipCode
 * @property {string} pickup_address.placeId
 * @property {string} pickup_address.place
 * @property {string} pickup_address.timeZoneId
 * @property {number} dest_address.lat
 * @property {number} dest_address.lng
 * @property {string} dest_address.formattedAddress
 * @property {string} dest_address.suiteNumber
 * @property {string} dest_address.city
 * @property {string} dest_address.province
 * @property {string} dest_address.country
 * @property {string} dest_address.zipCode
 * @property {string} dest_address.placeId
 * @property {string} dest_address.place
 * @property {string} dest_address.timeZoneId
 * @property {string[]} images
 * @property {boolean} isFoodRun
 */

/**
 * Creates a new donation.
 * @param {RecurringDonation} donation
 */
export async function createRecurring(donation) {
  const res = await fetchAPI('/donation/recurring', METHODS.post, donation);
  return parseBody(res);
}

/**
 * Update Future recurring donations.
 * @param {RecurringDonation}
 */
export async function update(data) {
  const res = await fetchAPI('/donation/recurring', METHODS.put, data);
  return parseBody(res);
}

/**
 * Update single instance donation
 */
export async function updateSingleInstanceDonation(data) {
  const res = await fetchAPI('/donation/single-instance', METHODS.put, data);
  return parseBody(res);
}

/**
 * Gets Food Run.
 * @returns Promise<Donation[]>
 */
export async function foodRun() {
  const res = await fetchAPI('/donation/food-run', METHODS.get);
  const foodRuns = await parseBody(res);
  return foodRuns;
}

/**
 * Gets Food run.
 * @returns Promise<Donation[]>
 */
export async function unassignSingleFoodInstance(body) {
  const res = await fetchAPI(
    `/donation/food-run/unassign-runner`,
    METHODS.put,
    body
  );
  const result = await parseBody(res);
  return result;
}

export async function unassignAllFoodInstance(body) {
  const res = await fetchAPI(
    `/donation/food-run/unassign-runner-all-instances`,
    METHODS.put,
    body
  );
  const result = await parseBody(res);
  return result;
}

/**
 * assign food run.
 * @param {NewFoodRun} donation
 */
export async function assignFoodRun(body) {
  const res = await fetchAPI(
    '/donation/food-run/assign-runner-single-instance',
    METHODS.put,
    body
  );
  await parseBody(res);
}

/**
 * assign food run.
 * @param {NewFoodRun} donation
 */
export async function assignFoodRunAllInstances(body) {
  const res = await fetchAPI(
    '/donation/food-run/assign-runner-all-instance',
    METHODS.put,
    body
  );
  await parseBody(res);
}

/**
 * Fetched food run details by id.
 * @param {NewFoodRun} donation
 */
export async function getFoodRun({ id }) {
  const res = await fetchAPI(`/donation/food-run/${id}`, METHODS.get);
  await assertErrors(res);
  const result = await parseBody(res);
  return _get(result, '[0]', {});
}

export async function updateFoodRunStatus(data) {
  const res = await fetchAPI(`/donation/food-run/status`, METHODS.put, data);
  return parseBody(res);
}

export async function cancelFoodRunInstance(data) {
  const res = await fetchAPI(
    `/donation/food-run/single-instance`,
    METHODS.delete,
    data
  );
  return parseBody(res);
}

/**
 * Cancel Donation
 * @param {string} param0.donationId.
 * @param {string} param0.reasonId
 * @param {string} param0.otherReason
 */
export async function cancelDonation(data) {
  const res = await fetchAPI(
    `/donation/${data.donationId}`,
    METHODS.delete,
    data
  );
  return parseBody(res);
}

/**
 * Cancels all future instances of recurring donation
 * @param {string} parentId.
 * @param {string} reasonId
 * @param {string} otherReason
 */
export async function cancelRecurringDonations(data) {
  const res = await fetchAPI(`/donation/recurring`, METHODS.delete, data);
  return parseBody(res);
}

/**
 * Cancel donation single instance
 * @param {string} parentId.
 * @param {string} donationId.
 */
export async function cancelDonationInstance(data) {
  const res = await fetchAPI(`/donation/single-instance`, METHODS.delete, data);
  return parseBody(res);
}

/**
 * Fetched food run group details by id.
 * @param {NewFoodRun} donation
 */
export async function getRecurringPatternById({ id }) {
  const res = await fetchAPI(`/donation/recurring-pattern/${id}`, METHODS.get);
  const result = await parseBody(res);
  return result;
}

export async function getIncompleteDonations({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/donation/incomplete/get?${qs.stringify({
      offset,
      step,
    })}`,
    METHODS.post,
    { filterModel }
  );
  const result = await parseBody(res);
  return result;
}

/**
 * Insert rating for a team
 * @param {string} param0.rescueId
 * @param {string} param0.teamId
 * @param {number} param0.rating
 * @param {string} param0.notes
 * @returns {Promise<void>}
 */
export async function insertRating({ rescueId, teamId, rating, notes }) {
  const res = await fetchAPI(`/donation/rating`, METHODS.post, {
    rescueId,
    teamId,
    rating,
    notes,
  });
  return await parseBody(res);
}

/**
 * Fetch donation event logs
 * @param {string} param0.donationId
 */
export async function getDonationEventLogs({ donationId }) {
  const res = await fetchAPI(
    `/activity/donation/log/${donationId}`,
    METHODS.get
  );
  return await parseBody(res);
}

export async function createDonationImages({
  donationId,
  isRecurring,
  images,
}) {
  const res = await fetchAPI(`/donation/images`, METHODS.post, {
    isRecurring,
    donationId,
    images,
  });
  return await parseBody(res);
}

export async function convertToFoodRun(data) {
  const res = await fetchAPI(
    `/donation/convert-to-foodrun`,
    METHODS.post,
    data
  );
  return await parseBody(res);
}
