import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'src/utils/date-fns';
// @mui
import { Avatar, Box, Typography, Stack } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useChatClient from 'src/hooks/useChatClient';
import { useTranslation } from 'react-i18next';
// components

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  message: PropTypes.object,
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItem({ message, onOpenLightbox }) {
  const { t } = useTranslation('translation');

  const { conversationParticipants, activeConversation } = useChatClient();
  const participants =
    (activeConversation && conversationParticipants[activeConversation.sid]) ??
    [];
  const author = participants.find(
    ({ participant }) => participant.identity === message.author
  );
  const { user } = useAuth();

  const isImage = false;
  const firstName = author && author.user.friendlyName;
  const currentUser =
    author && message.author === `UID${user.id};TID${user.team_id}`;
  const senderDetails = {
    name: author && author.user.friendlyName,
    avatar: author && author.participant.attributes.avatar,
  };

  return (
    <Stack
      direction="row"
      justifyContent={currentUser ? 'flex-end' : 'unset'}
      sx={{ mb: 3 }}
    >
      {!currentUser && (
        <Avatar
          alt={senderDetails.name}
          src={senderDetails.avatar}
          sx={{ width: 32, height: 32, mr: 2 }}
        />
      )}

      <Stack spacing={1} alignItems="flex-end">
        <Typography
          noWrap
          variant="caption"
          sx={{
            color: 'text.disabled',
            ...(!currentUser && {
              mr: 'auto',
            }),
          }}
        >
          {!currentUser ? firstName : t('chat.you')}, &nbsp;
          {formatDistanceToNowStrict(message.dateCreated, {
            addSuffix: true,
          })}
        </Typography>

        <Stack
          sx={{
            p: 1.5,
            minWidth: 48,
            maxWidth: 320,
            borderRadius: 1,
            overflow: 'hidden',
            typography: 'body2',
            bgcolor: 'background.neutral',
            ...(currentUser && {
              color: 'grey.800',
              bgcolor: 'primary.lighter',
            }),
            ...(isImage && {
              p: 0,
            }),
          }}
        >
          {isImage ? (
            <Box
              component="img"
              alt="attachment"
              src={message.body}
              onClick={() => onOpenLightbox(message.body)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.9,
                },
              }}
            />
          ) : (
            message.body
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
