import PropTypes from 'prop-types';
// @mui
import { InputAdornment, ClickAwayListener, TextField } from '@mui/material';
// components
import Iconify from '@iconify/react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

ChatNavSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClickAway: PropTypes.func,
};

export default function ChatNavSearch({ value, onChange, onClickAway }) {
  const { t } = useTranslation('translation');
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <TextField
        fullWidth
        size="small"
        value={value}
        onChange={onChange}
        placeholder={t('chat.search-conversation')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
        sx={{ mt: 2.5 }}
      />
    </ClickAwayListener>
  );
}
