import { Box, Button } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { PATH_DASHBOARD, PATH_TRAINING } from 'src/routes/paths';
import { DocumentProvider } from 'src/views/TrainingEditor/y';
import useProgess, { ProgressProvider } from 'src/hooks/useProgress';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

function TrainingInner({ children }) {
  const history = useHistory();
  const { section } = useProgess();

  useEffect(() => {
    const sectionPaths = {
      start: PATH_TRAINING.start,
      lesson: PATH_TRAINING.lesson,
      exam: PATH_TRAINING.exam,
      review: PATH_TRAINING.examReview,
      waiver: PATH_TRAINING.waiver,
      complete: PATH_TRAINING.complete,
    };

    if (!section) return;
    if (history.location.pathname === sectionPaths[section]) return;

    history.push(sectionPaths[section]);
  }, [section, history, history.location.pathname]);

  return children;
}

export default function TrainingLayout({ children }) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const handleBack = () => history.push(PATH_DASHBOARD.root);

  return (
    <Box sx={{ height: '100%' }}>
      <ProgressProvider>
        <DocumentProvider>
          <TrainingInner>{children}</TrainingInner>
        </DocumentProvider>
      </ProgressProvider>

      <Box position="fixed" top={0} left={0} padding="25px">
        <Button onClick={handleBack} startIcon={<ChevronLeft />}>
          {t('training-program.back-to-dashboard')}
        </Button>
      </Box>
    </Box>
  );
}
