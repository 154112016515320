import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { user } from 'src/backend';

// ----------------------------------------------------------------------

/**
 * @typedef Invites
 * @property {Invite[]} invites
 * @property {boolean} isLoading
 * @property {Function} invalidate
 */

/**
 * Encapsulates the user's pending invites state management & logic, returning
 * that logic's state.
 * Note: invites is never null, but an empty array if there is no data.
 * @returns {Invites}
 */
export default function useInvites(email) {
  const { enqueueSnackbar } = useSnackbar();
  const [cachedInvites, setCachedInvites] = useState(null);

  useEffect(() => {
    if (cachedInvites) return;
    if (!email) return;

    user
      .getInvites(email)
      .then((invites) => {
        if (!Array.isArray(invites)) {
          setCachedInvites([]);
          return;
        }
        setCachedInvites(invites);
      })
      .catch((error) => {
        // Check if the error response contains a specific message indicating no invites
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === 'There are no pending invites'
        ) {
          setCachedInvites([]); // There are no invites, handle as empty list
        } else {
          // Handle other kinds of errors
          enqueueSnackbar(
            'An error occurred while trying to fetch your invites. Please try again later.',
            { variant: 'error' }
          );
        }
      });
  }, [enqueueSnackbar, email, cachedInvites]);

  const invalidate = () => setCachedInvites(null);

  return {
    invites: cachedInvites ?? [],
    isLoading: cachedInvites === null,
    invalidate,
  };
}
