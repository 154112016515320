import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import authReducer from './slices/auth';
import documentReducer from './slices/document';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import onboardingReducer from './slices/onboarding';
import pageFilters from './slices/pageFilters';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings', 'pageFilters'],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  document: documentReducer,
  mail: mailReducer,
  blog: blogReducer,
  user: userReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  onboarding: onboardingReducer,
  product: persistReducer(productPersistConfig, productReducer),
  pageFilters,
});

export { rootPersistConfig, rootReducer };
