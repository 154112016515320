import { createContext, useContext, useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import _random from 'lodash/random';
import {
  amber,
  blue,
  blueGrey,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { executive } from 'src/backend';
import useAuth from './useAuth';
import { useGetSelectedGeofences } from './teamGeofences';

const GeofenceContext = createContext(null);

const randomColor = () => {
  const colors = [
    amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
  ];

  const shades = [500, 600, 700, 800, 900];

  return colors[_random(0, colors.length - 1)][
    shades[_random(0, shades.length - 1)]
  ];
};

export function GeofenceProvider({ children }) {
  const { user } = useAuth();

  const { data: activeGeofences = [], refetch: refetchActiveGeofences } =
    useGetSelectedGeofences({
      refetchOnWindowFocus: false,
    });

  const [active, setActive] = useState(new Set([]));
  const [cache, setCache] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!user?.is_executive) {
      setCache([]);
      return;
    }

    executive
      .getAllGeofences()
      .then(setCache)
      .catch(() =>
        enqueueSnackbar('There was an error fetching your geozones.', {
          variant: 'error',
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const geofences = useMemo(
    () =>
      (cache ?? []).map(({ points, aabb, ...geofence }) => ({
        points: typeof points === 'string' ? JSON.parse(points) : points,
        aabb: typeof aabb === 'string' ? JSON.parse(aabb) : aabb,
        strokeColor: randomColor(),
        ...geofence,
      })),
    [cache]
  );

  useEffect(() => {
    setActive(new Set(activeGeofences.map((item) => item.id)));
  }, [JSON.stringify(activeGeofences)]);
  return (
    <GeofenceContext.Provider
      value={{
        geofences,
        isLoading: cache === undefined,
        setCache,
        active,
        activeGeofences,
        refetchActiveGeofences,
      }}
    >
      {children}
    </GeofenceContext.Provider>
  );
}

export default function useGeofences() {
  const {
    geofences,
    isLoading,
    setCache,
    active,
    activeGeofences,
    refetchActiveGeofences,
  } = useContext(GeofenceContext);

  return {
    geofences,
    activeGeofences,
    refetchActiveGeofences,
    isLoading,
    setCache,
    active,
  };
}
