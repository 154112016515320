import moment from 'moment-timezone';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keys from 'lodash/keys';
import { constructUTCTimes } from 'careit';

const processDaysOfTheWeek = (data) => {
  return _map(
    _filter(_keys(data), (key) => key.includes('repeat') && data[key]),
    (key) => Number(key.replace('repeat-', ''))
  );
};

const processRecurringIntervalData = (data) => {
  const { startDate } = data;
  //Check if the start date falls on DST
  const isDST = moment.tz(startDate, data.timezone_id).hours(11).isDST();

  const utc = constructUTCTimes(data);

  return {
    isRecurring: ['recurring', 'indefinite'].includes(data.occurrenceType),
    interval: data.interval,
    daysOfTheWeek: processDaysOfTheWeek(data),
    startDate: utc.startDate,
    endDate: utc.endDate,
    startTime: utc.startTime,
    endTime: utc.endTime,
    isDST,
    occurrenceType: data.occurrenceType,
  };
};

/**
 * Given an interval, and a moment object of the start date for that interval,
 * change the end date to a time which is more reasonable for that interval.
 *
 * This can be useful, for example, when the user changes the interval from
 * daily to monthly. In that case, it does not make sense for the end date to
 * be only 1 day after the start date.
 *
 * @param {string} Interval
 * @param {moment} endDate This is mutated in-place.
 */
const recomputeEndDate = (interval, endDate) => {
  switch (interval) {
    case 'Daily':
      endDate.add(1, 'day');
      break;
    case 'Weekly':
      endDate.add(1, 'week');
      break;
    case 'Bi-weekly':
      endDate.add(2, 'weeks');
      break;
    case 'Monthly':
      endDate.add(1, 'month');
      break;
    default: // The above cases should be exhaustive
      throw new Error('Unreachable');
  }
};

export {
  processRecurringIntervalData,
  constructUTCTimes,
  processDaysOfTheWeek,
  recomputeEndDate,
};
