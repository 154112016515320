import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  Badge,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import { Task, ArrowRightAlt } from '@mui/icons-material';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import usePrereqs from 'src/hooks/usePrereqs';
import { useTranslation } from 'react-i18next';

PrereqItem.propTypes = {
  prereq: PropTypes.object.isRequired,
};

function PrereqItem({ prereq }) {
  return (
    <ListItem
      button
      {...(prereq.location
        ? {
            to: prereq.location,
            component: RouterLink,
          }
        : {
            onClick: prereq.action,
          })}
      sx={{
        py: 1.5,
        px: 2.5,
        '&:not(:last-of-type)': { mb: '1px' },
        ...(prereq.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemText
        primary={
          <Typography variant="subtitle2">{prereq.displayName}</Typography>
        }
        secondary={
          <Typography variant="body2">{prereq.description}</Typography>
        }
      />
      <ListItemAvatar>
        <ArrowRightAlt color="primary" />
      </ListItemAvatar>
    </ListItem>
  );
}

export default function PrereqsPopover() {
  const { t } = useTranslation('translation');
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const prereqs = usePrereqs();

  if (prereqs.length === 0) {
    return null;
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        color={open ? 'primary' : 'default'}
      >
        <Badge badgeContent={prereqs.length} color="error">
          <Task />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {t('finish-profile.title')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('finish-profile.pending-tasks', { count: prereqs.length })}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding>
            {prereqs.map((prereq, i) => (
              <PrereqItem key={i} prereq={prereq} />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
