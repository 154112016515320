import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { team } from 'src/backend';

// ----------------------------------------------------------------------

/**
 * @typedef TeamMembers
 * @property {TeamMember[]} teamMembers
 * @property {boolean} isLoading
 * @property {Function} invalidate
 */

/**
 * Encapsulates team member data state management & logic, returning that
 * logic's state.
 * Note: teamMembers is never null, but an empty array if there is no data.
 * @returns {TeamMembers}
 */
export default function useTeamMembers(
  { fetchOnMount = true } = { fetchOnMount: true }
) {
  const { enqueueSnackbar } = useSnackbar();

  const [cachedTeamMembers, setCachedTeamMembers] = useState();

  const fetchData = useCallback(() => {
    team
      .get()
      .then(setCachedTeamMembers)
      .catch(() => {
        enqueueSnackbar(
          'An error occurred while trying to fetch your team members. Please try again later.',
          { variant: 'error' }
        );
      });
  }, [setCachedTeamMembers, enqueueSnackbar]);

  useEffect(() => {
    if (fetchOnMount) {
      fetchData();
    }
  }, []);

  return {
    teamMembers: cachedTeamMembers ?? [],
    isLoading: !cachedTeamMembers,
    invalidate: fetchData,
  };
}
