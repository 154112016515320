import { fetchAPI, METHODS, parseBody, assertErrors } from './common';

/**
 * Get an auth token for use by a twilio conversations client.
 *
 * This can be saved in localStorage or a similar store and then may be reused
 * until twilio returns a 401.
 *
 * @returns {Promise<string>} The chat token (a JWT).
 */
export async function getAuthToken() {
  const res = await fetchAPI('/chat/access-token', METHODS.get);
  await assertErrors(res);
  const { chatToken } = await parseBody(res);
  return chatToken;
}

/**
 * Get (or create if necessary) the team-wide conversation.
 *
 * @returns {Promise<string>} The sid of the team conversation.
 */
export async function getTeamConversation() {
  const res = await fetchAPI('/chat/conversation/team', METHODS.get);
  await assertErrors(res);
  const { conversationId } = await parseBody(res);
  return conversationId;
}

export async function getAdminTeamConversation() {
  const res = await fetchAPI('/chat/conversation/admin-team', METHODS.get);
  await assertErrors(res);
  const { conversationId } = await parseBody(res);
  return conversationId;
}

/**
 * Get (or create if necessary) the conversation for a donation.
 *
 * @param {string} donationId
 * @returns {Promise<string>} The sid of the team conversation.
 */
export async function getDonationConversation(donationId) {
  const res = await fetchAPI(
    `/chat/conversation/donation/${donationId}`,
    METHODS.get
  );
  await assertErrors(res);
  const { conversationId } = await parseBody(res);
  return conversationId;
}

/**
 * Turn off push notification for a participant in a conversation
 *
 * @param {string} param0.conversationId
 * @param {string} param0.participantId
 * @returns {Promise<void>}
 */
export async function toggleMuteParticipantInConversation({
  conversationId,
  participantId,
}) {
  const res = await fetchAPI(`/chat/conversation/mute`, METHODS.put, {
    conversationId,
    participantId,
  });
  return res;
}
