import { assertErrors, fetchAPI, METHODS, parseBody } from './common';
import qs from 'qs';

/**
 * Fetches all organizations within the account's geo-fence.
 * @param {number[]} geofences List of geofence ids.
 * @returns Organization[]
 */
export async function getAllOrganizations({ geofences, limit }) {
  const res = await fetchAPI(
    `/executive/organizations${limit ? `?limit=${limit}` : ''}`,
    METHODS.post,
    {
      geofences,
    }
  );
  const { organizations } = await parseBody(res);
  return organizations;
}

/**
 * Fetches all organizations within the account's geo-fence.
 * @param {number[]} geofences List of geofence ids.
 * @returns Organization[]
 */
export async function getOrganization(organizationId) {
  const res = await fetchAPI(
    `/executive/organizations/${organizationId}`,
    METHODS.get
  );
  const { organization } = await parseBody(res);
  return organization;
}

/**
 * Get top donors located in the selected geofences
 * @param {array} geofences
 * @returns DonorsResult
 */
export async function getTopDonors({ geofences }) {
  const res = await fetchAPI(`/executive/top-donors`, METHODS.post, {
    geofences,
  });

  const { donors } = await parseBody(res);
  return donors;
}

/**
 * Fetches all geozones assigned to the executive user.
 * @returns Geofence[]
 */
export async function getAllGeofences() {
  const res = await fetchAPI('/executive/geozones', METHODS.get);
  const { geozones } = await parseBody(res);
  return geozones;
}

/**
 * Rename a geofence.
 * @param {string} id
 * @param {string} name
 */
export async function renameGeofence(id, name) {
  await fetchAPI(`/executive/geozones/${id}`, METHODS.put, {
    name,
  });
}

/**
 * Delete a geofence.
 * @param {string} id
 */
export async function deleteGeofence(id) {
  await fetchAPI(`/executive/geozones/${id}`, METHODS.delete);
}

/**
 * Update the executive organization's settings.
 * @param {string} param0.name
 * @param {string} param0.email
 * @param {string} param0.about
 */
export async function updateSettings({ name, email, about }) {
  await fetchAPI('/executive/settings/organization', METHODS.put, {
    name,
    email,
    about,
  });
}

/**
 * @typedef Note
 * @property {string} id
 * @property {string} text
 * @property {Date} created
 */

/**
 * Get all of the notes for an organization with id `team_id`.
 * @param {string} teamId
 * @returns Promise<Note>
 */
export async function getNotes(teamId) {
  const res = await fetchAPI(`/executive/notes/${teamId}`, METHODS.get);
  const { notes } = await parseBody(res);
  return notes;
}

/**
 * Add a note on an organization with id `team_id`.
 * @param {string} teamId
 * @param {string} text Note text, max 500 chars.
 */
export async function addNote(teamId, text) {
  await fetchAPI(`/executive/note/${teamId}`, METHODS.post, { text });
}

/**
 * @typedef Tag
 * @property {string} id
 * @property {string} name
 */

/**
 * Get all available tags owned by an organization.
 * @returns Promise<Tag[]>
 */
export async function getTags() {
  const res = await fetchAPI('/executive/tags', METHODS.get);
  const { tags } = await parseBody(res);
  return tags;
}

/**
 * Create a new tag for the user's organization.
 * The user's organization is henceforth the "owning organization."
 * @param {string} name Name of the tag.
 * @returns string ID of the new tag
 */
export async function createTag(name) {
  const res = await fetchAPI('/executive/tags', METHODS.post, { name });
  const { id } = await parseBody(res);
  return id;
}

/**
 * Get all tags (belonging to the executive organization) attached to a specific
 * organization.
 * @param {string} teamId ID of the attached team.
 * @returns Promise<Tag[]>
 */
export async function getTagsFromOrg(teamId) {
  const res = await fetchAPI(`/executive/tags/${teamId}`, METHODS.get);
  const { tags } = await parseBody(res);
  return tags;
}

/**
 * Attach a pre-exiting tag to an organization.
 * It is assumed that the `id` of the tag is valid and under ownership of the
 * executive user.
 * @param {string} id ID of the tag to attach
 * @param {string} teamId ID of the team of which is being tagged.
 */
export async function addTagToOrg(id, teamId) {
  await fetchAPI(`/executive/tags/${teamId}`, METHODS.post, { id });
}

/**
 * Remove or un-attach a tag from an organization.
 * @param {string} id ID of the tag.
 * @param {string} teamId ID of the team of which the tag is attached.
 */
export async function deleteTagFromOrg(id, teamId) {
  await fetchAPI(`/executive/tags/${teamId}/${id}`, METHODS.delete);
}

/**
 * Get all agreements concerning the organization of `teamId`.
 * @param {string} teamId
 * @returns Promise<Agreement[]>
 */
export async function getAgreementsForOrg(teamId) {
  const res = await fetchAPI(`/executive/agreements/${teamId}`, METHODS.get);
  const { agreements } = await parseBody(res);
  return agreements;
}

/**
 * Get a specific inspection by `id`.
 * @param {string} id
 * @param {"auto" | string | null} type
 * @returns Promise<Inspection | null>
 */
export async function getInspectionById(id, type = null) {
  const res = await fetchAPI(
    `/executive/inspection/${id}${type ? `?${qs.stringify({ type })}` : ''}`,
    METHODS.get
  );
  const { inspection } = await parseBody(res);
  return inspection;
}

/**
 * Get all inspections (created by the currect executive org).
 * @params {boolean | null} query.isComplete
 * @params {number | null} query.offset
 * @params {number | null} query.step
 * @params {FilterModel} filterModel
 * @params {string[]} geofences Ids of active geofences
 * @returns Promise<Inspection[]>
 */
export async function getAllInspections(query, filterModel, geofences) {
  const res = await fetchAPI(
    `/executive/inspections?${qs.stringify(query)}`,
    METHODS.post,
    { filterModel, geofences }
  );
  const { inspections } = await parseBody(res);
  return inspections;
}

/**
 * Get a list of inspections for a given inspector.
 * @params {string} userId
 * @params {boolean | null} query.isComplete
 * @params {number | null} query.offset
 * @params {number | null} query.step
 * @params {FilterModel} filterModel
 * @params {string[]} geofences Ids of active geofences
 * @returns Promise<Inspection[]>
 */
export async function getInspectionsForInspector(
  userId,
  query,
  filterModel,
  geofences
) {
  const res = await fetchAPI(
    `/executive/inspections/inspector/${userId}?${qs.stringify(query)}`,
    METHODS.post,
    { filterModel, geofences }
  );
  const { inspections } = await parseBody(res);
  return inspections;
}

/**
 * Get a list of inspections for a given team.
 * @params {string} teamId
 * @params {boolean | null} query.isComplete
 * @params {number | null} query.offset
 * @params {number | null} query.step
 * @params {FilterModel} filterModel
 * @params {string[]} geofences Ids of active geofences
 * @returns Promise<Inspection[]>
 */
export async function getInspectionsForTeam(
  teamId,
  query,
  filterModel,
  geofences
) {
  const res = await fetchAPI(
    `/executive/inspections/organization/${teamId}?${qs.stringify(query)}`,
    METHODS.post,
    { filterModel, geofences }
  );
  const { inspections } = await parseBody(res);
  return inspections;
}

/**
 * Create a new inspection of an inspectee with data (js object)
 * @param {string} inspecteeTeamId
 * @param {string} type
 * @param {object} data
 * @param {boolean} isComplete
 */
export async function createInspection(
  inspecteeTeamId,
  type,
  data,
  isComplete
) {
  const res = await fetchAPI('/executive/inspection', METHODS.post, {
    inspecteeTeamId,
    type,
    data,
    isComplete,
  });
  await assertErrors(res);
}

/**
 * Update an existing inspection.
 * @param {string} id
 * @param {object} data
 * @param {boolean} isComplete
 */
export async function updateInspection(id, data, isComplete) {
  const res = await fetchAPI('/executive/inspection', METHODS.put, {
    id,
    data,
    isComplete,
  });
  await assertErrors(res);
}

/**
 * Delete an existing inspection.
 * @param {string} id
 */
export async function deleteInspection(id) {
  const res = await fetchAPI(`/executive/inspection/${id}`, METHODS.delete, {
    id,
  });
  await assertErrors(res);
}

/**
 * Fetches all donations within the account's geo-fence.
 * @param {number[]} param0.geofences List of geofence ids.
 * @param {String} param0.type One of INTERNAL, INCOMING, OUTGOING or null
 * @param {number} param0.step
 * @param {number} param0.offset
 * @param {object} param0.filterModel Data grid column filter
 * @param {String} param0.timeZoneId Timezone Id of the user
 * @returns Donation[]
 */
export async function getAllDonationsPaginated({
  geofences,
  type,
  step = 20,
  offset = 1,
  filterModel,
  timeZoneId,
}) {
  const res = await fetchAPI('/executive/donations/paginated', METHODS.post, {
    geofences,
    type,
    step,
    offset,
    filterModel,
    timeZoneId,
  });
  const { donations, count, totalWeight } = await parseBody(res);
  return { donations, count, totalWeight };
}

/**
 * Download all donations within the account's geo-fence as csv.
 * @param {number[]} param0.geofences List of geofence ids.
 * @param {String} param0.type One of INTERNAL, INCOMING, OUTGOING or null
 * @param {object} param0.filterModel Data grid column filter
 * @param {string} param0.timeZoneId Timezone Id of the user
 * @param {string} param0.visibleColumns visible columns in the data grid
 * @returns File
 */
export async function downloadDonationsGeofencedCsv({
  geofences,
  type,
  filterModel,
  timeZoneId,
  visibleColumns,
}) {
  const res = await fetchAPI('/executive/donations/csv', METHODS.post, {
    geofences,
    type,
    filterModel,
    timeZoneId,
    visibleColumns,
  });
  const csvBlob = await res.blob();
  const url = window.URL.createObjectURL(csvBlob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'donations.csv');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
}
