import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Badge,
} from '@mui/material';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { School } from '@mui/icons-material';
import useAuth from 'src/hooks/useAuth';
import PrereqsPopover from './PrereqsPopover';
import ChatPopover from './ChatPopover';
import { SwitchLanguageButton } from '../auth';
import { useTranslation } from 'react-i18next';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({
  onOpenSidebar,
  trainingComplete,
  handleOpenTraining,
}) {
  const theme = useTheme();
  const { user } = useAuth();
  const { t } = useTranslation('translation');
  return (
    <RootStyle>
      <ToolbarStyle>
        <Box display={{ md: 'block', lg: 'none' }}>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
            size="large"
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Box>

        {!user.is_executive && !user.is_corporate && <Searchbar />}
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: { xs: 1.5, sm: 2, lg: 3 },
            },
          }}
        >
          {!trainingComplete && (
            <Tooltip title={t('training-program.training-not-yet-completed')}>
              <IconButton
                sx={{ margin: '15px' }}
                onClick={handleOpenTraining}
                size="large"
              >
                <Badge color="error" variant="dot">
                  <School htmlColor={theme.palette.info.main} />
                </Badge>
              </IconButton>
            </Tooltip>
          )}

          <PrereqsPopover />

          {/*<NotificationsPopover />*/}
          <ChatPopover />
          {!user.is_executive &&
            !user.is_corporate &&
            user.account_type !== 'ADMIN' && (
              <SwitchLanguageButton
                excludeLanguages={['fr-CA']}
                containerProps={{
                  sx: {},
                }}
                size="large"
              />
            )}
          <AccountPopover />
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
