import {
  formatDistanceToNowStrict as formatDistanceToNowStrictDateFns,
  formatDistanceToNow as formatDistanceToNowDateFns,
  format as formatDateFns,
} from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import i18n from 'src/locales/i18n';

const locales = {
  'en-US': enUS,
  'es-US': es,
};

export const format = (date, formatStr, options) => {
  return formatDateFns(date, formatStr, {
    locale: locales[i18n.language],
    ...options,
  });
};

export const formatDistanceToNowStrict = (date, options) => {
  return formatDistanceToNowStrictDateFns(date, {
    locale: locales[i18n.language],
    ...options,
  });
};

export const formatDistanceToNow = (date, options) => {
  return formatDistanceToNowDateFns(date, {
    locale: locales[i18n.language],
    ...options,
  });
};
