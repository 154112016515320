import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  ToggleButton,
  Typography,
  useTheme,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  InputLabel,
  Select,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import makeStyles from '@mui/styles/makeStyles';
// icons
import SvgIconStyle from 'src/components/SvgIconStyle';
import { Icon } from '@iconify/react';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// utils
import { fData } from 'src/utils/formatNumber';
import { motion, AnimatePresence } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
// components
import {
  DesktopDatePicker,
  DesktopTimePicker,
  MobileDatePicker,
  MobileTimePicker,
} from '@mui/x-date-pickers-pro';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { MIconButton } from 'src/components/@material-extend';
import { varFadeInRight } from 'src/components/animate';
import Slider from 'react-slick';
import { CarouselControlsArrowsIndex } from 'src/components/carousel';
import {
  Schedule,
  Today,
  Sync,
  Upload,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { BUCKET_NAME, S3_BASE_URL } from 'src/config';
import _keys from 'lodash/keys';
import _xor from 'lodash/xor';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import MeasureField, {
  useMeasureFieldUnits,
} from 'src/components/MeasureField';
import { isValidNumber } from 'src/utils/number';
import { extractPlaceData, Weight } from 'careit';
import _isFinite from 'lodash/isFinite';
import _range from 'lodash/range';
import _noop from 'lodash/noop';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import useGetLocaleTextDatePicker from './useGetLocaleTextDatePicker';

// ----------------------------------------------------------------------

const SIZE_INTERVALS = ['xs', 'sm', 'md', 'lg', 'xl'];
const isSizeBelow = (interval, control) =>
  SIZE_INTERVALS.indexOf(interval) <= SIZE_INTERVALS.indexOf(control);

/**
 * @deprecated
 * This is an old form utility which has been deprecated due to it's unecessary
 * complexity. In any new forms, please use the new form system which is
 * currently being used in the inspection forms among other places.
 */
export default function useForm({
  noClose = false,
  handleSave,
  form,
  title,
  defaultState: _defaultState,
  schema,
}) {
  const { t } = useTranslation('translation');
  // apply custom default state mixin
  const defaultState = { ..._defaultState, _step: 0, _steps: 0 };

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState(defaultState ?? {});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = (value) => {
    setOpen(true);
    if (value) {
      setState({ ...value, _step: 0, _steps: 0 });
    }
  };
  const handleClose = () => {
    setOpen(false);
    setState(defaultState ?? {});
    setErrors({});
  };

  const handleDoSave = () => {
    setLoading(true);
    setErrors({});
    schema
      .validate(state, { abortEarly: false })
      .then(handleSave)
      .then(() => {
        setState(defaultState ?? {});
        handleClose();
      })
      .catch((err) => {
        // If the error is not from validation, but rather from "handleSave" it
        // will not have an inner attribute. We should handle this like any
        // other error.
        if (!err.inner) {
          console.error(err);
          enqueueSnackbar('An error occurred. Please try again', {
            variant: 'error',
          });
          Sentry.withScope((scope) => {
            scope.setContext('feedbackDialog', { hide: true });
            Sentry.captureException(err);
          });
          return;
        }

        const errors_raw = err.inner
          .filter(({ errors }) => errors.length > 0)
          .map(({ errors, path }) => [errors[0], path]);
        const errors = {};
        for (let i = 0, e = errors_raw.length; i < e; i++) {
          const [error, path] = errors_raw[i];
          errors[path] = error;
        }
        setErrors(errors);
      })
      .finally(() => setLoading(false));
  };

  const defaultOnChange = (e) => {
    return e.target.value;
  };
  const defaultCondition = () => true;
  const defaultComponent = ({ error, ...params }) => (
    <TextField helperText={error} error={!!error} {...params} />
  );
  const defaultMapUpdate = ({ state, id, updates }) => ({
    ...state,
    [id]: updates,
  });

  const renderItem = (item) => {
    if (state[item.id] === undefined && item.defaultState !== undefined) {
      setState({ ...state, [item.id]: item.defaultState });
    }

    const onChange = (...params) =>
      setState((state) =>
        (item.mapUpdate ?? defaultMapUpdate)({
          state,
          id: item.id,
          updates: (item.onChange ?? defaultOnChange)(...params),
        })
      );

    const component = (item.component ?? defaultComponent)({
      key: item.id,
      error: errors[item.id],
      fullWidth: true,
      margin: 'normal',
      label: item.name,
      value: state[item.id] ?? item.defaultState ?? '',
      onChange,
      ...(item.mapState ?? (() => ({})))(state),
      ...(typeof item.params === 'function'
        ? item.params(state[item.id] ?? item.defaultState ?? '')
        : item.params),
    });

    return (item.condition ?? defaultCondition)(state) ? (
      component
    ) : (
      <Box display="none">{component}</Box>
    );
  };

  const renderItemOrSection = (item, i) => {
    if (!(item && _keys(item).length > 1)) {
      return <Box></Box>;
    }
    return {
      undefined: renderItem,
      stepper: (section) => {
        let _indexCounter = 0;
        const steps = section.steps.map((step) => {
          // Recursively search the form tree to see if any children have
          // errors.
          const findError = (node) => {
            if (errors[node?.id]) {
              return true;
            }

            if (Array.isArray(node.items)) {
              return node.items.some(findError);
            }

            return false;
          };
          const containsError = findError(step);

          // If step.condition exists and returns false, then we should hide
          // this step.
          //
          // We cannot simply return `null` here as it interferes with react's
          // component update expectations, so we set `hidden=true` on <Step />.
          //
          // However, by hiding the step, MUI does not automattically
          // recalculate the indexes of each step to skip the hidden step. To
          // fix this, we manually compute the step indexes ourselves.
          //
          // KNOWN ISSUES:
          //  This will cause strange behaviour if a previous step is hidden
          //  after a change in a later step. This is, of course, generally bad
          //  behaviour even if we handled the issue gracefully. Please avoid
          //  doing this, and if possible avoid useForm altogether.
          const hidden = step.condition ? !step.condition(state) : false;

          let index = _indexCounter;
          if (hidden) {
            // The index must be unique, so we allocate indexes beyond
            // steps.length for hidden steps.
            index = section.steps.length + index;
          } else {
            _indexCounter += 1;
          }

          return (
            <Step key={step.title} hidden={hidden} index={index}>
              <StepLabel error={containsError}>{step.title}</StepLabel>
              <StepContent>{step.items.map(renderItemOrSection)}</StepContent>
            </Step>
          );
        });

        return (
          <Stepper
            orientation={section.vertical ? 'vertical' : 'horizontal'}
            activeStep={state._step}
          >
            {(() => {
              if (section.steps.length - 1 !== state._steps) {
                setState({ ...state, _steps: section.steps.length - 1 });
              }
            })()}
            {steps}
          </Stepper>
        );
      },
      horizontal: (section) => {
        const rendered = (
          <>
            {section.title && (
              <Typography
                color="text.secondary"
                variant="caption"
                margin="10px"
              >
                {section.title}
              </Typography>
            )}
            <Box
              key={i}
              display="flex"
              sx={{
                flexDirection: section.collapseSize
                  ? SIZE_INTERVALS.reduce((acc, x) => {
                      acc[x] = isSizeBelow(x, section.collapseSize)
                        ? 'column'
                        : 'row';
                      return acc;
                    }, {})
                  : 'row',
              }}
              justifyContent={
                section.compact
                  ? section.left
                    ? 'start'
                    : 'center'
                  : 'space-evenly'
              }
            >
              {section.items.map(renderItem)}
            </Box>
            {errors[section.id] && (
              <Typography color="error" variant="caption" margin="10px">
                {errors[section.id]}
              </Typography>
            )}
          </>
        );

        return section.condition && !section.condition(state) ? (
          <Box display="none">{rendered}</Box>
        ) : (
          rendered
        );
      },
    }[item.section](item);
  };

  const setFieldState = (id, value) => {
    setState({ ...state, [id]: value });
  };

  return {
    handleOpen,
    handleClose,
    form: () => (
      <Dialog
        open={open}
        onClose={noClose ? undefined : handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {form.map((item, i) => renderItemOrSection(item, i))}
        </DialogContent>
        <DialogActions>
          {state._step !== 0 && (
            <Button onClick={handleClose} sx={{ mt: 1, mr: 1 }}>
              {t('form.actions.cancel')}
            </Button>
          )}

          <Box flex="1" />

          <Box
            position="fixed"
            left="calc(50vw - 100px)"
            width="200px"
            display="flex"
            justifyContent="center"
          >
            {Object.keys(errors).length > 0 && (
              <Typography marginRight="15px" color="error">
                {t('form.fix-all-errors')}
              </Typography>
            )}
          </Box>

          <Button
            onClick={() =>
              state._step === 0
                ? handleClose()
                : setState({ ...state, _step: state._step - 1 })
            }
            sx={{ mt: 1, mr: 1 }}
            disabled={loading}
          >
            {state._step === 0
              ? t('form.actions.cancel')
              : t('form.actions.back')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => {
              state._step === state._steps
                ? handleDoSave()
                : setState({ ...state, _step: state._step + 1 });
            }}
            sx={{ mt: 1, mr: 1 }}
            loading={loading}
          >
            {state._step === state._steps
              ? t('form.actions.finish')
              : t('form.actions.continue')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    ),
    state,
    setState,
    open,
    setFieldState,
    errors,
  };
}

// ----------------------------------------------------------------------

export function makeMultiline(rows, maxLen) {
  return (value) => ({
    multiline: true,
    rows,
    inputProps: { maxLength: maxLen },
    helperText: `${value?.length ?? 0} / ${maxLen}`,
  });
}

export function checkboxFormOnChange(e) {
  return e.target.checked;
}

export function CheckboxFormComponent({
  label,
  key,
  error,
  value,
  checked,
  ...params
}) {
  return (
    <FormControl margin="normal" error={!!error} key={key}>
      <FormControlLabel
        control={<Checkbox {...params} checked={value ?? checked} />}
        label={<Typography color="text.secondary">{label}</Typography>}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export function radioGroupOnChange(e) {
  return e.target.checked;
}

export const RadioGroupComponent = (items, Header = null) =>
  function RadioGroupComponentImpl({
    label,
    key,
    error,
    fullWidth,
    ...params
  }) {
    return (
      <FormControl
        margin="normal"
        error={!!error}
        key={key}
        fullWidth={fullWidth}
      >
        <FormLabel component="legend">{label}</FormLabel>
        {Header}
        <RadioGroup aria-label={label} name="radio-buttons-group" {...params}>
          {items.map((item) => (
            <FormControlLabel
              value={item.value}
              key={item.value}
              control={<Radio />}
              label={item.label}
              disabled={item.disabled ?? false}
            />
          ))}
        </RadioGroup>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  };

export function ToggleButtonComponent({
  label,
  key,
  onChange,
  value,
  ...params
}) {
  return (
    <Button
      key={key}
      onClick={() => onChange({ target: { checked: !value } })}
      size="small"
      color={value ? 'primary' : 'inherit'}
      variant="contained"
      {...params}
      sx={{ ...params.sx, minWidth: '25px' }}
    >
      {label}
    </Button>
  );
}

export function toggleButtonGroupOnChange(_, value) {
  return value;
}

export function createToggleButtonGroup(items) {
  return function ToggleButtonGroupImpl({ key, error, label, ...params }) {
    const handleChangeInMobile = (a, b) => {
      const newValues = _xor(params.value, [b]);
      params.onChange(a, newValues);
    };

    return (
      <span key={key}>
        <Typography margin="5px" color={error ? 'error' : 'text.secondary'}>
          {label}
        </Typography>

        <Grid container spacing={1}>
          {items.map((item) => (
            <Grid item xs={6} sm={3} md={3} key={item.id}>
              <ToggleButton
                key={item.id}
                value={item.id}
                fullWidth
                onChange={handleChangeInMobile}
                selected={params.value.includes(item.id)}
                color="primary"
              >
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="15px"
                >
                  {item.image.endsWith('.svg') ? (
                    <SvgIconStyle
                      src={item.image}
                      sx={{ width: 50, height: 50 }}
                      color={error ? 'error' : 'inherit'}
                    />
                  ) : (
                    <img
                      src={item.image}
                      style={{ width: 50, height: 50 }}
                      color={error ? 'error' : 'inherit'}
                      alt={`Icon for ${item.title}`}
                    />
                  )}
                  <Typography
                    margin="15px"
                    textAlign="center"
                    color={error ? 'error' : 'text.secondary'}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </ToggleButton>
            </Grid>
          ))}
        </Grid>

        {error && (
          <Typography margin="5px" color="error" variant="body2">
            {error}
          </Typography>
        )}
      </span>
    );
  };
}

export function foodCategoryOnChange(_, value) {
  return value;
}

export function createFoodCategoryGroup(items, { fullSize = false } = {}) {
  return function FoodCategoryGroupImpl({
    key,
    error,
    label,
    defaultUnit,
    ...params
  }) {
    const { t } = useTranslation('translation');
    const handleInputChange = (name) => (value) => {
      params.onChange(name, {
        ...params.value,
        [name]: value,
      });
    };

    const { WEIGHT_UNITS } = useMeasureFieldUnits();
    // users can click the food category to "toggle" it
    //
    // if the food run is inactive (no value) -> activate this category and set the value to 0
    // if the food run is active and the value is 0 -> deactivate this category and clear the value
    // otherwise pass
    const handleToggle = (id) => () => {
      let value = _get(params, `value[${id}].value`);

      if (parseFloat(value) === 0) {
        // remove `id` key from `value` object
        const clonedValue = _cloneDeep(params.value);
        delete clonedValue[id];
        params.onChange(id, clonedValue);
      } else if (!isValidNumber(value)) {
        handleInputChange(id)({
          value: 0,
          unit: defaultUnit,
        });
      }
    };

    return (
      <span key={key}>
        <Typography margin="5px" color={error ? 'error' : 'text.secondary'}>
          {label}
        </Typography>
        <Box>
          <Grid container spacing={1}>
            {items.map((item) => {
              const selected = _get(params.value, `${item.id}.value`, -1) >= 0;
              return (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  {...(fullSize ? { lg: 3 } : {})}
                >
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ py: 2 }}
                    key={item.id}
                    className="food-category-item"
                    style={{
                      background: selected ? '#f4fbf7' : null,
                      cursor: 'pointer',
                    }}
                    onClick={handleToggle(item.id)}
                  >
                    <SvgIconStyle
                      src={item.image}
                      sx={{ width: 50, height: 50 }}
                      color={
                        error ? 'error' : selected ? 'primary' : 'disabled'
                      }
                    />
                    <Typography
                      margin="15px"
                      textAlign="center"
                      color={
                        error
                          ? 'error'
                          : selected
                          ? 'primary'
                          : 'text.secondary'
                      }
                      fontSize={14}
                      height={40}
                    >
                      {item.title}
                    </Typography>

                    <MeasureField
                      label={t('common.weight')}
                      units={WEIGHT_UNITS}
                      value={_get(params, `value[${item.id}]`)}
                      onChange={handleInputChange(item.id)}
                      style={{ width: '80%' }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {error && (
          <Typography margin="5px" color="error" variant="body2">
            {error}
          </Typography>
        )}
      </span>
    );
  };
}

export function MeasureFieldComponent({ ...params }) {
  const { WEIGHT_UNITS } = useMeasureFieldUnits();
  return (
    <MeasureField
      units={WEIGHT_UNITS.filter((item) => item.value !== 'meals')}
      style={{ width: '100%' }}
      {...params}
      onChange={(value) => {
        params.onChange(params.key, {
          ...value,
        });
      }}
    />
  );
}

function onChangeIdentity(val) {
  return val;
}

export const dateTimeOnChange = onChangeIdentity;

export function DateTimeComponent({ key, error, ...params }) {
  return (
    <Box key={key} marginTop="15px" mx="10px" display="flex">
      <DesktopDatePicker
        {...params}
        onChange={(dateJs) => {
          const date = moment(dateJs);
          const newDate = moment(params.value)
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .toDate();
          params.onChange(newDate);
        }}
        label={params.label + ' (Date)'}
        dateRangeIcon={<Today color="disabled" />}
        timeIcon={<Schedule color="disabled" />}
        renderInput={(params) => (
          <TextField helperText={error} error={!!error} {...params} />
        )}
      />

      <Box width="20px" />

      <DesktopTimePicker
        {...params}
        onChange={(dateJs) => {
          const date = moment(dateJs);
          const newDate = moment(params.value)
            .hour(date.hour())
            .minute(date.minute())
            .toDate();
          params.onChange(newDate);
        }}
        label={params.label + ' (Time)'}
        dateRangeIcon={<Today color="disabled" />}
        timeIcon={<Schedule color="disabled" />}
        renderInput={(params) => (
          <TextField helperText={error} error={!!error} {...params} />
        )}
      />
    </Box>
  );
}

export const dateOnChange = onChangeIdentity;

export function DateComponent({ key, error, ...params }) {
  const localeText = useGetLocaleTextDatePicker();

  return (
    <Box key={key} margin={1} marginTop="15px">
      <MobileDatePicker
        {...params}
        localeText={localeText}
        value={params.value ? new Date(params.value) : null}
        dateRangeIcon={<Today color="disabled" />}
        renderInput={(params) => (
          <TextField {...params} helperText={error} error={!!error} />
        )}
      />
    </Box>
  );
}

export const timeOnChange = onChangeIdentity;

export function TimeComponent({ key, error, ...params }) {
  const localeText = useGetLocaleTextDatePicker();

  return (
    <Box key={key} margin={1} marginTop="15px">
      <MobileTimePicker
        {...params}
        localeText={localeText}
        dateRangeIcon={<Today color="disabled" />}
        renderInput={(params) => (
          <TextField {...params} helperText={error} error={!!error} />
        )}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  map: {
    height: '50vh',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(),
  },
  search: {
    top: 8,
    left: 0,
    right: 0,
    width: '50%',
    margin: 'auto',
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    // '&.Mui-focused': { width: 320, boxShadow: theme.shadows[25].z8 },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  },
  foodCategoryItem: {
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
}));

export function SelectAddressComponent({
  key,
  error,
  value,
  onChange,
  label,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [autocomplete, setAutocomplete] = useState(null);

  const [mapOptions, setMapOptions] = useState({
    zoom: 2,
    minZoom: 2,
    maxZoom: 16,
    center:
      value.lat && value.lng
        ? { lat: value.lat, lng: value.lng }
        : { lat: 38.685, lng: -115.234 },
    controlSize: 24,
    disableDefaultUI: true,
    gestureHandling: 'none',
    zoomControl: false,
    mapTypeControl: true,
  });

  useEffect(() => {
    if (!value.lng || !value.lat) return;

    if (
      mapOptions.center.lat === value.lat &&
      mapOptions.center.lng === value.lng &&
      mapOptions.zoom === 16
    )
      return;

    setMapOptions({ ...mapOptions, zoom: 16, center: value });
  }, [value, mapOptions]);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const handleChangePlace = async () => {
    if (autocomplete === null) return;

    const place = autocomplete.getPlace();
    if (!place.place_id) return; // If, eg. the user types some text and then presses <Enter>

    // autocomplete API stores these as lazy getters
    place.geometry.location.lat = place.geometry.location.lat();
    place.geometry.location.lng = place.geometry.location.lng();

    const placeData = extractPlaceData(place);

    onChange(placeData);

    setMapOptions({
      ...mapOptions,
      zoom: 16,
      center: { lat: placeData.lat, lng: placeData.lng },
    });
  };

  return (
    <Box height="50vh" {...props} key={key}>
      <GoogleMap
        options={mapOptions}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          border: error && '3px solid red',
          borderRadius: 4,
        }}
      >
        {/**
         * Key is added to autocomplete to re-render the input field when value was set manually using `setFieldState`
         * Otherwise the map shows the location of the updated value but the input has empty value
         **/}
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={handleChangePlace}
          key={value.name || value.formattedAddress}
        >
          <OutlinedInput
            type="text"
            size="small"
            placeholder={label}
            className={classes.search}
            error={!!error}
            defaultValue={value.name || value.formattedAddress}
          />
        </Autocomplete>
        {mapOptions.zoom === 16 && (
          <Marker
            position={mapOptions.center}
            icon={{
              path: 'M8 0C3.61277632-.00021937.04387223 3.53299568 0 7.92 0 13.4 7.05 19.5 7.35 19.76c.374224.3200877.925776.3200877 1.3 0C9 19.5 16 13.4 16 7.92 15.9561278 3.53299568 12.3872237-.00021937 8 0zm0 11c-1.9329966 0-3.5-1.5670034-3.5-3.5C4.5 5.56700338 6.0670034 4 8 4s3.5 1.56700338 3.5 3.5c0 .9282577-.3687489 1.8184964-1.0251263 2.4748737C9.8184964 10.6312511 8.9282577 11 8 11z',
              fillColor: theme.palette.error.main,
              fillOpacity: 1.0,
              strokeWeight: 0,
              scale: 1.5,
            }}
          />
        )}
      </GoogleMap>
      {error && (
        <Typography margin="5px" variant="body2" color="error">
          {error}
        </Typography>
      )}
    </Box>
  );
}

export function SelectAddressComponentPopup(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TextField
        onClick={() => setOpen(true)}
        label={props.label}
        fullWidth
        margin="normal"
        sx={{ mx: 1 }}
        padding={(theme) => theme.spacing()}
        value={props.value?.formattedAddress}
        InputLabelProps={{ shrink: !!props.value?.formattedAddress }}
      />

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <DialogTitle>{props.label}</DialogTitle>

        <DialogContent>
          <SelectAddressComponent {...props} width="750px" />
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const selectAddressOnChange = onChangeIdentity;

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    flexDirection: 'row',
  },
}));

export function UploadImagesComponent({
  label,
  value,
  onChange: setFiles,
  sx,
  error,
  width = 250,
  height = 250,
  objectFit,
  displayLabel,
  maxFiles = 0,
  handleFileCountExceed = _noop,
  ...other
}) {
  const { t } = useTranslation('translation');
  const files = value.filter((item) => item);
  const [open, setOpen] = useState(false);
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  const hasFile = files.length > 0;

  const readImage = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onabort = () => reject('Read Aborted');
      reader.onerror = () => reject(reader.error);
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });

  const handleDrop = useCallback(
    (acceptedFiles) =>
      (async () => {
        let allFiles = [];
        for (let i = 0, e = acceptedFiles.length; i < e; i++) {
          const file = acceptedFiles[i];
          let data = await readImage(file);
          allFiles.push({ file, data });
        }
        setFiles(files.concat(allFiles));
      })(),
    [files, setFiles]
  );

  const handleRemoveFile = (id) => {
    setFiles(files.filter((_, i) => i !== id));
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'],
      onDrop: handleDrop,
      maxFiles,
      validator: () => {
        if (maxFiles > 0 && files.length >= maxFiles) {
          handleFileCountExceed();
        }
      },
    });

  const CarouselItem = ({ item, onClick }) => {
    let data, url;
    if (typeof item === 'string') {
      url = item;
    } else {
      data = item.data;
      url = item.url;
    }

    return (
      <ButtonBase onClick={onClick} sx={{ width, height }}>
        <Box
          component="img"
          sx={{ objectFit: objectFit ?? 'cover' }}
          src={url ? `${S3_BASE_URL}/${BUCKET_NAME}/${url}` : data}
          width="100%"
          height="100%"
        />
      </ButtonBase>
    );
  };

  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_current, next) => setCurrentIndex(next),
  };

  return (
    <>
      {displayLabel ? <FormLabel component="legend">{label}</FormLabel> : null}
      {files.length > 0 ? (
        <Card
          sx={{
            width,
            height,
            border: error && '2px solid red',
            mt: 1,
            ...sx,
          }}
        >
          <Slider ref={carouselRef} {...settings}>
            {files.map((item) => (
              <CarouselItem
                key={item.title || item.file}
                item={item}
                onClick={() => setOpen(true)}
              />
            ))}
          </Slider>

          <CarouselControlsArrowsIndex
            index={currentIndex}
            total={files.length}
            onNext={() => carouselRef.current.slickNext()}
            onPrevious={() => carouselRef.current.slickPrev()}
          />
        </Card>
      ) : (
        <Button
          startIcon={<Upload />}
          onClick={() => setOpen(true)}
          variant="outlined"
          sx={{ mt: 2, mb: 1 }}
        >
          {t('upload-file.upload-files')}
        </Button>
      )}

      {error && (
        <Typography variant="body2" margin="5px" color="error">
          {error}
        </Typography>
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Box sx={{ width: '100%' }} {...other}>
            <DropZoneStyle
              {...getRootProps()}
              sx={{
                ...(isDragActive && { opacity: 0.72 }),
                ...((isDragReject || error) && {
                  borderColor: 'primary.light',
                  bgcolor: 'primary.lighter',
                }),
              }}
            >
              <input {...getInputProps()} />

              <Box
                component="img"
                alt="select file"
                src="/static/illustrations/illustration_upload.svg"
                sx={{ height: 160 }}
              />

              <Box
                sx={{
                  p: 3,
                  ml: { md: 2 },
                }}
              >
                <Typography color="text.primary" gutterBottom variant="h5">
                  {t('upload-file.drop-or-select-file')}
                </Typography>

                {label ? (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {label}
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {t('upload-file.drop-file-here-or')}&nbsp;
                    <Link underline="always">{t('upload-file.browse')}</Link>
                    &nbsp;
                    {t('upload-file.thorough-your-machine')}
                  </Typography>
                )}
              </Box>
            </DropZoneStyle>

            <List disablePadding sx={{ ...(hasFile && { my: 5 }) }}>
              <AnimatePresence>
                {files.map(({ file }, id) => (
                  <ListItem
                    key={id}
                    component={motion.div}
                    {...varFadeInRight}
                    sx={{
                      my: 1,
                      py: 0.5,
                      px: 2,
                      borderRadius: 1,
                      border: (theme) => `solid 1px ${theme.palette.divider}`,
                      bgcolor: 'background.paper',
                    }}
                  >
                    <ListItemIcon>
                      <Icon icon={fileFill} width={32} height={32} />
                    </ListItemIcon>
                    <ListItemText
                      primary={file?.name ?? `Image #${id + 1}`}
                      secondary={file?.size ? fData(file.size) : ''}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                    <ListItemSecondaryAction>
                      <MIconButton
                        edge="end"
                        size="small"
                        onClick={() => handleRemoveFile(id)}
                      >
                        <Icon icon={closeFill} />
                      </MIconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </AnimatePresence>
            </List>

            {hasFile && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  '& > *': { ml: 1.5 },
                }}
              >
                <Button onClick={() => setFiles([])} sx={{ mr: 1.5 }}>
                  {t('upload-file.remove-all')}
                </Button>
                <Button variant="contained" onClick={() => setOpen(false)}>
                  {t('upload-file.done')}
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export const uploadImagesOnChange = onChangeIdentity;

export function UploadFileComponent({
  label,
  value: file,
  onChange: setFile,
  sx,
  error,
  width = '100%',
  height = '40vh',
  accept,
  preview = () => <></>,
  maxSize,
  onDropRejected = _noop,
  ...other
}) {
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);

  const hasFile = !!file;

  const readFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onabort = () => reject('Read Aborted');
      reader.onerror = () => reject(reader.error);
      reader.onload = () => resolve(reader.result);
      reader.readAsText(file);
    });

  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }

    let data = await readFile(file);
    setFile({ file, data });
  };

  const handleRemoveFile = () => setFile();

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept,
      multiple: false,
      onDrop: handleDrop,
      maxSize,
      onDropRejected,
    });
  const Preview = preview;
  return (
    <>
      <Card sx={{ width, height, border: error && '2px solid red', ...sx }}>
        {hasFile ? (
          <Preview file={file} data={file.data} />
        ) : (
          <ButtonBase
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => setOpen(true)}
          >
            <Box
              component="img"
              alt="select file"
              src="/static/illustrations/illustration_upload.svg"
              sx={{ objectFit: 'cover' }}
              height={height / 2}
            />
            <Box height="50px" />
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('upload-file.click-here-to-upload-files')}
            </Typography>
          </ButtonBase>
        )}
      </Card>

      {error && (
        <Typography variant="body2" margin="5px" color="error">
          {error}
        </Typography>
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Box sx={{ width: '100%' }} {...other}>
            <DropZoneStyle
              {...getRootProps()}
              sx={{
                ...(isDragActive && { opacity: 0.72 }),
                ...((isDragReject || error) && {
                  borderColor: 'primary.light',
                  bgcolor: 'primary.lighter',
                }),
              }}
            >
              <input {...getInputProps()} />

              <Box
                component="img"
                alt="select file"
                src="/static/illustrations/illustration_upload.svg"
                sx={{ height: 160 }}
              />

              <Box
                sx={{
                  p: 3,
                  ml: { md: 2 },
                }}
              >
                <Typography color="text.primary" gutterBottom variant="h5">
                  {t('upload-file.drop-or-select-file')}
                </Typography>

                {label ? (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {label}
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {t('upload-file.drop-file-here-or')}&nbsp;
                    <Link underline="always">{t('upload-file.browse')}</Link>
                    &nbsp;
                    {t('upload-file.thorough-your-machine')}
                  </Typography>
                )}
              </Box>
            </DropZoneStyle>

            <List disablePadding sx={{ ...(hasFile && { my: 5 }) }}>
              <AnimatePresence>
                {hasFile && (
                  <ListItem
                    key={file.file.name}
                    component={motion.div}
                    {...varFadeInRight}
                    sx={{
                      my: 1,
                      py: 0.5,
                      px: 2,
                      borderRadius: 1,
                      border: (theme) => `solid 1px ${theme.palette.divider}`,
                      bgcolor: 'background.paper',
                    }}
                  >
                    <ListItemIcon>
                      <Icon icon={fileFill} width={32} height={32} />
                    </ListItemIcon>
                    <ListItemText
                      primary={file.file.name}
                      secondary={fData(file.file.size)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                    <ListItemSecondaryAction>
                      <MIconButton
                        edge="end"
                        size="small"
                        onClick={handleRemoveFile}
                      >
                        <Icon icon={closeFill} />
                      </MIconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </AnimatePresence>
            </List>

            {hasFile && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  '& > *': { ml: 1.5 },
                }}
              >
                <Button onClick={() => setFile()} sx={{ mr: 1.5 }}>
                  {t('upload-file.remove')}
                </Button>
                <Button variant="contained" onClick={() => setOpen(false)}>
                  {t('upload-file.done')}
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export const uploadFileOnChange = onChangeIdentity;

export const SelectComponent = ({
  items = [],
  displayRefresh = false,
  ...props
}) => {
  const getItems = (items) => {
    if (typeof items === 'function') {
      return items();
    }
    return items;
  };

  return (
    <Box
      width={'100%'}
      display="flex"
      alignItems="center"
      justifyContent={'center'}
    >
      <TextField
        SelectProps={{
          endAdornment: props.hasClear ? (
            <IconButton
              sx={{ visibility: !isEmpty(props.value) ? 'visible' : 'hidden' }}
              onClick={(e) => props.onChange({ ...e, target: { value: '' } })}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            ''
          ),
        }}
        {...props}
        select
        helperText={props.error}
      >
        {getItems(items).map(({ value, title }) => (
          <MenuItem key={value} value={value}>
            {title}
          </MenuItem>
        ))}
      </TextField>
      {displayRefresh ? (
        <Button onClick={props.onRefresh}>
          <Sync />
        </Button>
      ) : null}
    </Box>
  );
};

export function processFoodTypes(foodTypes) {
  let foodWeights = {};

  for (const [type, weight] of Object.entries(foodTypes)) {
    if (weight?.value >= 0) {
      foodWeights[type] = {
        value: new Weight(parseFloat(weight.value), weight.unit).as('lbs'),
        unit: weight.unit,
      };
    }
  }

  return foodWeights;
}

export const DayOfTheMonthSelector = ({ value, ...params }) => {
  const { t } = useTranslation('translation');
  const type = useMemo(() => {
    if (value.isLastDay) {
      return 'last';
    }

    return _isFinite(_get(value, 'week.dayOfTheWeek'))
      ? 'day_of_the_week'
      : 'custom';
  }, [value]);
  return (
    <>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {t('donations.select-day-of-the-month-to-repeat')}
      </Typography>
      <RadioGroup
        value={type}
        onChange={(event) => {
          const inpValue = event.target.value;
          if (inpValue === 'last') {
            params.onChange({
              isLastDay: true,
              customDay: null,
              week: {
                number: null,
                dayOfTheWeek: null,
              },
            });
          } else if (inpValue === 'day_of_the_week') {
            params.onChange({
              isLastDay: false,
              customDay: null,
              week: {
                number: 1,
                dayOfTheWeek: 1,
              },
            });
          } else {
            params.onChange({
              isLastDay: false,
              customDay: null,
              week: {
                number: null,
                dayOfTheWeek: null,
              },
            });
          }
        }}
      >
        <FormControlLabel
          value="last"
          control={<Radio />}
          label={t('donations.last-day-of-the-month')}
        />
        <Box>
          <FormControlLabel
            value="day_of_the_week"
            control={<Radio />}
            label={t('donations.select-day-of-the-week')}
          />
          <Box display="flex" flexDirection={'column'}>
            <Box display="flex">
              <FormControl
                disabled={type !== 'day_of_the_week'}
                margin="normal"
                style={{ width: 200, marginLeft: 20, marginRight: 20 }}
              >
                <InputLabel
                  id="no-of-the-week"
                  shrink={_isFinite(_get(value, 'week.number'))}
                >
                  {t('donations.no-of-the-week')}
                </InputLabel>
                <Select
                  labelId="no-of-the-week"
                  id="no-of-the-week"
                  value={_get(value, 'week.number')}
                  label={t('donations.no-of-the-week')}
                  onChange={(e) => {
                    params.onChange({
                      isLastDay: false,
                      week: {
                        ..._get(value, 'week', {}),
                        number: e.target.value,
                      },
                    });
                  }}
                >
                  <MenuItem value={1}>{t('donations.1st')}</MenuItem>
                  <MenuItem value={2}>{t('donations.2nd')}</MenuItem>
                  <MenuItem value={3}>{t('donations.3rd')}</MenuItem>
                  <MenuItem value={4}>{t('donations.4th')}</MenuItem>
                  <MenuItem value={5}>{t('donations.5th')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                disabled={type !== 'day_of_the_week'}
                margin="normal"
                style={{ width: 200 }}
              >
                <InputLabel
                  id="day-of-the-week"
                  shrink={_isFinite(_get(value, 'week.dayOfTheWeek'))}
                >
                  {t('donations.day-of-the-week')}
                </InputLabel>
                <Select
                  labelId="day-of-the-week"
                  id="day-of-the-week"
                  value={_get(value, 'week.dayOfTheWeek')}
                  label={t('donations.day-of-the-week')}
                  onChange={(e) =>
                    params.onChange({
                      isLastDay: false,
                      week: {
                        ..._get(value, 'week', {}),
                        dayOfTheWeek: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={0}>{t('donations.Sunday')}</MenuItem>
                  <MenuItem value={1}>{t('donations.Monday')}</MenuItem>
                  <MenuItem value={2}>{t('donations.Tuesday')}</MenuItem>
                  <MenuItem value={3}>{t('donations.Wednesday')}</MenuItem>
                  <MenuItem value={4}>{t('donations.Thursday')}</MenuItem>
                  <MenuItem value={5}>{t('donations.Friday')}</MenuItem>
                  <MenuItem value={6}>{t('donations.Saturday')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box>
          <FormControlLabel
            value="custom"
            control={<Radio />}
            label={t('donations.select-any-day-of-the-month')}
          />
          <Box style={{ marginLeft: 20, marginRight: 20 }}>
            <FormControl
              disabled={type !== 'custom'}
              margin="normal"
              style={{ width: 200 }}
            >
              <InputLabel id="day-of-the-month" shrink={value.customDay}>
                {t('donations.day-of-the-month')}
              </InputLabel>
              <Select
                labelId="day-of-the-month"
                id="day-of-the-month"
                value={value.customDay}
                label={t('donations.day-of-the-month')}
                onChange={(e) =>
                  params.onChange({
                    isLastDay: false,
                    customDay: e.target.value,
                    week: {
                      number: null,
                      dayOfTheWeek: null,
                    },
                  })
                }
              >
                {_range(1, 32).map((i) => (
                  <MenuItem value={i} key={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </RadioGroup>
      <Typography color="error" variant="caption" margin="10px">
        {params.error}
      </Typography>
    </>
  );
};
