import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import useSearchQuery from 'src/hooks/useSearchQuery';
import useAuth from 'src/hooks/useAuth';
import useTeams from 'src/hooks/useTeams';
import LoadingScreen from 'src/components/LoadingScreen';

/**
 * HACK to run a callback effect upon rendering.
 */
function RunEffect({ effect }) {
  useEffect(() => effect(), []);

  return null;
}

/**
 * If a route has a teamId query, it may have been a link from an email. Using
 * this hook, we make sure that we are on the correct team and switch to the
 * correct one if we aren't. The result of this hook is either a ReactNode
 * which should be displayed or null which means that the parent should
 * instead display details about the resource.
 */
export default function useMaybeSwitchTeams() {
  const { user } = useAuth();
  const history = useHistory();
  const { teamId } = useSearchQuery();
  const { teams, loading: teamsLoading, switchTo } = useTeams();

  const teamIds = useMemo(
    () => new Set([...teams.map(({ team_id }) => team_id)]),
    [teams]
  );

  if (teamId && user?.team_id !== teamId) {
    if (teamsLoading) {
      return <LoadingScreen />;
    }

    if (teamIds.has(teamId)) {
      return (
        <RunEffect effect={() => switchTo(teamId, { changeRoute: false })} />
      );
    } else {
      // we do not have access to this donation
      history.push(PATH_PAGE.page404);
      return <LoadingScreen />;
    }
  }

  return null;
}
