import { Typography } from '@mui/material';
import {
  CheckboxFormComponent,
  checkboxFormOnChange,
  DateComponent,
  dateOnChange,
  dateTimeOnChange,
  makeMultiline,
  SelectComponent,
  ToggleButtonComponent,
  RadioGroupComponent,
  TimeComponent,
  timeOnChange,
  foodCategoryOnChange,
  SelectAddressComponentPopup,
  selectAddressOnChange,
  processFoodTypes,
  DayOfTheMonthSelector,
} from 'src/hooks/useForm';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { FOOD_TYPES, useGetVehicleLabels } from 'src/constants';
import { useMeasureFieldUnits } from 'src/components/MeasureField';
import { processRecurringIntervalData } from 'src/utils/recurring';
import _isFinite from 'lodash/isFinite';
import { roundMinutes } from 'src/utils/time';
import * as backend from 'src/backend';
import useCheckCompletedOnboarding from 'src/hooks/useCheckCompletedOnboarding';
import { getVehicle } from 'src/hooks/useCreateDonation';
import { useTranslation } from 'react-i18next';
import FoodTypeSelector from 'src/utils/foodTypeSelector';
import useDaysOfTheWeek from 'src/hooks/useDaysOfTheWeek';

export const useGetFormConfig = (
  donors,
  recipients,
  teamMembers,
  timezoneId = 'America/Los_Angeles',
  orgsRefresh,
  preferredUnit = 'lbs',
  vehicles
) => {
  const { t } = useTranslation('translation');
  const daysOfTheWeek = useDaysOfTheWeek();
  const vehicleLabelsWithUnit = useGetVehicleLabels();
  return [
    {
      section: 'stepper',
      vertical: true,
      steps: [
        {
          title: t('donations.food-run-details'),
          items: [
            { id: 'title', name: t('donations.title') },
            {
              id: 'description',
              name: t('donations.description'),
              params: makeMultiline(8, 1000),
            },
            {
              id: 'foodTypes',
              name: t('donations.food-types'),
              component: FoodTypeSelector,
              onChange: foodCategoryOnChange,
              params: { color: 'primary' },
            },
            {
              id: 'vehicleSize',
              name: t('donations.recommended-vehicle'),
              component: RadioGroupComponent(vehicleLabelsWithUnit),
              params: {
                row: true,
              },
            },
          ],
        },
        {
          title: t('donations.time-and-assignment'),
          items: [
            {
              section: 'horizontal',
              collapseSize: 'xs',
              items: [
                {
                  id: 'occurrenceType',
                  name: t('donations.occurrence-type'),
                  component: RadioGroupComponent([
                    { label: t('donations.one-time'), value: 'onetime' },
                    { label: t('donations.recurring'), value: 'recurring' },
                    { label: t('donations.indefinite'), value: 'indefinite' },
                  ]),
                  defaultState: 'onetime',
                  params: {
                    fullWidth: true,
                  },
                },
                {
                  id: 'interval',
                  name: t('donations.repeat-every'),
                  condition: (state) =>
                    ['recurring', 'indefinite'].includes(state.occurrenceType),
                  params: {
                    items: [
                      { value: 'Daily', title: t('donations.daily') },
                      { value: 'Weekly', title: t('donations.weekly') },
                      { value: 'Bi-weekly', title: t('donations.bi-weekly') },
                      { value: 'Monthly', title: t('donations.monthly') },
                    ],
                  },
                  component: SelectComponent,
                  defaultState: 'Daily',
                },
              ],
            },
            {
              id: 'dayOfTheMonth',
              name: t('donations.repeat-on'),
              condition: (state) =>
                ['recurring', 'indefinite'].includes(state.occurrenceType) &&
                state.interval === 'Monthly',
              defaultState: {
                isLastDay: false,
                customDay: null,
                week: {
                  number: null,
                  dayOfTheWeek: null,
                },
              },
              component: DayOfTheMonthSelector,
              onChange: dateOnChange,
            },
            {
              section: 'horizontal',
              name: 'dayOfTheWeek',
              id: 'dayOfTheWeek',
              condition: (state) =>
                ['recurring', 'indefinite'].includes(state.occurrenceType) &&
                (state.interval === 'Weekly' || state.interval === 'Bi-weekly'),
              compact: true,
              left: true,
              title: t('donations.repeat-on'),
              items: [...'SMTWTFS'].map((_, i) => ({
                id: `repeat-${i}`,
                name: daysOfTheWeek[i],
                params: { sx: { margin: '5px' } },
                component: ToggleButtonComponent,
                onChange: checkboxFormOnChange,
              })),
            },
            {
              section: 'horizontal',
              collapseSize: 'xs',
              condition: (state) => state.occurrenceType === 'onetime',
              items: [
                {
                  id: 'startDate',
                  name: t('donations.donation-available-date'),
                  defaultState: new Date(
                    moment()
                      .tz(timezoneId)
                      .format('donations.YYYY-MM-DD HH:mm:ss')
                  ),
                  component: DateComponent,
                  onChange: dateTimeOnChange,
                },
                {
                  id: 'startTime',
                  name: t('donations.start-time'),
                  defaultState: new Date(
                    roundMinutes(moment())
                      .tz(timezoneId)
                      .format('donations.YYYY-MM-DD HH:mm:ss')
                  ),
                  component: TimeComponent,
                  onChange: timeOnChange,
                },
                {
                  id: 'endTime',
                  name: t('donations.end-time'),
                  defaultState: new Date(
                    roundMinutes(moment())
                      .tz(timezoneId)
                      .add(5, 'hours')
                      .format('donations.YYYY-MM-DD HH:mm:ss')
                  ),
                  component: TimeComponent,
                  onChange: timeOnChange,
                  mapState: (state) => ({
                    minTime: state.startTime,
                  }),
                },
              ],
            },
            {
              section: 'horizontal',
              title: t('donations.recurring-interval'),
              name: t('donations.recurring-interval'),
              collapseSize: 'xs',
              condition: (state) =>
                ['recurring', 'indefinite'].includes(state.occurrenceType),
              items: [
                {
                  id: 'startDate',
                  name: t('donations.start-date'),
                  defaultState: new Date(
                    moment().tz(timezoneId).format('YYYY-MM-DD HH:mm:ss')
                  ),
                  component: DateComponent,
                  onChange: dateTimeOnChange,
                  mapState: () => ({
                    minDate: new Date(
                      moment().tz(timezoneId).format('YYYY-MM-DD HH:mm:ss')
                    ),
                  }),
                },
                {
                  id: 'endDate',
                  name: t('donations.end-date'),
                  condition: (state) => state.occurrenceType !== 'indefinite',
                  defaultState: new Date(
                    moment()
                      .tz(timezoneId)
                      .add(1, 'days')
                      .format('YYYY-MM-DD HH:mm:ss')
                  ),
                  component: DateComponent,
                  onChange: dateTimeOnChange,
                  mapState: (state) => ({
                    minDate: state.startDate,
                  }),
                },
                {
                  id: 'startTime',
                  name: t('donations.start-time'),
                  defaultState: new Date(
                    roundMinutes(moment())
                      .tz(timezoneId)
                      .format('YYYY-MM-DD HH:mm:ss')
                  ),
                  component: TimeComponent,
                  onChange: timeOnChange,
                },
                {
                  id: 'endTime',
                  name: t('donations.end-time'),
                  defaultState: new Date(
                    moment()
                      .tz(timezoneId)
                      .add(1, 'days')
                      .add(5, 'hours')
                      .format('YYYY-MM-DD HH:mm:ss')
                  ),
                  component: TimeComponent,
                  onChange: timeOnChange,
                  mapState: (state) => ({
                    minTime: state.startTime,
                  }),
                },
              ],
            },
            {
              id: 'donor',
              name: t('donations.assign-donor'),
              params: {
                className: 'full-width',
                fullWidth: false,
                sx: { width: 'calc(100% - 200px)' },
                autocomplete: true,
                onRefresh: orgsRefresh,
                displayRefresh: true,
                items: [
                  {
                    value: 'unassigned',
                    title: (
                      <Typography color="text.secondary">
                        {t('donations.unassigned')}
                      </Typography>
                    ),
                  },
                  ...donors.map((donor) => ({
                    value: donor.team_id,
                    title: (
                      <>
                        {donor.name} &nbsp;
                        <Typography color="text.secondary" variant="overlinee">
                          {donor.formatted_address}
                        </Typography>
                      </>
                    ),
                  })),
                ],
              },
              component: SelectComponent,
              defaultState: 'unassigned',
            },
            {
              id: 'receiver',
              name: t('donations.assign-receiver'),
              condition: (state) => state.donor !== 'unassigned',
              params: {
                className: 'full-width',
                fullWidth: false,
                sx: { width: 'calc(100% - 200px)' },
                autocomplete: true,
                onRefresh: orgsRefresh,
                displayRefresh: true,
                items: [
                  {
                    value: 'unassigned',
                    title: (
                      <Typography color="text.secondary">
                        {t('donations.unassigned')}
                      </Typography>
                    ),
                  },
                  ...recipients.map((receipient) => ({
                    value: receipient.team_id,
                    title: (
                      <>
                        {receipient.name} &nbsp;
                        <Typography color="text.secondary" variant="overlinee">
                          {receipient.formatted_address}
                        </Typography>
                      </>
                    ),
                  })),
                ],
              },
              component: SelectComponent,
              defaultState: 'unassigned',
            },

            {
              id: 'custom_address',
              name: t('donations.use-custom-address'),
              params: { width: '200px' },
              condition: (state) => state.donor !== 'unassigned',
              component: CheckboxFormComponent,
              onChange: checkboxFormOnChange,
            },
            {
              section: 'horizontal',
              collapseSize: 'xs',
              condition: (state) => state.custom_address,
              items: [
                {
                  id: 'pickup_address',
                  name: t('donations.custom-pickup-address'),
                  component: SelectAddressComponentPopup,
                  onChange: selectAddressOnChange,
                },
                {
                  id: 'dest_address',
                  name: t('donations.custom-destination-address'),
                  component: SelectAddressComponentPopup,
                  onChange: selectAddressOnChange,
                },
              ],
            },
            {
              id: 'vehicle',
              name: t('donations.vehicle'),
              component: SelectComponent,
              defaultState: null,
              params: {
                hasClear: true,
                className: 'full-width',
                fullWidth: false,
                sx: { width: 'calc(100% - 200px)' },
                autocomplete: true,
                items: getVehicle(vehicles, preferredUnit),
              },
              condition: () => vehicles.length,
            },
            {
              id: 'driver',
              name: t('donations.runner'),
              params: {
                className: 'full-width',
                fullWidth: false,
                sx: { width: 'calc(100% - 200px)' },
                autocomplete: true,
                items: [
                  {
                    value: 'unassigned',
                    title: (
                      <Typography color="text.secondary">
                        {t('donations.unassigned')}
                      </Typography>
                    ),
                  },
                  ...teamMembers.map((teamMember) => ({
                    value: teamMember.id,
                    title: (
                      <>
                        {teamMember.name} &nbsp;
                        <Typography color="text.secondary" variant="overlinee">
                          {teamMember.user_formatted_address ||
                            teamMember.email}
                        </Typography>
                      </>
                    ),
                  })),
                ],
              },
              component: SelectComponent,
              defaultState: 'unassigned',
            },
            {
              id: 'available_on_unassign',
              name: t('donations.make-available-on-unassign'),
              condition: (state) =>
                ['recurring', 'indefinite'].includes(state.occurrenceType) &&
                ![null, undefined, 'unassigned'].includes(state.driver),
              defaultState: true,
              params: { width: '200px' },
              component: CheckboxFormComponent,
              onChange: checkboxFormOnChange,
            },
          ],
        },
        {
          title: t('donations.instructions'),
          items: [
            {
              id: 'pickupInstructions',
              name: t('donations.pickup-instructions'),
              params: makeMultiline(8, 1000),
            },
            {
              id: 'dropoffInstructions',
              name: t('donations.dropoff-instructions'),
              params: makeMultiline(8, 1000),
            },
            {
              id: 'notifyOnEdit',
              name: t('donations.notify-if-changes-made-on-edit'),
              params: { width: '200px' },
              component: CheckboxFormComponent,
              onChange: checkboxFormOnChange,
            },
          ],
        },
      ],
    },
  ];
};

export const useAddFoodRunSchema = () => {
  const { t } = useTranslation('translation');
  const { WEIGHT_UNITS } = useMeasureFieldUnits();
  return (donors, recipients) =>
    Yup.object({
      description: Yup.string().required(t('donations.description-required')),
      foodTypes: Yup.object({
        ...FOOD_TYPES.map(({ value }) => value.toLowerCase()).reduce(
          (foodTypes, foodType) => ({
            ...foodTypes,
            [foodType]: Yup.object({
              value: Yup.number(),
              unit: Yup.string().oneOf(WEIGHT_UNITS.map(({ value }) => value)),
            }),
          }),
          {}
        ),
      }).test(
        'at-least-one-property',
        t('donations.at-least-one-food-type'),
        (value) => Object.entries(processFoodTypes(value)).length > 0
      ),
      occurrenceType: Yup.string()
        .oneOf(['onetime', 'recurring', 'indefinite'])
        .required(t('donations.select-a-occurrence-type')),
      interval: Yup.string().when('occurrenceType', {
        is: (occurrenceType) =>
          ['recurring', 'indefinite'].includes(occurrenceType),
        then: Yup.string().required(t('donations.select-a-recurring-interval')),
      }),
      startDate: Yup.string().required(t('donations.select-start-date')),
      endDate: Yup.string()
        .when(['occurrenceType'], {
          is: 'recurring',
          then: Yup.string()
            .required(t('donations.select-end-date'))
            .test(
              'is-after-start-date',
              t('donations.end-date-must-be-after-start-date'),
              (value, context) => {
                return (
                  value &&
                  context.parent.startDate &&
                  moment(value)
                    .startOf('day')
                    .isAfter(moment(context.parent.startDate).startOf('day'))
                );
              }
            ),
        })
        .when(['occurrenceType'], {
          is: 'indefinite',
          then: Yup.string().nullable(),
        }),
      startTime: Yup.date().required(t('donations.select-a-start-time')),
      endTime: Yup.date()
        .required(t('donations.select-a-end-time'))
        .test(
          'is-after-start-time',
          t('donations.end-time-must-be-after-start-time'),
          (value, context) => {
            const endDate = moment(value).clone().startOf('day');
            const endTimeInMinutes = moment(value).diff(endDate, 'minutes');
            const startDate = moment(context.parent.startTime).startOf('day');
            const startTimeInMinues = moment(context.parent.startTime).diff(
              startDate,
              'minutes'
            );
            return endTimeInMinutes > startTimeInMinues;
          }
        ),
      daysOfTheWeek: Yup.array().when(['occurrenceType', 'interval'], {
        is: (occurrenceType, interval) =>
          ['recurring', 'indefinite'].includes(occurrenceType) &&
          ['Weekly', 'Bi-weekly'].includes(interval),
        then: Yup.array()
          .min(1, t('donations.select-at-least-one-day'))
          .required(),
      }),
      dayOfTheMonth: Yup.object({}).when(['occurrenceType', 'interval'], {
        is: (occurrenceType, interval) =>
          ['indefinite', 'recurring'].includes(occurrenceType) &&
          interval === 'Monthly',
        then: Yup.object()
          .shape({
            isLastDay: Yup.bool(),
            customDay: Yup.number().nullable(),
            week: Yup.object()
              .shape({
                number: Yup.number().nullable(),
                dayOfTheWeek: Yup.number().nullable(),
              })
              .nullable(),
          })
          .test(
            'atLeastOneNonNull',
            t('donations.select-day-of-the-month'),
            function (value) {
              const { isLastDay, customDay, week } = value;
              return (
                isLastDay ||
                customDay ||
                (_isFinite(week.number) && _isFinite(week.dayOfTheWeek))
              );
            }
          )
          .required(t('donations.select-day-of-the-month')),
        otherwise: Yup.object().optional(),
      }),
      donor: Yup.string()
        .notOneOf(['unassigned'], t('donations.select-a-value'))
        .test(
          'completed-onboarding',
          ({ value: donor }) =>
            t('donations.you-must-complete-the-required-onboarding-items', {
              name: donors.find((team) => team.team_id === donor).name,
            }),
          async (donor, context) => {
            if (donor === 'unassigned' || !context.parent.isFoodRun) {
              return true;
            }

            const requiredItems =
              await backend.team.getItemsRequiredToRescueFrom(donor);
            return requiredItems.length === 0;
          }
        )
        .required(t('donations.select-a-donor')),
      driver: Yup.string().when(['deliveryOption'], {
        is: (deliveryOption) => Number(deliveryOption) === 0,
        then: Yup.string()
          .notOneOf(['unassigned'], t('donations.select-a-driver'))
          .required(t('donations.select-a-driver')),
        otherwise: Yup.string().optional(),
      }),
      receiver: Yup.string()
        .notOneOf(['unassigned'], t('donations.select-a-receiver'))
        .test(
          'completed-onboarding',
          ({ value: receiver }) =>
            t('donations.must-complete-onboarding-items', {
              name: recipients.find((team) => team.team_id === receiver).name,
            }),
          async (receiver) => {
            if (receiver === 'unassigned') {
              return true;
            }

            const requiredItems = await backend.team.getItemsRequiredToDonateTo(
              receiver
            );
            return requiredItems.length === 0;
          }
        )
        .required(t('donations.select-a-receiver')),
      vehicleSize: Yup.string().required(
        t('donations.select-recommended-vehicle-size')
      ),
    });
};

export const useEditRecurringDonationSchema = () => {
  const { t } = useTranslation('translation');
  const checkCompletedOnboarding = useCheckCompletedOnboarding();
  const AddFoodRunSchema = useAddFoodRunSchema();
  return (donors, recipients) =>
    AddFoodRunSchema(donors, recipients).shape({
      deliveryOption: Yup.string().required(
        t('donations.select-a-delivery-option')
      ),
      receiver: Yup.string()
        .when(['isFoodRun'], {
          is: true,
          then: Yup.string()
            .notOneOf(['unassigned'])
            .required(t('donations.select-a-receiver')),
          otherwise: Yup.string().optional().nullable(),
        })
        .test(
          'completed-onboarding',
          ({ value: receiver }) =>
            checkCompletedOnboarding.buildErrorMessage(recipients, receiver),
          checkCompletedOnboarding.validate
        ),
      dayOfTheMonth: Yup.object({}).when(['occurrenceType', 'interval'], {
        is: (occurrenceType, interval) =>
          occurrenceType === 'recurring' && interval === 'Monthly',
        then: Yup.object()
          .shape({
            isLastDay: Yup.bool(),
            customDay: Yup.string().nullable(),
            week: Yup.object()
              .shape({
                number: Yup.number(),
                dayOfTheWeek: Yup.number(),
              })
              .nullable(),
          })
          .test(
            'atLeastOneNonNull',
            t('donations.select-day-of-the-month'),
            function (value) {
              const { isLastDay, customDay, week } = value;
              return (
                isLastDay ||
                customDay ||
                (_isFinite(week.number) && _isFinite(week.dayOfTheWeek))
              );
            }
          )
          .required(t('donations.select-day-of-the-month')),
        otherwise: Yup.object().optional(),
      }),
      driver: Yup.string().when(['deliveryOption', 'receiver', 'isFoodRun'], {
        is: (deliveryOption, receiver, isFoodRun) =>
          Number(deliveryOption) === 0 && receiver && !isFoodRun,
        then: Yup.string()
          .notOneOf(['unassigned'], t('donations.select-a-driver'))
          .required(t('donations.select-a-driver'))
          .nullable(),
        otherwise: Yup.string().optional().nullable(),
      }),
    });
};

export const processFoodRunFormData = (data) => {
  const {
    occurrenceType,
    isRecurring,
    interval,
    daysOfTheWeek,
    startDate,
    endDate,
    startTime,
    endTime,
    isDST,
  } = processRecurringIntervalData(data);

  const foodWeights = processFoodTypes(data.foodTypes);

  const newEndDate = isRecurring ? endDate : startDate; //End date same as start date for one time runs
  const isCompleted =
    data.occurrenceType === 'onetime' &&
    moment(
      moment(newEndDate).format('YYYY-MM-DD') +
        ' ' +
        moment(data.endTime).format('HH:mm:00')
    ).isBefore(moment());

  const newFoodRun = {
    isRecurring,
    interval,
    dayOfTheMonth: data.dayOfTheMonth,
    daysOfTheWeek,
    startDate,
    endDate: occurrenceType === 'indefinite' ? null : newEndDate,
    startTime,
    endTime,
    isDST,

    vehicleSize: Number(data.vehicleSize),
    vehicle: data.vehicle,
    title: data.title?.trim() ? data.title : null,
    description: data.description,
    donationId: uuidv4(),
    donorTeam: data.donor,
    receiverTeam: data.receiver,
    driverId: data.driver === 'unassigned' ? null : data.driver,
    availableOnUnassign: data.available_on_unassign,
    pickupInstructions: data.pickupInstructions,
    dropoffInstructions: data.dropoffInstructions,
    notifyOnEdit: data.notifyOnEdit,
    foodWeights,
    isCompleted,
    timezone_id: data.timezone_id,
    pickup_address: {
      ...data.pickup_address,
      place: data.pickup_address?.name,
    },
    dest_address: { ...data.dest_address, place: data.dest_address?.name },
  };
  return newFoodRun;
};
