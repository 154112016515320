/* eslint-disable */
import { Badge, Card, ClickAwayListener, Modal } from '@mui/material';
import { Chat as ChatIcon } from '@mui/icons-material';
import { MIconButton } from '../../components/@material-extend';
import Chat from 'src/components/Chat/Chat';
import useChatClient from 'src/hooks/useChatClient';
import useResponsive from 'src/hooks/useResponsive';

export default function ChatPopover() {
  const {
    client,
    chatOpen: open,
    setChatOpen: setOpen,
    drawerOpen,
    setDrawerOpen,
    unreadMessages,
  } = useChatClient();
  const isDesktop = useResponsive('up', 'md');
  if (!client) {
    return null;
  }

  const handleToggle = () => {
    if (isDesktop) {
      setOpen(!open);
      setDrawerOpen(!drawerOpen);
    } else {
      setDrawerOpen(!drawerOpen);
    }
  };

  const handleClose = () => {
    if (isDesktop) {
      setOpen(false);
      setDrawerOpen(false);
    } else {
      setDrawerOpen(false);
    }
  };

  return (
    <>
      <MIconButton onClick={handleToggle} color={open ? 'primary' : 'default'}>
        <Badge badgeContent={unreadMessages} color="error">
          <ChatIcon />
        </Badge>
      </MIconButton>

      <Modal
        open={open || drawerOpen}
        onClose={handleClose}
        hideBackdrop
        disableEnforceFocus
        sx={{
          width: '100vw',
          height: '100vw',
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'end',
          p: 1,
          top: 'unset',
          left: 'unset',
        }}
      >
        {isDesktop ? (
          <ClickAwayListener onClickAway={handleClose}>
            <Card sx={{ height: '72vh', width: '900px', display: 'flex' }}>
              <Chat />
            </Card>
          </ClickAwayListener>
        ) : (
          <ClickAwayListener onClickAway={handleClose}>
            <Chat />
          </ClickAwayListener>
        )}
      </Modal>
    </>
  );
}
