import { Box, Button } from '@mui/material';
import Lightbox from 'src/components/LightboxModal';

import { useEffect, useState, useRef, useCallback, useMemo } from 'react';

import ChatMessageItem from './ChatMessageItem';
import useChatClient from 'src/hooks/useChatClient';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChatMessageList() {
  const { t } = useTranslation('translation');
  const scrollRef = useRef(null);
  const scrollMessagesToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [scrollRef]);

  const { activeConversation: conversation } = useChatClient();
  const [messagePaginator, setMessagePaginator] = useState(null);
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if (!conversation) {
      return;
    }

    conversation
      .getMessages()
      .then((messages) => {
        setMessages(messages.items);
        setMessagePaginator(messages);
        scrollMessagesToBottom();
      })
      .catch(console.log);

    conversation.on('messageAdded', (message) => {
      setMessages((messages) =>
        [...messages, message].filter(
          // De-duplicate the messages array by message.sid
          ({ sid }, pos, self) =>
            self.findIndex((other) => other.sid === sid) === pos
        )
      );

      scrollMessagesToBottom();
    });
  }, [conversation, scrollMessagesToBottom]);

  const prevPage = useCallback(() => {
    if (!messagePaginator) {
      throw new Error('Should not be called without a message paginator');
    }

    messagePaginator.prevPage().then((messages) => {
      setMessagePaginator(messages);
      setMessages((existing) => [...messages.items, ...existing]);
    });
  }, [messagePaginator]);
  const hasPrevPage = useMemo(() => {
    if (!messagePaginator) {
      return false;
    }

    return messagePaginator.hasPrevPage;
  }, [messagePaginator]);

  const [selectedImage, setSelectedImage] = useState(-1);
  const imagesLightbox = messages
    .filter((messages) => messages.contentType === 'image')
    .map((messages) => ({ src: messages.body }));
  const handleOpenLightbox = (imageUrl) => {
    const imageIndex = imagesLightbox.findIndex(
      (image) => image.src === imageUrl
    );
    setSelectedImage(imageIndex);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  return (
    <>
      <Box
        ref={scrollRef}
        sx={{ p: 3, pt: 6, height: 1, flexGrow: 1, overflow: 'scroll' }}
      >
        {hasPrevPage && (
          <Box display="flex" justifyContent="center">
            <Button onClick={prevPage}>{t('chat.more-messages')}</Button>
          </Box>
        )}

        {messages.map((message) => (
          <ChatMessageItem
            key={message.id}
            message={message}
            onOpenLightbox={() => handleOpenLightbox(message.body)}
          />
        ))}
      </Box>

      <Lightbox
        photoIndex={selectedImage}
        images={imagesLightbox}
        isOpen={selectedImage >= 0}
        onClose={handleCloseLightbox}
      />
    </>
  );
}
