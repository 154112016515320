import { Redirect } from 'react-router-dom';
import GuestGuard from 'src/guards/GuestGuard';
import RegisterLayout from 'src/layouts/register';
import { PATH_AUTH } from './paths';
import lazy from './lazy';

const RegisterRoutes = {
  path: PATH_AUTH.register.root + '/*',
  guard: GuestGuard, // FIXME: this needs a custom guard
  layout: RegisterLayout,
  routes: [
    {
      exact: true,
      path: PATH_AUTH.register.business.aboutYou,
      component: lazy(() =>
        import('../views/authentication/Register/AboutYou')
      ),
    },
    {
      exact: true,
      path: PATH_AUTH.register.business.aboutBusiness,
      component: lazy(() =>
        import('../views/authentication/Register/AboutBusiness')
      ),
    },

    {
      exact: true,
      path: PATH_AUTH.register.nonProfit.aboutYou,
      component: lazy(() =>
        import('../views/authentication/Register/AboutYou')
      ),
    },
    {
      exact: true,
      path: PATH_AUTH.register.nonProfit.aboutBusiness,
      component: lazy(() =>
        import('../views/authentication/Register/AboutBusiness')
      ),
    },
    {
      exact: true,
      path: PATH_AUTH.register.joinTeam.invitesFor(':initialEmail'),
      component: lazy(() =>
        import('src/views/authentication/Register/JoinTeam')
      ),
    },
    {
      exact: true,
      path: PATH_AUTH.register.pendingVerification(':teamId', ':userId'),
      component: lazy(() =>
        import('src/views/authentication/Register/PendingVerification')
      ),
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default RegisterRoutes;
