import { assertErrors, fetchAPI, METHODS, parseBody } from './common';
import qs from 'qs';
import { BUCKET_NAME, S3_BASE_URL } from 'src/config.js';

/**
 * @typedef TeamMember
 * @property {string} email
 * @property {string} fullName
 * @property {number} type 0 = staff, 1 = driver
 * @property {string} teamId
 */

/**
 * Gets all team members in user's team.
 * @returns Promise<TeamMember[]>
 */
export async function get() {
  const res = await fetchAPI('/user/team-members', METHODS.get);
  const parsedBody = await parseBody(res);
  if (parsedBody.length) {
    return parsedBody.map((i) => {
      return {
        ...i,
        doc_url:
          i.doc_file_name && i.doc_key_name
            ? `${S3_BASE_URL}/${BUCKET_NAME}/${i.doc_key_name}`
            : null,
      };
    });
  } else {
    return [];
  }
}

/**
 * Invites a new team member to the user's team.
 * @param {TeamMember} teamMember
 */
export async function add(data) {
  const res = await fetchAPI('/user/add-team-member', METHODS.post, data);
  await parseBody(res);
}

/**
 * Updates the team user's type - staff <-> driver.
 * @param {string} teamUserId
 * @param {boolean} isDriver
 */
export async function updateUserType(teamUserId, isDriver) {
  const res = await fetchAPI('/user/teamuser', METHODS.post, {
    teamUserId,
    isDriver,
  });
  await parseBody(res);
}

/**
 * Updates the team user's perms - promote/demote.
 * @param {string} param.id
 * @param {string} param.teamId
 * @param {boolean} param.isAdmin
 */
export async function updateUserPerms({ id, teamId, isAdmin, isDriver }) {
  const res = await fetchAPI('/user/teamuser-perms', METHODS.post, {
    teamUserId: id,
    isAdmin,
    isDriver,
    teamId,
  });
  await parseBody(res);
}

/**
 * Set's the user's `is_team_user_active` status for the current team.
 * @param {string} teamUserId
 * @param {boolean} isActive
 */
export async function setActive(teamUserId, isActive) {
  const res = await fetchAPI('/user/set-active-teamuser', METHODS.post, {
    teamUserId,
    isActive,
  });
  await parseBody(res);
}

/**
 * Removes the team user from the user's team
 * @param {string} teamUserId
 */
export async function remove(teamUserId) {
  const res = await fetchAPI('/user/delete-teamuser', METHODS.delete, {
    teamUserId,
  });
  await parseBody(res);
}

export const EMAIL_TYPE = {
  WELCOME: 'WELCOME',
};

/**
 * Fetches the current team's email template.
 * @param {string} type
 * @returns Promise<string>
 */
export async function getEmailTemplate(type) {
  const res = await fetchAPI(`/team/email/${type}`, METHODS.get);
  const { body } = await parseBody(res);
  return body;
}

export async function driversReports({ filterModel, ...data } = {}) {
  const res = await fetchAPI(
    `/team/drivers-reports/?${qs.stringify(data)}`,
    METHODS.post,
    {
      filterModel,
    }
  );
  return await parseBody(res);
}

/**
 * Updates the current team's email template.
 * @param {string} type
 * @param {string} data
 * @returns Promise<Template>
 */
export async function updateEmailTemplate(type, body) {
  await fetchAPI(`/team/email/${type}`, METHODS.post, { body });
}

/**
 * @typedef UpdateTeamUser
 * @property {string} userId
 * @property {string} phone
 * @property {number} lat
 * @property {number} lng
 */

/**
 * Update a team user's data.
 * @param {UpdateTeamUser} data
 */
export async function updateTeamUser({ userId, name, email, phone, lat, lng }) {
  await fetchAPI('/user/teamuser/update', METHODS.post, {
    userId,
    name,
    email,
    phone,
    lat,
    lng,
  });
}

/**
 * Update the notes for a team user.
 * @param {string} userId
 * @param {string} notes
 */
export async function updateTeamUserNotes(userId, notes) {
  await fetchAPI('/user/teamuser/notes', METHODS.post, { userId, notes });
}

/**
 * Update the notes for a team user.
 * @param {object} weekTimesObj
 */
export async function updateOperationalHours(weekTimesObj) {
  await fetchAPI('/team/hours', METHODS.put, { weekTimesObj });
}

/**
 * Resend an invite to an invited team user.
 * @param {string} userId
 */
export async function resendInvite(userId) {
  await fetchAPI('/team/resend-invite', METHODS.post, { userId });
}

/**
 * @typedef SearchResult
 * @property {string} id
 * @property {"DONATION" | "RESCUE" | "FOODRUN" | "ORGANIZATION" | "USER"} type
 * @property {string} primary Primary "title" of the item.
 * @property {string} secondary A "description" of the item providing some futher detail.
 */

/**
 * Preform a query of all resources accessible to the current user.
 * @param {string} text Textual query of recources
 * @returns SearchResult[]
 */
export async function search(text) {
  const query = qs.stringify({ text });
  const res = await fetchAPI(`/search?${query}`, METHODS.get);
  const results = await parseBody(res);
  return results;
}

/**
 * Get the name of an organzation with the given `teamId`.
 * Will throw an error if the `teamId` is not found.
 * @param {string} teamId
 * @returns Promise<string>
 */
export async function nameOf(teamId) {
  const res = await fetchAPI(`/team/name/${teamId}`, METHODS.get);
  await assertErrors(res);
  const name = await res.text();
  return name;
}

/**
 * Get all of my orgs of a given type.
 * @param {"DONOR" | "RECIPIENT"} type Type of orgs to return.
 * @returns {Promise<Team>}
 */
export async function getMyOrgs(type) {
  const res = await fetchAPI(`/team/my?type=${type}`, METHODS.get);
  const { teams } = await parseBody(res);
  return teams;
}

/**
 * Add an org to my orgs list.
 * An error will be thrown if the org already exists on the my orgs list.
 * @param {string} param.teamId id of team to be added to the list
 * @param {boolean} param.isDonor if true , it is added to My Donors or else added to My Recipients
 */
export async function addToMyOrgs({ teamId, isDonor = true }) {
  const payload = { isDonor };
  const res = await fetchAPI(
    `/team/my/${teamId}?${qs.stringify(payload)}`,
    METHODS.post
  );
  await assertErrors(res);
}

/**
 * Remove an org from my orgs list.
 * An error will be thrown if the org does not exist on the my orgs list.
 * @param {string} param.teamId id of team to be removed from the list
 * @param {boolean} param.isDonor if true , delete from my donors list, or else delete from my recipients list
 * @returns {Promise<void>}
 */
export async function removeFromMyOrgs({ teamId, isDonor }) {
  const payload = { isDonor };
  const res = await fetchAPI(
    `/team/my/${teamId}?${qs.stringify(payload)}`,
    METHODS.delete
  );
  await assertErrors(res);
}

export async function getInviteUrl() {
  const res = await fetchAPI('/team/invite-url', METHODS.get);
  await assertErrors(res);
  return await res.text();
}

/**
 * Update the custom metatadata for a given teamId
 * @param {Metadata} metadata
 * @param {string} teamId
 * @returns {Promise<void>}
 */
export async function updateMetadata(metadata, teamId) {
  const res = await fetchAPI(`/team/metadata/${teamId}`, METHODS.post, {
    metadata,
  });
  await assertErrors(res);
}

/**
 * Get team member details
 * @param {string} id
 */
export async function getTeamMember(id) {
  const res = await fetchAPI(`/team/user/${id}`, METHODS.get);
  return parseBody(res, true);
}

/**
 * @typedef Driver
 * @property {string} id
 * @property {number} lat
 * @property {number} lng
 * @property {number} distance
 */

/**
 * Get notifyable drivers near a given food run. We use for the "request
 * drivers over SMS" feature.
 * @param {string} donationId
 * @returns {Promise<Driver[]>}
 */
export async function getNorifyableDrivers(donationId) {
  const res = await fetchAPI(
    `/team/notifyable-drivers/${donationId}`,
    METHODS.get
  );
  await assertErrors(res);
  const { drivers } = await parseBody(res);
  return drivers;
}

/**
 * Get all corporate onboarding requests for this team.
 * @returns {Promise<OnboardingRequest[]>}
 */
export async function getAllOnboardingRequests() {
  const res = await fetchAPI('/team/corp-onboarding/requests', METHODS.get);
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Count the incomplete onboarding requests for a single npo.
 * @returns {Promise<{ count: number }>}
 */
export async function countOpenOnboardingRequests() {
  const res = await fetchAPI(`/team/corp-onboarding/requests`, 'get');
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get all paginated items (without details).
 * @param {object} opts
 * @param {number?} opts.step  Set to -1 or leave undefined to disable pagination
 * @param {number?} opts.offset
 * @returns {Promise<PaginatedOnboardingItems>}
 */
export async function getOnboardingProgressForBusiness({
  step = -1,
  offset = -1,
  filterModel,
}) {
  const res = await fetchAPI(
    `/team/corp-onboarding/progress/get?${qs.stringify({
      step,
      offset,
    })}`,
    METHODS.post,
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get all paginated onboarding requests for an NPO with progress information.
 * @param {object} opts
 * @param {string} opts.teamId Team id of the NPO
 * @param {number?} opts.step  Set to -1 or leave undefined to disable pagination
 * @param {number?} opts.offset
 * @returns {Promise<PaginatedOnboardingItems>}
 */
export async function getOnboardingRequestsProgressForBusiness({
  teamId,
  step = -1,
  offset = -1,
  filterModel,
}) {
  const res = await fetchAPI(
    `/team/corp-onboarding/progress/${teamId}/requests/get?${qs.stringify({
      step,
      offset,
    })}`,
    METHODS.post,
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get all paginated items (without details).
 * @param {object} opts
 * @param {number?} opts.step  Set to -1 or leave undefined to disable pagination
 * @param {number?} opts.offset
 * @param {string} opts.corporateTeamId
 * @returns {Promise<PaginatedOnboardingItems>}
 */
export async function getAllOnboardingItems({
  step = -1,
  offset = -1,
  filterModel,
  corporateTeamId,
  complianceProfileId,
}) {
  const res = await fetchAPI(
    `/team/corp-onboarding/items/${corporateTeamId}/${complianceProfileId}/get?${qs.stringify(
      {
        step,
        offset,
      }
    )}`,
    METHODS.post,
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get the details for one onboarding item.
 * @param {string} itemId
 * @returns {Promise<import('./corporate').OnboardingItemDetails>}
 */
export async function getOnboardingItemDetails(itemId) {
  const res = await fetchAPI(
    `/team/corp-onboarding/item/${itemId}`,
    METHODS.get
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Complete an onboarding item
 * @param {string} itemId
 * @param {object} data  TODO: type annotations
 */
export async function completeOnboardingItem(itemId, data) {
  const res = await fetchAPI(
    `/team/corp-onboarding/item/${itemId}`,
    METHODS.post,
    data
  );
  await assertErrors(res);
}

/**
 * Reupload a document onboarding item
 * @param {string} param0.oldDocument
 * @param {string} param0.document
 * @param {string} param0.itemId
 * @param {string} param0.npoTeamId
 * @returns {Promise<void>}
 */
export async function reUploadSignedDocument({
  oldDocument,
  document,
  itemId,
  npoTeamId,
}) {
  const res = await fetchAPI(
    `/team/corp-onboarding/item/document/reupload`,
    METHODS.put,
    { oldDocument, document, itemId, npoTeamId }
  );
  await assertErrors(res);
}

/**
 * Reupload a file upload onboarding item
 * @param {string} param0.oldDocument
 * @param {string} param0.document
 * @param {string} param0.itemId
 * @param {string} param0.npoTeamId
 * @returns {Promise<void>}
 */
export async function reUploadFileUpload({
  oldDocument,
  document,
  itemId,
  npoTeamId,
}) {
  const res = await fetchAPI(
    `/team/corp-onboarding/item/upload/reupload`,
    METHODS.put,
    { oldDocument, document, itemId, npoTeamId }
  );
  await assertErrors(res);
}

/**
 * @typedef Feedback
 * @property {string} id
 * @property {string} donor
 * @property {string} donor_formatted_address
 * @property {number} donor_rating
 * @property {string} donor_feedback
 * @property {string} recipient
 * @property {string} recipient_formatted_address
 * @property {number} recipient_rating
 * @property {string} recipient_feedback
 * @property {string} created
 * @property {string} donation_id
 */

/**
 * Get team's feedback
 * @param {number} offset
 * @param {number} step
 * @params {FilterModel} filterModel
 * @returns {Promise<Feedback[]>}
 */
export async function getTeamFeedback({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/team/feedback?${qs.stringify({
      offset,
      step,
    })}`,
    METHODS.post,
    { filterModel }
  );
  await assertErrors(res);
  return await parseBody(res);
}

/**
 * Insert document data associated with the team
 * @param {string} param0.url
 * @param {string} param0.name
 * @param {number} param0.size
 */
export async function insertTeamDocument({ url, name, size }) {
  const res = await fetchAPI(`/team/document`, METHODS.post, {
    url,
    name,
    size,
  });
  await assertErrors(res);
  return await parseBody(res);
}

/**
 * @typedef Document
 * @property {string} id
 * @property {string} team_id
 * @property {string} key_name
 * @property {number} file_name
 */

/**
 * Fetch team documents
 * @returns {Promise<Document[]>}
 */
export async function getTeamDocuments() {
  const res = await fetchAPI(`/team/document`, METHODS.get);
  await assertErrors(res);
  return await parseBody(res);
}

/**
 * Delete team document
 * @param {string} param0.id
 * @param {string} param0.documentKey s3 path of the document
 * @returns {Promise<void>}
 */
export async function deleteTeamDocument({ id, documentKey }) {
  const res = await fetchAPI(`/team/document`, METHODS.delete, {
    id,
    documentKey,
  });
  await assertErrors(res);
  return await parseBody(res);
}

/**
 * @typedef OnboardingItem
 * @property {string} id
 * @property {string} title
 * @property {string} corporate_team_id
 */

/**
 * Get corporate onboarding items required in order to rescue from the given team.
 * @params {string} teamId
 * @returns {Promise<OnboardingItem[]>}
 */
export async function getItemsRequiredToRescueFrom(teamId) {
  const res = await fetchAPI(
    `/team/corp-onboarding/items-required-to-rescue-from/${teamId}`,
    METHODS.get
  );
  await assertErrors(res);
  const { requiredItems } = await parseBody(res);
  return requiredItems;
}

/**
 * Get corporate onboarding items required in order to donate to the given team.
 * @params {string} teamId
 * @returns {Promise<OnboardingItem[]>}
 */
export async function getItemsRequiredToDonateTo(teamId) {
  const res = await fetchAPI(
    `/team/corp-onboarding/items-required-to-donate-to/${teamId}`,
    METHODS.get
  );
  await assertErrors(res);
  const { requiredItems } = await parseBody(res);
  return requiredItems;
}

/**
 * Get a paginated view of all associated teams for the compliance profile.
 * @param {object} opts
 * @param {import("./rescues").FilterModelObj} opts.filterModel
 * @param {string} opts.corpTeamId
 * @param {string} opts.complianceProfileId
 * @param {number?} opts.step
 * @param {number?} opts.offset
 * @returns {Promise<PaginatedComplianceBusiness>}
 */
export async function getTeamsForComplianceProfile({
  corpTeamId,
  complianceProfileId,
  step = -1,
  offset = -1,
  filterModel,
}) {
  const res = await fetchAPI(
    `/team/corp-onboarding/compliance-profile/teams-of-corp/${corpTeamId}/${complianceProfileId}/get?${qs.stringify(
      {
        step,
        offset,
      }
    )}`,
    METHODS.post,
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get all paginated items with progress details for a business (without details).
 * @param {object} opts
 * @param {number?} opts.step  Set to -1 or leave undefined to disable pagination
 * @param {number?} opts.offset
 * @param {import('./rescues').FilterModelObj} opts.filterModel
 * @param {string} opts.teamId Id of the business
 * @param {string} opts.id Id of the compliance profile
 * @returns {Promise<PaginatedOnboardingItems>}
 */
export async function getOnboardingProgress({
  step = -1,
  offset = -1,
  filterModel,
  teamId,
  id,
}) {
  const res = await fetchAPI(
    `/team/corp-onboarding/progress/${teamId}/${id}/items/get?${qs.stringify({
      step,
      offset,
    })}`,
    'post',
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Check if user already exists in the team
 * @param {string} email
 */
export async function checkIfUserExistsInTeam(email) {
  const res = await fetchAPI(`/team/user/exists/${email}`, 'get');
  await assertErrors(res);
  return parseBody(res);
}

export async function updateTeamInvites(data) {
  const res = await fetchAPI('/team/invites', METHODS.put, data);
  await assertErrors(res);
}

export async function disableInviteLink(data) {
  const res = await fetchAPI('/team/disable-invite-link', METHODS.post, data);
  await assertErrors(res);
}

export async function checkInviteLinkName(data = {}) {
  const res = await fetchAPI(
    `/team/check-invite-name?${qs.stringify(data)}`,
    METHODS.get
  );
  await assertErrors(res);
  return parseBody(res);
}

export async function authorizeCanJoin(data = {}) {
  const res = await fetchAPI(`/team/authorize-join-team`, METHODS.post, data);
  await assertErrors(res);
  return parseBody(res);
}

export async function verifyTokenJoinTeam(token) {
  const res = await fetchAPI(
    `/team/verify-token-join-team?token=${token}`,
    'get'
  );
  await assertErrors(res);
  return parseBody(res);
}

export async function donationsReportList({ filterModel, type, ...args }) {
  const res = await fetchAPI(
    `/team/donations-${type}-report-list?${qs.stringify(args)}`,
    'post',
    {
      filterModel,
    }
  );
  await assertErrors(res);
  return parseBody(res);
}

export async function donationsReportGraph({ type, ...args }) {
  const res = await fetchAPI(
    `/team/donations-${type}-report-graph?${qs.stringify(args)}`,
    'get'
  );
  await assertErrors(res);
  return parseBody(res);
}

export async function donationsDifferenceReportGraph(args) {
  const res = await fetchAPI(
    `/team/donations-difference-report-graph?${qs.stringify(args)}`,
    'get'
  );
  await assertErrors(res);
  return parseBody(res);
}

export async function addSelectedGeofence(data) {
  const res = await fetchAPI('/team/add-selected-geofence', METHODS.post, data);
  await assertErrors(res);
  return parseBody(res);
}

export async function getSelectedGeofences() {
  const res = await fetchAPI('/team/get-selected-geofences', METHODS.get);
  await assertErrors(res);
  const body = await parseBody(res);
  return body?.map((item) => {
    return {
      ...item,
      points: JSON.parse(item.points),
      aabb: JSON.parse(item.aabb),
    };
  });
}

export async function driverViewOpenDonations(args = {}) {
  const res = await fetchAPI('/team/driver-view-open-donations', 'put', args);
  await assertErrors(res);
  return parseBody(res);
}
