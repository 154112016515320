import { fetchAPI, METHODS, assertErrors, parseBody } from './common';
import _toLower from 'lodash/toLower';
import _map from 'lodash/map';
import qs from 'qs';

/**
 * @typedef User
 */

/**
 * @typedef Org
 */

/**
 * Gets all users in database.
 * @returns Promise<User[]>
 */
export async function getAllUsers({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/admin/users?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );
  const { users } = await parseBody(res);
  return users;
}

/**
 * Updates a user by their id.
 * @param {string} id
 * @param {Partial<User>} user
 */
export async function updateUser(id, user) {
  const res = await fetchAPI('/admin/users', METHODS.put, {
    user: { ...user, id },
  });
  await parseBody(res);
}

/**
 * Remove a user from a team.
 * @param {object} params
 * @param {string} params.userId
 * @param {string} params.teamId
 */
export async function removeTeamUser({ userId, teamId }) {
  const res = await fetchAPI(`/admin/team/${teamId}/${userId}`, METHODS.delete);
  await assertErrors(res);
}

/**
 * Deactivates an existing user.
 * @param {string} id
 */
export async function deactivateUser(id) {
  const res = await fetchAPI('/admin/deactivate-user', METHODS.post, { id });
  await parseBody(res);
}

/**
 * Activates an existing user.
 * @param {string} id
 */
export async function activateUser(id) {
  const res = await fetchAPI('/admin/activate-user', METHODS.post, { id });
  await parseBody(res);
}

/**
 * Gets all organizations.
 * @returns Promise<Org[]>
 */
export async function getAllOrgs({
  page,
  filterTagsToInclude,
  filterTagsToExclude,
  city,
  province,
  formattedAddress,
  name,
  type,
  isApproved,
}) {
  const isPageAvailable = !isNaN(page);
  const includeTags = _map(filterTagsToInclude, (tag) => _toLower(tag.label));
  const excludeTags = _map(filterTagsToExclude, (tag) => _toLower(tag.label));
  const queryObj = {
    page,
    includeTags,
    excludeTags,
    city,
    province,
    formattedAddress,
    name,
    type,
    isApproved,
  };
  const res = await fetchAPI(
    `/admin/orgs${isPageAvailable ? `?${qs.stringify(queryObj)}` : ''}`,
    METHODS.get
  );

  const { orgs } = await parseBody(res);
  return orgs;
}

export async function getAllOrgsMaps(args) {
  const queryObj = args;

  const res = await fetchAPI(
    `/admin/orgs/map/?${qs.stringify(queryObj)}`,
    METHODS.get
  );

  const { orgs } = await parseBody(res);
  return orgs;
}

/**
 * Gets all organizations.
 * @returns Promise<Org[]>
 */
export async function getAllOrgsSubs({
  step,
  offset,
  name,
  planTypes,
  usesStripe,
}) {
  const queryObj = {
    step,
    offset,
    name,
    planTypes,
    usesStripe,
  };
  const res = await fetchAPI(
    `/admin/orgs-subs?${qs.stringify(queryObj)}`,
    METHODS.get
  );

  const { orgs } = await parseBody(res);
  return orgs;
}

/**
 * Gets all organizations.
 * @returns Promise<Org[]>
 */
export async function downloadAllOrgs({
  filterTagsToInclude,
  filterTagsToExclude,
  city,
  province,
  name,
  type,
  isApproved,
}) {
  const includeTags = _map(filterTagsToInclude, (tag) => _toLower(tag.label));
  const excludeTags = _map(filterTagsToExclude, (tag) => _toLower(tag.label));
  const queryObj = {
    includeTags,
    excludeTags,
    city,
    province,
    name,
    type,
    isApproved,
  };
  const res = await fetchAPI(
    `/admin/orgs/download?${qs.stringify(queryObj)}`,
    METHODS.get
  );

  const { orgs } = await parseBody(res);
  return orgs;
}

/**
 * Updates an existing organization by id.
 * @param {string} id
 * @param {Partial<Org>} org
 */
export async function updateOrg(id, org) {
  const res = await fetchAPI('/admin/orgs', METHODS.put, {
    org: { ...org, teamId: id },
  });
  await assertErrors(res);
}

/**
 * Updates an existing executive organization by id.
 * @param {string} id
 * @param {Partial<Org>} org
 */
export async function updateExecutiveOrg(id, org) {
  const res = await fetchAPI('/admin/orgs/executive', METHODS.put, {
    org: { ...org, teamId: id },
  });
  await assertErrors(res);
}

/**
 * Updates an existing executive organization by id.
 * @param {string} id
 * @param {Partial<Org>} org
 */
export async function updateCorporateOrg(id, org) {
  const res = await fetchAPI('/admin/orgs/corporate', METHODS.put, {
    org: { ...org, teamId: id },
  });
  await assertErrors(res);
}

/**
 * Creates a new organization.
 * @param {Org} org
 */
export async function createOrg(org) {
  const res = await fetchAPI('/admin/orgs', METHODS.post, { org });
  await assertErrors(res);
}

export async function getTeam(teamId) {
  const res = await fetchAPI(`/admin/team/${teamId}`, METHODS.get);
  const { team } = await parseBody(res);
  return team;
}

export async function updateTeamUser(userId, { isAdmin, isDriver }) {
  const res = await fetchAPI('/admin/team/user', METHODS.put, {
    userId,
    isAdmin,
    isDriver,
  });
  await parseBody(res);
}

export async function getAllFoodRuns({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/admin/food-runs/get?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );
  return await parseBody(res);
}

export async function getAllDonations({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/admin/donations/get?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );
  return await parseBody(res);
}

export async function getAllRescues({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/admin/rescues/get?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );
  return await parseBody(res);
}

export async function impersonateUser(userId) {
  const res = await fetchAPI('/admin/impersonate-user', METHODS.post, {
    userId,
  });
  const { user } = await parseBody(res);
  return user;
}

/**
 * Get the plan for an organisation. (May be stripe powered or artificial)
 */
export async function getTeamPlan(teamId) {
  const res = await fetchAPI(`/admin/plan/${teamId}`, METHODS.get);
  const { plan } = await parseBody(res);
  return plan;
}

/**
 * Update an artificial plan for an organisation.
 */
export async function updateTeamPlan(teamId, status, planId) {
  await fetchAPI(`/admin/plan/${teamId}`, METHODS.post, {
    status,
    stripe_price_id: planId,
  });
}

/**
 * Delete an artificial plan such that the organisation can switch to a stripe
 * plan.
 */
export async function deleteTeamPlan(teamId) {
  await fetchAPI(`/admin/plan/${teamId}`, METHODS.delete);
}

/**
 * Delete a donation
 *
 */
export async function deleteDonation(donationId) {
  return await fetchAPI(`/admin/donation/${donationId}`, METHODS.delete);
}

/**
 * Permanently Delete a user
 *
 */
export async function deleteUser(userId) {
  const res = await fetchAPI(`/admin/user/${userId}`, METHODS.delete);
  return await parseBody(res);
}

/**
 * Permanently Delete an org
 *
 */
export async function deleteOrg(teamId) {
  await fetchAPI(`/admin/org/${teamId}`, METHODS.delete);
}

/**
 * Add tags to an org
 *
 */
export async function addOrgTags(data) {
  await fetchAPI(`/admin/org/tag`, METHODS.post, data);
}

/**
 * Get tag suggestions
 *
 */
export async function getTags() {
  const res = await fetchAPI(`/admin/org/tag`, METHODS.get);
  const data = await parseBody(res);
  return data;
}

/**
 * Get all executive users.
 * @returns ExecutiveAccount[]
 */
export async function getAllExecutiveAccounts() {
  const res = await fetchAPI(`/admin/executive-accounts`, METHODS.get);
  const { users } = await parseBody(res);
  return users;
}

/**
 * Add an executive user.
 * The new user will get an email instructing them how to access their new account.
 * @param {string} name
 * @param {string} email
 * @param {string} businessEmail
 * @param {string} businessName
 */
export async function addExecutiveAccount(
  name,
  email,
  businessEmail,
  businessName
) {
  await fetchAPI(`/admin/executive-account`, METHODS.post, {
    name,
    email,
    businessEmail,
    businessName,
  });
}

/**
 * Delete an executive account, it's geozones, and any team members who aren't part of any other teams.
 * @param {string} teamId ID the the executive team.
 * @returns Promise<void>
 */
export async function deleteExecutiveAccount(teamId) {
  const res = await fetchAPI(
    `/admin/executive-account/${teamId}`,
    METHODS.delete,
    {}
  );
  if (res.status !== 200) {
    let body;
    try {
      body = await res.json();
    } catch {
      throw new Error(res.statusText);
    }

    if (body.error) {
      throw new Error(body.message);
    } else {
      throw new Error(res.statusText);
    }
  }
}

/**
 * @typedef Geofence
 * @property {string} id
 * @property {string} name
 * @property {Point[][]} points
 * @property {AABB} aabb
 */

/**
 * @typedef Point
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef AABB
 * @property {Point} min
 * @property {Point} max
 */

/**
 * Get all geofences for the executive user.
 * @param {string} userId
 */
export async function getAllGeofences(userId) {
  const res = await fetchAPI(`/admin/geofences/${userId}`, METHODS.get);
  const { geofences } = await parseBody(res);
  return geofences;
}

/**
 * Add a geofence for an executive user.
 * @param {string} teamId
 * @param {Geofence} geofence
 */
export async function addGeofence(teamId, geofence) {
  await fetchAPI(`/admin/geofences/${teamId}`, METHODS.post, geofence);
}

/**
 * Create multiple geofences to an executive user (in bulk).
 * @param {string} teamId
 * @param {Geofence[]} geofences
 */
export async function addGeofences(teamId, geofences) {
  await fetchAPI(`/admin/geofences/bulk/${teamId}`, METHODS.post, {
    geofences,
  });
}

/**
 * Get all agreements concerning the organization of `teamId`.
 * @param {string} teamId
 * @returns Promise<Agreement[]>
 */
export async function getAgreementsForOrg(teamId) {
  const res = await fetchAPI(`/admin/agreements/${teamId}`, METHODS.get);
  const { agreements } = await parseBody(res);
  return agreements;
}

/**
 * Rename a geofence.
 * @param {string} id
 * @param {string} name
 * @param {string} teamId
 */
export async function renameGeofence(id, name, teamId) {
  await fetchAPI(`/admin/geozones/${teamId}/${id}`, METHODS.put, {
    name,
  });
}

/**
 * Update a geofence.
 * @param {string} id
 * @param {string} name
 * @param {Point[][]} points
 * @param {AABB} aabb
 * @param {string} teamId
 */
export async function updateGeofence(id, name, points, aabb, teamId) {
  const res = await fetchAPI(
    `/admin/geozones/detail/${teamId}/${id}`,
    METHODS.put,
    {
      name,
      points,
      aabb,
    }
  );
  await assertErrors(res);
}

/**
 * Delete a geofence.
 * @param {string} id
 * @param {string} teamId
 */
export async function deleteGeofence(id, teamId) {
  await fetchAPI(`/admin/geozones/${teamId}/${id}`, METHODS.delete);
}

/**
 * Invite multiple users to the organization with team id `teamId`.
 * @param {string} teamId
 * @param {UploadUser[]} users
 * @returns Promise<void>
 */
export async function importUsers(teamId, users) {
  const res = await fetchAPI(`/admin/import/users/${teamId}`, METHODS.post, {
    users,
  });
  if (res.status !== 200) {
    throw new Error(
      `Fetch admin/import/users/${teamId} received error: [${res.status}] ${res.statusText}`
    );
  }
}

/**
 * @typedef {object} Settings
 * @param {boolean} addAdmin
 * @param {string | null} setSubscription
 */

/**
 * Invite multiple organizations to careit.
 * @param {UploadOrg[]} users
 * @param {boolean | null} sendEmail Defaults to true.
 * @param {string | null} emailBody Defaults to the standard invite email.
 * @param {string | null} linkedCorp Team id of coporate account to link all uploaded orgs to. Set to null to disable.
 * @param {Settings} settings Various import settings.
 * @returns Promise<void>
 */
export async function importOrgs(
  orgs,
  sendEmail,
  emailBody,
  linkedCorp,
  settings
) {
  const res = await fetchAPI('/admin/import/orgs', METHODS.post, {
    orgs,
    sendEmail,
    emailBody,
    linkedCorp,
    settings,
  });

  const { result } = await parseBody(res);

  return result;
}

/**
 * Resend an invite to an invited team user.
 * @param {string} userId
 * @param {string} teamId
 */
export async function resendInvite(userId, teamId) {
  await fetchAPI('/admin/resend-invite', METHODS.post, { userId, teamId });
}

/**
 * Update a user's email.
 * NOTE: This does not send another invite to the user in any case.
 * @param {string} userId
 * @param {string} email
 */
export async function updateUserEmail(userId, email) {
  await fetchAPI(`/admin/update-email/${userId}`, METHODS.put, { email });
}

/**
 * Get all teams the current signed in user is a part of if no userId is mentioned otherwise returns teams of userId.
 * @param {string} userId
 * @returns Promise<Team[]>
 */
export async function getTeamsOfUser(userId) {
  const res = await fetchAPI(`/admin/user/teams/${userId}`, METHODS.get);
  const { teams } = await parseBody(res);
  return teams;
}

/**
 * Get all corporate accounts.
 * @returns CorporateAccount[]
 */
export async function getAllCorporateAccounts() {
  const res = await fetchAPI(`/admin/corporate-orgs`, METHODS.get);
  const { orgs } = await parseBody(res);
  return orgs;
}

/**
 * Get teams that aren't part of existing team list in coporate account.
 * @param {string} param0.teamId
 * @param {string} param0.name
 */
export async function getEligibleTeamsToAddToCoporateAccount({
  teamId,
  name = '',
}) {
  const res = await fetchAPI(
    `/admin/corporate-org/teams-to-add/${teamId}?${qs.stringify({ name })}`,
    METHODS.get
  );
  const { teams } = await parseBody(res);
  return teams;
}

/**
 * Add a corporate account.
 * This will also create a new admin user. They will get an email instructing
 * them how to access their new account.
 * @param {string} param0.name
 * @param {string} param0.email
 * @param {string} param0.businessEmail
 * @param {string} param0.businessName
 */
export async function addCoporateAccount({
  name,
  email,
  businessEmail,
  businessName,
}) {
  await fetchAPI(`/admin/corporate-org`, METHODS.post, {
    name,
    email,
    businessEmail,
    businessName,
  });
}

/**
 * Delete a corporate account, it's corporate team relations, and any team members who aren't part of any other teams.
 * @param {string} teamId ID the the executive team.
 * @returns Promise<void>
 */
export async function deleteCorporateAccount(teamId) {
  const res = await fetchAPI(
    `/admin/corporate-org/${teamId}`,
    METHODS.delete,
    {}
  );

  if (res.status !== 200) {
    let body;
    try {
      body = await res.json();
    } catch {
      throw new Error(res.statusText);
    }

    if (body.error) {
      throw new Error(body.message);
    } else {
      throw new Error(res.statusText);
    }
  }
}

export async function getCorpTeams(teamId) {
  const res = await fetchAPI(
    `/admin/corporate-org/teams/${teamId}`,
    METHODS.get
  );

  const { teams } = await parseBody(res);
  return teams;
}

export async function addCorpTeam({ corpTeamId, teamId }) {
  const res = await fetchAPI(
    `/admin/corporate-org/team/${corpTeamId}/${teamId}`,
    METHODS.post
  );

  if (res.status !== 200) {
    let body;
    try {
      body = await res.json();
    } catch {
      throw new Error(res.statusText);
    }

    if (body.error) {
      throw new Error(body.message);
    } else {
      throw new Error(res.statusText);
    }
  }
}

export async function removeCorpTeam({ corpTeamId, teamId }) {
  const res = await fetchAPI(
    `/admin/corporate-org/team/${corpTeamId}/${teamId}`,
    METHODS.delete
  );

  if (res.status !== 200) {
    let body;
    try {
      body = await res.json();
    } catch {
      throw new Error(res.statusText);
    }

    if (body.error) {
      throw new Error(body.message);
    } else {
      throw new Error(res.statusText);
    }
  }
}

/**
 * Estimate the timezoneId for the given address.
 * Returns null if a timezoneId cannot be resolved for the address.
 * @param {string} param0.streetAddress
 * @param {string} param0.city
 * @param {string} param0.state
 * @param {string} param0.country
 * @returns Promise<string | null>
 */
export async function estimateTimezoneId({
  streetAddress,
  city,
  state,
  country,
}) {
  const res = await fetchAPI('/admin/estimate-timezone-id', METHODS.post, {
    address: { streetAddress, city, state, country },
  });
  const { timezoneId } = await parseBody(res);
  return timezoneId;
}

/**
 * Estimate the zip/postal code for the given address.
 * Returns null if a zip/postal code cannot be resolved for the address.
 * @param {string} param0.streetAddress
 * @param {string} param0.city
 * @param {string} param0.state
 * @param {string} param0.country
 * @returns Promise<string | null>
 */
export async function estimateZipPostalCode({
  streetAddress,
  city,
  state,
  country,
}) {
  const res = await fetchAPI('/admin/estimate-zip-code', METHODS.post, {
    address: { streetAddress, city, state, country },
  });
  const { zipPostalCode } = await parseBody(res);
  return zipPostalCode;
}

/**
 * Merge one or more organizations into a the given organization (`into`).
 * This will then delete all organizations in the `organizations` list. The
 * procedure is irreversible and may result in data loss. This will throw an
 * error if active subscription plans exist on any of the organizations which
 * will be deleted.
 * @param {string} param.into Team id of organization to have the others merged into.
 * @param {string[]} param.organizations Team ids of organizations to merge into `param.into`.
 * @returns {Promise<void>}
 */
export async function mergeOrganizations({ into, organizations }) {
  const res = await fetchAPI('/admin/merge/organizations', METHODS.post, {
    into,
    organizations,
  });
  await assertErrors(res);
}

/**
 * @typedef Resources
 * @property {string} id
 * @property {string} name
 * @property {string} formatted_address
 * @property {number} donations
 * @property {number} rescues
 * @property {number} foodRuns
 * @property {number} teamMembers
 * @property {number} geozones
 * @property {number} inspections
 * @property {number} corporateOrgs
 */

/**
 * @typedef {"DONATIONS" | "RESCUES" | "FOOD_RUNS" | "TEAM_MEMBERS" | "GEOZONES" | "INSPECTIONS" | "CORPORATE_ORGS"} Entity
 */

/**
 * Count the amount of `entity` owned by the given organization (by team id).
 * @param {Entity} entity Name of the entity to tally up.
 * @param {string} teamId Team id of organizations to count the resources of.
 * @returns {Promise<number>} The count of the given entity.
 */
export async function count(entity, teamId) {
  const res = await fetchAPI(`/admin/count/${entity}/${teamId}`, METHODS.get);
  const { count } = await parseBody(res);
  return count;
}

/**
 * Count the amount of all resources owned by the given organization (by team id).
 * @param {string} teamId Team id of organizations to count the resources of.
 * @returns {Promise<Resources>}
 */
export async function countAll(teamId) {
  const res = await fetchAPI(`/admin/count/ALL/${teamId}`, METHODS.get);
  const { count } = await parseBody(res);
  return count;
}

/**
 * @typedef UserSessionHistory
 * @property {string} id
 * @property {string} browser_name
 * @property {string} browser_version
 * @property {string} os_name
 * @property {string} os_version
 * @property {string} user_id
 * @property {string} team_id
 * @property {string} event
 * @property {string} ipv4
 * @property {string} ipv6
 * @property {Date} created
 *
 */

/**
 * Gets all user session history
 * @returns Promise<UserSessionHistory[]>
 */
export async function getUserSessionHistory({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/admin/user-session-history?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );
  const { userSessionHistory } = await parseBody(res);
  return userSessionHistory;
}

/**
 * Send email verification link to org admin
 * @param {string} teamId Team id of organization to send verification link.
 * @returns Promise<UserSessionHistory[]>
 */
export async function sendVerificationEmailToOrgAdmin(teamId) {
  const res = await fetchAPI(`/admin/org/verify-email`, METHODS.put, {
    teamId,
  });
  await assertErrors(res);
  return parseBody(res);
}

export async function toggleOrgStatus(args) {
  const res = await fetchAPI(`/admin/toggle-org-status`, METHODS.post, args);
  await assertErrors(res);
  return parseBody(res);
}
