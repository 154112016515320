import moment from 'moment-timezone';

/**
 * Given a JS date, moment date or date-formatted string with the timezone in
 * UTC, convert it to the given user's timezone. The retuned data is a js date.
 *
 * If a fmt string is passed, this will return the date formatted according to
 * that string.
 *
 * @param {(Date | moment | string)?} date
 * @param {{ timezone_id: string? }} user
 * @param {string?} fmt
 * @returns {Date}
 */
export function parseUTC(date, user, fmt) {
  if (!date) {
    return null;
  }

  let parsed = moment.utc(date);
  if (user.timezone_id) {
    parsed = parsed.tz(user.timezone_id);
  } else {
    parsed = parsed.local();
  }

  if (fmt) {
    return parsed.format(fmt);
  }

  // HACK: We have to recreate the moment without any timezone information in
  //       order to prevent toDate from returning the time in local time.
  return moment(
    parsed.format('YYYY-MM-DD HH:mm:ss'),
    'YYYY-MM-DD HH:mm:ss'
  ).toDate();
}

const timezones = new Set(moment.tz.names());

/**
 * Validates that the given timezone is valid.
 * @param {string} timezone
 * @returns {boolean} If true, the timezone is valid.
 */
export function validateTimezone(timezone) {
  return timezones.has(timezone);
}
