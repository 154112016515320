import { fetchAPI, METHODS, parseBody } from './common';
/**
 * Fetch all cancellation reasons that are not created by user
 */
export async function fetchCancellationReasons() {
  const res = await fetchAPI(`/cancel/reasons`, METHODS.get, null);
  return await parseBody(res);
}

/**
 * Fetch cancellation report
 */
export async function fetchCancellationReport({ step, offset, filterModel }) {
  const res = await fetchAPI(`/cancel/fetch-report`, METHODS.post, {
    step,
    offset,
    filterModel,
  });
  return await parseBody(res);
}
