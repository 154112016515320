import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import QS from 'qs';

/**
 * Use query parameters in the current URL (after the '?'). This will return
 * the parsed result from a query string in an object. A url with no query
 * paramaters will result in an empty object.
 * @returns {object}
 */
export default function useSearchQuery() {
  const location = useLocation();
  return useMemo(() => {
    const query = location.search.slice(1);
    return QS.parse(query);
  }, [location.search]);
}
