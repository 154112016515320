import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  List,
  Drawer,
  Typography,
  ListSubheader,
  Menu,
  MenuItem,
  Divider,
  TextField,
  Tooltip,
} from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import useSidebarConfig from './SidebarConfig';
import SidebarItem from './SidebarItem';
import useTeams from 'src/hooks/useTeams';
import { Add, KeyboardArrowDown, Search } from '@mui/icons-material';
import { checkRoute, PLAN_ID_SELECTOR } from 'src/utils/permissions';
import useEventBus from 'src/hooks/useEventBus';
import { PLANS } from 'src/views/Pricing';
import { sortBy } from 'lodash';
import useGeofences from 'src/hooks/useGeofences';
import useGeozonesSelectorDialog from 'src/layouts/dashboard/useGeozonesSelectorDialog';
import { useTranslation } from 'react-i18next';
import useDebounce from 'src/hooks/useDebounce';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

function reduceChild({ user, array, item, pathname, level }) {
  const key = item.href + level;

  const items = (item.items ?? []).filter(({ href }) => checkRoute(user, href));
  if (items.length > 0) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        user={user}
        level={level}
        badge={item.badge}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items,
          user,
        })}
      </SidebarItem>,
    ];
  }

  return [
    ...array,
    <SidebarItem
      key={key}
      user={user}
      level={level}
      href={item.href}
      badge={item.badge}
      icon={item.icon}
      info={item.info}
      title={item.title}
      crossOrigin={item.crossOrigin ?? false}
    />,
  ];
}

function renderSidebarItems({ user, items: i, pathname, level = 0 }) {
  let items = i;
  if (typeof items === 'function') items = items(user);

  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ user, array, item, pathname, level }),
        []
      )}
    </List>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

function groupTeams(teams) {
  let executive = [];
  let corporate = [];
  let admin = [];
  let staff = [];
  let driver = [];

  for (const team of teams) {
    if (team.is_executive) {
      executive.push(team);
    } else if (team.is_corporate) {
      corporate.push(team);
    } else if (team.is_admin) {
      admin.push(team);
    } else if (team.is_driver) {
      admin.push(team);
    } else {
      staff.push(team);
    }
  }

  return [executive, corporate, admin, staff, driver].map((group) =>
    sortBy(group, 'name')
  );
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  handleDonationOpen,
}) {
  const { pathname } = useLocation();
  const MenuLinks = useSidebarConfig();
  const { user } = useAuth();
  const { teams, refresh, switchTo } = useTeams();
  const { teams: searchTeams, refresh: refetchTeams } = useTeams();
  const { t } = useTranslation('translation');
  const emitCreateFoodRun = useEventBus('foodrun', 'create');
  const { handleChangeGeozones, GeozonesSelectorDialog } =
    useGeozonesSelectorDialog();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { activeGeofences, geofences } = useGeofences();

  const [canSelectGeozones] = useMemo(() => {
    const isExecutive = !!user.is_executive;

    return [isExecutive];
  }, [user]);

  const teamsGrouped = useMemo(() => groupTeams(searchTeams), [searchTeams]);

  const [canCreateFoodRuns] = useMemo(() => {
    const currentPlan =
      PLANS.find(
        ({ priceId }) =>
          priceId[PLAN_ID_SELECTOR] === user.subscription_price_id
      )?.item ?? 'free';
    const planActive = user.subscription_status?.toUpperCase() === 'ACTIVE';
    const plan = planActive ? currentPlan : 'free';

    const isNPO = !!user.is_non_profit;

    return [
      plan === 'foodMover' && isNPO && (user.is_admin || !user.is_driver),
    ];
  }, [user]);

  const canDisplayCreateDonationatioBtn = useMemo(
    () =>
      !(
        !user.team_id ||
        user.is_non_profit ||
        user.is_executive ||
        user.is_corporate
      ),
    [user]
  );

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    refresh();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    refetchTeams({
      search: debouncedSearchQuery,
    });
  }, [debouncedSearchQuery]);

  const renderContent = (
    <>
      <Scrollbar>
        <Box sx={{ px: 2.5, py: 3 }}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>

        <AccountStyle onClick={handleClick}>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user.business_name}
            </Typography>
          </Box>
          <Box flex="1" />
          {teams.length > 1 && <KeyboardArrowDown color="disabled" />}
        </AccountStyle>

        <Menu
          sx={{
            '& .MuiPaper-root': {
              scrollbarWidth: 'thin',
            },
          }}
          anchorEl={anchorEl}
          open={open && teams.length > 1}
          onClose={handleClose}
        >
          <Box width="240px" />
          <MenuItem disabled>{t('sidebar.switch-teams')}:</MenuItem>
          {teams.length >= 10 ? (
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                mx: 1.2,
                mb: 1,
                backgroundColor: 'white',
                zIndex: 20,
                pt: 1,
              }}
            >
              <TextField
                fullWidth
                size="small"
                placeholder={t('common.search')}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                InputProps={{
                  endAdornment: (
                    <Search
                      sx={{
                        fill: (t) => t.palette.grey[500],
                      }}
                    />
                  ),
                }}
              />
            </Box>
          ) : null}
          {teamsGrouped
            .filter((group) => group.length > 0)
            // render each item in each group
            .map((group) =>
              group
                .filter(({ team_id }) => team_id !== user.team_id)
                .map((team) => (
                  <MenuItem
                    key={team.team_id}
                    onClick={() => {
                      switchTo(team.team_id);
                      handleClose();
                    }}
                  >
                    <Typography
                      sx={{
                        width: '100%',
                        position: 'relative',
                        overflow: 'hidden',
                        pb: '24px',
                      }}
                    >
                      {team.name}
                      <Box
                        sx={{
                          position: 'absolute',
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        {!team.is_executive && !team.is_corporate ? (
                          <Typography variant="caption" paragraph={false}>
                            {team.province}, {team.city}, {team.street_address}
                          </Typography>
                        ) : (
                          <Typography variant="caption" paragraph={false}>
                            {team.is_executive
                              ? t('sidebar.executive-account')
                              : t('sidebar.corporate-account')}
                          </Typography>
                        )}
                      </Box>
                    </Typography>
                  </MenuItem>
                ))
            )
            // place a divider between groups
            .reduce(
              (otherGroups, group) =>
                otherGroups.length > 0
                  ? // eslint-disable-next-line react/jsx-key
                    [...otherGroups, <Divider />, ...group]
                  : group,
              []
            )}
        </Menu>

        {canCreateFoodRuns && (
          <CreateButton type="food-run" onClick={emitCreateFoodRun} />
        )}
        {canDisplayCreateDonationatioBtn && (
          <CreateButton type="donation" onClick={handleDonationOpen} />
        )}

        {geofences.length > 1 && canSelectGeozones && (
          <Box
            width="100%"
            px="22px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="outlined"
              onClick={handleChangeGeozones}
              sx={{
                backgroundColor: '#467F71',
                color: '#ffffff',
              }}
            >
              {activeGeofences.length === 0
                ? `${t('executive.home.choose-geozones', {
                    activeGeofencesLength: activeGeofences.length,
                  })}`
                : `${t('executive.home.change-geozones', {
                    activeGeofencesLength: activeGeofences.length,
                  })}`}
            </Button>
          </Box>
        )}

        {MenuLinks.map((list) => {
          let items = list.items;
          if (typeof items === 'function') items = items(user);

          // if no items pass the perms check then don't render the section
          if (!items.map(({ href }) => checkRoute(user, href)).find((x) => x))
            return <></>;

          return (
            items.length !== 0 && (
              <List
                disablePadding
                key={list.subheader}
                subheader={
                  <ListSubheader
                    disableSticky
                    disableGutters
                    sx={{
                      mt: 3,
                      mb: 2,
                      pl: 5,
                      color: 'text.primary',
                      typography: 'overline',
                    }}
                  >
                    {list.subheader.replace(/#/g, '')}
                  </ListSubheader>
                }
              >
                {renderSidebarItems({
                  user,
                  items,
                  pathname,
                })}
              </List>
            )
          );
        })}
      </Scrollbar>

      <Typography textAlign="center" variant="caption" sx={{ m: 1 }}>
        {t('careit-co')} &copy; {new Date().getFullYear()} -{' '}
        <RouterLink
          to={{
            pathname: 'https://careit.com/terms-of-service/',
          }}
          target="_blank"
          rel="noreferrer"
          underline="always"
          sx={{ color: 'text.primary' }}
        >
          {t('sidebar.terms-of-service')}
        </RouterLink>
      </Typography>
    </>
  );

  return (
    <RootStyle>
      <Box
        display={{
          xl: 'none',
          lg: 'none',
          md: 'block',
          sm: 'block',
          xs: 'block',
        }}
      >
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </Box>
      <Box
        display={{
          xl: 'block',
          lg: 'block',
          md: 'none',
          sm: 'none',
          xs: 'none',
        }}
      >
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' },
          }}
        >
          {renderContent}
        </Drawer>
      </Box>

      <GeozonesSelectorDialog />
    </RootStyle>
  );
}

const CreateButton = ({ onClick, type }) => {
  const { user } = useAuth();
  const businessIsActive = !!user.is_business_visible_to_others;
  const { t } = useTranslation('translation');

  const createBtnText = {
    donation: t('sidebar.create-donation'),
    'food-run': t('sidebar.create-food-run'),
  };
  return (
    <Tooltip
      open={businessIsActive ? false : undefined}
      title={
        type == 'donation'
          ? t('sidebar.organization-disabled', {
              context: 'donation',
            })
          : t('sidebar.organization-disabled', {
              context: 'food-run',
            })
      }
    >
      <Box
        width="100%"
        px="22px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          disabled={!businessIsActive}
          sx={{ width: '100%', height: '100%' }}
          variant="outlined"
          onClick={onClick}
          startIcon={<Add />}
        >
          {createBtnText[type]}
        </Button>
      </Box>
    </Tooltip>
  );
};
