import { assertErrors, fetchAPI, METHODS, parseBody } from './common';

/**
 * @typedef User
 * @property {string} password
 * @property {Profile} profile
 */

/**
 * @typedef Profile
 * @property {string} name
 * @property {string} email
 * @property {string} phone
 * @property {string} userAccountType
 * @property {BusinessInfo} businessInfo
 */

/**
 * @typedef BusinessInfo
 * @property {string} name
 * @property {string} phone
 * @property {Location} location
 * @property {string} ein
 */

/**
 * @typedef Location
 * @property {string} lat
 * @property {string} lng
 * @property {string} formattedAddress
 * @property {string} streetAddress
 * @property {string} suiteNumber
 * @property {string} city
 * @property {string} province
 * @property {string} country
 * @property {string} zipCode
 * @property {string} name
 * @property {string} placeId
 */

/**
 * Checks if the email is already being used
 * @param {string} email
 * @returns Promise<boolean>
 */
export async function checkEmail(email) {
  const res = await fetchAPI(`/auth/checkemail?email=${email}`, METHODS.get);
  const body = await parseBody(res);

  return body.isPresent;
}

/**
 * Register a new user
 * @param {User} user
 * @returns Promise<User>
 */
export async function register(user) {
  const res = await fetchAPI('/auth/register', METHODS.post, user);
  const body = await parseBody(res);
  return body;
}

/**
 * AUTH FLOW
 * - User Enters Creds
 * - PUT /user/get-team `getTeams(email, password)`
 * - User selects team (or we can default to the first if there is only one?)
 * - PUT /auth/login `login(email, password, teamId)`
 */

/**
 * @typedef Team
 * @property {string} team_id
 * @property {string} user_id
 * @property {boolean} is_admin
 * @property {string} name
 * @property {string} email
 * @property {string} phone
 * @property {number} lat
 * @property {number} lng
 * @property {string} place
 * @property {string} ein
 * @property {string} team_id
 * @property {string} description
 * @property {string} formatted_address
 * @property {string} street_address
 * @property {string} city
 * @property {string} province
 * @property {string} zip_code
 * @property {string} place_id
 * @property {string} suite_number
 */

/**
 * List all teams that the user is a part of (used in the auth flow).
 * @param {string} email
 * @param {string} password
 * @returns Promise<Team[]>
 */
export async function getTeams(email, password) {
  const res = await fetchAPI('/auth/get-team', METHODS.put, {
    email,
    password,
  });
  const body = await parseBody(res);

  return body;
}

/**
 * Log-in a user into an existing account
 * @param {string} email
 * @param {string} password
 * @param {string} teamId
 * @returns Promise<User>
 */
export async function login(email, password, teamId) {
  const res = await fetchAPI('/auth/login', METHODS.put, {
    email,
    password,
    teamId,
  });
  const body = await parseBody(res);

  return body;
}

/**
 * Fetch's user data - used for initialization from a saved token
 * Throws an error id the token is invalid.
 * @returns Promise<User>
 */
export async function getUserData() {
  const res = await fetchAPI('/user/me', METHODS.get);
  return parseBody(res);
}

/**
 * Verify email.
 * Throws an error if the token is invalid.
 * @returns Promise<Object>
 */
export async function verifyEmail(verificationToken) {
  const res = await fetchAPI('/auth/verify-email', METHODS.post, {
    token: verificationToken,
  });
  return parseBody(res);
}

/**
 * Reverify email.
 * Throws an error if the token is invalid.
 * @returns Promise<Object>
 */
export async function reverifyEmail(verificationToken) {
  const res = await fetchAPI('/auth/reverify-email', METHODS.post, {
    token: verificationToken,
  });
  return parseBody(res);
}

/**
 * Get email suppression status.
 * @returns Promise<Object>
 */
export async function getSuppressionStatus(email) {
  const res = await fetchAPI('/email/suppression-status', METHODS.post, {
    email,
  });
  return parseBody(res);
}

/**
 * Resend email verification.
 * @returns Promise<Object>
 */
export async function resendEmailVerification({ email, verificationToken }) {
  const res = await fetchAPI('/auth/verify-email', METHODS.put, {
    email,
    verificationToken,
  });
  return parseBody(res);
}

/**
 * Send verification email to unsuppress the email address
 * @returns Promise<Object>
 */
export async function sendVerificationToUnsuppressEmail({ email }) {
  const res = await fetchAPI(
    '/email/send-unsuppress-verification',
    METHODS.put,
    {
      email,
    }
  );
  return parseBody(res);
}

/**
 * Request a password reset via email (and OTP)
 * returns a message for the user
 * @param {string} email
 * @returns Promise<string>
 */
export async function resetPasswordOTP(email) {
  await fetchAPI('/auth/reset-password-otp', METHODS.post, {
    email,
  });
}

/**
 * Using a user provided OTP (sent via email) reset the user's password
 * returns a message for the user
 * @param {string} email
 * @param {string} password
 * @param {string} OTP
 * @returns Promise<Response>
 */
export async function resetPassword(email, password, OTP) {
  const res = await fetchAPI('/auth/reset-password', METHODS.post, {
    email,
    password,
    OTP,
  });
  const body = await parseBody(res);

  return body.message;
}

/**
 * Change loggedin user's password
 * returns a message for the user
 * @param {string} param0.currentPassword
 * @param {string} param0.newPassword
 * @returns Promise<Response>
 */
export async function changePassword({ currentPassword, newPassword }) {
  const res = await fetchAPI('/auth/change-password', METHODS.post, {
    currentPassword,
    newPassword,
  });
  const body = await parseBody(res);
  return body;
}

/**
 * Accepts a team member invite
 * Returns the updated user
 * @param {string} data.email
 * @param {string} data.password
 * @param {string} data.teamId
 * @param {string} data.phone
 * @param {string} data.fullName
 * @returns Promise<User>
 */
export async function acceptTeamMember(data) {
  const res = await fetchAPI('/auth/accept-team-member', METHODS.put, data);
  const body = await parseBody(res);
  return body;
}

/**
 * Join a team via an invite link.
 * Returns a session for the (possibly new) user logged into the given team.
 * @param {string} data.teamId
 * @param {string | null} data.email    Required if the user isn't already logged in
 * @param {string | null} data.password Required if the user isn't already logged in
 * @param {string | null} data.name     Required if the user does not yet have an account
 * @param {string | null} data.phone    Required if the user does not yet have an account
 * @returns Promise<Session>
 */
export async function joinTeam({
  teamId,
  email,
  password,
  name,
  phone,
  inviteLinkName,
  inviteLinkPassword,
}) {
  const res = await fetchAPI(`/auth/join-team/${teamId}`, METHODS.post, {
    email,
    password,
    name,
    phone,
    inviteLinkName,
    inviteLinkPassword,
  });
  const session = await parseBody(res);
  return session;
}

// TODO: can an attacker keep a token forever by switching teams?

/**
 * @typedef {object} NewTokens
 * @property {string} accessToken
 * @property {string} refreshToken
 */

/**
 * Use a refresh token, this revokes and token and returns a new refresh/access
 * token pair.
 *
 * @param {string} refreshToken
 * @returns {Promise<NewTokens>}
 */
export async function useRefreshToken(refreshToken) {
  const res = await fetchAPI(
    '/auth/refresh-token',
    METHODS.post,
    {
      refreshToken,
    },
    { noAuthRefresh: true }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Revoke a refresh token and do not create any new tokens. Do this when the
 * user logs out or switches teams.
 *
 * @param {string} refreshToken
 * @returns {Promise<void>}
 */
export async function revokeRefreshToken(refreshToken) {
  const res = await fetchAPI(
    '/auth/refresh-token',
    METHODS.delete,
    {
      refreshToken,
    },
    { noAuthRefresh: true }
  );
  await assertErrors(res);
}
