import { useCallback, useEffect, useState } from 'react';
import { donations } from 'src/backend';

export default function useDonation(id) {
  const [donation, setDonation] = useState(null);
  const [valid, setValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => setValid(false), [id]);

  useEffect(() => {
    if (!id) {
      setDonation(null);
      return;
    }

    if (valid) {
      return;
    }

    setValid(true);
    setIsLoading(true);

    donations
      .getById(id)
      .then(setDonation)
      .finally(() => setIsLoading(false));
  }, [id, valid]);

  const invalidate = useCallback(() => setValid(false), [setValid]);

  return {
    donation,
    isLoading,
    invalidate,
  };
}
