import { fetchAPI, parseBody, assertErrors, METHODS } from './common';
import qs from 'qs';

/**
 * @typedef DonationsResult
 * @param {number} count
 * @param {Donation[]} results
 */

/**
 * @typedef OrganizationResult
 * @param {number} count
 * @param {Organization[]} results
 */

/**
 * Get top donors associated with the current corporate account teams.
 * @param {number} offset
 * @param {number} step
 * @param {FilterModelObj} filterModel
 * @returns DonorsResult
 */
export async function getTopDonors({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/corporate/top-donors?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );

  const { donors } = await parseBody(res);
  return donors;
}

export async function archiveNpo({ isActive, npoTeamId, corporateTeamId }) {
  const res = await fetchAPI(`/corporate/npos/archive`, METHODS.post, {
    isActive,
    npoTeamId,
    corporateTeamId,
  });
  return await parseBody(res);
}

/**
 * Get all donations belonging to the current corporate account.
 * @param {number} offset
 * @param {number} step
 * @param {FilterModelObj} filterModel
 * @param {string[]} organizations List of organization team ids to filter
 * @returns DonationsResult
 */
export async function getDonations({
  offset,
  step,
  filterModel,
  organizations,
}) {
  const res = await fetchAPI(
    `/corporate/donations?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel, organizations }
  );

  const { donations } = await parseBody(res);
  return donations;
}

/**
 * Get all teams belonging to the current corporate account.
 * @returns Organization[]
 */
export async function getOrganizations() {
  const res = await fetchAPI('/corporate/organizations', METHODS.get);
  const { organizations } = await parseBody(res);
  return organizations;
}

/**
 * Get all teams belonging to the current corporate account.
 * @param {number} offset
 * @param {number} step
 * @param {FilterModelObj} filterModel
 * @returns OrganizationResult
 */
export async function getOrganizationsSSF({ offset, step, type, filterModel }) {
  const res = await fetchAPI(
    `/corporate/organizations/ssf?${qs.stringify({ offset, step, type })}`,
    METHODS.post,
    { filterModel }
  );
  const { organizations } = await parseBody(res);
  return organizations;
}

/**
 * Get details about a team belonging to the current corporate account.
 * @param {string} id
 * @returns {Promise<OrganizationResult>}
 */
export async function getOrganization(id) {
  const res = await fetchAPI(`/corporate/organizations/${id}`, METHODS.get);
  await assertErrors(res);
  const { organization } = await parseBody(res);
  return organization;
}

export async function getCorporateReport({ month, year }) {
  const res = await fetchAPI(
    `/corporate/report?${qs.stringify({ month, year })}`,
    METHODS.get,
    undefined
  );
  const { report } = await parseBody(res);
  return report;
}

/**
 * @typedef OnboardingItem
 * @property {string} title
 * @property {bool} is_required
 * @property {Date} created (in UTC)
 * @property {Date?} updated (in UTC)
 */

/**
 * @typedef PaginatedOnboardingItems
 * @property {OnboardingItem} items
 * @property {number?} count
 */

/**
 * @typedef OnboardingRequestProgress
 * @property {string} id
 * @property {string} title
 * @property {string} name
 * @property {Date} created (in UTC)
 * @property {number} needed_items
 * @property {number} completed_items
 */

/**
 * @typedef PaginatedOnboardingRequestsProgress
 * @property {OnboardingItem} items
 * @property {number?} count
 */

/**
 * @typedef CreateOnboardingItemDetails
 * @property {string} id
 * @property {string} title
 * @property {bool} is_required
 * @property {Date} created  in UTC
 * @property {Date?} updated  in UTC
 * @property {string?} agreement  In Quill text format
 * @property {string?} document  URL of PDF in S3
 * @property {OnboardingItemForm?} form
 */

/**
 * @typedef OnboardingItemDetails
 * @property {string} id
 * @property {string} title
 * @property {bool} is_required
 * @property {Date} created  in UTC
 * @property {Date?} updated  in UTC
 * @property {string?} agreement  In Quill text format
 * @property {string?} document  URL of PDF in S3
 * @property {OnboardingItemForm?} form
 */

/**
 * @typedef OnboardingItemFormField
 * @property {string} name
 * @property {"text" | "select" | "checkbox"} type
 * @property {string | int | int[]} answer  string if type = "text",
 *                                          int if type = "select",
 *                                          int[] if type = "checkbox"  (int refers to the index in `options`)
 * @property {string[]?} options  present iff type in ("select", "checkbox")
 */

/**
 * @typedef OnboardingItemForm
 * @property {OnboardingItemFormField[]} form
 * @property {number} min_score  Score required to pass (in range [0, 1])
 */

/**
 * Get all paginated items (without details).
 * @param {object} opts
 * @param {number?} opts.step  Set to -1 or leave undefined to disable pagination
 * @param {number?} opts.offset
 * @param {FilterModelObj} opts.filterModel
 * @returns {Promise<PaginatedOnboardingItems>}
 */
export async function getAllOnboardingItems({
  step = -1,
  offset = -1,
  filterModel,
}) {
  const res = await fetchAPI(
    `/corporate/onboarding/items/get?${qs.stringify({ step, offset })}`,
    'post',
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get all paginated items (without details) for a compliance profile.
 * @param {object} opts
 * @param {number?} opts.step  Set to -1 or leave undefined to disable pagination
 * @param {number?} opts.offset
 * @param {FilterModelObj} opts.filterModel
 * @param {string} opts.prodileId
 * @returns {Promise<PaginatedOnboardingItems>}
 */
export async function getAllOnboardingItemsForProfile({
  step = -1,
  offset = -1,
  filterModel,
  profileId,
}) {
  const res = await fetchAPI(
    `/corporate/onboarding/items/profile/${profileId}/get?${qs.stringify({
      step,
      offset,
    })}`,
    'post',
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get details for an onboarding item.
 * @param {string} itemId
 * @returns {Promise<OnboardingItemDetails>}
 */
export async function getOnboardingItemDetails(itemId) {
  const res = await fetchAPI(`/corporate/onboarding/item/${itemId}`, 'get');
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Create a new onboarding item.
 * @param {CreateOnboardingItemDetails} details
 * @returns {Promise<string>} Id of the new item
 */
export async function createOnboardingItem(details) {
  const res = await fetchAPI('/corporate/onboarding/item', 'post', details);
  await assertErrors(res);
  const { itemId } = await parseBody(res);
  return itemId;
}

/**
 * Update an existing onboarding item.
 * @param {string} itemId
 * @param {Partial<OnboardingItemDetails>} updates
 */
export async function updateOnboardingItem(itemId, updates) {
  const res = await fetchAPI(
    `/corporate/onboarding/item/${itemId}`,
    'put',
    updates
  );
  await assertErrors(res);
}

/**
 * Delete an existing onboarding item.
 * @param {string} itemId
 */
export async function deleteOnboardingItem(itemId) {
  const res = await fetchAPI(`/corporate/onboarding/item/${itemId}`, 'delete');
  await assertErrors(res);
}

/**
 * Get all onboarding requests for a corporation.
 */
export async function getOnboardingRequests() {
  const res = await fetchAPI('/corporate/onboarding/request', 'get');
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get the onboarding request for a single business.
 * @param {string} teamId
 * @param {string} complianceProfileId
 */
export async function getOnboardingRequest(teamId, complianceProfileId) {
  const res = await fetchAPI(
    `/corporate/onboarding/request/${teamId}/${complianceProfileId}`,
    'get'
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Request an NPO to complete some onboarding.
 * @param {string} teamId  Id of NPO to request
 * @param {string} profileId  Id of compliance profile
 */
export async function createOnboardingRequest(teamId, profileId) {
  const res = await fetchAPI('/corporate/onboarding/request', 'post', {
    teamId,
    profileId,
  });
  await assertErrors(res);
}

/**
 * Cancel an active onboarding request.
 * @param {string} teamId  Id of NPO associated with the request
 * @param {string} complianceProfileId  Id of compliance profile associated with the request
 */
export async function cancelOnboardingRequest(teamId, complianceProfileId) {
  const res = await fetchAPI(
    `/corporate/onboarding/request/${teamId}/${complianceProfileId}`,
    METHODS.delete
  );
  await assertErrors(res);
}

/**
 * Get all paginated items with progress details for an NPO (without details).
 * @param {object} opts
 * @param {number?} opts.step  Set to -1 or leave undefined to disable pagination
 * @param {number?} opts.offset
 * @param {import('./rescues').FilterModelObj} opts.filterModel
 * @param {string} opts.teamId Id of the NPO
 * @param {string} opts.complianceProfileId Id of the compliance profile
 * @returns {Promise<PaginatedOnboardingItems>}
 */
export async function getOnboardingProgress({
  step = -1,
  offset = -1,
  filterModel,
  teamId,
  complianceProfileId,
}) {
  const res = await fetchAPI(
    `/corporate/onboarding/progress/${teamId}/${complianceProfileId}/items/get?${qs.stringify(
      {
        step,
        offset,
      }
    )}`,
    'post',
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get all paginated onboarding requests with progress details for an NPO (without details).
 * @param {object} opts
 * @param {number?} opts.step  Set to -1 or leave undefined to disable pagination
 * @param {number?} opts.offset
 * @param {import('./rescues').FilterModelObj} opts.filterModel
 * @param {string} opts.teamId Id of the NPO
 * @returns {Promise<PaginatedOnboardingRequestsProgress>}
 */
export async function getOnboardingRequestsProgressForNPO({
  step = -1,
  offset = -1,
  filterModel,
  teamId,
}) {
  const res = await fetchAPI(
    `/corporate/onboarding/progress/${teamId}/requests/get?${qs.stringify({
      step,
      offset,
    })}`,
    'post',
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get details for the progress of an onboarding item.
 * @param {string} itemId
 * @param {string} teamId Team id of the NPO
 * @returns {Promise<OnboardingItemDetails>}
 */
export async function getOnboardingProgressDetails(itemId, teamId) {
  const res = await fetchAPI(
    `/corporate/onboarding/progress/${teamId}/details/${itemId}`,
    'get'
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * @typedef PaginatedComplianceProfiles
 * @property {ComplianceProfile[]} items
 * @property {number?} count Null if pagination is disabled
 */

/**
 * @typedef ComplianceProfile
 * @property {string} id
 * @property {string} title
 * @property {string} description
 * @property {string} created
 * @property {string?} updated Null if never updated
 */

/**
 * @typedef UpdateComplianceProfile
 * @property {string} title
 * @property {string} description
 */

/**
 * Get a paginated view of all compliance profiles under this account.
 * @param {object} opts
 * @param {import("./rescues").FilterModelObj} opts.filterModel
 * @param {number?} opts.step
 * @param {number?} opts.offset
 * @returns {Promise<PaginatedComplianceProfiles>}
 */
export async function getComplianceProfiles({
  step = -1,
  offset = -1,
  filterModel,
}) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profiles/get?${qs.stringify({
      step,
      offset,
    })}`,
    METHODS.post,
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Get a single compliance profile under this account.
 * @param {string} id
 * @returns {Promise<ComplianceProfile>}
 */
export async function getComplianceProfile(id) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profile/${id}`,
    METHODS.get
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Create a new compliance profile under this account.
 * @param {UpdateComplianceProfile} details
 * @returns {Promise<string>} Id of the new profile.
 */
export async function createComplianceProfile(details) {
  const res = await fetchAPI(
    '/corporate/onboarding/compliance-profile',
    METHODS.post,
    details
  );
  await assertErrors(res);
  return res.text();
}

/**
 * Update a single compliance profile under this account.
 * @param {string} id
 * @param {UpdateComplianceProfile} updates
 * @returns {Promise<ComplianceProfile>}
 */
export async function updateComplianceProfile(id, updates) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profile/${id}`,
    METHODS.put,
    updates
  );
  await assertErrors(res);
}

/**
 * Delete a single compliance profile under this account.
 * @param {string} id
 * @returns {Promise<ComplianceProfile>}
 */
export async function deleteComplianceProfile(id) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profile/${id}`,
    METHODS.delete
  );
  await assertErrors(res);
}

/**
 * Add existing onboarding items to a compliance profile.
 * @param {string} id
 * @param {string[]} items
 * @returns {Promise<void>}
 */
export async function addItemsToComplianceProfile(id, items) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profile/items/${id}`,
    METHODS.post,
    { items }
  );
  await assertErrors(res);
}

/**
 * Remove onboarding items from a compliance profile.
 * @param {string} id
 * @param {string[]} items
 * @returns {Promise<void>}
 */
export async function removeItemsFromComplianceProfile(id, items) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profile/items/${id}`,
    METHODS.delete,
    { items }
  );
  await assertErrors(res);
}

/**
 * @typedef PaginatedComplianceBusiness
 * @property {ComplianceBusiness[]} items
 * @property {number?} count Null if `step=-1` or `offset=-1`
 */

/**
 * @typedef ComplianceBusiness
 * @property {string} id
 * @property {string} name
 * @property {string} formatted_address
 */

/**
 * Get a paginated view of all associated teams for the compliance profile.
 * @param {object} opts
 * @param {import("./rescues").FilterModelObj} opts.filterModel
 * @param {string} opts.profileId
 * @param {number?} opts.step
 * @param {number?} opts.offset
 * @returns {Promise<PaginatedComplianceBusiness>}
 */
export async function getTeamsForComplianceProfile({
  profileId,
  step = -1,
  offset = -1,
  filterModel,
}) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profile/teams/${profileId}/get?${qs.stringify(
      {
        step,
        offset,
      }
    )}`,
    METHODS.post,
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Add associated teams to a compliance profile.
 * @param {string} id
 * @param {string[]} teams
 * @returns {Promise<void>}
 */
export async function addTeamsToComplianceProfile(id, teams) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profile/teams/${id}`,
    METHODS.post,
    { teams }
  );
  await assertErrors(res);
}

/**
 * Remove associated teams from a compliance profile.
 * @param {string} id
 * @param {string[]} teams
 * @returns {Promise<void>}
 */
export async function removeTeamsFromComplianceProfile(id, teams) {
  const res = await fetchAPI(
    `/corporate/onboarding/compliance-profile/teams/${id}`,
    METHODS.delete,
    { teams }
  );
  await assertErrors(res);
}

/**
 * Accept or reject the an NPO's submission for an onboarding item.
 * @param {bool} param0.isAccepted
 * @param {string} param0.itemId
 * @param {string} param0.npoTeamId
 * @param {string} param0.rejectReason
 * @returns {Promise<void>}
 */
export async function verifyOnboardingProgress({
  isAccepted,
  itemId,
  npoTeamId,
  rejectReason,
}) {
  const res = await fetchAPI(`/corporate/onboarding/verify`, METHODS.put, {
    isAccepted,
    itemId,
    npoTeamId,
    rejectReason,
  });
  await assertErrors(res);
}

/**
 * Reset the progress of an NPO's submission of an onboarding item.
 * @param {string} param0.itemId
 * @param {string} param0.npoTeamId
 * @returns {Promise<void>}
 */
export async function resetOnboardingProgress({ itemId, npoTeamId }) {
  const res = await fetchAPI(`/corporate/onboarding/reset`, METHODS.delete, {
    itemId,
    npoTeamId,
  });
  await assertErrors(res);
}

/**
 * @typedef {object} PaginatedNpos
 * @property {Npo[]} items
 * @property {number?} count Null if pagination is disabled
 */

/**
 * @typedef {object} Npo
 * @property {string} team_id
 * @property {string} name
 * @property {string} formatted_address
 * @property {string} province
 * @property {string} city
 * @property {string} country
 */

/**
 * Get a paginated view of all associated npos for the entire corporate account.
 * @param {object} opts
 * @param {import("./rescues").FilterModelObj} opts.filterModel
 * @param {number?} opts.step
 * @param {number?} opts.offset
 * @returns {Promise<PaginatedNpos>}
 */
export async function getCorporateNpos({
  step = -1,
  offset = -1,
  filterModel,
}) {
  const res = await fetchAPI(
    `/corporate/npos/get?${qs.stringify({
      step,
      offset,
    })}`,
    METHODS.post,
    { filterModel }
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Find a not-yet associated NPO to add to this corporate organization.
 * @param {object} opts
 * @param {string} opts.query
 * @returns {Promise<Npo[]>}
 */
export async function findNposToAdd({ query }) {
  const res = await fetchAPI(
    `/corporate/npos?${qs.stringify({ query })}`,
    METHODS.get
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Add an associated NPO to a compliance profile.
 * @param {string} teamId
 * @returns {Promise<void>}
 */
export async function addCorporateNpo(teamId) {
  const res = await fetchAPI(`/corporate/npo/${teamId}`, METHODS.post);
  await assertErrors(res);
}

/**
 * Remove an associated NPO to a compliance profile.
 * @param {string} teamId
 * @returns {Promise<void>}
 */
export async function removeCorporateNpo(teamId) {
  const res = await fetchAPI(`/corporate/npo/${teamId}`, METHODS.delete);
  await assertErrors(res);
}

/**
 * Check if teamId is part of the corporate team.
 * @param {string} teamId
 * @returns {Promise<boolean>}
 */
export async function checkIfOrgExists(teamId) {
  const res = await fetchAPI(
    `/corporate/organization/exists/${teamId}`,
    METHODS.get
  );
  await assertErrors(res);
  return parseBody(res);
}

/**
 * Update the corporation's settings.
 * @param {string} param0.name
 * @param {string} param0.email
 * @param {string} param0.about
 * @param {string} param0.lang
 */
export async function updateSettings({ name, email, about, lang }) {
  await fetchAPI('/corporate/settings/organization', METHODS.put, {
    name,
    email,
    about,
    lang,
  });
}

/**
 * Check if teamId is part of the corporate team.
 * @param {string} teamId
 * @returns {Promise<Users[]>}
 */
export async function getTeamMembersOfOrg(teamId) {
  const res = await fetchAPI(
    `/corporate/organization/${teamId}/users`,
    METHODS.get
  );
  await assertErrors(res);
  return parseBody(res);
}
