import { Buffer } from 'buffer';

import './index.css';

// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// moment locales
import 'moment/locale/es';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// mui x-grid
import { LicenseInfo } from '@mui/x-data-grid-premium';

import './index.css';
import ReactDOM from 'react-dom';

import App from './App';
import { XGRID_LICENSE_KEY } from './config.js';

window.Buffer = Buffer;

// ----------------------------------------------------------------------

LicenseInfo.setLicenseKey(XGRID_LICENSE_KEY);

// ----------------------------------------------------------------------

ReactDOM.render(<App />, document.getElementById('root'));
