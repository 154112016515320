import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import { Marker, GoogleMap, InfoWindow } from '@react-google-maps/api';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

// ----------------------------------------------------------------------

GoogleMapMarker.propTypes = {
  locations: PropTypes.array,
  onView: PropTypes.func,
};

function GoogleMapMarker({ locations = [], onView = () => {}, ...other }) {
  const theme = useTheme();
  const [, setMap] = useState(null);
  const [zoom, setZoom] = useState(2);
  const [center, setCenter] = useState(locations[0].pos);
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    locations.map((location) => {
      bounds.extend(location.pos);
      return location.id;
    });
    map.fitBounds(bounds);
  }, []);

  const onUnmount = useCallback(
    // eslint-disable-next-line no-unused-vars
    function callback(_map) {
      setMap(null);
    },
    [setMap]
  );

  const markerLoadHandler = (marker, location) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [location.id]: marker };
    });
  };

  // eslint-disable-next-line no-unused-vars
  const markerClickHandler = (_event, location) => {
    setSelectedPlace(location);
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
    if (zoom < 12) {
      setZoom(12);
    }
    setCenter(location.pos);
  };

  const mapOptions = {
    zoom: zoom,
    minZoom: 2,
    maxZoom: 16,
    center: center,
    controlSize: 24,
    scaleControl: true,
    streetViewControl: false,
  };

  return (
    <GoogleMap
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
      mapContainerStyle={{
        width: '100%',
        height: '100%',
      }}
      {...other}
    >
      {locations.map((location) => (
        <Marker
          key={location.id}
          position={location.pos}
          onLoad={(marker) => markerLoadHandler(marker, location)}
          onClick={(event) => markerClickHandler(event, location)}
          icon={{
            path: 'M8 0C3.61277632-.00021937.04387223 3.53299568 0 7.92 0 13.4 7.05 19.5 7.35 19.76c.374224.3200877.925776.3200877 1.3 0C9 19.5 16 13.4 16 7.92 15.9561278 3.53299568 12.3872237-.00021937 8 0zm0 11c-1.9329966 0-3.5-1.5670034-3.5-3.5C4.5 5.56700338 6.0670034 4 8 4s3.5 1.56700338 3.5 3.5c0 .9282577-.3687489 1.8184964-1.0251263 2.4748737C9.8184964 10.6312511 8.9282577 11 8 11z',
            fillColor: theme.palette.error.main,
            fillOpacity: 1.0,
            strokeWeight: 0,
            scale: 1.5,
          }}
        />
      ))}

      {infoOpen && selectedPlace && (
        <InfoWindow
          anchor={markerMap[selectedPlace.id]}
          onCloseClick={() => setInfoOpen(false)}
        >
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="subtitle2" sx={{ color: 'grey.800' }}>
              {selectedPlace.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'grey.600' }}>
              {selectedPlace.formatted_address}
            </Typography>

            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Button
                endIcon={<ChevronRight />}
                onClick={() => onView(selectedPlace.id)}
              >
                View
              </Button>
            </Box>
          </Box>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

export default GoogleMapMarker;
