import { fetchAPI, METHODS, parseBody } from './common';
import { isProduction } from 'src/config';

/**
 * @enum Items
 * @readonly
 * @type {string}
 */
const ITEMS = {
  basic: {
    test: 'price_1J2JwiHRNVAUEyHHDhDHJhvZ',
    live: 'price_1JCdyUHRNVAUEyHHQFp7SDwc',
  },
  advanced: {
    test: 'price_1J2JxFHRNVAUEyHHLTNACpAp',
    live: 'price_1JCdyYHRNVAUEyHHC1oDVyDQ',
  },
  foodMover: {
    live: 'price_1JCdybHRNVAUEyHHHX63HPS3',
    test: 'price_1J2JxOHRNVAUEyHH4krNi4Mc',
  },
};

/**
 * @typedef CreateSubscription
 * @property {string} subscriptionId
 * @property {string} clientSecret
 */

/**
 * @typedef Subscription
 * @property {string} id
 * @property {boolean} cancel_at_period_end
 * @property {number} current_period_end
 * @property {string} status
 */

/**
 * @typedef PaymentMethod
 * @property {string} id
 * @property {BillingDetails} billing_details
 * @property {Card} card
 */

/**
 * @typedef BillingDetails
 * @property {string} name
 */

/**
 * @typedef Card
 * @property {string} brand
 * @property {string} country
 * @property {number} exp_month Expiry month
 * @property {number} exp_year Expiry year
 * @property {string} last4
 */

/**
 * Creates a new inactive subscription to `item`.
 * Note about active subscriptions: this subscription must be activated with
 * the user's payment info via the stripe API.
 * see: https://stripe.com/docs/billing/subscriptions/elements#collect-payment
 * @param {Items} item
 * @returns CreateSubscription
 */
export async function create(item) {
  const priceId = !isProduction ? ITEMS[item].test : ITEMS[item].live;
  if (!priceId) throw new Error('Invalid item');
  const res = await fetchAPI('/stripe/subscription', METHODS.post, {
    priceId,
  });
  return await parseBody(res);
}

/**
 * Cancels the active subscription.
 */
export async function cancel() {
  const res = await fetchAPI('/stripe/subscription', METHODS.delete);
  if (res.status !== 200) throw new Error(res.statusText);
}

/**
 * Fetches the active subscription.
 * @returns Subscription
 */
export async function get() {
  const res = await fetchAPI('/stripe/subscription', METHODS.get);
  let { subscription } = await res.json();
  return subscription;
}

/**
 * Fetches the current payment method, returns an error if one cannot be found.
 * @returns PaymentMethod
 */
export async function getPaymentMethod() {
  const res = await fetchAPI('/stripe/payment-method', METHODS.get);
  let { paymentMethod } = await parseBody(res);
  return paymentMethod;
}

/**
 * @typedef Session
 * @property {string} url
 */

/**
 * Creates a stripe checkout session.
 * @param {Items} item
 * @returns Session
 */
export async function createCheckoutSession(item) {
  const priceId = !isProduction ? ITEMS[item].test : ITEMS[item].live;
  if (!priceId) throw new Error('Invalid item');

  const res = await fetchAPI('/stripe/create-checkout-session', METHODS.post, {
    priceId,
  });
  const { session } = await parseBody(res);
  return session;
}

/**
 * Creates a stripe customer portal session.
 * @returns Session
 */
export async function createCustomerPortalSession() {
  const res = await fetchAPI(
    '/stripe/create-customer-portal-session',
    METHODS.post
  );
  const { session } = await parseBody(res);
  return session;
}
