// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ ...other }) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/careit_text.svg"
      height={40}
      {...other}
    />
  );
}
