import {
  Avatar,
  List,
  Stack,
  Select,
  Divider,
  MenuItem,
  Typography,
} from '@mui/material';
import MenuPopover from 'src/components/MenuPopover';
import BadgeStatus from 'src/components/BadgeStatus';

import { useState, useRef } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const STATUS = ['online', 'invisible', 'away'];

export default function ChatNavAccount() {
  const { t } = useTranslation('translation');
  const { user } = useAuth();
  const history = useHistory();

  const [status, setStatus] = useState('online');

  const anchorRef = useRef();
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleGotoSettings = () => {
    handleClosePopover();
    history.push(PATH_DASHBOARD.settings.account('general', 'update-user'));
  };

  return (
    <>
      <Avatar
        ref={anchorRef}
        src={user?.photoURL}
        alt={user?.displayName}
        name={user?.displayName}
        BadgeProps={{
          badgeContent: <BadgeStatus status={status} />,
        }}
        onClick={handleOpenPopover}
        sx={{ cursor: 'pointer', width: 48, height: 48 }}
      />

      <MenuPopover
        anchorEl={anchorRef.current}
        open={openPopover}
        onClose={handleClosePopover}
        arrow="top-left"
        sx={{ p: 0 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ py: 2, pr: 1, pl: 2.5 }}
        >
          <div>
            <Typography noWrap variant="subtitle2">
              {user?.name} ({t('chat.me')})
            </Typography>

            <Typography noWrap variant="body2" sx={{ color: 'text.secondary' }}>
              {user?.email}
            </Typography>
          </div>
        </Stack>

        <Divider />

        <List sx={{ px: 1 }}>
          <MenuItem>
            <BadgeStatus
              size="large"
              status={status}
              sx={{ mr: 1.5, mt: 0.5, flexShrink: 0 }}
            />

            <Select
              fullWidth
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              sx={{
                textTransform: 'capitalize',
                '& .MuiInputBase-input': {
                  p: 0,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  display: 'none',
                },
              }}
            >
              {STATUS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </MenuItem>

          <MenuItem onClick={handleGotoSettings}>
            {t('chat.my-profile')}
          </MenuItem>

          <MenuItem onClick={handleClosePopover}>{t('chat.close')}</MenuItem>
        </List>
      </MenuPopover>
    </>
  );
}
