import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en-US',
    label: 'English (US)',
  },
  {
    value: 'es-US',
    label: 'Spanish (US)',
  },
  {
    value: 'fr-CA',
    label: 'French (Canada)',
  },
];

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n } = useTranslation();
  const currentLang = i18n.language ?? localStorage.getItem('i18nextLng');

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    currentLang,
    allLang: LANGS,
  };
}
