import { useTranslation } from 'react-i18next';
import { esES } from '@mui/x-date-pickers/locales';

const useGetLocaleTextDatePicker = () => {
  const { i18n } = useTranslation();
  return i18n.language == 'es-US'
    ? esES.components.MuiLocalizationProvider.defaultProps.localeText
    : undefined;
};

export default useGetLocaleTextDatePicker;
