import React, { useCallback, useMemo, useState } from 'react';
// material
import { Box, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridRootProps,
  useGridApiContext,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';
// components
import Page from 'src/components/Page';
// hooks
import { useCheckAction } from 'src/utils/permissions';
import { MBreadcrumbs } from 'src/components/@material-extend';
import { esES } from '@mui/x-data-grid/locales';
import { useTranslation } from 'react-i18next';
import useOpenRowDataGrid from 'src/hooks/useOpenRowDataGrid';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  grid: { border: 0 },
}));

// ----------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
function CustomGridToolbarExport({ onClick: _, ...other }) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();

      const exportHandler = rootProps.customExport;
      if (exportHandler) {
        exportHandler();
      }
    },
    [rootProps.customExport]
  );

  return (
    <rootProps.components.BaseButton
      color="primary"
      size="small"
      startIcon={<rootProps.components.ExportIcon />}
      {...other}
      onClick={handleClick}
      {...rootProps.componentsProps?.baseButton}
    >
      {apiRef.current.getLocaleText('toolbarExport')}
    </rootProps.components.BaseButton>
  );
}

const Toolbar = React.forwardRef(function ToolbarImpl(props, ref) {
  // eslint-disable-next-line no-unused-vars
  const { className, csvOptions, printOptions, showExport, ...other } = props;
  const rootProps = useGridRootProps();

  const canExport = useCheckAction('export');

  if (
    rootProps.hideToolbar ||
    (rootProps.disableColumnFilter &&
      rootProps.disableColumnSelector &&
      rootProps.disableDensitySelector)
  ) {
    return null;
  }

  return (
    <GridToolbarContainer ref={ref} {...other}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {canExport && showExport ? (
        props.customExport ? (
          <CustomGridToolbarExport />
        ) : (
          <GridToolbarExport />
        )
      ) : null}

      <Box flex="1" />

      {rootProps.showQuickFilter ? <GridToolbarQuickFilter /> : null}
    </GridToolbarContainer>
  );
});

const TableComp = ({
  rows,
  columns,
  isLoading,
  serverSideFiltering,
  setFilterModel,
  other,
  showExport,
  classes,
  height,
}) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    columns.reduce((a, c) => ({ ...a, [c.field]: !c.hide }), {})
  );
  return (
    <Box sx={{ height, width: '100%', margin: '0' }}>
      <DataGridPremium
        localeText={
          currentLocale == 'es-US'
            ? esES.components.MuiDataGrid.defaultProps.localeText
            : undefined
        }
        rows={rows}
        columns={columns}
        className={classes.grid}
        loading={isLoading}
        filterMode={serverSideFiltering ? 'server' : 'client'}
        onFilterModelChange={setFilterModel}
        sx={{
          ...(other?.sx ?? {}),
          height: '100%',
          width: '100%',
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
            overflowX: 'scroll !important',
          },
        }}
        {...other}
        components={{
          Toolbar,
          ...(other.components ?? {}),
        }}
        initialState={{
          pinnedColumns: {
            left: ['__view__'],
          },
          ...(other.initialState ?? {}),
        }}
        componentsProps={{
          toolbar: { showExport, customExport: other.customExport },
          ...(other?.componentsProps ?? {}),
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
      />
    </Box>
  );
};
export default function TableView({
  serverSideFiltering = false, // flag to enable server-side filtering
  setFilterModel = () => {},
  title,
  resolvePath,
  selected,
  form,
  injectHeader,
  action,
  rows,
  columns,
  isLoading,
  breadcrumbs,
  showExport = false,
  wrapActionToNextLineOnMobile,
  renderOnlyTable = false,
  height = 'calc(100vh - 160px)',
  ...other
}) {
  const classes = useStyles();
  const makeOpenRowButton = useOpenRowDataGrid();
  const memoizedColumns = useMemo(() => {
    return resolvePath
      ? [
          makeOpenRowButton((params) => resolvePath(params.id, params.row)),
          ...columns,
        ]
      : columns;
  }, [resolvePath, columns]);

  if (renderOnlyTable) {
    return (
      <TableComp
        rows={rows}
        columns={memoizedColumns}
        isLoading={isLoading}
        serverSideFiltering={serverSideFiltering}
        setFilterModel={setFilterModel}
        showExport={showExport}
        classes={classes}
        other={other}
      />
    );
  }
  return (
    <Page title={typeof title === 'string' ? `${title} | Careit` : undefined}>
      {form ? form() : null}

      <Box display={selected ? 'none' : 'block'}>
        {title || action ? (
          wrapActionToNextLineOnMobile ? (
            <Grid container>
              <Grid item sm={12} md={7}>
                {title && <Typography variant="h3">{title}</Typography>}
              </Grid>
              <Grid
                item
                sm={12}
                md={5}
                alignItems={'center'}
                alignContent={'center'}
              >
                {action}
              </Grid>
            </Grid>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              {title && <Typography variant="h3">{title}</Typography>}
              {action}
            </Box>
          )
        ) : null}

        {breadcrumbs && <MBreadcrumbs links={breadcrumbs} />}

        {injectHeader}

        <TableComp
          height={height}
          rows={rows}
          columns={memoizedColumns}
          isLoading={isLoading}
          serverSideFiltering={serverSideFiltering}
          setFilterModel={setFilterModel}
          showExport={showExport}
          classes={classes}
          other={other}
        />
      </Box>
    </Page>
  );
}
