import { GOOGLE_API_KEY } from 'src/config';

const getTimeZoneIdFromCoords = async (location) => {
  const timeZoneURL = `https://maps.googleapis.com/maps/api/timezone/json?location=${
    location?.lat
  },${location?.lng}&timestamp=${Date.now() / 1000}&key=${GOOGLE_API_KEY}`;

  const res = await fetch(timeZoneURL);
  const { timeZoneId } = await res.json();
  return timeZoneId;
};

export default getTimeZoneIdFromCoords;
