import PropTypes from 'prop-types';
import {
  Link,
  Link as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import { useMemo } from 'react';
import useOnboarding from 'src/hooks/useOnboarding';
import useAuth from 'src/hooks/useAuth';
import { PATH_AUTH } from 'src/routes/paths';
import { auth, user } from 'src/backend';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { searchExistingOrgs } from 'src/backend/org';
import { SwitchLanguageButton } from '../auth';
import { setSession } from 'src/redux/slices/auth';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5),
  },
}));

export default function RegisterLayout({ children }) {
  return (
    <RootStyle title="Get Started | Careit">
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>
      <Container>{children}</Container>
    </RootStyle>
  );
}

const STEPS = {
  BUSINESS: [
    PATH_AUTH.register.business.aboutYou,
    PATH_AUTH.register.business.aboutBusiness,
  ],
  NON_PROFIT: [
    PATH_AUTH.register.nonProfit.aboutYou,
    PATH_AUTH.register.nonProfit.aboutBusiness,
  ],
  JOIN_TEAM: [
    PATH_AUTH.register.joinTeam.email,
    PATH_AUTH.register.joinTeam.invites,
    PATH_AUTH.register.joinTeam.info,
  ],
};

function RegisterButton({ agree, children }) {
  return agree ? (
    <span style={{ width: 480, display: 'flex', justifyContent: 'stretch' }}>
      {children}
    </span>
  ) : (
    <Tooltip title="You must first agree to the Terms of Service and Privacy Policy">
      <span style={{ width: 480, display: 'flex', justifyContent: 'stretch' }}>
        {children}
      </span>
    </Tooltip>
  );
}

RegisterPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

export function RegisterPage({
  title,
  description,
  page,
  children,
  hideNavControls = false,
}) {
  const history = useHistory();
  const location = useLocation();
  const { data, setData, valid, updateField } = useOnboarding();
  const {
    register,
    isOrgModerationEnabled,
    user: userWithSession,
    logout,
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [agree, setAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const [next, prev, end] = useMemo(() => {
    let steps;
    let step;

    if (data.type) {
      steps = STEPS[data.type];
      step = steps.indexOf(history.location.pathname);
    } else {
      // The page was just loaded, try to detect the type given our current
      // path.
      for (const [type, routes] of Object.entries(STEPS)) {
        const invitesFor = PATH_AUTH.register.joinTeam.invitesFor('');
        if (location.pathname.startsWith(invitesFor)) {
          // Grab email from the pathname
          const email = location.pathname.slice(invitesFor.length);
          setData({ type: 'JOIN_TEAM', email });

          steps = STEPS.JOIN_TEAM;
          step = steps.indexOf(PATH_AUTH.register.joinTeam.invites);

          history.replace({
            pathname: steps[step],
            search: location.search,
          });
        } else if (routes.includes(location.pathname)) {
          setData({ ...data, type });
          steps = routes;
          step = 0;

          // Set the current path with the start route. We use `replace` not
          // `push` so users cannot visit a page part way, be redirected to the
          // start, press the back button and then be redirected back to a bad
          // state.
          history.replace({
            pathname: routes[0],
            search: location.search,
          });
        }
      }

      if (!steps) {
        return [null, null, false];
      }
    }
    let nextStep = steps[step + 1];
    let prevStep = steps[step - 1];
    if (
      nextStep === PATH_AUTH.register.joinTeam.invites &&
      location.pathname === PATH_AUTH.register.joinTeam.email &&
      token
    ) {
      nextStep = PATH_AUTH.register.joinTeam.info;
    }
    if (location.pathname === PATH_AUTH.register.joinTeam.info && token) {
      prevStep = PATH_AUTH.register.joinTeam.email;
    }
    return [
      nextStep,
      prevStep ?? PATH_AUTH.register.root,
      step === steps.length - 1,
    ];
  }, [data.type, history, location]);
  const handleRegisterSuccess = (user) => {
    if (isOrgModerationEnabled && user && user?.id && !user?.is_team_active) {
      history.push(
        PATH_AUTH.register.pendingVerification(user.team_id, user.id)
      );
    } else {
      setIsLoading(false);
      history.push(PATH_AUTH.login);
    }
  };

  const handleDone = async () => {
    setIsLoading(true);
    if (['NON_PROFIT', 'BUSINESS'].includes(data.type)) {
      const existingOrgs = await searchExistingOrgs({
        placeId: data.businessPlaceId,
        lat: data.businessLat,
        lng: data.businessLng,
        suiteNumber: data.businessSuiteNumber,
      });
      if (existingOrgs.isBusinessAvailable) {
        enqueueSnackbar('An org already exists at this location', {
          variant: 'error',
        });
        setIsLoading(false);
      } else {
        if (data.type === 'NON_PROFIT') {
          const user = {
            password: data.password,
            profile: {
              name: data.name,
              email: data.email,
              phone: data.phone,
              vehicle: data.vehicle === 'NO_VEHICLE' ? null : data.vehicle,
              isNonProfit: true,
              businessInfo: {
                name: data.businessName,
                phone: data.businessPhoneNumber,
                email: data.businessEmail,
                ein: data.ein,
                lang: data.lang,
                location: {
                  formattedAddress: data.businessFormattedAddress,
                  streetAddress: data.businessStreetAddress,
                  suiteNumber: data.businessSuiteNumber,
                  city: data.businessCity,
                  province: data.businessProvince,
                  country: data.businessCountry,
                  zipCode: data.businessZipCode,
                  name: data.businessLocationName,
                  lng: data.businessLng,
                  lat: data.businessLat,
                  placeId: data.businessPlaceId,
                },
              },
            },
          };
          register(user, enqueueSnackbar, setData, handleRegisterSuccess);
        } else if (data.type === 'BUSINESS') {
          const user = {
            password: data.password,
            profile: {
              name: data.name,
              email: data.email,
              phone: data.phone,
              vehicle: data.vehicle,
              isNonProfit: false,
              businessInfo: {
                name: data.businessName,
                phone: data.businessPhoneNumber,
                email: data.businessEmail,
                ein: data.ein,
                lang: data.lang,
                location: {
                  formattedAddress: data.businessFormattedAddress,
                  streetAddress: data.businessStreetAddress,
                  suiteNumber: data.businessSuiteNumber,
                  city: data.businessCity,
                  province: data.businessProvince,
                  country: data.businessCountry,
                  zipCode: data.businessZipCode,
                  name: data.businessLocationName,
                  lng: data.businessLng,
                  lat: data.businessLat,
                  placeId: data.businessPlaceId,
                },
              },
            },
          };
          register(user, enqueueSnackbar, setData, handleRegisterSuccess);
        }
      }
    } else if (data.type === 'JOIN_TEAM') {
      const handleTeamMemberSuccess = (user) => {
        if (userWithSession) {
          logout();
        }
        setSession(user.token);
        handleRegisterSuccess(user);
        setData({});
        enqueueSnackbar(
          'You are added to the team successfully. Please login',
          { variant: 'success' }
        );
      };
      if (!data.name) {
        user
          .acceptInviteAndLogin(data.email, data.password, data.team)
          .then(handleTeamMemberSuccess)
          .catch(() => {
            enqueueSnackbar('Incorrect password', { variant: 'error' });
            setData({ ...data, password: '' });
          });
        return;
      }

      auth
        .acceptTeamMember({
          email: data?.originalEmail || data.email,
          password: data.password,
          teamId: data.team,
          phone: data.phone,
          fullName: data.name,
          vehicle: data.vehicle,
          profile: { businessInfo: { lang: data.lang } },
          newEmail: data?.originalEmail ? data.email : null,
          joinAllTeams: data.joinAllTeams,
          token,
        })
        .then(handleTeamMemberSuccess)
        .catch((err) => {
          console.log({ err });
          enqueueSnackbar(err.message, { variant: 'error' });
        });
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box width={480} display="block" textAlign="left">
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            {description}
          </Typography>
        </Box>
      </Box>

      <SwitchLanguageButton />

      {typeof children === 'function' ? (
        <Page title="Register | Careit">
          {children(handleDone, prev, valid, page, isLoading)}
        </Page>
      ) : (
        <Page title="Register | Careit">{children}</Page>
      )}

      <Box
        display="flex"
        marginTop="15px"
        flexDirection="column"
        alignItems="center"
      >
        {!end &&
          next &&
          (data.type === 'JOIN_TEAM' &&
          location.pathname.includes('team-invites') ? (
            <>
              {data.invites?.length > 1 ? (
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{ maxWidth: 480, mt: 1 }}
                  onClick={() => {
                    updateField('joinAllTeams', true);
                    history.push({
                      pathname: next,
                      search: location.search,
                    });
                  }}
                >
                  Join All
                </Button>
              ) : null}
              <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={() =>
                  history.push({
                    pathname: next,
                    search: location.search,
                  })
                }
                sx={{ maxWidth: 480, mt: 1 }}
                disabled={!valid[page]}
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <FormGroup sx={{ my: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agree}
                      onChange={(e) => setAgree(e.target.checked)}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      align="center"
                      sx={{ color: 'text.secondary' }}
                    >
                      I agree to the Careit&nbsp;
                      <Link
                        to={{
                          pathname: 'https://careit.com/terms-of-service/',
                        }}
                        target="_blank"
                        rel="noreferrer"
                        underline="always"
                        sx={{ color: 'text.primary' }}
                      >
                        Terms of Service
                      </Link>
                      &nbsp;and&nbsp;
                      <Link
                        to={{
                          pathname: 'https://careit.com/privacy-policy/',
                        }}
                        target="_blank"
                        rel="noreferrer"
                        underline="always"
                        sx={{ color: 'text.primary' }}
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Typography>
                  }
                />
              </FormGroup>

              <RegisterButton agree={agree}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() =>
                    history.push({
                      pathname: next,
                      search: location.search,
                    })
                  }
                  sx={{ maxWidth: 480, mt: 1 }}
                  disabled={!agree || !valid[page]}
                >
                  Next
                </Button>
              </RegisterButton>
            </>
          ))}

        {end &&
          (data.type === 'JOIN_TEAM' ? (
            <>
              <FormGroup sx={{ mt: 3 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agree}
                      onChange={(e) => setAgree(e.target.checked)}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      align="center"
                      sx={{ color: 'text.secondary' }}
                    >
                      I agree to the Careit&nbsp;
                      <Link
                        to={{
                          pathname: 'https://careit.com/terms-of-service/',
                        }}
                        target="_blank"
                        rel="noreferrer"
                        underline="always"
                        sx={{ color: 'text.primary' }}
                      >
                        Terms of Service
                      </Link>
                      &nbsp;and&nbsp;
                      <Link
                        to={{
                          pathname: 'https://careit.com/privacy-policy/',
                        }}
                        target="_blank"
                        rel="noreferrer"
                        underline="always"
                        sx={{ color: 'text.primary' }}
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Typography>
                  }
                />
              </FormGroup>

              <RegisterButton agree={agree}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleDone}
                  sx={{ maxWidth: 480, mt: 1 }}
                  disabled={!agree || !valid[page]}
                >
                  Done
                </Button>
              </RegisterButton>
            </>
          ) : (
            <></>
          ))}

        {prev && hideNavControls ? (
          <></>
        ) : (
          <Button
            fullWidth
            size="large"
            onClick={() =>
              history.push({
                pathname: prev,
                search: location.search,
              })
            }
            sx={{ maxWidth: 480, mt: 1 }}
          >
            Back
          </Button>
        )}
      </Box>
    </>
  );
}
