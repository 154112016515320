import { useTranslation } from 'react-i18next';

const useDonationStatus = () => {
  const { t } = useTranslation('translation');

  const statuses = {
    ASSIGNED: t('donations.status-values.ASSIGNED'),
    PICKUP_IN_PROGRESS: t('donations.status-values.PICKUP_IN_PROGRESS'),
    DELIVERY_IN_PROGRESS: t('donations.status-values.DELIVERY_IN_PROGRESS'),
    COMPLETED: t('donations.status-values.COMPLETED'),
    CANCELLED: t('donations.status-values.CANCELLED'),
    WAITING_FOR_REASSIGN: t('donations.status-values.WAITING_FOR_REASSIGN'),
    INCOMPLETE: t('donations.status-values.INCOMPLETE'),
    AVAILABLE: t('donations.status-values.AVAILABLE'),
    STARTED: t('donations.status-values.STARTED'),
    WAITING_FOR_APPROVAL: t('donations.status-values.WAITING_FOR_APPROVAL'),
    RESERVED: t('donations.status-values.RESERVED'),
    OPEN: t('donations.status-values.OPEN'),
    'IN-PROGRESS': t('donations.status-values.IN-PROGRESS'),
    RESCUED: t('donations.status-values.RESCUED'),
    'NOT-STARTED': t('donations.status-values.NOT-STARTED'),
  };
  const getStatus = (key) => {
    return statuses[key];
  };

  return {
    getStatus,
  };
};

export default useDonationStatus;
