// components
import TableView from 'src/components/dashboard/tables/TableView';
import { MBadge } from 'src/components/@material-extend';
// hooks
import { createContext, useContext, useMemo, useState } from 'react';
import useRescues from 'src/hooks/useRescues';
import useCreateDonation from 'src/hooks/useCreateDonation';
//
import { withPagination } from 'src/utils/pagination';
import moment from 'moment-timezone';
import { parseUTC } from 'src/utils/timezones';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import useEventBus from 'src/hooks/useEventBus';
import useDateFilterForTable from 'src/hooks/useDateFilterForTable';
import { useTranslation } from 'react-i18next';
import { useWeightsAndTempsColumns } from 'src/hooks/useWeightsAndTempsColumns';
import CreateDonationButton from 'src/components/CreateDonationButton';
import useDonationStatus from 'src/hooks/useDonationStatus';

const useIncomingRequestsColumns = () => {
  const { t, i18n } = useTranslation('translation');
  const weightsAndTemps = useWeightsAndTempsColumns();
  const { getStatus } = useDonationStatus();
  return useMemo(
    () => [
      {
        field: 'donation_status',
        type: 'string',
        headerName: t('donations.status'),
        valueGetter: (params) => getStatus(params.value),
        width: 200,
      },
      {
        field: 'rescue_status',
        type: 'string',
        headerName: t('donations.rescue-status'),
        valueGetter: (params) => getStatus(params.value),
        width: 150,
      },
      {
        field: 'start_date',
        type: 'date',
        headerName: t('donations.start'),
        width: 150,
        valueGetter: (params) =>
          moment
            .utc(params.row.start_date)
            .tz(params.row.donor_timezone_id)
            .format('MM/DD/YYYY'),
      },
      {
        field: 'start_time',
        type: 'date',
        headerName: t('donations.start-time'),
        width: 150,
        filterable: false,
        valueGetter: (params) =>
          parseUTC(
            params.row.start_date,
            { timezone_id: params.row.donor_timezone_id },
            'hh:mm:ss a'
          ),
      },
      {
        field: 'title',
        type: 'string',
        headerName: t('donations.title'),
        width: 150,
      },
      {
        field: 'donor_business_name',
        type: 'string',
        headerName: t('donations.donor'),
        width: 150,
      },
      {
        field: 'formatted_address',
        type: 'string',
        headerName: t('donations.address'),
        width: 200,
        filter: false,
      },
      {
        field: 'end_date',
        type: 'date',
        headerName: t('donations.end'),
        width: 150,
        valueGetter: (params) =>
          moment
            .utc(params.row.end_date)
            .tz(params.row.donor_timezone_id)
            .format('MM/DD/YYYY'),
      },
      ...weightsAndTemps.map((col) => ({ ...col, filterable: false })),
    ],
    [i18n.language]
  );
};

// ----------------------------------------------------------------------

const IncomingRequestsContext = createContext();

export function IncomingRequestsProvider({ children }) {
  const [pageState, setPageState] = useState({ page: 0, pageSize: 25 });
  const {
    filterModelWithDateRange: filterModel,
    setFilterModel,
    DateRangeSelector,
  } = useDateFilterForTable({
    defaultDateRange: {
      start: null,
      end: null,
    },
  });
  const filterModelWithStatusFilter = useMemo(
    () => ({
      items: [
        ...(filterModel?.items ?? []),
        {
          id: 0,
          columnField: 'rescue_status',
          operatorValue: 'equals',
          value: 'WAITING_FOR_APPROVAL',
        },
      ],
      linkOperator: 'and',
    }),
    [filterModel]
  );

  const { rescues, count, isLoading, invalidate } = useRescues({
    page: pageState.page,
    pageSize: pageState.pageSize,
    filterModel: filterModelWithStatusFilter,
    requests: true,
  });

  useEventBus(
    'incoming-request',
    'invalidate',
    () => {
      invalidate();
    },
    [invalidate]
  );

  const value = {
    filterModel,
    setFilterModel,
    pageState,
    setPageState,
    rescues,
    count,
    isLoading,
    invalidate,
    DateRangeSelector,
  };

  return (
    <IncomingRequestsContext.Provider value={value}>
      {children}
    </IncomingRequestsContext.Provider>
  );
}

function useIncomingRequests() {
  const ctx = useContext(IncomingRequestsContext);

  if (!ctx) {
    throw new Error(
      'Could not use an IncomingRequestsContext. Did you forget an IncomingRequestsProvider'
    );
  }

  return ctx;
}

export function IncomingRequestsBadge({ location, children }) {
  const { count } = useIncomingRequests();

  if (location === 'center') {
    return (
      <MBadge
        badgeContent={count}
        color="error"
        sx={{ '& .MuiBadge-badge': { right: 0, top: 0 } }}
      />
    );
  }

  return (
    <MBadge badgeContent={count} color="error">
      {children}
    </MBadge>
  );
}

export default function IncomingRequestsTable() {
  const { t } = useTranslation('translation');
  const COLUMNS = useIncomingRequestsColumns();

  const {
    setFilterModel,
    pageState,
    setPageState,
    rescues,
    count,
    isLoading,
    invalidate,
  } = useIncomingRequests();

  const { user } = useAuth();
  const { handleOpen, form } = useCreateDonation(invalidate);

  return (
    <TableView
      id="incomingdonations"
      title={t('sidebar.rescues.incoming-requests')}
      resolvePath={PATH_DASHBOARD.viewDonation}
      form={form}
      action={
        user.account_type === 'ADMIN' ? null : (
          <CreateDonationButton onClick={handleOpen} />
        )
      }
      rows={rescues}
      columns={COLUMNS}
      isLoading={isLoading}
      {...withPagination(pageState, setPageState, count, invalidate)}
      serverSideFiltering
      setFilterModel={setFilterModel}
    />
  );
}
