import { Temperature, Weight } from 'careit';
import { useTranslation } from 'react-i18next';
import { useMeasureFieldUnits } from 'src/components/MeasureField';
import { useFoodTypes } from 'src/constants';

export const useWeightsAndTempsColumns = ({
  hide = false,
  filterable = false,
} = {}) => {
  const { t } = useTranslation('translation');
  const foodTypes = useFoodTypes();
  const { defaultWeightUnit, defaultTemperatureUnit } = useMeasureFieldUnits();

  const updatedFoodTypes = [
    {
      title: t('data.food-types.total-food'),
      key: 'total_food',
    },
    ...foodTypes,
    {
      title: t('data.food-types.unservable'),
      key: 'unservable',
    },
  ];

  const foodTypesTemp = [
    {
      title: t('data.food-types.dairy-pickup-temp'),
      key: 'dairy_pickup_temp',
    },
    {
      title: t('data.food-types.dairy-dropoff-temp'),
      key: 'dairy_dropoff_temp',
    },
    {
      title: t('data.food-types.produce-pickup-temp'),
      key: 'produce_pickup_temp',
    },
    {
      title: t('data.food-types.produce-dropoff-temp'),
      key: 'produce_dropoff_temp',
    },
    {
      title: t('data.food-types.meat-pickup-temp'),
      key: 'meat_pickup_temp',
    },
    {
      title: t('data.food-types.meat-dropoff-temp'),
      key: 'meat_dropoff_temp',
    },
    {
      title: t('data.food-types.prepared-pickup-temp'),
      key: 'prepared_pickup_temp',
    },
    {
      title: t('data.food-types.prepared-dropoff-temp'),
      key: 'prepared_dropoff_temp',
    },
  ];

  return [
    ...updatedFoodTypes.map((food) => ({
      field: food.key + '_weight',
      type: 'number',
      headerName: `${food.title} (${defaultWeightUnit.label})`,
      width: 150,
      hide,
      valueGetter: (params) =>
        new Weight(params.value ?? 0, 'lbs')
          .into(defaultWeightUnit.value)
          .valueOf(),
      valueFormatter: (params) => Math.round(params.value * 100) / 100,
      filterable,
    })),
    ...foodTypesTemp.map((food) => ({
      field: food.key,
      type: 'number',
      headerName: `${food.title} (${defaultTemperatureUnit.label})`,
      width: 150,
      hide,
      valueGetter: (params) =>
        Number.isFinite(params.value)
          ? new Temperature(params.value, 'f')
              .into(defaultTemperatureUnit.value)
              .valueOf()
          : null,
      valueFormatter: (params) =>
        params.value !== null ? Math.round(params.value * 10) / 10 : '-',
      filterable,
    })),
  ];
};

export const useGetWeightValues = () => {
  const { defaultWeightUnit } = useMeasureFieldUnits();
  return (params) =>
    new Weight(
      new Weight(params.value ?? 0, 'lbs').as(
        params.row[params.field + '_unit'] ?? defaultWeightUnit.value
      ),
      params.row[params.field + '_unit'] ?? defaultWeightUnit.value
    );
};
