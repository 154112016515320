import { Redirect } from 'react-router-dom';
import GuestGuard from '../guards/GuestGuard';
import AuthLayout from '../layouts/auth';
import { PATH_AUTH } from './paths';
import lazy from './lazy';

const AuthRoutes = {
  path: PATH_AUTH.root,
  guard: GuestGuard,
  layout: AuthLayout,
  routes: [
    {
      exact: true,
      path: PATH_AUTH.login,
      component: lazy(() => import('../views/authentication/Login')),
    },
    {
      exact: true,
      path: PATH_AUTH.register.root,
      component: lazy(() => import('../views/authentication/Register')),
    },
    {
      exact: true,
      path: PATH_AUTH.resetPassword,
      component: lazy(() => import('../views/authentication/ResetPassword')),
    },
    {
      exact: true,
      path: PATH_AUTH.verifyEmail,
      component: lazy(() => import('../views/authentication/VerifyEmail')),
    },
    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default AuthRoutes;
