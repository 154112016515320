import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import SearchNotFound from 'src/components/SearchNotFound';
import ChatNavItem from './ChatNavItem';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

ChatNavSearchResults.propTypes = {
  searchResults: PropTypes.array,
  onSelectConversation: PropTypes.func,
  searchConversations: PropTypes.string,
};

export default function ChatNavSearchResults({
  searchConversations,
  searchResults,
  onSelectConversation,
}) {
  const { t } = useTranslation('translation');
  const isNotFound = !searchResults.length && !!searchConversations;

  return (
    <>
      <Typography
        paragraph
        variant="h6"
        sx={{
          px: 2.5,
        }}
      >
        {t('chat.conversations')}
      </Typography>

      {isNotFound ? (
        <SearchNotFound
          searchQuery={searchConversations}
          sx={{
            p: 3,
            mx: 'auto',
            width: `calc(100% - 40px)`,
            bgcolor: 'background.neutral',
          }}
        />
      ) : (
        <>
          {searchResults.map(
            (result) =>
              result && (
                <ChatNavItem
                  key={result.sid}
                  conversation={result}
                  openNav={true}
                  isSelected={false}
                  onSelect={() => onSelectConversation(result)}
                />
              )
          )}
        </>
      )}
    </>
  );
}
