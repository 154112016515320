import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { admin, rescues } from 'src/backend';
import useAuth from './useAuth';

// ----------------------------------------------------------------------

/**
 * @typedef Rescues
 * @property {Rescue[]} rescues
 * @property {number} count
 * @property {boolean} isLoading
 */

/**
 * Encapsulates rescue data state management & logic, returning that logic's
 * state.
 * Note: rescues is never null, but an empty array if there is no data.
 * @param {number} param0.page
 * @param {number} param0.pageSize
 * @param {boolean} param0.assignedTo
 * @param {boolean} param0.recent
 * @returns {Rescues}
 */
export default function useRescues({
  page,
  pageSize,
  assignedTo = false,
  recent = false,
  filterModel,
  requests = false,
} = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [cachedRescues, setCachedRescues] = useState(null);
  const [count, setCount] = useState(null);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(false);
  }, [filterModel, page, pageSize]);

  useEffect(() => {
    if (valid) {
      return;
    }

    const handleError = (err) => {
      console.error(err);
      enqueueSnackbar(
        'An error occurred while trying to fetch your rescues. Please try again later.',
        { variant: 'error' }
      );
    };

    if (user.account_type === 'ADMIN') {
      admin
        .getAllRescues({ offset: page, step: pageSize, filterModel })
        .then(({ results, count }) => {
          setCachedRescues(results);
          setCount(count);
        })
        .then(() => setValid(true))
        .catch(handleError);
    } else if (assignedTo) {
      rescues
        .getMyRescues({ offset: page, step: pageSize, filterModel })
        .then(({ results, count }) => {
          setCachedRescues(results);
          setCount(count);
        })
        .then(() => setValid(true))
        .catch(handleError);
    } else if (recent) {
      rescues
        .getRecent()
        .then(setCachedRescues)
        .then(() => setValid(true))
        .catch(handleError);
    } else if (!user.is_executive && !user.is_corporate) {
      rescues
        .get({ offset: page, step: pageSize, filterModel, requests })
        .then(({ results, count }) => {
          setCachedRescues(results);
          setCount(count);
        })
        .then(() => setValid(true))
        .catch(handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enqueueSnackbar,
    user.account_type,
    assignedTo,
    recent,
    page,
    pageSize,
    valid,
    filterModel,
  ]);

  const invalidate = useCallback(() => {
    setValid(false);
  }, []);

  return {
    rescues: (cachedRescues ?? []).map((r) => ({
      ...r,
      start_date: new Date(r.start_date),
      end_date: new Date(r.end_date),
    })),
    count: count ?? 0,
    isLoading: !cachedRescues || !valid,
    invalidate,
  };
}
