import {
  Box,
  Button,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import {
  ChevronRight,
  Close,
  NotificationAdd,
  NotificationsOff,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';

import useChatClient from 'src/hooks/useChatClient';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import { toggleMuteParticipantInConversation } from 'src/backend/chat';
import useResponsive from 'src/hooks/useResponsive';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChatHeader() {
  const { t } = useTranslation('translation');
  const isDesktop = useResponsive('up', 'md');
  const [isMuteLoading, setMuteLoading] = useState(false);
  const [isMuted, setMuted] = useState(false);
  const { user } = useAuth();
  const {
    activeConversation: conversation,
    setChatOpen,
    fetchParticipantsOfAConversation,
    setDrawerOpen,
  } = useChatClient();

  useEffect(async () => {
    if (conversation) {
      fetchMute();
    }
  }, [conversation]);

  const handleToggleMuteConversationNotification = async (conversation) => {
    setMuteLoading(true);
    let participants = await fetchParticipantsOfAConversation(conversation.sid);
    participants = participants.map((participant) => ({
      sid: participant?.state?.sid,
      identity: participant?.state?.identity,
    }));
    const currentParticipant = participants.find(
      (item) => item.identity === `UID${user.id};TID${user.team_id}`
    );
    toggleMuteParticipantInConversation({
      conversationId: conversation.sid,
      participantId: currentParticipant.sid,
    }).finally(async () => {
      await fetchMute();
      setMuteLoading(false);
    });
  };

  const fetchMute = useCallback(async () => {
    if (conversation) {
      const participants = await fetchParticipantsOfAConversation(
        conversation.sid
      );
      const currentParticipant = participants.find(
        (item) => item.identity === `UID${user.id};TID${user.team_id}`
      );
      setMuted(currentParticipant?.state?.attributes?.muted);
    }
  }, [conversation, user]);

  if (!conversation) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(2, 1, 2, 2),
      }}
    >
      <Typography>
        {conversation.friendlyName
          ?.replace('Admin Chat:', `${t('chat.admin-chat')}:`)
          .replace('Team Chat:', `${t('chat.team-chat')}:`)}
      </Typography>

      <Box flex="1" />

      {conversation.attributes.donationId && (
        <Button
          endIcon={<ChevronRight />}
          LinkComponent={Link}
          to={PATH_DASHBOARD.viewDonation(conversation.attributes.donationId)}
        >
          {t('chat.view-donation')}
        </Button>
      )}

      <IconButton
        onClick={() => handleToggleMuteConversationNotification(conversation)}
      >
        {isMuteLoading ? (
          <CircularProgress size={20} />
        ) : isMuted ? (
          <NotificationAdd />
        ) : (
          <NotificationsOff />
        )}
      </IconButton>
      <IconButton
        onClick={() => {
          if (isDesktop) {
            setChatOpen(false);
            setDrawerOpen(false);
          } else {
            setChatOpen(false);
          }
        }}
      >
        <Close />
      </IconButton>
    </Stack>
  );
}
