import { createSlice } from '@reduxjs/toolkit';
import { document } from 'src/backend';

const initialState = {
  document: {},
};

const slice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setUserDocument(state, action) {
      state.document = action.payload.document;
    },
  },
});

export default slice.reducer;

export const setUserDocument = slice.actions.setUserDocument;

export function createDocument(data) {
  return async (dispatch) => {
    const newDocument = await document.createDocument(data);
    dispatch(slice.actions.setUserDocument({ document: newDocument }));
  };
}

export function getMyDocument() {
  return async (dispatch) => {
    const myDocument = await document.getMyDocument();
    dispatch(slice.actions.setUserDocument({ document: myDocument }));
  };
}
