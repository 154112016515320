function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_TRAINING = '/training';

export const PATH_APP_LINK = '/apps';
export const PATH_INVITE_LINK = (teamName) => `/invite/${teamName}`;

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: {
    root: path(ROOTS_AUTH, '/register'),
    nonProfit: {
      aboutYou: path(ROOTS_AUTH, '/register/non-profit/about-you'),
      aboutBusiness: path(ROOTS_AUTH, '/register/non-profit/about-business'),
      addTeamMembers: path(ROOTS_AUTH, '/register/non-profit/add-team-members'),
    },
    business: {
      aboutYou: path(ROOTS_AUTH, '/register/business/about-you'),
      aboutBusiness: path(ROOTS_AUTH, '/register/business/about-business'),
      addTeamMembers: path(ROOTS_AUTH, '/register/business/add-team-members'),
    },
    joinTeam: {
      email: path(ROOTS_AUTH, '/register/join-team'),
      invites: path(ROOTS_AUTH, '/register/team-invites'),
      invitesFor: (email) =>
        path(ROOTS_AUTH, `/register/team-invites/${email}`),
      info: path(ROOTS_AUTH, '/register/about-you-join-team'),
    },
    pendingVerification: (teamId, userId) =>
      path(ROOTS_AUTH, `/register/pending-verification/${teamId}/${userId}`),
  },
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verifyEmail: path(ROOTS_AUTH, '/verify-email/:verificationToken'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  unsubscribe: '/unsubscribe/:type/:userId/:teamId',
  unsubscribeAll: '/unsubscribe-all/:userId/:teamId',
};

export const PATH_HOME = {
  dashboard: ROOTS_DASHBOARD,
};

export const PATH_TRAINING = {
  root: ROOTS_TRAINING,
  start: path(ROOTS_TRAINING, '/start'),
  lesson: path(ROOTS_TRAINING, '/lesson'),
  exam: path(ROOTS_TRAINING, '/exam'),
  examReview: path(ROOTS_TRAINING, '/results'),
  waiver: path(ROOTS_TRAINING, '/waiver'),
  complete: path(ROOTS_TRAINING, '/complete'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  chatShortcut: (conversationSid) =>
    path(ROOTS_DASHBOARD, `/chat/${conversationSid}`),
  impact: path(ROOTS_DASHBOARD, '/impact'),
  reverifyEmail: path(ROOTS_DASHBOARD, '/reverify-email/:verificationToken'),
  incompleteDonations: path(ROOTS_DASHBOARD, '/incomplete-donations'),
  allPlans: path(ROOTS_DASHBOARD, '/all-plans'),
  payment: path(ROOTS_DASHBOARD, '/payment'),
  rescues: path(ROOTS_DASHBOARD, '/rescues'),
  donations: path(ROOTS_DASHBOARD, '/donations'),
  reports: path(ROOTS_DASHBOARD, '/reports'),
  donationsReport: path(ROOTS_DASHBOARD, '/donations-report'),
  mydonations: path(ROOTS_DASHBOARD, '/my-donations'),
  drivers: path(ROOTS_DASHBOARD, '/drivers'),
  incomingRequests: path(ROOTS_DASHBOARD, '/incoming-requests'),
  myrescues: path(ROOTS_DASHBOARD, '/my-rescues'),
  business: path(ROOTS_DASHBOARD, '/business'),
  npo: path(ROOTS_DASHBOARD, '/npo'),
  vehicle: path(ROOTS_DASHBOARD, '/vehicle'),
  findDonations: path(ROOTS_DASHBOARD, '/find-donations'),
  viewDonation: (id) => path(ROOTS_DASHBOARD, `/donations/${id}`),
  cancellationReport: path(ROOTS_DASHBOARD, '/cancellations'),
  viewFoodRun: (id) => path(ROOTS_DASHBOARD, `/food-run/view/${id}`),
  editDonationSingleInstance: (id) =>
    path(ROOTS_DASHBOARD, `/donation/edit/individual/${id}`),
  editRecurringDonation: (id) =>
    path(ROOTS_DASHBOARD, `/donation/edit/recurring/${id}`),
  editFoodRunSingleInstance: (id) =>
    path(ROOTS_DASHBOARD, `/food-run/edit/individual-run/${id}`),
  editRecurringFoodRun: (id) =>
    path(ROOTS_DASHBOARD, `/food-run/edit/recurring/${id}`),
  editOrg: (id) => path(ROOTS_DASHBOARD, `/org/edit/${id}`),
  viewOrg: (id) => path(ROOTS_DASHBOARD, `/org/${id}`),
  teams: path(ROOTS_DASHBOARD, '/my-team'),
  users: path(ROOTS_DASHBOARD, '/users'),
  viewTeamMember: (id) => path(ROOTS_DASHBOARD, `/team-member/${id}`),
  trainingEditor: path(ROOTS_DASHBOARD, '/training-editor'),
  customizeEmail: path(ROOTS_DASHBOARD, '/customize-email'),
  agreements: path(ROOTS_DASHBOARD, '/agreements'),
  partnerships: path(ROOTS_DASHBOARD, '/partnerships'),
  agreement: (id) => path(ROOTS_DASHBOARD, `/agreement/${id}`),
  corporateOnboarding: {
    request: (id, corpId) =>
      path(ROOTS_DASHBOARD, `/corp-onboarding/corp/${id}/${corpId}`),
    progress: (id, teamId) =>
      path(ROOTS_DASHBOARD, `/corp-onboarding/progress/${id}/${teamId}`),
    complete: {
      form: (id) =>
        path(ROOTS_DASHBOARD, `/corp-onboarding/complete/form/${id}`),
      agreement: (id) =>
        path(ROOTS_DASHBOARD, `/corp-onboarding/complete/agreement/${id}`),
      document: (id) =>
        path(ROOTS_DASHBOARD, `/corp-onboarding/complete/document/${id}`),
      upload: (id) =>
        path(ROOTS_DASHBOARD, `/corp-onboarding/complete/upload/${id}`),
    },
    view: {
      form: (id) => path(ROOTS_DASHBOARD, `/corp-onboarding/view/form/${id}`),
      agreement: (id) =>
        path(ROOTS_DASHBOARD, `/corp-onboarding/view/agreement/${id}`),
      document: (id) =>
        path(ROOTS_DASHBOARD, `/corp-onboarding/view/document/${id}`),
      upload: (id) =>
        path(ROOTS_DASHBOARD, `/corp-onboarding/view/upload/${id}`),
    },
  },
  schedule: {
    root: path(ROOTS_DASHBOARD, '/my-schedule'),
    donations: path(ROOTS_DASHBOARD, '/upcoming-donations'),
  },
  admin: {
    users: path(ROOTS_DASHBOARD, '/admin/users'),
    viewUser: (id) => path(ROOTS_DASHBOARD, `/admin/user/${id}`),
    orgs: path(ROOTS_DASHBOARD, '/admin/organizations'),
    map: path(ROOTS_DASHBOARD, '/admin/map'),
    mergeOrgs: path(ROOTS_DASHBOARD, '/admin/organizations/merge'),
    createOrg: path(ROOTS_DASHBOARD, `/admin/organization/create`),
    viewOrg: (id) => path(ROOTS_DASHBOARD, `/admin/organization/${id}`),
    subscriptions: path(ROOTS_DASHBOARD, '/admin/subscriptions'),
    executiveUsers: path(ROOTS_DASHBOARD, '/executive-users'),
    viewExecutiveUsers: (id) => path(ROOTS_DASHBOARD, `/executive-users/${id}`),
    corporateAccounts: path(ROOTS_DASHBOARD, '/corporate-users'),
    viewCorporateAccount: (id) =>
      path(ROOTS_DASHBOARD, `/corporate-users/${id}`),
  },
  settings: {
    account: (tab, section) =>
      path(
        ROOTS_DASHBOARD,
        `/settings/account/${tab}${section ? '#' : ''}${section || ''}`
      ),
    business: (tab, section) =>
      path(
        ROOTS_DASHBOARD,
        `/settings/business/${tab}${section ? '#' : ''}${section || ''}`
      ),
    tags: path(ROOTS_DASHBOARD, `/settings/tags`),
    documents: path(ROOTS_DASHBOARD, '/documents'),
  },
  imports: {
    user: path(ROOTS_DASHBOARD, '/admin/import-users'),
    npo: path(ROOTS_DASHBOARD, '/admin/import-npo'),
    donor: path(ROOTS_DASHBOARD, '/admin/import-donor'),
  },
  foodRun: {
    allRuns: path(ROOTS_DASHBOARD, '/all-runs'),
    myRuns: path(ROOTS_DASHBOARD, '/my-runs'),
    availableRuns: path(ROOTS_DASHBOARD, '/available-runs'),
    runHistory: path(ROOTS_DASHBOARD, '/run-history'),
    inProgress: path(ROOTS_DASHBOARD, '/food-run/in-progress'),
    toBeReassigned: path(ROOTS_DASHBOARD, '/food-run/to-be-reassigned'),
  },
  executive: {
    home: path(ROOTS_DASHBOARD, '/executive/home'),
    orgs: path(ROOTS_DASHBOARD, '/executive/organizations'),
    viewOrg: (id) => path(ROOTS_DASHBOARD, `/executive/organizations/${id}`),
    donations: {
      all: path(ROOTS_DASHBOARD, '/executive/donations'),
      incoming: path(ROOTS_DASHBOARD, '/executive/donations/incoming'),
      outgoing: path(ROOTS_DASHBOARD, '/executive/donations/outgoing'),
      internal: path(ROOTS_DASHBOARD, '/executive/donations/internal'),
    },
    geozones: path(ROOTS_DASHBOARD, '/executive/geozones'),
    createOrg: path(ROOTS_DASHBOARD, '/executive/create-organization'),
    inspections: {
      all: path(ROOTS_DASHBOARD, '/executive/inspections'),
      mine: path(ROOTS_DASHBOARD, '/executive/my-inspections'),
      incomplete: path(ROOTS_DASHBOARD, '/executive/incomplete-inspections'),
      view: (id) => path(ROOTS_DASHBOARD, `/executive/inspections/${id}`),
      fro: path(ROOTS_DASHBOARD, '/executive/inspections/fro'),
      efg: path(ROOTS_DASHBOARD, '/executive/inspections/efg'),
      reports: {
        _: path(ROOTS_DASHBOARD, '/executive/report'),
        fro: path(ROOTS_DASHBOARD, '/executive/report/fro'),
        efg: path(ROOTS_DASHBOARD, '/executive/report/efg'),
      },
    },
    complaints: {
      all: path(ROOTS_DASHBOARD, '/executive/complaints'),
      mine: path(ROOTS_DASHBOARD, '/executive/my-complaints'),
      incomplete: path(ROOTS_DASHBOARD, '/executive/incomplete-complaints'),
      view: (id) => path(ROOTS_DASHBOARD, `/executive/complaints/${id}`),
      form: path(ROOTS_DASHBOARD, '/executive/complaints/form'),
      report: path(ROOTS_DASHBOARD, '/executive/report/complaints'),
    },
  },
  corporate: {
    home: path(ROOTS_DASHBOARD, '/corporate/home'),
    teams: path(ROOTS_DASHBOARD, '/corporate/teams'),
    npos: path(ROOTS_DASHBOARD, '/corporate/npos'),
    viewTeam: (id) => path(ROOTS_DASHBOARD, `/corporate/team/${id}`),
    schedule: path(ROOTS_DASHBOARD, '/corporate/donations/schedule'),
    donations: path(ROOTS_DASHBOARD, '/corporate/donations'),
    viewDonation: (id) => path(ROOTS_DASHBOARD, `/corporate/donations/${id}`),
    report: path(ROOTS_DASHBOARD, '/corporate/report'),
    onboarding: {
      root: path(ROOTS_DASHBOARD, '/corporate/onboarding'),
      requests: path(ROOTS_DASHBOARD, '/corporate/onboarding/requests'),
      request: (id, teamId) =>
        path(ROOTS_DASHBOARD, `/corporate/onboarding/requests/${teamId}/${id}`),
      complianceProfile: {
        root: path(
          ROOTS_DASHBOARD,
          '/corporate/onboarding/compliance-profiles'
        ),
        create: path(
          ROOTS_DASHBOARD,
          '/corporate/onboarding/compliance-profile/new'
        ),
        view: (id) =>
          path(
            ROOTS_DASHBOARD,
            `/corporate/onboarding/compliance-profile/details/${id}`
          ),
        edit: (id) =>
          path(
            ROOTS_DASHBOARD,
            `/corporate/onboarding/compliance-profile/edit/${id}`
          ),
      },
      create: (profileId) => ({
        form: path(
          ROOTS_DASHBOARD,
          `/corporate/onboarding/create/form/${profileId}`
        ),
        agreement: path(
          ROOTS_DASHBOARD,
          `/corporate/onboarding/create/agreement/${profileId}`
        ),
        document: path(
          ROOTS_DASHBOARD,
          `/corporate/onboarding/create/document/${profileId}`
        ),
        upload: path(
          ROOTS_DASHBOARD,
          `/corporate/onboarding/create/upload/${profileId}`
        ),
      }),
      edit: {
        form: (id) =>
          path(ROOTS_DASHBOARD, `/corporate/onboarding/edit/form/${id}`),
        agreement: (id) =>
          path(ROOTS_DASHBOARD, `/corporate/onboarding/edit/agreement/${id}`),
        document: (id) =>
          path(ROOTS_DASHBOARD, `/corporate/onboarding/edit/document/${id}`),
        upload: (id) =>
          path(ROOTS_DASHBOARD, `/corporate/onboarding/edit/upload/${id}`),
      },
      progress: {
        form: (id, teamId) =>
          path(
            ROOTS_DASHBOARD,
            `/corporate/onboarding/progress/form/${id}/${teamId}`
          ),
        agreement: (id, teamId) =>
          path(
            ROOTS_DASHBOARD,
            `/corporate/onboarding/progress/agreement/${id}/${teamId}`
          ),
        document: (id, teamId) =>
          path(
            ROOTS_DASHBOARD,
            `/corporate/onboarding/progress/document/${id}/${teamId}`
          ),
        upload: (id, teamId) =>
          path(
            ROOTS_DASHBOARD,
            `/corporate/onboarding/progress/upload/${id}/${teamId}`
          ),
      },
    },
  },
};
