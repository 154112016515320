import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { IconButton } from '@mui/material';

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[900], 0.48),
}));

CarouselControlsDownload.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default function CarouselControlsDownload({ onClick }) {
  return (
    <RootStyle>
      <IconButton
        aria-label="Download Completion Image"
        onClick={onClick}
        title="Download Completion Image"
      >
        <CloudDownloadIcon sx={{ color: 'white' }} />
      </IconButton>
    </RootStyle>
  );
}
