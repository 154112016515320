import { fetchAPI, METHODS, parseBody } from './common';
import qs from 'qs';

/**
 * @typedef Impact
 * @property {number} month
 * @property {number} food_weight
 * @property {number} unservable_weight
 * @property {number} non_food_weight
 * @property {number} perishable_weight
 * @property {number} non_perishable_weight
 * @property {number} dairy_weight
 * @property {number} produce_weight
 * @property {number} meat_weight
 * @property {number} baked_weight
 * @property {number} beverages_weight
 * @property {number} prepared_weight
 * @property {number} count
 */

/**
 * Get aggregated rescue statistics for the current user specifically.
 * @param {String} param0.startDate
 * @param {String} param0.endDate
 * @param {String} param0.interval One of month, year, all-time & custom
 * @returns Promise<Impact>
 */
export async function getMyImpact({ startDate, endDate, interval }) {
  const res = await fetchAPI(
    `/stats/my-impact?${qs.stringify({ startDate, endDate, interval })}`,
    METHODS.get
  );
  return parseBody(res);
}

/**
 * Get aggregated rescue statistics for the current user's team specifically.
 * @param {String} param0.startDate
 * @param {String} param0.endDate
 * @param {String} param0.interval One of month, year, all-time & custom
 * @returns Promise<Impact>
 */
export async function getTeamImpact({ startDate, endDate, interval }) {
  const res = await fetchAPI(
    `/stats/team-impact?${qs.stringify(
      { startDate, endDate, interval },
      { skipNulls: true }
    )}`,
    METHODS.get
  );
  return parseBody(res);
}

/**
 * Get aggregated donation statistics for the current user's corporate teams.
 * @param {String} param0.startDate
 * @param {String} param0.endDate
 * @param {String} param0.interval One of month, year, all-time & custom
 * @returns Promise<Impact>
 */
export async function getCorporateImpact({ startDate, endDate, interval }) {
  const res = await fetchAPI(
    `/stats/corporate-impact?${qs.stringify(
      { startDate, endDate, interval },
      { skipNulls: true }
    )}`,
    METHODS.get
  );
  return parseBody(res);
}

export async function getGeozoneImpact({
  startDate,
  endDate,
  interval,
  geofences,
}) {
  const res = await fetchAPI(
    `/stats/geozone-impact?${qs.stringify({ startDate, endDate, interval })}`,
    METHODS.post,
    { geofences }
  );
  return parseBody(res);
}
