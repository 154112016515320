import { useSnackbar } from 'notistack';
import useAuth from '../hooks/useAuth';
import { Alert, Link } from '@mui/material';

export default function EmailSuppressionStatus(others) {
  const { enqueueSnackbar } = useSnackbar();
  const { user, isEmailSuppressed, sendVerificationToUnsuppressEmail } =
    useAuth();

  const handleVerify = async () => {
    const result = await sendVerificationToUnsuppressEmail({
      email: user.email,
    });
    if (result.status) {
      enqueueSnackbar(
        'We have sent email to your inbox to reverify your email',
        {
          variant: 'success',
        }
      );
    }
  };

  return (
    <>
      {isEmailSuppressed && (
        <Alert severity="error" {...others}>
          There is an issue sending email to your inbox.{' '}
          <Link href="#" onClick={handleVerify}>
            Please verify
          </Link>{' '}
          your email address
        </Alert>
      )}
    </>
  );
}
