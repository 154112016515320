import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
// icons
import MoreVert from '@mui/icons-material/MoreVert';

// ----------------------------------------------------------------------

MoreButton.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      action: PropTypes.func.isRequired,
      showIf: PropTypes.bool,
    })
  ).isRequired,
};

MoreButton.defaultProps = {
  className: undefined,
};

export default function MoreButton({
  className,
  options: allOptions,
  displayButtonForSingleOption = true,
  Icon = MoreVert,
  title,
  ...other
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // Filter out hidden options using `showIf` which defaults to true, or
  // otherwise may be any falsey/truthy value.
  const options = useMemo(
    () => allOptions.filter(({ showIf = true }) => showIf),
    [allOptions]
  );

  // Collapse the menu if for 0 and 1 options
  if (options.length === 0) return null;

  if (options.length === 1 && displayButtonForSingleOption) {
    const [item] = options;

    return (
      <Button startIcon={item.icon} onClick={item.action} variant="text">
        {item.text}
      </Button>
    );
  }

  return (
    <>
      {title ? (
        <Button
          ref={ref}
          className={className}
          onClick={() => setIsOpen(true)}
          {...other}
          endIcon={<Icon />}
        >
          {title}
        </Button>
      ) : (
        <IconButton
          ref={ref}
          className={className}
          onClick={() => setIsOpen(true)}
          {...other}
        >
          <Icon />
        </IconButton>
      )}

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map(({ showIf = true, ...item }) =>
          showIf ? (
            <MenuItem
              key={item.text}
              onClick={(...args) => {
                item.action(...args);
                setIsOpen(false);
              }}
              sx={{ color: 'text.secondary' }}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </MenuItem>
          ) : null
        )}
      </Menu>
    </>
  );
}
