import { assertErrors, fetchAPI, METHODS, parseBody } from './common';

/**
 * @typedef UploadConfig
 * @property {string} folder
 * @property {string} filetype

/**
 * Uploads file to an S3 bucket, returns it's url.
 * @param {File} file 
 * @param {UploadConfig} config 
 * @param {UploadConfig} path 
 */
export async function upload(file, config, path) {
  const res = await fetchAPI('/document/signed-url', METHODS.post, config);
  let { url, fileName } = await parseBody(res);

  const blobData = new Blob([await file.arrayBuffer()], { type: file.type });
  const uploadRes = await fetch(url, { method: METHODS.put, body: blobData });
  if (uploadRes.status !== 200) {
    throw new Error(res.statusText);
  }

  return `${path}/${fileName}`;
}

/**
 * @typedef CreateDocument
 * @property {string} name
 */

/**
 * @param {CreateDocument} data
 */
export async function createDocument(data) {
  const res = await fetchAPI('/document', METHODS.post, data);
  return parseBody(res, true);
}

export async function getMyDocument() {
  const res = await fetchAPI('/document/me', METHODS.get);
  if (res.status !== 200) throw new Error(res.statusText);

  const createdDocument = await parseBody(res, true);
  return createdDocument;
}

export async function getUserDocuments(userId) {
  const res = await fetchAPI(`/document/user/${userId}`, METHODS.get);
  if (res.status !== 200) throw new Error(res.statusText);

  return parseBody(res, true);
}

export async function getDocumentTypes() {
  const res = await fetchAPI('/document/types', METHODS.get);
  if (res.status !== 200) throw new Error(res.statusText);

  const docTypes = await parseBody(res, true);
  return docTypes;
}

export async function deleteDocument(id) {
  const res = await fetchAPI(`/document/${id}`, METHODS.delete);
  await assertErrors(res);
}

export async function deleteDocumentInS3(key) {
  const res = await fetchAPI(`/document/s3`, METHODS.delete, { key });
  await assertErrors(res);
}
