import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setData, setValid } from 'src/redux/slices/onboarding';

// ----------------------------------------------------------------------

const useOnboarding = () => {
  const { data, valid } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const handleSetData = (payload) => dispatch(setData(payload));
  const updateField = (name, value) =>
    dispatch(setData({ ...data, [name]: value }));
  const updateValid = useCallback(
    (page, value) => dispatch(setValid({ section: page, valid: value })),
    []
  );

  return {
    data,
    valid,
    setData: handleSetData,
    updateValid,
    updateField,
  };
};

// ----------------------------------------------------------------------

const useOnboardingForm = () => {
  const { data, updateField, ...other } = useOnboarding();

  const getFields = (name, defaultValue = '') => ({
    value: data[name] ?? defaultValue,
    onChange: (e) => updateField(name, e.target.value),
  });

  return {
    ...other,
    data,
    updateField,
    getFields,
  };
};

export default useOnboarding;
export { useOnboardingForm };
