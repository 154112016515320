import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'src/utils/date-fns';
// @mui
import {
  Avatar,
  AvatarGroup,
  Badge,
  Stack,
  Typography,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
} from '@mui/material';
// components
import BadgeStatus from 'src/components/BadgeStatus';
import useChatClient from 'src/hooks/useChatClient';
import { format } from 'src/utils/date-fns';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const CURRENT_USER_ID = '8864c717-587d-472a-929a-8e5f298024da-0';

ChatNavItem.propTypes = {
  conversation: PropTypes.object,
};

Other.propTypes = {
  openNav: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  conversation: PropTypes.object,
};

export default function ChatNavItem({
  conversation,
  openNav,
  isSelected,
  onSelect,
}) {
  const { t } = useTranslation('translation');
  const { conversationParticipants } = useChatClient();
  const participants = conversationParticipants[conversation.sid];

  const lastActivity =
    conversation.lastMessage?.dateCreated ?? conversation.dateCreated;
  const isUnread =
    conversation.lastMessage &&
    conversation.lastMessage?.index !== conversation.lastReadMessageIndex;

  if (!participants) {
    return null; // TODO: loading indicator
  }

  return (
    <ListItemButton
      disableGutters
      onClick={onSelect}
      sx={{
        py: 1.5,
        px: 2.5,
        ...(isSelected && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar
        sx={{
          width: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {participants.length > 1 ? (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              /*hasOnlineInGroup*/ participants.length === 'false for now' && (
                <BadgeStatus status="online" />
              )
            }
          >
            <AvatarGroup max={2}>
              {participants.map(({ participant, user }) => (
                <Avatar
                  key={participant.sid}
                  alt={user.friendlyName}
                  src={participant.attributes.avatar}
                  sx={{ width: 32, height: 32 }}
                />
              ))}
            </AvatarGroup>
          </Badge>
        ) : (
          <Avatar
            key={participants[0].participant.sid}
            alt={participants[0].user.friendly}
            src={participants[0].participant.attributes.avatar}
            BadgeProps={{
              badgeContent: (
                <BadgeStatus status={participants[0].user.status} />
              ),
            }}
            sx={{ width: 48, height: 48 }}
          />
        )}
      </ListItemAvatar>

      {openNav && (
        <>
          <ListItemText
            primary={conversation.friendlyName
              ?.replace('Admin Chat:', `${t('chat.admin-chat')}:`)
              .replace('Team Chat:', `${t('chat.team-chat')}:`)}
            primaryTypographyProps={{ noWrap: true, variant: 'subtitle2' }}
            secondary={format(
              new Date(conversation.dateCreated.toString()),
              'EEE MMM dd yyyy'
            )}
            secondaryTypographyProps={{
              noWrap: true,
              variant: isUnread ? 'subtitle2' : 'body2',
              color: isUnread ? 'text.primary' : 'text.secondary',
            }}
          />

          <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
            <Typography
              noWrap
              variant="body2"
              component="span"
              sx={{
                mb: 1.5,
                fontSize: 12,
                color: 'text.disabled',
              }}
            >
              {formatDistanceToNowStrict(new Date(lastActivity), {
                addSuffix: false,
              })}
            </Typography>
            {isUnread && <BadgeStatus status="unread" size="small" />}
          </Stack>
        </>
      )}
    </ListItemButton>
  );
}

function Other({ conversation, openNav, isSelected, onSelect }) {
  const details = getDetails(conversation, CURRENT_USER_ID);

  const lastActivity =
    conversation.messages[conversation.messages.length - 1].createdAt;

  const isGroup = details.otherParticipants.length > 1;

  const isUnread = conversation.unreadCount > 0;

  const hasOnlineInGroup =
    isGroup &&
    details.otherParticipants.map((item) => item.status).includes('online');

  return (
    <ListItemButton
      disableGutters
      onClick={onSelect}
      sx={{
        py: 1.5,
        px: 2.5,
        ...(isSelected && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar
        sx={{
          width: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isGroup ? (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={hasOnlineInGroup && <BadgeStatus status="online" />}
          >
            <AvatarGroup max={2}>
              {details.otherParticipants.map((participant) => (
                <Avatar
                  key={participant.id}
                  alt={participant.name}
                  src={participant.avatar}
                  sx={{ width: 32, height: 32 }}
                />
              ))}
            </AvatarGroup>
          </Badge>
        ) : (
          <Avatar
            key={details.otherParticipants[0].id}
            alt={details.otherParticipants[0].name}
            src={details.otherParticipants[0].avatar}
            BadgeProps={{
              badgeContent: (
                <BadgeStatus status={details.otherParticipants[0].status} />
              ),
            }}
            sx={{ width: 48, height: 48 }}
          />
        )}
      </ListItemAvatar>

      {openNav && (
        <>
          <ListItemText
            primary={details.displayNames}
            primaryTypographyProps={{ noWrap: true, variant: 'subtitle2' }}
            secondary={details.displayText}
            secondaryTypographyProps={{
              noWrap: true,
              variant: isUnread ? 'subtitle2' : 'body2',
              color: isUnread ? 'text.primary' : 'text.secondary',
            }}
          />

          <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
            <Typography
              noWrap
              variant="body2"
              component="span"
              sx={{
                mb: 1.5,
                fontSize: 12,
                color: 'text.disabled',
              }}
            >
              {formatDistanceToNowStrict(new Date(lastActivity), {
                addSuffix: false,
              })}
            </Typography>

            {isUnread && <BadgeStatus status="unread" size="small" />}
          </Stack>
        </>
      )}
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

const getDetails = (conversation, currentUserId) => {
  const otherParticipants = conversation.participants.filter(
    (participant) => participant.id !== currentUserId
  );

  const displayNames = otherParticipants
    .map((participant) => participant.name)
    .join(', ');

  let displayText = '';

  const lastMessage = conversation.messages[conversation.messages.length - 1];
  if (lastMessage) {
    const sender = lastMessage.senderId === currentUserId ? 'You: ' : '';

    const message =
      lastMessage.contentType === 'image' ? 'Sent a photo' : lastMessage.body;

    displayText = `${sender}${message}`;
  }
  return { otherParticipants, displayNames, displayText };
};
