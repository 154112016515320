import PropTypes from 'prop-types';
import { useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from '@mui/material';
// components
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
// hooks
import Paywall from 'src/views/Paywall';
import { useHistory } from 'react-router';
import { PATH_TRAINING } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import CreateFoodRun from 'src/components/CreateFoodRun';
import EmailSuppressionStatus from 'src/components/EmailSuppressionStatus';
import { IncomingRequestsProvider } from 'src/views/Donations/IncomingRequests';
import { ChatClientProvider } from 'src/hooks/useChatClient';
import { OnboardingRequestsProvider } from 'src/views/Partnerships/Requests';
import useCreateDonation from 'src/hooks/useCreateDonation';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default function DashboardLayout({ children }) {
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  const history = useHistory();

  const trainingComplete =
    !user.is_safety_program_enabled || user.is_done_training;
  const [trainingOpen, setTrainingOpen] = useState(true);
  const handleCloseTraining = () => setTrainingOpen(false);
  const handleOpenTraining = () => setTrainingOpen(true);
  const handleStartTraining = () => history.push(PATH_TRAINING.start);

  const { handleOpen, form: createDonationForm } = useCreateDonation(() => {});

  return (
    <RootStyle>
      <ChatClientProvider>
        <IncomingRequestsProvider>
          <OnboardingRequestsProvider>
            <DashboardNavbar
              onOpenSidebar={() => setOpen(true)}
              trainingComplete={trainingComplete}
              handleOpenTraining={handleOpenTraining}
            />
            <DashboardSidebar
              handleDonationOpen={handleOpen}
              isOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
            />
            <MainStyle>
              <Paywall>
                <Box my={2}>
                  <EmailSuppressionStatus />
                </Box>

                {children}
              </Paywall>
              <CreateFoodRun />
              {createDonationForm()}
            </MainStyle>

            <Dialog
              open={!trainingComplete && trainingOpen}
              onClose={handleCloseTraining}
            >
              <DialogTitle>{t('training-program.title')}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('training-program.description')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseTraining}>
                  {t('training-program.not-now')}
                </Button>
                <Button variant="contained" onClick={handleStartTraining}>
                  {t('training-program.start-training')}
                </Button>
              </DialogActions>
            </Dialog>
          </OnboardingRequestsProvider>
        </IncomingRequestsProvider>
      </ChatClientProvider>
    </RootStyle>
  );
}
