import { Redirect } from 'react-router-dom';
// guards
import AuthGuard from '../guards/AuthGuard.js';
// layouts
import TrainingLayout from '../layouts/training';
//
import { PATH_TRAINING } from './paths';
import lazy from './lazy';

// ----------------------------------------------------------------------

const TrainingRoutes = {
  path: PATH_TRAINING.root,
  guard: AuthGuard,
  layout: TrainingLayout,
  routes: [
    {
      exact: true,
      path: PATH_TRAINING.complete,
      component: lazy(() => import('../views/Training/Complete.js')),
    },

    {
      exact: true,
      path: PATH_TRAINING.exam,
      component: lazy(() => import('../views/Training/Exam.js')),
    },

    {
      exact: true,
      path: PATH_TRAINING.examReview,
      component: lazy(() => import('../views/Training/Review.js')),
    },

    {
      exact: true,
      path: PATH_TRAINING.lesson,
      component: lazy(() => import('../views/Training/Lesson.js')),
    },

    {
      exact: true,
      path: PATH_TRAINING.start,
      component: lazy(() => import('../views/Training/Start.js')),
    },

    {
      exact: true,
      path: PATH_TRAINING.waiver,
      component: lazy(() => import('../views/Training/Waiver.js')),
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default TrainingRoutes;
