import moment from 'moment';
import { fetchAPI, METHODS, parseBody, assertErrors } from './common';
import qs from 'qs';

/**
 * @typedef FilterModelObj
 * @property {FilterModelObjItem[]} items
 * @property {LinkOperator} linkOperator
 */

/**
 * @typedef FilterModelObjItem
 * @property {number}    id
 * @property {string}    columnField
 * @property {Operator}  operatorValue
 * @property {any?}      value
 */

/**
 * @typedef {"contains" | "equals" | "startsWith" | "endsWith" | "isEmpty" | "isNotEmpty" | "isAnyOf"} Operator
 */

/**
 * @typedef {"and" | "or"} LinkOperator
 */

/**
 * @typedef Rescue
 * @property {string} address
 * @property {string} assigned_to
 * @property {string} assignor_team
 * @property {string} business_id
 * @property {string} business_name
 * @property {Date} rescue_date
 * @property {Date} created
 * @property {string} donation_id
 * @property {string} driver_id
 * @property {string} id
 * @property {string} notes
 * @property {string} status
 * @property {string} team_id
 * @property {string} user_id
 * @property {number} weight
 */

/**
 * Gets all rescues associated with this user's team
 * @param {number} param0.offset
 * @param {number} param0.step
 * @param {FilterModelObj} param0.filterModel
 * @param {boolean} excludeSelfRunsFromRescue
 * @param {boolean} requests
 * @returns Promise<Rescue>
 */
export async function get({
  offset,
  step,
  filterModel,
  excludeSelfRunsFromRescue,
  requests,
}) {
  const res = await fetchAPI(
    `/rescue/get?${qs.stringify({
      offset,
      step,
      excludeSelfRunsFromRescue,
      requests,
    })}`,
    METHODS.post,
    { filterModel }
  );
  const { rescues } = await parseBody(res);

  rescues.results = rescues.results.map((rescue) => ({
    ...rescue,
    rescue_date: moment.utc(rescue.rescue_date),
    created: moment.utc(rescue.created),
  }));

  return rescues;
}

/**
 * Gets all rescues associated with this user
 * @param {number} param0.offset
 * @param {number} param0.step
 * @param {FilterModelObj} param0.filterModel
 * @returns Promise<Rescue>
 */
export async function getMyRescues({ offset, step, filterModel }) {
  const res = await fetchAPI(
    `/rescue/self/get?${qs.stringify({ offset, step })}`,
    METHODS.post,
    { filterModel }
  );
  const { rescues } = await parseBody(res);

  rescues.results = rescues.results.map((rescue) => ({
    ...rescue,
    rescue_date: moment.utc(rescue.rescue_date),
    created: moment.utc(rescue.created),
  }));

  return rescues;
}

/**
 * Gets all rescues associated with this user's team from the past week. (LIMIT 100)
 * @returns Promise<Rescue>
 */
export async function getRecent() {
  const res = await fetchAPI('/rescue/recent', METHODS.get);
  const { rescues } = await parseBody(res);

  return rescues.map((rescue) => ({
    ...rescue,
    rescue_date: moment.utc(rescue.rescue_date),
    created: moment.utc(rescue.created),
  }));
}

/**
 * @typedef Impact
 * @property {number} month
 * @property {number} food_weight
 * @property {number} unservable_weight
 * @property {number} non_food_weight
 * @property {number} perishable_weight
 * @property {number} non_perishable_weight
 * @property {number} dairy_weight
 * @property {number} produce_weight
 * @property {number} meat_weight
 * @property {number} baked_weight
 * @property {number} beverages_weight
 * @property {number} prepared_weight
 * @property {number} count
 */

/**
 * Get aggregated rescue statistics for the current user specifically.
 * @param {String} param0.startDate
 * @param {String} param0.endDate
 * @param {String} param0.interval One of month, year, all-time & custom
 * @returns Promise<Impact>
 */
export async function getMyImpact({ startDate, endDate, interval }) {
  const res = await fetchAPI(
    `/stats/my-impact?${qs.stringify({ startDate, endDate, interval })}`,
    METHODS.get
  );
  return parseBody(res);
}

/**
 * Get aggregated rescue statistics for the current user's team specifically.
 * @param {String} param0.startDate
 * @param {String} param0.endDate
 * @param {String} param0.interval One of month, year, all-time & custom
 * @returns Promise<Impact>
 */
export async function getTeamImpact({ startDate, endDate, interval }) {
  const res = await fetchAPI(
    `/stats/team-impact?${qs.stringify(
      { startDate, endDate, interval },
      { skipNulls: true }
    )}`,
    METHODS.get
  );
  return parseBody(res);
}

export async function assignDriverToIncompleteDonation({
  vehicleId,
  driverId,
  donationId,
}) {
  const res = await fetchAPI('/rescue/incomplete', METHODS.post, {
    driverId,
    donationId,
    vehicle: vehicleId,
  });
  return parseBody(res);
}

/**
 * Get a rescue by it corresponding donation id.
 * Returns null if a donation with that id cannot be found.
 * @param {string} id Id of corresponding donation
 * @returns Promise<Rescue>
 */
export async function getById(id) {
  const res = await fetchAPI(`/rescue/${id}`, METHODS.get);
  return parseBody(res);
}

/**
 * Create a rescue for an open donation.
 * @param {string} param0.donationId
 * @param {User} param0.driver
 * @param {User} param0.user
 * @returns {Promise<string>} Returns the id of the new rescue.
 */
export async function create({ donationId, driver, user, vehicleId }) {
  const res = await fetchAPI('/donation/create-rescue', METHODS.post, {
    donationId,
    userId: user.id,
    reservedByTeam: user.team_id,
    assignedTo: driver.id,
    driverId: driver.id,
    driverTeamId: driver.team_id,
    vehicle: vehicleId,
  });

  const { rescueId } = await parseBody(res);
  return rescueId;
}

/**
 * Cancel a rescue. This will release the rescue back into the public so others
 * may claim it.
 * @param {string} param0.donationId
 * @param {string} param0.rescueId
 * @param {string} param0.reasonId Id of the selected reason for cancellation
 * @param {string} param0.otherReason Any custom reason given
 * @returns {}
 */
export async function cancel({ donationId, rescueId, reasonId, otherReason }) {
  const res = await fetchAPI('/donation/cancel-rescue', METHODS.put, {
    donationId,
    rescueId,
    reasonId,
    otherReason: otherReason ? otherReason : null,
  });
  await assertErrors(res);
}

/**
 * Start a rescue.
 * @param {string} param0.donationId
 * @param {string} param0.rescueId
 */
export async function start({ donationId, rescueId, vehicleId }) {
  const res = await fetchAPI('/donation/start-rescue', METHODS.put, {
    donationId,
    rescueId,
    vehicleId,
  });
  await assertErrors(res);
}

/**
 * Complete a rescue.
 * @param {object} data Completion data
 */
export async function complete(data) {
  const res = await fetchAPI('/donation/complete-rescue', METHODS.put, data);
  await assertErrors(res);
}

/**
 * Accept a donation from a business.
 * @param {object} data
 * @param {string} data.donationId
 * @param {string} data.rescueId
 * @param {string} data.driverId
 */
export async function accept({ donationId, rescueId, driverId, vehicleId }) {
  const res = await fetchAPI('/donation/accept-rescue', METHODS.put, {
    donationId,
    rescueId,
    driverId,
    vehicleId,
  });
  await assertErrors(res);
}
