import { useState, useEffect } from 'react';
import { user, admin } from 'src/backend';
import { useSnackbar } from 'notistack';
import { setSession } from 'src/redux/slices/auth';
import { setUserDocument } from 'src/redux/slices/document';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

export default function useTeams() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [teams, setTeams] = useState();
  const history = useHistory();

  const getTeamsByUserId = (userId) => {
    admin
      .getTeamsOfUser(userId)
      .then(setTeams)
      .catch(() =>
        enqueueSnackbar('There was an error fetching teams', {
          variant: 'error',
        })
      );
  };

  const refresh = (args) => {
    user
      .getTeams({
        search: args?.search,
      })
      .then(setTeams)
      .catch(() => {}); // ignroe this error
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, []);

  return {
    teams: teams ?? [],
    loading: !teams,
    refresh,
    switchTo: (teamId, { changeRoute = true } = {}) => {
      const refreshToken = localStorage.getItem('refresh-token');
      localStorage.removeItem('refresh-token'); // will no longer be valid after switching teams
      localStorage.removeItem('active-geofences');

      user
        .switchTeams(teamId, refreshToken)
        .then(({ token, refreshToken }) => {
          setSession(token, refreshToken);
          dispatch(setUserDocument({ document: {} }));

          if (changeRoute) {
            history.push(PATH_DASHBOARD.root);
          }

          setTimeout(() => window.location.reload(), 500);
        })
        .catch(() =>
          enqueueSnackbar('There was an error switching teams', {
            variant: 'error',
          })
        );
    },
    getTeamsByUserId,
  };
}
