import { fetchAPI, parseBody, assertErrors, METHODS } from './common';
// import qs from 'qs';

/**
 * Get Vehicles belonging to the current account.
 * @returns Vehicles[]
 */
export async function getVehicles() {
  const res = await fetchAPI('/vehicles', METHODS.get);
  await assertErrors(res);
  const { vehicles } = await parseBody(res);
  return vehicles;
}

export async function updateVehicle(data) {
  const res = await fetchAPI(`/vehicles/${data.vehicle.id}`, METHODS.put, data);
  await assertErrors(res);
}
export async function createVehicle(data) {
  const res = await fetchAPI(`/vehicles`, METHODS.post, data);
  await assertErrors(res);
}

export async function deleteVehicle(data) {
  const res = await fetchAPI(`/vehicles/${data.id}`, METHODS.delete);
  await assertErrors(res);
}
