import { Stack, Card } from '@mui/material';

import ChatNav from './nav/ChatNav';
import ChatRoom from './room/ChatRoom';
import ChatMessageInput from './message/ChatMessageInput';
import ChatMessageList from './message/ChatMessageList';
import ChatHeader from './ChatHeader';
import useChatClient from 'src/hooks/useChatClient';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Chat() {
  const { chatOpen } = useChatClient();
  const isDesktop = useResponsive('up', 'md');
  return (
    <>
      <ChatNav />

      {chatOpen ? (
        isDesktop ? (
          <ChatContent />
        ) : (
          <Card sx={{ height: '72vh', width: '900px', display: 'flex' }}>
            <ChatContent />
          </Card>
        )
      ) : null}
    </>
  );
}

const ChatContent = () => {
  return (
    <Stack flexGrow={1}>
      <ChatHeader />

      <Stack
        direction="row"
        flexGrow={1}
        sx={{
          overflow: 'hidden',
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Stack flexGrow={1}>
          <ChatMessageList />

          <ChatMessageInput />
        </Stack>

        <ChatRoom />
      </Stack>
    </Stack>
  );
};
