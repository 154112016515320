import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  data: {},
  valid: {},
};

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setValid(state, action) {
      state.valid[action.payload.section] = action.payload.valid;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setData, setValid } = slice.actions;
