// material
import {
  styled,
  alpha,
  Box,
  Button,
  Dialog,
  DialogContent,
  Input,
  InputAdornment,
  IconButton,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Slide,
  DialogTitle,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
} from '@mui/material';
// icons
import SvgIconStyle from 'src/components/SvgIconStyle';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  AccountCircle,
  ChevronLeft,
  ChevronRight,
  Close as CloseIcon,
  LocalShipping,
} from '@mui/icons-material';
// hooks
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
// components
import { MIconButton } from 'src/components/@material-extend';
// utils
import { PATH_DASHBOARD } from 'src/routes/paths';
import { team } from 'src/backend';
import { matchSorter } from 'match-sorter';
import Label from 'src/components/Label';
import moment from 'moment-timezone';
import useAuth from 'src/hooks/useAuth';
import GoogleMapInfoMarkers from 'src/components/GoogleMaps/GoogleMapInfoMarkers';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const InfoContainer = ({ children }) => {
  return (
    <Box bgcolor="#F4F6F8" p={2} borderRadius="16px" mt={2}>
      {children}
    </Box>
  );
};

const ResultCollection = ({
  icon,
  title,
  query,
  items,
  renderItem,
  onView,
  allowMap = false,
}) => {
  const { t } = useTranslation('translation');
  const [viewMore, setViewMore] = useState(false);
  const [view, setView] = useState('list');

  const itemsView = useMemo(() => {
    if (!items) {
      return [];
    }

    const matchSorted = new Set([
      ...matchSorter(items, query, {
        keys: ['primary', 'secondary'],
      }).map(({ id }) => id),
    ]);

    const sorted = items
      .map((item) => ({
        ...item,
        relevance: (item.relevance ?? 0) + (matchSorted.has(item.id) ? 5 : 0),
      }))
      .sort((a, b) => b.relevance - a.relevance);

    return sorted.map(({ lat, lng, ...other }) => ({
      ...other,
      pos: lat && lng && { lat, lng },
    }));
  }, [items, query]);

  if (itemsView.length === 0) {
    return null;
  }

  return (
    <InfoContainer>
      <Box display="flex" alignItems="center" justifyContent="stretch">
        {icon}

        <Typography variant="h5">{title}</Typography>

        <Box flex="1" />

        {allowMap && (
          <ToggleButtonGroup
            color="primary"
            value={view}
            exclusive
            onChange={(_event, newValue) => setView(newValue)}
            size="small"
            sx={{ borderRadius: 1 }}
          >
            <ToggleButton value="list" sx={{ py: 0 }}>
              {t('search-bar.list')}
            </ToggleButton>
            <ToggleButton value="map" sx={{ py: 0 }}>
              {t('search-bar.map')}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>

      {view === 'list' ? (
        <List>
          {(viewMore ? itemsView : itemsView.slice(0, 3)).map(renderItem)}
        </List>
      ) : (
        <Box height="500px" mt={2} borderRadius={1} overflow="hidden">
          <GoogleMapInfoMarkers locations={itemsView} onView={onView} />
        </Box>
      )}

      {itemsView.length > 3 &&
        (viewMore ? (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              endIcon={<ChevronLeft />}
              variant="outlined"
              onClick={() => setViewMore(false)}
            >
              {t('search-bar.view-less')}
            </Button>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" sx={{ mr: 3, ml: 1 }}>
              {t('search-bar.and-more', { count: itemsView.length - 3 })}
            </Typography>

            <Button
              endIcon={<ChevronRight />}
              variant="outlined"
              onClick={() => setViewMore(true)}
            >
              {t('search-bar.view-all')}
            </Button>
          </Box>
        ))}
    </InfoContainer>
  );
};

const ExactMatchDetails = ({ match: [type, result] }) => {
  const { user } = useAuth();
  const { t } = useTranslation('translation');
  if (type === 'orgs') {
    return (
      <Box display="flex" alignItems="center" justifyContent="stretch">
        <SvgIconStyle
          src={`/static/icons/navbar/ic_company.svg`}
          sx={{ mr: 2 }}
        />

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h5">{result.name}</Typography>

            <Typography variant="body1">{result.email}</Typography>
          </Box>

          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="body2">
              {t('search-bar.joined', {
                date: moment
                  .utc(result.created)
                  .tz(user.timezone_id)
                  .format('MMM D, YYYY'),
              })}
            </Typography>

            <Typography variant="body2">{result.formatted_address}</Typography>
          </Box>
        </Box>
      </Box>
    );
  } else if (['rescues', 'donations'].includes(type)) {
    return (
      <Box display="flex" alignItems="center" justifyContent="stretch">
        {type === 'donations' ? (
          <SvgIconStyle src="/static/icons/navbar/ic_cart.svg" sx={{ mr: 2 }} />
        ) : (
          <LocalShipping sx={{ mr: 2 }} />
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h5">{result.title}</Typography>

            <Typography variant="body1">
              {result.is_rescue_complete
                ? t('search-bar.completed')
                : t('search-bar.not-completed')}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="body2">
              {t('search-bar.opened', {
                date: moment
                  .utc(result.start_date)
                  .tz(user.timezone_id)
                  .format('MMM D, YYYY'),
              })}
            </Typography>

            <Typography variant="body2">
              {result.pickup_formatted_address}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  } else if (type === 'foodruns') {
    return (
      <Box display="flex" alignItems="center" justifyContent="stretch">
        <SvgIconStyle
          src="/static/icons/navbar/ic_animate.svg"
          sx={{ mr: 2 }}
        />

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h5">{result.title}</Typography>

            <Typography variant="body1">
              {result.pickup_formatted_address}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="body2">
              {t('search-bar.food-run-item', {
                count: result.count,
                date: moment(result.latest_date)
                  .tz(user.timezone_id)
                  .format('MMM D, YYYY'),
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  } else if (['users', 'teamUsers'].includes(type)) {
    return (
      <Box display="flex" alignItems="center" justifyContent="stretch">
        <AccountCircle sx={{ mr: 2 }} />

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h5">{result.name}</Typography>

            <Typography variant="body1">{result.email}</Typography>
          </Box>

          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="body2">
              {t('search-bar.joined', {
                date: moment(result.created)
                  .tz(user.timezone_id)
                  .format('MMM D, YYYY'),
              })}
            </Typography>

            <Typography variant="body2">{result.phone}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};

const ExactMatch = ({ query, results, handleClick }) => {
  const { t } = useTranslation('translation');
  const match = useMemo(() => {
    if (!results) {
      return null;
    }

    return (
      Object.entries(results)
        .map(([type, items]) => [
          type,
          items.find(
            ({ title, name }) =>
              (title || name).toLowerCase() === query.toLowerCase()
          ),
        ])
        /* eslint-disable-next-line no-unused-vars */
        .find(([_type, result]) => !!result)
    );
  }, [query, results]);

  if (!match) {
    return null;
  }

  const [type, result] = match;

  return (
    <InfoContainer>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Label color="primary" sx={{ mb: 1 }}>
          {t('search-bar.exact-match')}
        </Label>

        <Button
          endIcon={<ChevronRight />}
          variant="outlined"
          onClick={() => handleClick(result.id, type)}
          size="small"
        >
          {t('search-bar.view')}
        </Button>
      </Box>

      <ExactMatchDetails match={match} />
    </InfoContainer>
  );
};

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const { t } = useTranslation('translation');
  const [isOpen, setOpen] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { user } = useAuth();

  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(null);
  const search = useCallback(() => {
    setLoading(true);
    team
      .search(query)
      .then(setResults)
      .catch((err) => {
        console.error(err);
        setResults([]);
      })
      .finally(() => setLoading(false));
  }, [query]);

  const history = useHistory();
  const handleClick = useCallback((id, type) => {
    switch (type) {
      case 'users':
        history.push(PATH_DASHBOARD.admin.viewUser(id));
        break;

      case 'teamUsers':
        history.push(PATH_DASHBOARD.viewTeamMember(id));
        break;

      case 'orgs':
        if (user.account_type === 'ADMIN') {
          history.push(PATH_DASHBOARD.admin.viewOrg(id));
        } else {
          history.push(PATH_DASHBOARD.viewOrg(id));
        }
        break;

      case 'donations':
      case 'rescues':
        history.push(PATH_DASHBOARD.viewDonation(id));
        break;

      case 'foodruns':
        history.push(PATH_DASHBOARD.viewFoodRun(id));
        break;

      default:
        console.log('unknown type');
        break;
    }

    setResultsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    if (!resultsOpen) {
      setQuery('');
    }
  };

  const handleCloseResults = () => {
    setResultsOpen(false);
    setResults(null);
    setQuery('');
  };

  // add "toggle open quick search" global key bind (shift+space)
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.addEventListener('keydown', (event) => {
      if (event.shiftKey && event.code === 'Space') {
        event.preventDefault();
        handleOpen();
      }
    });
  }, []);

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          {!isOpen && (
            <MIconButton onClick={handleOpen}>
              <Icon icon={searchFill} width={20} height={20} />
            </MIconButton>
          )}

          <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
            <SearchbarStyle>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (query === '') return;
                    setOpen(false);
                    setResultsOpen(true);
                    search();
                  }
                }}
                placeholder={t('search-bar.search-placeholder')}
                startAdornment={
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  if (query === '') return;
                  setResultsOpen(true);
                  search();
                }}
              >
                {t('search-bar.search')}
              </Button>
            </SearchbarStyle>
          </Slide>
        </div>
      </ClickAwayListener>

      <Dialog
        open={resultsOpen}
        onClose={handleCloseResults}
        fullWidth
        scroll="body"
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {t('search-bar.results-for', { query })}
            <IconButton size="large" onClick={handleCloseResults}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        {loading && (
          <Box
            width="100%"
            height="400px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}

        {results &&
          Object.values(results).every((list) => list.length === 0) && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              my={4}
            >
              <img
                alt={t('search-bar.no-results')}
                src="/static/illustrations/illustration_seo.svg"
              />

              <Typography
                textAlign="center"
                variant="body1"
                sx={{ mt: 2, mx: 2 }}
              >
                {t('search-bar.no-results')}
              </Typography>
            </Box>
          )}

        <DialogContent>
          <ExactMatch
            query={query}
            results={results}
            handleClick={handleClick}
          />

          <ResultCollection
            title={t('search-bar.users')}
            icon={<AccountCircle sx={{ mr: 2 }} />}
            query={query}
            items={results?.users}
            renderItem={(user) => (
              <ListItem
                button
                key={user.id}
                onClick={() => handleClick(user.id, 'users')}
              >
                <ListItemText primary={user.name} secondary={user.email} />
              </ListItem>
            )}
          />

          <ResultCollection
            title={t('search-bar.team-members')}
            icon={<AccountCircle sx={{ mr: 2 }} />}
            query={query}
            items={results?.teamUsers}
            renderItem={(user) => (
              <ListItem
                button
                key={user.id}
                onClick={() => handleClick(user.id, 'teamUsers')}
              >
                <ListItemText primary={user.name} secondary={user.email} />
              </ListItem>
            )}
          />

          <ResultCollection
            title={t('search-bar.organizations')}
            allowMap
            onView={(id) => handleClick(id, 'orgs')}
            icon={
              <SvgIconStyle
                src={`/static/icons/navbar/ic_company.svg`}
                sx={{ mr: 2 }}
              />
            }
            query={query}
            items={results?.orgs}
            renderItem={(org) => (
              <ListItem
                button
                key={org.id}
                onClick={() => handleClick(org.id, 'orgs')}
              >
                <ListItemText
                  primary={org.name}
                  secondary={org.formatted_address}
                />
              </ListItem>
            )}
          />

          <ResultCollection
            title={t('search-bar.food-runs')}
            icon={
              <SvgIconStyle
                src="/static/icons/navbar/ic_animate.svg"
                sx={{ width: 22, height: 22, mr: 2 }}
              />
            }
            query={query}
            items={results?.foodruns}
            renderItem={(foodrun) => (
              <ListItem
                button
                key={foodrun.id}
                onClick={() => handleClick(foodrun.id, 'foodruns')}
              >
                <ListItemText
                  primary={foodrun.title}
                  secondary={t('search-bar.food-run-item', {
                    count: foodrun.count,
                    date: moment(foodrun.latest_date)
                      .tz(user.timezone_id)
                      .format('MMM D, YYYY'),
                  })}
                />
              </ListItem>
            )}
          />

          <ResultCollection
            title={t('search-bar.donations')}
            icon={
              <SvgIconStyle
                src="/static/icons/navbar/ic_cart.svg"
                sx={{ mr: 2 }}
              />
            }
            query={query}
            items={results?.donations}
            renderItem={(donation) => (
              <ListItem
                button
                key={donation.id}
                onClick={() => handleClick(donation.id, 'donations')}
              >
                <ListItemText
                  primary={donation.title}
                  secondary={donation.pickup_formatted_address}
                />
              </ListItem>
            )}
          />

          <ResultCollection
            title={t('search-bar.rescues')}
            icon={<LocalShipping sx={{ mr: 2 }} />}
            query={query}
            items={results?.rescues}
            renderItem={(rescue) => (
              <ListItem
                button
                key={rescue.id}
                onClick={() => handleClick(rescue.id, 'rescues')}
              >
                <ListItemText
                  primary={rescue.title}
                  secondary={rescue.pickup_formatted_address}
                />
              </ListItem>
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
