import { useEffect, useState, useMemo } from 'react';
import { team as teamService, org as orgService } from 'src/backend';
import _find from 'lodash/find';

/**
 * Fetch my orgs first , if not present then fetch nearby orgs
 */
const useOrgsForFoodRunCreation = ({
  lat,
  lng,
  fetchOnMount = true,
  existingDonorTeamId = null,
  existingRecipientTeamId = null,
}) => {
  const [recipients, setRecipients] = useState([]);
  const [donors, setDonors] = useState([]);
  const [existingDonorTeam, setExistingDonorTeam] = useState({});
  const [existingRecipientTeam, setExistingRecipientTeam] = useState({});
  const [isMyRecipientsEmpty, setMyRecipientsEmpty] = useState(false);

  const fetchData = () => {
    setMyRecipientsEmpty(false);
    teamService.getMyOrgs('RECIPIENT').then((myOrgs) => {
      if (myOrgs.length) {
        setRecipients(myOrgs);
      } else {
        setMyRecipientsEmpty(true);
      }
    });
    teamService.getMyOrgs('DONOR').then((myOrgs) => {
      if (myOrgs.length) {
        setDonors(myOrgs);
      } else {
        fetchNearByOrgs({ isDonor: true });
      }
    });
  };

  const fetchNearByOrgs = ({ isDonor = true, donorLat, donorLng }) => {
    if (isDonor) {
      orgService.getNearByDonors({ lat, lng }).then(setDonors);
    } else {
      orgService
        .getNearByNPOs({
          lat: isFinite(donorLat) ? donorLat : lat,
          lng: isFinite(donorLng) ? donorLng : lng,
          includeSelf: true,
        })
        .then(setRecipients);
    }
  };

  useEffect(() => {
    if (fetchOnMount) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMount]);

  useEffect(() => {
    if (existingDonorTeamId) {
      orgService
        .getOrgById({ id: existingDonorTeamId })
        .then(setExistingDonorTeam);
    }

    if (existingRecipientTeamId) {
      orgService
        .getOrgById({ id: existingRecipientTeamId })
        .then(setExistingRecipientTeam);
    }
  }, [existingDonorTeamId, existingRecipientTeamId]);

  const fetchNearByRecipients = ({ donorLat, donorLng }) => {
    fetchNearByOrgs({ isDonor: false, donorLat, donorLng });
  };

  const totalDonors = useMemo(() => {
    let isDonorFound = _find(
      donors,
      (team) => team.team_id === existingDonorTeam?.team_id
    );
    return isDonorFound ? donors : [...donors, existingDonorTeam];
  }, [donors, existingDonorTeam]);

  const totalRecipients = useMemo(() => {
    let isRecipientFound = _find(
      recipients,
      (team) => team.team_id === existingRecipientTeam?.team_id
    );
    return isRecipientFound
      ? recipients
      : [...recipients, existingRecipientTeam];
  }, [recipients, existingRecipientTeam]);

  return {
    donors: totalDonors,
    recipients: totalRecipients,
    invalidateMyOrgs: fetchData,
    isMyRecipientsEmpty,
    fetchNearByRecipients,
  };
};

export default useOrgsForFoodRunCreation;
