import { Box, Stack, Drawer, IconButton } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useResponsive from 'src/hooks/useResponsive';
import useChatClient from 'src/hooks/useChatClient';
import Icon from '@iconify/react';
import backFill from '@iconify/icons-eva/arrow-ios-back-fill';
import forwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import Scrollbar from 'src/components/Scrollbar';
import ChatNavList from './ChatNavList';
import ChatNavSearch from './ChatNavSearch';
import ChatNavAccount from './ChatNavAccount';
import ChatNavSearchResults from './ChatNavSearchResults';
import { matchSorter } from 'match-sorter';

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;
const NAV_COLLAPSE_WIDTH = 96;

export default function ChatNav() {
  const {
    setActiveConversationId,
    conversations,
    drawerOpen,
    setDrawerOpen,
    setChatOpen,
  } = useChatClient();

  const theme = useTheme();

  const [searchResults, setSearchResults] = useState([]);
  const [searchConversations, setSearchConversations] = useState('');

  const isDesktop = useResponsive('up', 'md');

  const handleToggleNav = () => {
    setDrawerOpen(!drawerOpen);
  };

  const conversationsIndex = useMemo(
    () =>
      Object.values(conversations).map((conversation) => ({
        sid: conversation.sid,
        friendlyName: conversation.friendlyName,
      })),
    [conversations]
  );

  const handleChangeSearch = async (event) => {
    const results = matchSorter(conversationsIndex, event.target.value, {
      keys: ['friendlyName'],
    });
    setSearchConversations(event.target.value);
    setSearchResults(results.map(({ sid }) => conversations[sid]));
  };

  const handleSelectConversation = (result) => {
    setSearchConversations('');
    setActiveConversationId(result.sid);
  };

  const renderContent = (
    <>
      <Box sx={{ p: 2.5 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          {drawerOpen && (
            <>
              <ChatNavAccount />
              <Box sx={{ flexGrow: 1 }} />
            </>
          )}

          <IconButton onClick={handleToggleNav}>
            <Icon icon={drawerOpen ? backFill : forwardFill} />
          </IconButton>
        </Stack>

        {drawerOpen && (
          <ChatNavSearch
            value={searchConversations}
            onChange={handleChangeSearch}
            onClickAway={() => setSearchConversations('')}
          />
        )}
      </Box>

      <Scrollbar>
        {!searchConversations ? (
          <ChatNavList
            openNav={drawerOpen}
            onCloseNav={() => {
              setDrawerOpen(false);
              setChatOpen(true);
            }}
          />
        ) : (
          <ChatNavSearchResults
            searchConversations={searchConversations}
            searchResults={searchResults}
            onSelectConversation={handleSelectConversation}
          />
        )}
      </Scrollbar>
    </>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer
          open={drawerOpen}
          variant="persistent"
          PaperProps={{
            sx: {
              pb: 1,
              width: 1,
              position: 'static',
              ...(!drawerOpen && {
                transform: 'none !important',
                visibility: 'visible !important',
              }),
            },
          }}
          sx={{
            width: drawerOpen ? NAV_WIDTH : NAV_COLLAPSE_WIDTH,
            transition: theme.transitions.create('width'),
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            sx: {
              pb: 1,
              width: NAV_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
