import TableView from 'src/components/dashboard/tables/TableView';
import { MBadge } from 'src/components/@material-extend';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { parseUTC } from 'src/utils/timezones';
import { team } from 'src/backend';
import { fPercent } from 'src/utils/formatNumber';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useTranslation } from 'react-i18next';
import useOpenRowDataGrid from 'src/hooks/useOpenRowDataGrid';

const OnboardingRequestsContext = createContext();

export function OnboardingRequestsProvider({ children }) {
  const [cache, setCache] = useState(null);
  const [valid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (valid) {
      return;
    }

    setLoading(true);
    team
      .getAllOnboardingRequests()
      .then(setCache)
      .catch((err) => {
        console.error(err);
        enqueueSnackbar('There was an error getting your onboarding requests', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setValid(true);
      });
  }, [valid]);

  const invalidate = useCallback(() => {
    setValid(false);
  }, [setValid]);

  return (
    <OnboardingRequestsContext.Provider
      value={{ items: cache ?? [], isLoading, invalidate }}
    >
      {children}
    </OnboardingRequestsContext.Provider>
  );
}

export function useOnboardingRequests() {
  const ctx = useContext(OnboardingRequestsContext);

  if (!ctx) {
    throw new Error(
      'Could not use an OnboardingRequestsContext. Did you forget an OnboardingRequestsProvider'
    );
  }

  return ctx;
}

export function OnboardingRequestsBadge({ children }) {
  const { items } = useOnboardingRequests();

  const count = useMemo(() => {
    const incomplete = items.filter(
      ({ needed_items, completed_items }) => completed_items < needed_items
    );
    return incomplete.length;
  }, [items]);

  return (
    <MBadge badgeContent={count} color="info">
      {children}
    </MBadge>
  );
}

const useRequestPartnershipsColumns = () => {
  const { t, i18n } = useTranslation('translation');
  const { user } = useAuth();
  const makeOpenRowButton = useOpenRowDataGrid();

  return useMemo(
    () => [
      makeOpenRowButton((params) =>
        PATH_DASHBOARD.corporateOnboarding.request(
          params.row.compliance_profile_id,
          params.row.corporate_team_id
        )
      ),
      {
        field: 'name',
        headerName: t('partnerships.corporation-name'),
        flex: 1,
      },
      {
        field: 'compliance_profile_title',
        headerName: t('partnerships.compilance-profile'),
        flex: 1,
      },
      {
        field: 'no_businesses',
        headerName: t('partnerships.businesses'),
        type: 'number',
        flex: 1,
      },
      {
        field: 'progress',
        type: 'number',
        headerName: t('partnerships.onboarding-progress'),
        width: 150,
        valueGetter: (params) =>
          (params.row.completed_items / params.row.needed_items) * 100,
        valueFormatter: (params) => fPercent(params.value),
      },
      {
        field: 'created',
        type: 'date',
        headerName: t('partnerships.created'),
        width: 150,
        valueGetter: (params) => parseUTC(params.row.created, user, 'YYYY/M/D'),
      },
    ],
    [i18n.language]
  );
};

export default function Requests() {
  const { items, isLoading } = useOnboardingRequests();
  const COLUMNS = useRequestPartnershipsColumns();

  return (
    <TableView
      id="onboarding-requests-for-npo"
      getRowId={(row) => row.corporate_team_id + row.compliance_profile_id}
      rows={items}
      columns={COLUMNS}
      isLoading={isLoading}
      initialState={{
        pinnedColumns: {
          left: ['__view__'],
        },
      }}
    />
  );
}
