import { io } from 'socket.io-client';
import { SOCKET_ORIGIN } from 'src/backend/common';

let cachedIO;

const SocketIO = {
  init: function () {
    cachedIO = io(SOCKET_ORIGIN, {
      transports: ['websocket', 'polling'], // use WebSocket first, if available
    });
  },
  getIO: function () {
    if (!cachedIO) {
      throw new Error("Can't get io instance before calling .init()");
    }
    return cachedIO;
  },
};

export default SocketIO;
