import PropTypes from 'prop-types';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import { Icon } from '@iconify/react';
// material
import { styled } from '@mui/material/styles';
import { Button, Typography, Box } from '@mui/material';
//
import Label from '../Label';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

PricingPlanCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  hidePrice: PropTypes.bool,
};

export default function PricingPlanCard({
  card,
  index,
  selected,
  onClick,
  hidePrice,
}) {
  const { subscription, icon, price, caption, lists, labelAction } = card;

  return (
    <RootStyle>
      {index === 1 && (
        <Label
          color="info"
          sx={{
            top: 16,
            right: 16,
            position: 'absolute',
          }}
        >
          POPULAR
        </Label>
      )}

      <Typography
        variant={hidePrice ? 'h3' : 'overline'}
        sx={{ ...(hidePrice ? {} : { color: 'text.secondary' }) }}
      >
        {subscription}
      </Typography>

      {!hidePrice &&
        (price === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <Typography variant="h2" sx={{ mx: 1 }}>
              Free
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
              $
            </Typography>
            <Typography variant="h2" sx={{ mx: 1 }}>
              {price}
            </Typography>
            <Typography
              gutterBottom
              component="span"
              variant="subtitle2"
              sx={{
                alignSelf: 'flex-end',
                color: 'text.secondary',
              }}
            >
              /mo
            </Typography>
          </Box>
        ))}

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
        }}
      >
        {caption}
      </Typography>

      <Box
        component="img"
        alt={subscription}
        src={icon}
        sx={{ width: 80, height: 80, mt: 3 }}
      />

      <Box component="ul" sx={{ my: 5, width: '100%' }}>
        {lists.map((item) => (
          <Box
            key={item.text}
            component="li"
            sx={{
              display: 'flex',
              typography: 'body2',
              alignItems: 'center',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
              '&:not(:last-of-type)': { mb: 2 },
            }}
          >
            <Box
              component={Icon}
              icon={checkmarkFill}
              sx={{ minWidth: 20, height: 20, mr: 1.5 }}
            />
            {item.text}
          </Box>
        ))}
      </Box>

      {onClick && (
        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={() => onClick(card)}
          disabled={selected || card.comingSoon}
          startIcon={selected && <Icon icon={checkmarkFill} />}
        >
          {card.comingSoon
            ? 'coming soon'
            : selected
            ? 'current plan'
            : labelAction}
        </Button>
      )}
    </RootStyle>
  );
}
