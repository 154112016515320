import { fetchAPI, METHODS, parseBody } from './common';
import qs from 'qs';

/**
 * @typedef Organisations
 */

/**
 * Gets teams by province.
 * @returns Promise<Teams[]>
 */
export async function getOrgsByProvince({ isNonProfit, province }) {
  const res = await fetchAPI(
    `/team/${isNonProfit ? 'npo' : 'business'}/province/${province}`,
    METHODS.get
  );
  const business = await parseBody(res);
  return business;
}

/**
 * Gets teams by country.
 * @returns Promise<Teams[]>
 */
export async function getOrgsByCountry({ isNonProfit, country, allOrgs }) {
  const res = await fetchAPI(
    `/team/${
      allOrgs ? 'org' : isNonProfit ? 'npo' : 'business'
    }/country/${country}`,
    METHODS.get
  );
  const business = await parseBody(res);
  return business;
}

/**
 * @typedef Organisation
 */

/**
 * Gets team by id.
 * @returns Promise<Team>
 */
export async function getOrgById({ id }) {
  const res = await fetchAPI(`/team/org/${id}`, METHODS.get);
  const business = await parseBody(res);
  return business;
}

export async function searchExistingOrgs({ placeId, ...rest }) {
  const res = await fetchAPI(
    `/team/org/place/${placeId}?${qs.stringify(rest)}`,
    METHODS.get
  );
  const business = await parseBody(res);
  return business;
}

export async function postOrg(data) {
  const res = await fetchAPI(`/team/org-by-npo`, METHODS.post, data);
  const business = await parseBody(res);
  return business;
}

export async function updateBusiness(data) {
  const res = await fetchAPI(`/team/business-by-npo`, METHODS.put, data);
  const business = await parseBody(res);
  return business;
}

export async function getBusinessTypes(data) {
  const res = await fetchAPI(`/team/business-type`, METHODS.get, data);
  const businessTypes = await parseBody(res);
  return businessTypes;
}

export async function getNPOTypes() {
  const res = await fetchAPI(`/team/npo-type`, METHODS.get);
  const NPOTypes = await parseBody(res);
  return NPOTypes;
}

export async function getNearByNPOs(data) {
  const res = await fetchAPI(
    `/team/nearby-npos?lat=${data.lat}&lng=${data.lng}&includeSelf=${data.includeSelf}`,
    METHODS.get
  );
  const NPOTypes = await parseBody(res);
  return NPOTypes;
}

export async function getNearByDonors(data) {
  const res = await fetchAPI(
    `/team/nearby-donors?lat=${data.lat}&lng=${data.lng}`,
    METHODS.get
  );
  const donors = await parseBody(res);
  return donors;
}

/**
 * @typedef Agreement
 * @property {string} id
 * @property {string} url File "Sub-URL", needs to be appended to the S3 root URL.
 * @property {string} donor_id
 * @property {string} donor_name
 * @property {string} receiver_id
 * @property {string} receiver_name
 */

/**
 * Get all agreements pertaining to the current organisation.
 * NPO/Businesses only.
 * @returns Promise<Agreement[]>
 */
export async function getAllAgreements() {
  const res = await fetchAPI('/agreement', METHODS.get);
  const { agreements } = await parseBody(res);
  return agreements;
}
