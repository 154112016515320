/* eslint-disable no-duplicate-case */

// Permissions System
//
// With the large amount of user types and account plans, we created this
// permissions system to manage access to resources and/or actions.
//
// There are three many concepts in this file:
//  1. Policies
//  2. Discriminators
//  3. Checkers
//
// Policies are a description of the kinds of users which may access a resource
// or perform an action. They have three components: plan, user and type.
// 1. "plan" refers to the plan the user must have in order to be allowed
//    access.
// 2. "user" refers to the type/role of user which the user must have to be
//    allowed access.
// 3. "type" refers to the type of organization the user must be a part of
//    which is allowed access.
//
// Discriminators are components derived from the user structure which allows
// us to check a user against a policy.
//
// Checkers take a user object and a given action or resource describer, find
// the appropriate policy for that action or resource and then see if the user
// passes the policy. Some checkers in this file are:
//  - checkAction
//  - checkRoute
//
// Checkers are to be the main interface used by other modules across the
// project. The other concepts are to be used exclusively by this module.

import { PATH_DASHBOARD, PATH_TRAINING } from 'src/routes/paths';
import { PLANS } from 'src/views/Pricing';
import * as config from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { useMemo } from 'react';

export const PLAN_ID_SELECTOR = config.isDevelopment ? 'test' : 'live';

const NONE = [];

// plan
const FOODMOVER = ['foodMover'];
const BASIC = ['basic', ...FOODMOVER];
const FREE = ['free', ...BASIC];

// user
const SUPER_ADMIN = 'super-admin';
const ADMIN = 'admin';
const STAFF = 'staff';
const DRIVER = 'driver';

// type
const NPO = 'npo';
const BUSINESS = 'business';
const EXECUTIVE = 'executive';
const CORPORATE = 'corporate';
const ALL = [NPO, BUSINESS, CORPORATE];

const policyForPath = (path) => {
  // shorthand helper
  const p = (p) => path.startsWith(p);

  switch (true) {
    // super admin only
    case p(PATH_DASHBOARD.admin.executiveUsers):
    case p(PATH_DASHBOARD.admin.viewExecutiveUsers('')):
    case p(PATH_DASHBOARD.admin.corporateAccounts):
    case p(PATH_DASHBOARD.admin.viewCorporateAccount('')):
    case p(PATH_DASHBOARD.admin.users):
    case p(PATH_DASHBOARD.admin.viewUser('')):
    case p(PATH_DASHBOARD.admin.orgs):
    case p(PATH_DASHBOARD.admin.viewOrg('')):
    case p(PATH_DASHBOARD.admin.map):
    case p(PATH_DASHBOARD.admin.mergeOrgs):
    case p(PATH_DASHBOARD.admin.createOrg):
    case p(PATH_DASHBOARD.admin.subscriptions):
      return { plan: FREE, user: [SUPER_ADMIN], type: [BUSINESS] };

    case p(PATH_DASHBOARD.trainingEditor):
    case p(PATH_DASHBOARD.findDonations):
      return { plan: FREE, user: [STAFF, ADMIN, DRIVER], type: [NPO] };

    // the donation and food run details pages handle permissions on their own
    case p(PATH_DASHBOARD.viewDonation('')):
    case p(PATH_DASHBOARD.viewFoodRun('')):
    case p(PATH_DASHBOARD.viewOrg('')):
      return {
        plan: FREE,
        user: [STAFF, ADMIN, DRIVER, SUPER_ADMIN],
        type: [...ALL, CORPORATE],
      };

    case p(PATH_DASHBOARD.chatShortcut('')):
    case p(PATH_DASHBOARD.schedule.root):
      return {
        plan: FREE,
        user: [STAFF, ADMIN, DRIVER],
        type: [NPO, BUSINESS],
      };

    // donations - basic npo/business staff and admins, OR super admins
    case p(PATH_DASHBOARD.drivers):
      return {
        plan: FREE,
        user: [ADMIN],
        type: [NPO, BUSINESS],
      };
    case p(PATH_DASHBOARD.donations):
      return {
        plan: FREE,
        user: [DRIVER, STAFF, ADMIN, SUPER_ADMIN],
        type: [NPO, BUSINESS],
      };

    // basic npo - all users
    case p(PATH_DASHBOARD.myrescues):
      return {
        plan: FREE,
        user: [DRIVER, STAFF, ADMIN, SUPER_ADMIN],
        type: [NPO],
      };

    // rescues - basic npo staff and admins, OR super admins
    case p(PATH_DASHBOARD.rescues):
      return {
        plan: FREE,
        user: [DRIVER, STAFF, ADMIN, SUPER_ADMIN],
        type: [NPO],
      };

    // basic npo
    case p(PATH_DASHBOARD.rescues):
    case p(PATH_DASHBOARD.business):
    case p(PATH_DASHBOARD.editOrg('')):
    case p(PATH_DASHBOARD.incomingRequests):
      return { plan: FREE, user: [STAFF, ADMIN], type: [NPO] };
    // basic npo or business - staff or admin
    case p(PATH_DASHBOARD.donations):
    case p(PATH_DASHBOARD.mydonations):
      return { plan: FREE, user: [STAFF, ADMIN], type: [NPO, BUSINESS] };

    // foodmover npo
    case p(PATH_DASHBOARD.foodRun.myRuns):
    case p(PATH_DASHBOARD.foodRun.availableRuns):
    case p(PATH_DASHBOARD.foodRun.inProgress):
    case p(PATH_DASHBOARD.foodRun.runHistory):
      return { plan: FOODMOVER, user: [DRIVER, STAFF, ADMIN], type: [NPO] };
    // foodmover npo - staff and admin only
    case p(PATH_DASHBOARD.npo):
      return { plan: FOODMOVER, user: [STAFF, ADMIN, DRIVER], type: [NPO] };
    case p(PATH_DASHBOARD.foodRun.allRuns):
      return {
        plan: FOODMOVER,
        user: [STAFF, ADMIN, DRIVER, SUPER_ADMIN],
        type: [NPO],
      };
    case p(PATH_DASHBOARD.donationsReport):
      return {
        plan: FOODMOVER,
        user: [ADMIN],
        type: [NPO],
      };
    case p(PATH_DASHBOARD.foodRun.toBeReassigned):
      return {
        plan: FOODMOVER,
        user: [STAFF, ADMIN],
        type: [NPO],
      };
    // foodmover npo - admin only
    case p(PATH_DASHBOARD.schedule.donations):
    case p(PATH_DASHBOARD.customizeEmail):
      return { plan: FOODMOVER, user: [ADMIN], type: [NPO] };

    // admin, staff of busines and npo
    case p(PATH_DASHBOARD.settings.documents):
      return { plan: FREE, user: [STAFF, ADMIN], type: [NPO, BUSINESS] };

    // all users - admin
    case p(PATH_DASHBOARD.settings.business('')):
    case p(PATH_DASHBOARD.payment):
    case p(PATH_DASHBOARD.allPlans):
      return { plan: FREE, user: [ADMIN], type: ALL };
    // all users - admin incl executive
    case p(PATH_DASHBOARD.teams):
      return { plan: FREE, user: [ADMIN], type: [...ALL, EXECUTIVE] };

    case p(PATH_DASHBOARD.partnerships):
    case p(PATH_DASHBOARD.agreements):
    case p(PATH_DASHBOARD.agreement('')):
      return { plan: FREE, user: [STAFF, ADMIN], type: [NPO, BUSINESS] };

    // executive users
    case p(PATH_DASHBOARD.executive.home):
    case p(PATH_DASHBOARD.executive.orgs):
    case p(PATH_DASHBOARD.executive.viewOrg('')):
    case p(PATH_DASHBOARD.executive.donations.all):
    case p(PATH_DASHBOARD.executive.donations.incoming):
    case p(PATH_DASHBOARD.executive.donations.internal):
    case p(PATH_DASHBOARD.executive.donations.outgoing):
    case p(PATH_DASHBOARD.executive.geozones):
    case p(PATH_DASHBOARD.executive.createOrg):
    case p(PATH_DASHBOARD.executive.inspections.all):
    case p(PATH_DASHBOARD.executive.inspections.mine):
    case p(PATH_DASHBOARD.executive.inspections.incomplete):
    case p(PATH_DASHBOARD.executive.inspections.view('')):
    case p(PATH_DASHBOARD.executive.inspections.efg):
    case p(PATH_DASHBOARD.executive.inspections.fro):
    case p(PATH_DASHBOARD.executive.inspections.reports.fro):
    case p(PATH_DASHBOARD.executive.inspections.reports.efg):
    case p(PATH_DASHBOARD.executive.complaints.all):
    case p(PATH_DASHBOARD.executive.complaints.mine):
    case p(PATH_DASHBOARD.executive.complaints.incomplete):
    case p(PATH_DASHBOARD.executive.complaints.view('')):
    case p(PATH_DASHBOARD.executive.complaints.form):
    case p(PATH_DASHBOARD.executive.complaints.report):
      return { plan: NONE, user: [ADMIN, STAFF], type: [EXECUTIVE] };

    // corporate users
    case p(PATH_DASHBOARD.corporate.home):
    case p(PATH_DASHBOARD.corporate.npos):
    case p(PATH_DASHBOARD.corporate.teams):
    case p(PATH_DASHBOARD.corporate.viewTeam('')):
    case p(PATH_DASHBOARD.corporate.donations):
    case p(PATH_DASHBOARD.corporate.schedule):
    case p(PATH_DASHBOARD.corporate.viewDonation('')):
    case p(PATH_DASHBOARD.corporate.report):
    case p(PATH_DASHBOARD.corporate.onboarding.root):
      return { plan: NONE, user: [ADMIN, STAFF], type: [CORPORATE] };
    case p(PATH_DASHBOARD.settings.tags):
      return { plan: NONE, user: [ADMIN, STAFF], type: [CORPORATE, EXECUTIVE] };

    // all users except super admins
    case path === 'https://help.careitapp.com':
      return {
        plan: FREE,
        user: [ADMIN, STAFF, DRIVER],
        type: [...ALL, EXECUTIVE, CORPORATE],
      };

    // all users incl executive
    case p(PATH_DASHBOARD.settings.account('')):
      return {
        plan: FREE,
        user: [SUPER_ADMIN, ADMIN, STAFF, DRIVER],
        type: [...ALL, EXECUTIVE],
      };

    // basic - staff and admin
    // this has to go last becase it is the root path, otherwise we match all dashboard paths
    case p(PATH_DASHBOARD.corporateOnboarding.request('')):
    case p(PATH_DASHBOARD.corporateOnboarding.complete.form('')):
    case p(PATH_DASHBOARD.corporateOnboarding.complete.agreement('')):
    case p(PATH_DASHBOARD.corporateOnboarding.complete.document('')):
    case p(PATH_DASHBOARD.corporateOnboarding.complete.upload('')):
      return {
        plan: FREE,
        user: [ADMIN],
        type: [NPO],
      };

    case p(PATH_DASHBOARD.corporateOnboarding.view.form('')):
    case p(PATH_DASHBOARD.corporateOnboarding.view.agreement('')):
    case p(PATH_DASHBOARD.corporateOnboarding.view.document('')):
    case p(PATH_DASHBOARD.corporateOnboarding.view.upload('')):
      return {
        plan: FREE,
        user: [ADMIN],
        type: [BUSINESS],
      };

    // basic - staff and admin
    // this has to go last becase it is the root path, otherwise we match all dashboard paths
    case p(PATH_DASHBOARD.root):
      return {
        plan: FREE,
        user: [STAFF, ADMIN, DRIVER],
        type: [NPO, BUSINESS, EXECUTIVE],
      };

    // any training route handles permissions on it's own
    case p(PATH_TRAINING.root):
      return {
        plan: FREE,
        user: [STAFF, ADMIN, DRIVER],
        type: [NPO, BUSINESS],
      };

    case p(PATH_DASHBOARD.cancellationReport):
      return {
        plan: FREE,
        user: [STAFF, ADMIN, DRIVER],
        type: [NPO, BUSINESS],
      };
    default:
      throw new Error('Unreachable');
  }
};

const policyForAction = (action) => {
  switch (action) {
    case 'export':
      return {
        plan: BASIC,
        user: [STAFF, ADMIN, DRIVER, SUPER_ADMIN],
        type: [NPO, BUSINESS, EXECUTIVE, CORPORATE],
      };

    case 'recurring-donation':
      return {
        plan: BASIC,
        user: [STAFF, ADMIN, DRIVER],
        type: [NPO, BUSINESS],
      };

    case 'request-drivers':
      return {
        plan: BASIC,
        user: [STAFF, ADMIN],
        type: [NPO],
        hasOrgFlag: 'is_sms_enabled',
      };

    default:
      throw new Error('Unreachable');
  }
};

const getDiscriminators = (user) => {
  const currentPlan =
    PLANS.find(
      ({ priceId }) => priceId[PLAN_ID_SELECTOR] === user.subscription_price_id
    )?.item ?? 'free';
  const planActive = user.subscription_status?.toUpperCase() === 'ACTIVE';
  const plan = planActive ? currentPlan : 'free';

  const orgType = (() => {
    if (user.is_non_profit) return NPO;
    if (user.is_executive) return EXECUTIVE;
    if (user.is_corporate) return CORPORATE;
    return BUSINESS;
  })();

  const userType = (() => {
    if (user.account_type === 'ADMIN') {
      return SUPER_ADMIN;
    } else {
      if (user.is_admin) {
        return ADMIN;
      } else if (user.is_driver) {
        return DRIVER;
      } else {
        return STAFF;
      }
    }
  })();

  return { plan, orgType, userType, user };
};

const allowedByPolicy = ({ plan, orgType, userType, user }, policy) => {
  if (userType === SUPER_ADMIN) {
    return !!policy.user.find((x) => x === userType);
  }

  if (orgType === EXECUTIVE || orgType === CORPORATE) {
    return (
      !!policy.user.find((x) => x === userType) &&
      !!policy.type.find((x) => x === orgType)
    );
  }

  if (policy.hasOrgFlag) {
    if (!user[policy.hasOrgFlag]) {
      return false;
    }
  }

  return (
    !!policy.plan.find((x) => x === plan) &&
    !!policy.user.find((x) => x === userType) &&
    !!policy.type.find((x) => x === orgType)
  );
};

export const checkAction = (user, action) => {
  const discriminators = getDiscriminators(user);
  const policy = policyForAction(action);
  return allowedByPolicy(discriminators, policy);
};

export const checkRoute = (user, path) => {
  const discriminators = getDiscriminators(user);
  const policy = policyForPath(path);
  return allowedByPolicy(discriminators, policy);
};

export const useCheckAction = (action) => {
  const { user } = useAuth();
  return useMemo(() => checkAction(user, action), [user, action]);
};
