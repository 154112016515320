import { Box, Typography, Stack, CircularProgress } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import ChatRoomCollapseButton from './ChatRoomCollapseButton';
import useAuth from 'src/hooks/useAuth';
import useDonation from 'src/hooks/useDonation';
import { fDateTimeSuffix } from 'src/utils/formatTime';
import { parseUTC } from 'src/utils/timezones';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChatRoomDetails({
  conversation,
  isCollapse,
  onCollapse,
}) {
  if (conversation.attributes.donationId) {
    return (
      <ChatRoomDetailsDonation
        donationId={conversation.attributes.donationId}
        isCollapse={isCollapse}
        onCollapse={onCollapse}
      />
    );
  }

  return null;
}

function DetailsList({ children }) {
  return <Box>{children}</Box>;
}

function DetailItem({ title, value }) {
  return (
    <Stack direction="column" alignItems="left" sx={{ mt: 2, px: 2.5 }}>
      <Typography variant="overline">{title}</Typography>
      <Typography variant="body">{value}</Typography>
    </Stack>
  );
}

function ChatRoomDetailsDonation({ donationId, isCollapse, onCollapse }) {
  const { t } = useTranslation('translation');
  const { user } = useAuth();
  const { donation, isLoading } = useDonation(donationId);

  let content;
  if (isLoading || !donation) {
    content = <CircularProgress />;
  } else {
    content = (
      <DetailsList>
        <DetailItem title={t('chat.title')} value={donation.title} />
        <DetailItem
          title={t('chat.available-from')}
          value={fDateTimeSuffix(parseUTC(donation.start_date, user))}
        />
        <DetailItem
          title={t('chat.available-until')}
          value={fDateTimeSuffix(parseUTC(donation.end_date, user))}
        />
        <DetailItem
          title={t('chat.donor')}
          value={donation.donor_business_name}
        />
        <DetailItem
          title={t('chat.recipient')}
          value={donation.recipient_business_name}
        />
      </DetailsList>
    );
  }

  return (
    <Stack
      flexGrow={1}
      sx={{
        pb: 2,
        height: 1,
        overflow: 'hidden',
        borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <ChatRoomCollapseButton
        isCollapse={isCollapse}
        onCollapse={onCollapse}
        sx={{
          ...(!isCollapse && {
            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          }),
        }}
      >
        {t('chat.donation-details')}
      </ChatRoomCollapseButton>

      <Box
        sx={{
          overflow: 'hidden',
          height: isCollapse ? 1 : 0,
          transition: (theme) =>
            theme.transitions.create('height', {
              duration: theme.transitions.duration.shorter,
            }),
        }}
      >
        <Scrollbar>{content}</Scrollbar>
      </Box>
    </Stack>
  );
}
