import {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useContext,
  createContext,
} from 'react';

const EventBusContext = createContext();

export function EventBusProvider({ children }) {
  const [handlers, setHandlers] = useState({});

  return (
    <EventBusContext.Provider value={{ handlers, setHandlers }}>
      {children}
    </EventBusContext.Provider>
  );
}

/**
 * Event bus interface hook.
 * An event bus allows different components across the component
 * tree to send events back and forth.
 *
 * # Example
 *
 * ```
 * function App() {
 *   return (
 *     <EventBusProvider>
 *       <A />
 *       <B />
 *     </EventBusProvider>
 *   );
 * }
 *
 * function A() {
 *   const emit = useEventBus("bus", "event");
 *
 *   return (
 *     <button onClick={emit}>
 *       Click Me!
 *     </button>
 *   );
 * }
 *
 * function B() {
 *   const [count, setCount] = useState(0);
 *   useEventBus("bus", "event", () => setCount(count + 1), [count]);
 *
 *   return (
 *     <p>Button clicked {count} times.</p>
 *   );
 * }
 * ```
 *
 * @param {string} bus                  ID of bus to listen on.
 * @param {string} event                Event ID to listen to.
 * @param {Function | null} handler     Event handler
 * @param {any[]} dependencies          Event handler dependencies, analogous to useCallback.
 * @param {object?} opts                Configuration options for the event bus.
 * @param {bool?} opts.crossTabChannel  Let the event bus operate across all open tabs.
 * @returns {Function} emitter          Event emmiter function
 */
export default function useEventBus(
  bus,
  event,
  handler,
  depdendencies,
  { crossTabChannel = false } = {}
) {
  const { handlers, setHandlers } = useContext(EventBusContext);

  const callback = useCallback(handler ?? (() => {}), depdendencies ?? []);

  const channel = useMemo(
    () => (crossTabChannel ? new BroadcastChannel(bus) : null),
    [bus, crossTabChannel]
  );
  useEffect(() => {
    if (!channel) {
      return;
    }

    channel.onmessage = (message) => {
      if (message.data.event !== event) {
        return;
      }

      ((handlers[bus] ?? {})[event] ?? []).forEach((handler) =>
        handler(...message.data.args)
      );
    };
  }, [channel, bus, event, handlers]);

  useEffect(() => {
    if (!handler) return;
    setHandlers((handlers) => ({
      ...handlers,
      [bus]: {
        ...(handlers[bus] ?? {}),
        [event]: [...((handlers[bus] ?? {})[event] ?? []), callback],
      },
    }));
  }, [bus, event, callback]);

  const emitter = useCallback(
    (...args) => {
      ((handlers[bus] ?? {})[event] ?? []).forEach((handler) =>
        handler(...args)
      );

      if (channel) {
        channel.postMessage({
          event,
          args,
        });
      }
    },
    [handlers, bus, event, channel]
  );

  return emitter;
}
