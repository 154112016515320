import { useEffect } from 'react';
import useForm from 'src/hooks/useForm';
import useTeamMembers from 'src/hooks/useTeamMembers';
import useAuth from 'src/hooks/useAuth';
import {
  useGetFormConfig,
  processFoodRunFormData,
  useAddFoodRunSchema,
} from 'src/views/schedule/FoodRun/helper';
import { foodRun as foodRunService } from 'src/backend';
import useEventBus from 'src/hooks/useEventBus';
import { useSnackbar } from 'notistack';
import SocketIO from 'src/backend/SocketIO';
import useOrgsForFoodRunCreation from 'src/hooks/useOrgsForFoodRunCreation';
import _find from 'lodash/find';
import { getLocaleUnit } from 'careit';
import useVehicles from 'src/hooks/useVehicles';
import { useTranslation } from 'react-i18next';

export default function CreateFoodRun() {
  const { t } = useTranslation('translation');
  const AddFoodRunSchema = useAddFoodRunSchema();
  const socket = SocketIO.getIO();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { teamMembers } = useTeamMembers();
  const { vehicles = [], invalidate: VehicleRefresh } = useVehicles();
  const emitFetchFoodRuns = useEventBus('foodrun', 'fetch', null, null, {
    crossTabChannel: true,
  });
  const {
    donors,
    recipients,
    invalidateMyOrgs,
    isMyRecipientsEmpty,
    fetchNearByRecipients,
  } = useOrgsForFoodRunCreation({
    lat: user.user_lat || user.lat,
    lng: user.user_lng || user.lng,
    fetchOnMount: false,
  });
  const { weight: preferredUnit } = getLocaleUnit(user);

  const formConfig = useGetFormConfig(
    donors,
    recipients,
    teamMembers,
    user.timezone_id,
    invalidateMyOrgs,
    preferredUnit,
    vehicles
  );
  const { handleOpen, form, state, open } = useForm({
    noClose: true,
    handleSave: async (data) => {
      const newFoodRun = processFoodRunFormData({
        ...data,
        timezone_id: user.timezone_id,
      });
      foodRunService
        .create(newFoodRun)
        .then((data) => {
          emitFetchFoodRuns();
          socket.emit('DONATION-UPDATE', {
            patternId: data.patternId,
            donationId: null,
            teamId: user.team_id,
            type: 'FOOD-RUN',
          });
        })
        .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
    },
    form: formConfig,
    title: t('donations.create-food-run'),
    schema: AddFoodRunSchema(donors, recipients),
    defaultState: {
      daysOfTheWeek: [0], // later, this initial value will be changed before we send the form
    },
  });
  useEffect(() => {
    if (open) {
      VehicleRefresh();
    }
  }, [open]);
  useEffect(() => {
    if (state.donor) {
      const selectedDonor = _find(
        donors,
        (team) => team.team_id === state.donor
      );
      if (isMyRecipientsEmpty && selectedDonor) {
        fetchNearByRecipients({
          donorLat: selectedDonor.lat,
          donorLng: selectedDonor.lng,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.donor]);

  useEffect(() => {
    if (open) {
      invalidateMyOrgs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEventBus('foodrun', 'create', () => handleOpen(), []);
  useEventBus('foodrun', 'invalidate-orgs', invalidateMyOrgs, []);

  return <>{form()}</>;
}
