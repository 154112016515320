import { fetchAPI, METHODS, parseBody, assertErrors } from './common';
import qs from 'qs';

/**
 * @typedef UserUpdate
 * @property {string} name
 * @property {string} phone
 * @property {number} lat
 * @property {number} lng
 * @property {number} pickup_dist
 */

/**
 * Updates the user entry.
 * Note: only the name & phone entry can be updated.
 * @param {UserUpdate} data
 */
export async function updateUser(data) {
  const res = await fetchAPI('/user', METHODS.put, data);
  if (res.status !== 200) throw new Error(res.statusText);
}

/**
 * @typedef Invite
 * @property {string} name
 * @property {string} team_id
 * @property {boolean} isNewUser
 */

/**
 * Fetched all outgoing invitations associated with `email`.
 * @returns Promise<Inivite[]>
 */
export async function getInvites(email) {
  const res = await fetchAPI(`/user/pending-invites/${email}`, METHODS.get);
  await assertErrors(res);
  const invites = await parseBody(res, true);
  return invites;
}

export async function updateChatSettings(data) {
  const res = await fetchAPI('/user/business-chat-info', METHODS.put, data);
  return await parseBody(res);
}

/**
 * Accepts an invite (requires the user to already be signed in)
 * @param {string} teamId
 * @param {string} email
 */
export async function acceptInvite(teamId, email) {
  const res = await fetchAPI('/user/accept-team-invite', METHODS.put, {
    teamId,
    email,
  });
  await parseBody(res);
}

/**
 * Ignores an invite (requires the user to already be signed in)
 * @param {string} teamId
 * @param {string} email
 */
export async function ignoreInvite(teamId, email) {
  const res = await fetchAPI('/user/ignore-team-invite', METHODS.put, {
    teamId,
    email,
  });
  await parseBody(res);
}

/**
 * @typedef WeekTimesObj
 * See `src/utils/weekTimes.js` for utilities to generate this object
 */

/**
 * @typedef BusinessUpdate
 * @property {string} businessName
 * @property {string} businessPhone
 * @property {string} lat
 * @property {string} lng
 * @property {string} address
 * @property {string} place
 * @property {string} ein
 * @property {string} description
 * @property {WeekTimesObj} weekTimesObj
 */

/**
 * Updates the business entry.
 * @param {BusinessUpdate} data
 */
export async function updateBusiness(data) {
  const res = await fetchAPI('/user/business', METHODS.put, data);
  if (res.status !== 200) throw new Error(res.statusText);
}

/**
 * Returns true if a user with the email `email` exists.
 * @param {string} email
 * @returns Promise<boolean>
 */
export async function checkUserExists(email) {
  const res = await fetchAPI(`/user/exists/${email}`, METHODS.get);
  const { exists } = await parseBody(res);
  return exists;
}

/**
 * Logins in a user and accepts an invite at the same time.
 * @param {string} email
 * @param {string} email
 * @param {string} teamId
 * @returns Promise<User>
 */
export async function acceptInviteAndLogin(email, password, teamId) {
  const res = await fetchAPI(
    '/user/accept-team-invite-and-login',
    METHODS.post,
    { email, password, teamId }
  );
  const { user } = await parseBody(res);
  return user;
}

/**
 * Reauthenticate under a new team.
 * @param {string} teamId
 * @param {string} refreshToken Note, this refresh token will be revoked
 * @returns Promise<User>
 */
export async function switchTeams(teamId, refreshToken) {
  const res = await fetchAPI(`/user/switch-teams/${teamId}`, METHODS.post, {
    refreshToken,
  });
  const { user } = await parseBody(res);
  return user;
}

/**
 * Get all teams the current signed in user is a part of.
 * @returns Promise<Team[]>
 */
export async function getTeams(args = {}) {
  const res = await fetchAPI(`/user/teams/?${qs.stringify(args)}`, METHODS.get);
  const { teams } = await parseBody(res);
  return teams;
}

/**
 * Manually set the status of a training program for a user.
 * @param {string} userId
 * @param {boolean} isDone
 */
export async function setTrainingStatus(userId, isDone) {
  await fetchAPI('/user/training-status', METHODS.post, { userId, isDone });
}

/**
 * Reset a user's progress on the training program
 * @param {string} userId
 */
export async function resetProgress(userId) {
  await fetchAPI('/user/reset-progress', METHODS.post, { userId });
}

/**
 * Fetch a user's notification settings for the current team.
 * @returns Promise<NotificationSettings>
 */
export async function getNotificationSettings() {
  const res = await fetchAPI('/user/notification-settings', METHODS.get);
  const { settings } = await parseBody(res);
  return settings;
}

/**
 * Update a user's notification settings for the current team.
 * @param {object} settings
 */
export async function updateNotificationSettings(settings) {
  await fetchAPI('/user/notification-settings', METHODS.post, settings);
}

/**
 * Unsubscribe from a specific type of notifications or all notifications.
 * This route does not require authorization.
 * @param {"trainingCompleted" | "rescueAvailability" | "foodRunAssigned" | "foodRunUpcoming" | "foodRunMissingWeight" | "foodRunCancelled" | "foodRunChanged" | "foodRunIncomplete" | "foodRunDriverUnassigned" | "agreements" | "rescueAssign" | "rescuesIncomplete" | "all"} type
 * @param {string} userId
 * @param {string} teamId
 * @returns Promise<void>
 */
export async function unsubscribe(type, userId, teamId) {
  const res = await fetchAPI(
    `/notification/${type}/${userId}/${teamId}`,
    METHODS.delete
  );
  if (res.status !== 200) {
    let error = new Error(res.statusText);
    try {
      const { message } = await res.json();
      error = new Error(message);
    } catch {
      // pass
    }

    throw error;
  }
}

/**
 * Start verifying the user's phone number.
 */
export async function startPhoneVerification() {
  const res = await fetchAPI('/user/verify-phone/start', METHODS.post);
  await assertErrors(res);
}

/**
 * Finish verifying the user's phone number, requires the sent code.
 * @param {string} code
 */
export async function completePhoneVerification(code) {
  const res = await fetchAPI('/user/verify-phone/complete', METHODS.post, {
    code,
  });
  await assertErrors(res);
}

/**
 * Check the status of the most recent phone verification message.
 * @returns {Promise<"UNKNOWN" | "UNREACHABLE" | "SENT">}
 */
export async function checkPhoneVerificationStatus() {
  const res = await fetchAPI('/user/verify-phone/check-status', METHODS.get);
  await assertErrors(res);
  const { status } = await parseBody(res);
  return status;
}

/**
 * Fetch user data by email
 */
export async function getByEmail(email) {
  const res = await fetchAPI(`/user/email/${email}`, METHODS.get);
  return await parseBody(res, true);
}

export async function updateUserLang(args) {
  const res = await fetchAPI('/user/update-lang', METHODS.put, args);
  await assertErrors(res);
  return await parseBody(res, true);
}
