import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createFoodCategoryGroup } from 'src/hooks/useForm';

export default function FoodTypeSelector({ fullSize = false, ...other }) {
  const { t, i18n } = useTranslation('translation');
  const FoodCategoryGroup = useMemo(
    () =>
      createFoodCategoryGroup(
        [
          {
            id: 'dairy',
            title: t('data.food-types.dairy'),
            image: '/static/icons/food-types/ic_dairy.svg',
          },
          {
            id: 'produce',
            title: t('data.food-types.produce'),
            image: '/static/icons/food-types/ic_produce.svg',
          },
          {
            id: 'meat',
            title: t('data.food-types.meat'),
            image: '/static/icons/food-types/ic_meat.svg',
          },
          {
            id: 'prepared',
            title: t('data.food-types.prepared'),
            image: '/static/icons/food-types/ic_prepared.svg',
          },
          {
            id: 'baked',
            title: t('data.food-types.baked'),
            image: '/static/icons/food-types/ic_baked.svg',
          },
          {
            id: 'beverages',
            title: t('data.food-types.beverages'),
            image: '/static/icons/food-types/ic_beverages.svg',
          },
          {
            id: 'non-perishable',
            title: t('data.food-types.non-perishable'),
            image: '/static/icons/food-types/ic_nonperishable.svg',
          },
          {
            id: 'non-food',
            title: t('data.food-types.non-food'),
            image: '/static/icons/food-types/ic_non_food.svg',
          },
          {
            id: 'frozen',
            title: t('data.food-types.frozen'),
            image: '/static/icons/food-types/ic_frozen.svg',
          },
          {
            id: 'compost',
            title: t('data.food-types.compost'),
            image: '/static/icons/food-types/ic_compost.png',
          },
          {
            id: 'animal-feed',
            title: t('data.food-types.animal-feed'),
            image: '/static/icons/food-types/ic_animal_feed.svg',
          },
        ],
        { fullSize }
      ),
    [i18n.language]
  );

  return <FoodCategoryGroup {...other} />;
}
