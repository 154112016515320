import { Box, Typography, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import { Help } from '@mui/icons-material';
import { checkRoute } from 'src/utils/permissions';
import { useEffect, useMemo } from 'react';

export default function Paywall({ children }) {
  const { user } = useAuth();
  const history = useHistory();
  const { pathname: path } = useLocation();

  const hasPerms = useMemo(() => checkRoute(user, path), [user, path]);

  useEffect(() => {
    if (user?.is_corporate && path === PATH_DASHBOARD.root) {
      history.push(PATH_DASHBOARD.corporate.home);
      return;
    }

    // Check if the current path is under any corporate-related paths
    const isCorporatePath = Object.values(PATH_DASHBOARD.corporate).some(
      (corporatePath) => path.startsWith(corporatePath)
    );

    if (isCorporatePath && !user?.is_corporate) {
      // Redirect non-corporate users trying to access any corporate path to the root URL
      history.push(PATH_DASHBOARD.root);
      return;
    }

    if (!hasPerms && path === PATH_DASHBOARD.root) {
      history.push(PATH_DASHBOARD.settings.account('general'));
    }
  }, [history, path, hasPerms]);

  if (hasPerms) return <>{children}</>;

  return !hasPerms && path === PATH_DASHBOARD.root ? (
    <></>
  ) : (
    <Box
      width="100%"
      height="calc(100vh - 120px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography>You need a Subscription.&nbsp;</Typography>
      <Link to={PATH_DASHBOARD.allPlans}>
        Upgrade to unlock this page.&nbsp;
      </Link>
      <Tooltip title="If you've already subscribed, it can take our system a minute to update. Please try reloading your page. If your still stuck please contact our admin team.">
        <Help color="disabled" />
      </Tooltip>
    </Box>
  );
}
