import { fetchAPI, METHODS, parseBody } from './common';

/**
 * Get's the user's progress on a training program.
 * Note the progress is specific to this user and the current team.
 * @returns Promise<Progress>
 */
export async function getProgress() {
  const res = await fetchAPI('/training/progress', METHODS.get);
  const { progress } = await parseBody(res);
  return progress;
}

/**
 * Update a user's progress on a team training program.
 * Note the progress is specific to this user and the current team.
 * @param {Progress} training
 */
export async function updateProgress(progress) {
  await fetchAPI('/training/progress', METHODS.post, { progress });
}

/**
 * Mark the current user as finished their training program under the current
 * team.
 */
export async function finishProgram() {
  await fetchAPI('/training/finish', METHODS.post, {});
}

/**
 * Get the training settings for the current team.
 * @returns Promise<Settings>
 */
export async function getSettings() {
  const res = await fetchAPI('/training/settings', METHODS.get);
  const { settings } = await parseBody(res);
  return settings;
}

/**
 * Get the training settings for the current team.
 * @param {Settings} settings
 */
export async function updateSettings(settings) {
  await fetchAPI('/training/settings', METHODS.post, { settings });
}
