// eslint does not know about the `process` global
/* eslint-disable no-undef */

export const XGRID_LICENSE_KEY = process.env.REACT_APP_XGRID_LICENSE_KEY;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const ENV = process.env.REACT_APP_ENV;
export const isDevelopment = ENV === 'DEVELOPMENT';
export const isStaging = ENV === 'STAGING';
export const isProduction = ENV === 'PRODUCTION';
export const BUCKET_NAME = !isProduction
  ? 'dev-assets.careitapp.com'
  : 'assets.careitapp.com';

export const S3_BASE_URL = 'https://s3-us-west-2.amazonaws.com';

export const LANG_SETTINGS = process.env.REACT_APP_LANG_SETTINGS === '1';

export const MAX_ALLOWED_INSPECTION_PHOTOS = 10;
