import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { vehicle } from 'src/backend';
import { Weight } from 'careit';

// ----------------------------------------------------------------------

/**
 * @typedef Vehicles
 * @property {Vehicles[]} vehicles
 * @property {boolean} isLoading
 */

/**
 * Encapsulates team member data state management & logic, returning that
 * logic's state.
 * Note: teamMembers is never null, but an empty array if there is no data.
 * @returns {Vehicles}
 */
export default function useVehicles({ all = false, fetchOnMount = true } = {}) {
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = useCallback(() => {
    vehicle
      .getVehicles()
      .then((res) => {
        return res
          ?.map((item) => {
            let cap = item.capacity;
            if (cap) {
              const capacity = JSON.parse(item.capacity);
              cap = {
                value: new Weight(capacity?.value || 0, 'lbs').as(
                  capacity?.unit || 'lbs'
                ),
                unit: capacity?.unit,
              };
            }
            return { ...item, capacity: cap };
          })
          .filter((itr) => (all ? true : itr.is_active));
      })
      .then(setCachedVehicles)
      .catch(() => {
        enqueueSnackbar(
          'An error occurred while trying to fetch Vehicles. Please try again later.',
          { variant: 'error' }
        );
      });
  }, [all]);

  const [cachedVehicles, setCachedVehicles] = useState();
  useEffect(() => {
    if (fetchOnMount) {
      fetchData();
    }
  }, []);

  const invalidate = () => fetchData();

  return {
    vehicles: cachedVehicles ?? [],
    isLoading: !cachedVehicles,
    invalidate,
  };
}
