import PropTypes from 'prop-types';
// @mui
import { List, ListSubheader, Typography } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import { SkeletonConversationItem } from 'src/components/skeleton';
//
import ChatNavItem from './ChatNavItem';
import useChatClient from 'src/hooks/useChatClient';
import { useMemo } from 'react';
import _ from 'lodash';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

ChatNavList.propTypes = {
  sx: PropTypes.object,
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function ChatNavList({ openNav, onCloseNav, sx, ...other }) {
  const { t } = useTranslation('translation');
  const isDesktop = useResponsive('up', 'md');

  const { user } = useAuth();
  const { conversations, activeConversation, setActiveConversationId } =
    useChatClient();

  const loading = Object.keys(conversations).length === 0;

  const [teamConversations, donationConversations, adminConversations] =
    useMemo(() => {
      let teamConversations = [];
      let donationConversations = [];
      let adminConversations = [];

      for (const conversation of Object.values(conversations)) {
        if (conversation.attributes.teamId) {
          // only include the team chats for the current team
          if (conversation.attributes.teamId === user.team_id) {
            teamConversations.push(conversation);
          }
        } else if (conversation.attributes.adminTeamId) {
          if (conversation.attributes.adminTeamId === user.team_id) {
            adminConversations.push(conversation);
          }
        } else if (conversation.attributes.donationId) {
          donationConversations.push(conversation);
        }
      }

      const lastActivity = (c) => c.lastMessage?.dateCreated ?? c.dateCreated;
      teamConversations = _.orderBy(teamConversations, lastActivity, 'desc');
      donationConversations = _.orderBy(
        donationConversations,
        lastActivity,
        'desc'
      );

      return [teamConversations, donationConversations, adminConversations];
    }, [conversations]);
  const renderConversation = (conversation) => (
    <ChatNavItem
      key={conversation.sid}
      openNav={openNav}
      conversation={conversation}
      isSelected={activeConversation?.sid === conversation.sid}
      onSelect={() => {
        if (!isDesktop) {
          onCloseNav();
        }
        conversation.setAllMessagesRead();
        setActiveConversationId(conversation.sid);
      }}
    />
  );

  return (
    <List disablePadding sx={sx} {...other}>
      {loading ? (
        new Array(12).map((_, index) => (
          <SkeletonConversationItem key={index} />
        ))
      ) : (
        <>
          {user?.admin_chat_enabled && user?.is_admin ? (
            <>
              <ListSubheader>{t('chat.admin-chat')}</ListSubheader>
              {adminConversations.map(renderConversation)}
            </>
          ) : null}
          {user?.team_chat_enabled ? (
            <>
              <ListSubheader>{t('chat.team-chats')}</ListSubheader>
              {teamConversations.map(renderConversation)}
            </>
          ) : null}

          <ListSubheader>{t('chat.donation-chats')}</ListSubheader>
          {donationConversations.map(renderConversation)}
          {donationConversations.length === 0 && (
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{ w: '100%', textAlign: 'center' }}
            >
              {t('chat.no-donation-chats-found')}
            </Typography>
          )}
        </>
      )}
    </List>
  );
}
