import { assertErrors, fetchAPI, METHODS, parseBody } from './common';
import qs from 'qs';
import { parseUTC } from 'src/utils/timezones';

/**
 * ACTIVE referes to food runs which have any status which is neither NULL nor COMPLETED
 * IN_PROGRESS refers to any one of 'PICKUP_IN_PROGRESS' | 'DELIVERY_IN_PROGRESS'.
 * NONE refers to food runs which have no status, ie. the food run is available.
 * @typedef {'ASSIGNED' | 'PICKUP_IN_PROGRESS' | 'DELIVERY_IN_PROGRESS' | 'COMPLETED' | 'ACTIVE' | 'IN_PROGRESS' | 'NONE'} FoodRunStatus
 */

/**
 * FORWARD means to sort from past to future. For example, Oct. 3rd 2022 would appear before Dec. 12th 2030.
 * BACKWARD means to sort from future to past. For example, Oct. 3rd 2022 would appear after Dec. 12th 2030.
 * @typedef {"FORWARD" | "BACKWARD"} DateOrder
 */

/**
 * @typedef FoodRunFilters
 * @property {FoodRunStatus?} status         Food run status to select. Ignored if falsey (`null`, `undefined`, `false`).
 * @property {boolean}        mine           If true, only select foodruns in which the given user is the runner.
 * @property {boolean}        distance       If true, only select donations that are less than or equal to the `pickup_dist`
 *                                           user setting from the user's location.
 * @property {number}         step           For pagination, this is the page size. Must be >= 1.
 * @property {number}         offset         For pagination, this is the page index. Must be >= 0.
 * @property {FilterModel}    filterModel    A mui DataGrid filter model.
 * @property {string}         timezoneId     The current user's timezone id, used to parsing dates from UTC.
 * @property {boolean}        removePastRuns If true, only select foodruns that has start_date in today or in future.
 * @property {boolean}        related        If true, only select foodruns that has same parentId as the selected parentId.
 * @property {string}         parentId       This is null for other filter types except related. This fetches runs that share the same parent_id
 * @property {DateOrder?}     dateOrder      Ordering of food runs by date. Defaults to "FORWARD" if null or undefined.
 * @property {boolean?}       allowAvailable Modifier for the `mine` flag which allows available food runs as well. Defaults to false.
 */

/**
 * @type FoodRunResult
 * @property {FoodRun[]} results All food runs fitting the given filter and in the requested page.
 * @property {number}    count   Count of all food runs, regardless of pagination, which fit the filter.
 */

/**
 * Get all food runs acessible by the current user with pagination and server-side filtering.
 * @param {FoodRunFilters} filters
 * @returns Promise<FoodRunResult>
 */
export async function get({
  status,
  mine,
  distance,
  step,
  offset,
  filterModel,
  removePastRuns,
  related,
  parentId,
  dateOrder,
  allowAvailable = false,
}) {
  const params = qs.stringify({
    status,
    mine,
    distance,
    step,
    offset,
    removePastRuns,
    related,
    parentId,
    dateOrder,
    allowAvailable,
  });
  const res = await fetchAPI(`/food-run/get?${params}`, METHODS.post, {
    filterModel,
  });

  const parseDates = (foodRun) => {
    const timezoneId = JSON.parse(foodRun.donor_location).timezone_id;

    return {
      ...foodRun,
      start_date: parseUTC(foodRun.start_date, { timezone_id: timezoneId }),
      end_date: parseUTC(foodRun.end_date, { timezone_id: timezoneId }),
    };
  };

  const { results, count } = await parseBody(res);
  return { results: results.map(parseDates), count };
}

/**
 * @typedef NewDonation
 * @property {number} vehicleSize 0, 1, 2 => "CAR", "VAN", "TRUCK"
 * @property {number} deliveryOption 0, 1, 2 => "SELF-DELIVER", "RECEIVER-PICKUP", "PAID-PREMIUM-PICKUP"
 * @property {string[]} selectedFoodTypes ["PREPARED" | "PRODUCE" | "NON-PERISHABLE"]
 * @property {Date} startTime
 * @property {Date} endTime
 * @property {string} title
 * @property {string} description
 * @property {number} location.lat
 * @property {number} location.lng
 * @property {string} location.formattedAddress
 * @property {string} location.suiteNumber
 * @property {string} location.city
 * @property {string} location.province
 * @property {string} location.country
 * @property {string} location.zipCode
 * @property {string} location.placeId
 * @property {string} location.place
 * @property {string} location.timeZoneId
 * @property {string[]} images
 */

/**
 * Update food run.
 * @param {FoodRun}
 */
export async function update(data) {
  const res = await fetchAPI('/food-run', METHODS.put, data);
  return parseBody(res);
}

/**
 * Update food run single instance.
 * @param {FoodRun}
 */
export async function updateSingleInstance(data) {
  const res = await fetchAPI('/food-run/single-instance', METHODS.put, data);
  return parseBody(res);
}

/**
 * Get Food run single instance by id
 * @param {FoodRun}
 */
export async function getFoodRunSingleInstanceById(data) {
  const res = await fetchAPI(`/donation/food-run/${data.id}`, METHODS.get);
  return parseBody(res);
}

/**
 * Complete food run.
 * @param {string} rescueId
 * @param {object} foodWeights
 * @param {string} donorTeam
 * @param {string} receiverTeam
 * @param {number} donorRating
 * @param {string} donorRatingNotes
 * @param {number} receiverRating
 * @param {string} receiverRatingNotes
 * @param {boolean} noDonation
 */
export async function completeFoodRun(data) {
  const res = await fetchAPI('/food-run/complete', METHODS.put, data);
  return parseBody(res);
}

/**
 * Update food run completion data (food weights and completion images).
 * @param {string} data.rescueId
 * @param {object} data.foodWeights
 * @param {string[]} data.images
 */
export async function updateCompletionData(data) {
  const res = await fetchAPI('/food-run/completion-data', METHODS.put, data);
  return parseBody(res);
}

/**
 * Creates a new food run.
 * @param {FoodRun} donation
 */
export async function create(data) {
  const res = await fetchAPI('/food-run', METHODS.post, data);
  return parseBody(res);
}

/**
 * Creates a new food run.
 * @param {object} params
 * @param {string} params.id Id (donation id) of the relevant food run
 * @param {number} params.distance Max distance to pickup location to notify drivers.
 */
export async function requestDriversSMS({ distance, id }) {
  const res = await fetchAPI(`/food-run/request-drivers/${id}`, METHODS.post, {
    distance,
  });
  await assertErrors(res);
  return parseBody(res);
}
