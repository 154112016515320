import { PATH_DASHBOARD } from '../../routes/paths';
import { Assignment, Group, School } from '@mui/icons-material';
import { People } from '@mui/icons-material';
import { BusinessCenter, FileCopy } from '@mui/icons-material';
import {
  Public,
  RunningWithErrors,
  LocalShipping,
  Inventory,
  Report,
  FactCheck,
  Search,
  EventBusyRounded,
  Equalizer,
} from '@mui/icons-material';
import SvgIconStyle from '../../components/SvgIconStyle';
import Icon from '@iconify/react';
import sharpFoodBank from '@iconify/icons-ic/sharp-food-bank';
import truckIcon from '@iconify/icons-ant-design/car-filled';
import { IncomingRequestsBadge } from 'src/views/Donations/IncomingRequests';
import { OnboardingRequestsBadge } from 'src/views/Partnerships/Requests';
import { useTranslation } from 'react-i18next';

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  map: getIcon('ic_map'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  copy: getIcon('ic_copy'),
  error: getIcon('ic_error'),
  charts: getIcon('ic_charts'),
  editor: getIcon('ic_editor'),
  upload: getIcon('ic_upload'),
  company: getIcon('ic_company'),
  animate: getIcon('ic_animate'),
  calendar: getIcon('ic_calendar'),
  elements: getIcon('ic_elements'),
  carousel: getIcon('ic_carousel'),
  language: getIcon('ic_language'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  authenticator: getIcon('ic_authenticator'),
  reports: getIcon('ic_assessment'),
  vehicle: <Icon icon={truckIcon} width={24} height={24} />,
  foodBank: <Icon icon={sharpFoodBank} width={24} height={24} />,
  search: <Search />,
  assignment: <Assignment />,
  groups: <Group />,
  cancellationReport: <EventBusyRounded />,
  equalizer: <Equalizer />,
};

const useSidebarConfig = () => {
  const { t } = useTranslation('translation');

  return [
    // MY ORGANIZATION
    {
      subheader: '',
      items: (user) => {
        switch (user.account_type) {
          case 'TEAM-STAFF':
            if (user.is_driver) {
              return [
                {
                  title: t('sidebar.overview'),
                  href: PATH_DASHBOARD.root,
                  icon: ICONS.dashboard,
                },
                {
                  title: t('sidebar.my-impact'),
                  href: PATH_DASHBOARD.impact,
                  icon: <Public />,
                },
                {
                  title: t('sidebar.calendar'),
                  href: PATH_DASHBOARD.schedule.root,
                  icon: ICONS.calendar,
                },
                ...(user.is_non_profit && user.driver_can_view_open_donations
                  ? [
                      {
                        title: t('sidebar.find-donations'),
                        href: PATH_DASHBOARD.findDonations,
                        icon: ICONS.search,
                      },
                    ]
                  : []),
                ...(!user.is_non_profit
                  ? [
                      {
                        title: t('sidebar.donations.title'),
                        href: PATH_DASHBOARD.donations,
                        icon: <Inventory />,
                      },
                    ]
                  : []),
                {
                  title: t('sidebar.rescues.title'),
                  icon: <LocalShipping />,
                  badge: IncomingRequestsBadge,
                  href: PATH_DASHBOARD.rescues,
                  items: [
                    {
                      title: t('sidebar.rescues.my-rescues'),
                      href: PATH_DASHBOARD.myrescues,
                    },
                  ],
                },
                {
                  title: t('sidebar.food-runs.title'),
                  icon: ICONS.animate,
                  href: PATH_DASHBOARD.foodRun.myRuns,
                  items: [
                    {
                      title: t('sidebar.food-runs.my-runs'),
                      href: PATH_DASHBOARD.foodRun.myRuns,
                    },
                    {
                      title: t('sidebar.food-runs.available'),
                      href: PATH_DASHBOARD.foodRun.availableRuns,
                    },
                    {
                      title: t('sidebar.food-runs.in-progress'),
                      href: PATH_DASHBOARD.foodRun.inProgress,
                    },
                    {
                      title: t('sidebar.food-runs.history'),
                      href: PATH_DASHBOARD.foodRun.runHistory,
                    },
                  ],
                },
              ];
            }
            if (user.is_executive) {
              return [
                {
                  title: 'Home',
                  href: PATH_DASHBOARD.executive.home,
                  icon: ICONS.dashboard,
                },
                {
                  title: 'Donations',
                  href: PATH_DASHBOARD.executive.donations.all,
                  icon: ICONS.cart,
                  items: [
                    {
                      title: 'All Donations',
                      href: PATH_DASHBOARD.executive.donations.all,
                    },
                    {
                      title: 'Internal Donations',
                      href: PATH_DASHBOARD.executive.donations.internal,
                    },
                    {
                      title: 'Incoming Donations',
                      href: PATH_DASHBOARD.executive.donations.incoming,
                    },
                    {
                      title: 'Outgoing Donations',
                      href: PATH_DASHBOARD.executive.donations.outgoing,
                    },
                  ],
                },
                {
                  title: 'Organizations',
                  href: PATH_DASHBOARD.executive.orgs,
                  icon: ICONS.company,
                },
                {
                  title: 'Create Organization',
                  href: PATH_DASHBOARD.executive.createOrg,
                  icon: ICONS.copy,
                },
                {
                  title: 'Inspections',
                  href: PATH_DASHBOARD.executive.inspections.all,
                  icon: <FactCheck />,
                  items: [
                    {
                      title: 'All Inspections',
                      href: PATH_DASHBOARD.executive.inspections.all,
                    },
                    {
                      title: 'My Inspections',
                      href: PATH_DASHBOARD.executive.inspections.mine,
                    },
                    {
                      title: 'Incomplete Inspections',
                      href: PATH_DASHBOARD.executive.inspections.incomplete,
                    },
                  ],
                },
                {
                  title: 'Complaints',
                  href: PATH_DASHBOARD.executive.complaints.all,
                  icon: <Report />,
                  items: [
                    {
                      title: 'All Complaints',
                      href: PATH_DASHBOARD.executive.complaints.all,
                    },
                    {
                      title: 'My Complaints',
                      href: PATH_DASHBOARD.executive.complaints.mine,
                    },
                    {
                      title: 'Incomplete Complaints',
                      href: PATH_DASHBOARD.executive.complaints.incomplete,
                    },
                  ],
                },
                {
                  title: 'Reports',
                  href: PATH_DASHBOARD.executive.inspections.reports._,
                  icon: ICONS.analytics,
                  items: [
                    {
                      title: 'FRO Inspections Report',
                      href: PATH_DASHBOARD.executive.inspections.reports.fro,
                    },
                    {
                      title: 'EFG Inspections Report',
                      href: PATH_DASHBOARD.executive.inspections.reports.efg,
                    },
                    {
                      title: 'Complaints Report',
                      href: PATH_DASHBOARD.executive.complaints.report,
                    },
                  ],
                },
              ];
            }
            if (user.is_corporate) {
              return [
                {
                  title: 'Home',
                  href: PATH_DASHBOARD.corporate.home,
                  icon: ICONS.dashboard,
                },
                {
                  title: 'Donations',
                  href: PATH_DASHBOARD.corporate.donations,
                  icon: ICONS.cart,
                },
                {
                  title: 'Schedule',
                  href: PATH_DASHBOARD.corporate.schedule,
                  icon: ICONS.calendar,
                },
                {
                  title: 'Teams',
                  href: PATH_DASHBOARD.corporate.teams,
                  icon: ICONS.company,
                },
                {
                  title: 'NPOs',
                  href: PATH_DASHBOARD.corporate.npos,
                  icon: ICONS.foodBank,
                },
                {
                  title: 'Report',
                  href: PATH_DASHBOARD.corporate.report,
                  icon: ICONS.analytics,
                },
                {
                  title: 'Onboarding',
                  href: PATH_DASHBOARD.corporate.onboarding.root,
                  icon: ICONS.assignment,
                  items: [
                    {
                      title: 'Compliance Profiles',
                      href: PATH_DASHBOARD.corporate.onboarding
                        .complianceProfile.root,
                    },
                    {
                      title: 'Onboarding Requests',
                      href: PATH_DASHBOARD.corporate.onboarding.requests,
                    },
                    {
                      title: 'All Onboarding Items',
                      href: PATH_DASHBOARD.corporate.onboarding.root,
                    },
                  ],
                },
              ];
            }
            if (user.is_non_profit) {
              return [
                {
                  title: t('sidebar.overview'),
                  href: PATH_DASHBOARD.root,
                  icon: ICONS.dashboard,
                },
                {
                  title: t('sidebar.my-impact'),
                  href: PATH_DASHBOARD.impact,
                  icon: <Public />,
                },
                {
                  title: t('sidebar.calendar'),
                  href: PATH_DASHBOARD.schedule.root,
                  icon: ICONS.calendar,
                },
                {
                  title: t('sidebar.find-donations'),
                  href: PATH_DASHBOARD.findDonations,
                  icon: ICONS.search,
                },
                {
                  title: t('sidebar.food-runs.title'),
                  icon: ICONS.animate,
                  href: PATH_DASHBOARD.foodRun.allRuns,
                  items: [
                    {
                      title: t('sidebar.food-runs.all-runs'),
                      href: PATH_DASHBOARD.foodRun.allRuns,
                    },
                    {
                      title: t('sidebar.food-runs.my-runs'),
                      href: PATH_DASHBOARD.foodRun.myRuns,
                    },
                    {
                      title: t('sidebar.food-runs.to-be-reassigned'),
                      href: PATH_DASHBOARD.foodRun.toBeReassigned,
                    },
                    {
                      title: t('sidebar.food-runs.available'),
                      href: PATH_DASHBOARD.foodRun.availableRuns,
                    },
                    {
                      title: t('sidebar.food-runs.in-progress'),
                      href: PATH_DASHBOARD.foodRun.inProgress,
                    },
                    {
                      title: t('sidebar.food-runs.history'),
                      href: PATH_DASHBOARD.foodRun.runHistory,
                    },
                  ],
                },
                {
                  title: t('sidebar.rescues.title'),
                  icon: <LocalShipping />,
                  badge: IncomingRequestsBadge,
                  href: PATH_DASHBOARD.rescues,
                  items: [
                    {
                      title: t('sidebar.rescues.all-rescues'),
                      href: PATH_DASHBOARD.rescues,
                    },
                    {
                      title: t('sidebar.rescues.my-rescues'),
                      href: PATH_DASHBOARD.myrescues,
                    },
                    {
                      title: t('sidebar.rescues.incoming-requests'),
                      href: PATH_DASHBOARD.incomingRequests,
                      icon: ICONS.incoming,
                      info: <IncomingRequestsBadge location="center" />,
                    },
                  ],
                },
                {
                  title: t('sidebar.donations.title'),
                  icon: ICONS.cart,
                  href: PATH_DASHBOARD.donations,
                  items: [
                    {
                      title: t('sidebar.donations.all-donations'),
                      href: PATH_DASHBOARD.donations,
                    },
                    {
                      title: t('sidebar.donations.my-donations'),
                      href: PATH_DASHBOARD.mydonations,
                    },
                  ],
                },
                {
                  title: t('sidebar.donations.incomplete'),
                  icon: <RunningWithErrors />,
                  href: PATH_DASHBOARD.incompleteDonations,
                },
                {
                  title: t('sidebar.donors'),
                  href: PATH_DASHBOARD.business,
                  icon: ICONS.company,
                },
                {
                  title: t('sidebar.recipients'),
                  href: PATH_DASHBOARD.npo,
                  icon: ICONS.foodBank,
                },
                {
                  title: t('sidebar.partnerships'),
                  icon: (
                    <OnboardingRequestsBadge>
                      {ICONS.groups}
                    </OnboardingRequestsBadge>
                  ),
                  href: PATH_DASHBOARD.partnerships,
                },
                {
                  title: t('sidebar.reports.title'),
                  icon: ICONS.analytics,
                  href: PATH_DASHBOARD.reports,
                  items: [
                    {
                      title: t('sidebar.reports.cancellations'),
                      href: PATH_DASHBOARD.cancellationReport,
                    },
                    {
                      title: t('sidebar.reports.donations'),
                      href: PATH_DASHBOARD.donationsReport,
                    },
                    {
                      title: t('sidebar.reports.drivers'),
                      href: PATH_DASHBOARD.drivers,
                    },
                  ],
                },
              ];
            }
            return [
              {
                title: t('sidebar.overview'),
                href: PATH_DASHBOARD.root,
                icon: ICONS.dashboard,
              },
              {
                title: t('sidebar.my-impact'),
                href: PATH_DASHBOARD.impact,
                icon: <Public />,
              },
              {
                title: t('sidebar.calendar'),
                href: PATH_DASHBOARD.schedule.root,
                icon: ICONS.calendar,
              },
              {
                title: t('sidebar.donations.all-donations'),
                href: PATH_DASHBOARD.donations,
                icon: <Inventory />,
              },
              {
                title: t('sidebar.donations.my-donations'),
                href: PATH_DASHBOARD.mydonations,
                icon: ICONS.cart,
              },
              {
                title: t('sidebar.donations.incomplete'),
                icon: <RunningWithErrors />,
                href: PATH_DASHBOARD.incompleteDonations,
              },
              {
                title: t('sidebar.partnerships'),
                href: PATH_DASHBOARD.partnerships,
                icon: ICONS.groups,
              },
              {
                title: t('sidebar.reports.title'),
                icon: ICONS.analytics,
                href: PATH_DASHBOARD.reports,
                items: [
                  {
                    title: t('sidebar.reports.cancellations'),
                    href: PATH_DASHBOARD.cancellationReport,
                  },
                  {
                    title: t('sidebar.reports.drivers'),
                    href: PATH_DASHBOARD.drivers,
                  },
                ],
              },
            ];
          case 'ADMIN':
            return [
              {
                title: 'Users',
                href: PATH_DASHBOARD.admin.users,
                icon: ICONS.elements,
              },
              {
                title: 'Organizations',
                href: PATH_DASHBOARD.admin.orgs,
                icon: ICONS.company,
              },
              {
                title: 'Map',
                href: PATH_DASHBOARD.admin.map,
                icon: ICONS.map,
              },
              {
                title: 'Executive Accounts',
                href: PATH_DASHBOARD.admin.executiveUsers,
                icon: ICONS.user,
              },
              {
                title: 'Corporate Accounts',
                href: PATH_DASHBOARD.admin.corporateAccounts,
                icon: ICONS.user,
              },
              {
                title: 'Subscriptions',
                href: PATH_DASHBOARD.admin.subscriptions,
                icon: ICONS.elements,
              },
              {
                title: t('sidebar.rescues.title'),
                href: PATH_DASHBOARD.rescues,
                icon: <LocalShipping />,
              },
              {
                title: t('sidebar.donations.title'),
                href: PATH_DASHBOARD.donations,
                icon: ICONS.cart,
              },
              {
                title: t('sidebar.food-runs.title'),
                icon: ICONS.animate,
                href: PATH_DASHBOARD.foodRun.allRuns,
              },
              {
                title: t('sidebar.vehicles'),
                icon: ICONS.vehicle,
                href: PATH_DASHBOARD.vehicle,
              },
            ];
          default:
            throw new Error('Unsupported account type');
        }
      },
    },

    // SETTINGS
    {
      subheader: t('sidebar.settings'),
      items: (user) => {
        switch (user.account_type) {
          case 'ADMIN':
            return [
              {
                title: t('sidebar.account-settings'),
                href: PATH_DASHBOARD.settings.account(''),
                icon: ICONS.user,
              },
            ];
          default:
            if (user.is_executive) {
              return user.is_admin
                ? [
                    {
                      title: 'Geozones',
                      href: PATH_DASHBOARD.executive.geozones,
                      icon: ICONS.map,
                    },
                    {
                      title: t('sidebar.my-team'),
                      href: PATH_DASHBOARD.teams,
                      icon: <People />,
                    },
                    {
                      title: t('sidebar.my-organization'),
                      href: PATH_DASHBOARD.settings.business(''),
                      icon: <BusinessCenter />,
                    },
                    {
                      title: t('sidebar.account-settings'),
                      href: PATH_DASHBOARD.settings.account(''),
                      icon: ICONS.user,
                    },
                    {
                      title: t('sidebar.meta-data'),
                      href: PATH_DASHBOARD.settings.tags,
                      icon: ICONS.user,
                    },
                  ]
                : [
                    {
                      title: 'Geozones',
                      href: PATH_DASHBOARD.executive.geozones,
                      icon: ICONS.map,
                    },
                    {
                      title: t('sidebar.account-settings'),
                      href: PATH_DASHBOARD.settings.account(''),
                      icon: ICONS.user,
                    },
                  ];
            }

            if (user.is_non_profit) {
              return user.is_admin
                ? [
                    ...(user.is_safety_program_enabled
                      ? [
                          {
                            title: t('sidebar.training-program'),
                            href: PATH_DASHBOARD.trainingEditor,
                            icon: <School />,
                          },
                        ]
                      : []),
                    {
                      title: t('sidebar.my-team'),
                      href: PATH_DASHBOARD.teams,
                      icon: <People />,
                    },
                    {
                      title: t('sidebar.my-organization'),
                      href: PATH_DASHBOARD.settings.business(''),
                      icon: <BusinessCenter />,
                    },
                    {
                      title: t('sidebar.vehicles'),
                      icon: ICONS.vehicle,
                      href: PATH_DASHBOARD.vehicle,
                    },
                    {
                      title: t('sidebar.documents'),
                      href: PATH_DASHBOARD.settings.documents,
                      icon: <FileCopy />,
                    },
                    {
                      title: t('sidebar.email-settings'),
                      href: PATH_DASHBOARD.customizeEmail,
                      icon: ICONS.mail,
                    },
                    {
                      title: t('sidebar.account-settings'),
                      href: PATH_DASHBOARD.settings.account(''),
                      icon: ICONS.user,
                    },
                    {
                      title: t('sidebar.meta-data'),
                      href: PATH_DASHBOARD.settings.tags,
                      icon: ICONS.user,
                    },
                  ]
                : [
                    {
                      title: t('sidebar.documents'),
                      href: PATH_DASHBOARD.settings.documents,
                      icon: <FileCopy />,
                    },
                    {
                      title: t('sidebar.vehicles'),
                      icon: ICONS.vehicle,
                      href: PATH_DASHBOARD.vehicle,
                    },
                    {
                      title: t('sidebar.account-settings'),
                      href: PATH_DASHBOARD.settings.account(''),
                      icon: ICONS.user,
                    },
                  ];
            }

            return user.is_admin
              ? [
                  ...(user.is_safety_program_enabled
                    ? [
                        {
                          title: t('sidebar.training-program'),
                          href: PATH_DASHBOARD.trainingEditor,
                          icon: <School />,
                        },
                      ]
                    : []),
                  {
                    title: t('sidebar.my-team'),
                    href: PATH_DASHBOARD.teams,
                    icon: <People />,
                  },
                  {
                    title: t('sidebar.my-organization'),
                    href: PATH_DASHBOARD.settings.business(''),
                    icon: <BusinessCenter />,
                  },
                  {
                    title: t('sidebar.documents'),
                    href: PATH_DASHBOARD.settings.documents,
                    icon: <FileCopy />,
                  },
                  {
                    title: t('sidebar.email-settings'),
                    href: PATH_DASHBOARD.customizeEmail,
                    icon: ICONS.mail,
                  },
                  {
                    title: t('sidebar.account-settings'),
                    href: PATH_DASHBOARD.settings.account(''),
                    icon: ICONS.user,
                  },
                  {
                    title: t('sidebar.meta-data'),
                    href: PATH_DASHBOARD.settings.tags,
                    icon: ICONS.user,
                  },
                ]
              : [
                  {
                    title: t('sidebar.documents'),
                    href: PATH_DASHBOARD.settings.documents,
                    icon: <FileCopy />,
                  },
                  {
                    title: t('sidebar.account-settings'),
                    href: PATH_DASHBOARD.settings.account(''),
                    icon: ICONS.user,
                  },
                ];
        }
      },
    },

    {
      subheader: '#',
      items: [
        {
          title: t('sidebar.support'),
          href: 'https://help.careitapp.com',
          icon: ICONS.chat,
          crossOrigin: true,
        },
      ],
    },
  ];
};

export default useSidebarConfig;
