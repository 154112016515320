import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  default: {
    items: [],
    linkOperator: 'and',
  },
};

const slice = createSlice({
  name: 'pageFilters',
  initialState,
  reducers: {
    setFilterModel(state, action) {
      state[action.payload.page] = action.payload.data;
    },
    resetFilterModel: () => initialState,
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setFilterModel, resetFilterModel } = slice.actions;
