import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { Box, TextField } from '@mui/material';
import useGetLocaleTextDatePicker from 'src/hooks/useGetLocaleTextDatePicker';

const useDateFilterForTable = ({
  defaultDateRange,
  shouldDisableDate = false,
}) => {
  defaultDateRange = defaultDateRange ?? {
    start: null,
    end: null,
  };
  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: 'and',
  });
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const localeText = useGetLocaleTextDatePicker();
  const filterModelWithDateRange = useMemo(
    () => ({
      items:
        dateRange.start && dateRange.end
          ? filterModel.items.concat([
              {
                id: 'date-filter-start',
                columnField: 'start_date',
                operatorValue: 'onOrAfter',
                value: moment(dateRange.start)
                  .startOf('day')
                  .utc()
                  .format('YYYY-MM-DDTHH:mm'),
              },
              {
                id: 'date-filter-end',
                columnField: 'end_date',
                operatorValue: 'onOrBefore',
                value: moment(dateRange.end)
                  .endOf('day')
                  .utc()
                  .format('YYYY-MM-DDTHH:mm'),
              },
            ])
          : filterModel.items,
      linkOperator: 'and',
    }),
    [filterModel.items, dateRange]
  );

  const updateDateRange = useCallback((range) => {
    const start = range.start ?? moment(range.end).subtract(1, 'week').toDate();
    const end = range.end ?? moment(range.start).add(1, 'week').toDate();
    setDateRange({ start, end });
  }, []);

  const DateRangeSelector = (
    <MobileDateRangePicker
      value={[dateRange.start, dateRange.end]}
      onChange={([startDate, endDate]) => {
        updateDateRange({ start: startDate, end: endDate });
      }}
      localeText={localeText}
      shouldDisableDate={(date) =>
        shouldDisableDate
          ? moment(date).valueOf() < moment().subtract(1, 'year').valueOf()
          : false
      }
      renderInput={(startProps, endProps) => (
        <>
          <TextField {...startProps} size="small" />
          <Box sx={{ mx: 2 }}> to </Box>
          <TextField {...endProps} size="small" />
        </>
      )}
    />
  );

  return {
    filterModelWithDateRange,
    filterModel,
    setFilterModel,
    DateRangeSelector,
  };
};

export default useDateFilterForTable;
