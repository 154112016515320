import { Redirect } from 'react-router-dom';
// guards
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';
//
import { PATH_DASHBOARD } from './paths';
import lazy from './lazy';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: lazy(() => import('../views/Home')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.chatShortcut(':conversation'),
      component: lazy(() => import('src/views/ChatShortcut')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.reverifyEmail,
      component: lazy(() => import('../views/Home/ReverifyEmail')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.impact,
      component: lazy(() => import('../views/Home/MyImpact')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.incompleteDonations,
      component: lazy(() => import('src/views/Donations/IncompleteDonations')),
    },

    //----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.donationsReport,
      component: lazy(() => import('src/views/DonationsReport')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foodRun.allRuns,
      component: lazy(() => import('src/views/FoodRuns/AllRuns')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foodRun.myRuns,
      component: lazy(() => import('src/views/FoodRuns/MyRuns')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foodRun.availableRuns,
      component: lazy(() => import('src/views/FoodRuns/AvailableRuns')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foodRun.inProgress,
      component: lazy(() => import('src/views/FoodRuns/InProgress')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foodRun.runHistory,
      component: lazy(() => import('src/views/FoodRuns/RunHistory')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foodRun.toBeReassigned,
      component: lazy(() => import('src/views/FoodRuns/ToBeReassigned')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.rescues,
      component: lazy(() => import('src/views/Rescues/Rescues')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.myrescues,
      component: lazy(() => import('src/views/Rescues/MyRescues')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.findDonations,
      component: lazy(() => import('src/views/FindDonations')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.donations,
      component: lazy(() => import('src/views/Donations/Donations')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.mydonations,
      component: lazy(() => import('src/views/Donations/MyDonations')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.drivers,
      component: lazy(() => import('src/views/Drivers')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.incomingRequests,
      component: lazy(() => import('src/views/Donations/IncomingRequests')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.viewDonation(':id'),
      component: lazy(() => import('src/views/Donations/Donation')),
    },

    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.business,
      component: lazy(() => import('../views/org/business')),
    },
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.editOrg(':id'),
      component: lazy(() => import('../views/org/EditOrg')),
    },
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.npo,
      component: lazy(() => import('../views/org/npo')),
    },
    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.viewOrg(':id'),
      component: lazy(() => import('src/views/Organization')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.schedule.root,
      component: lazy(() => import('src/views/schedule/Schedule')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.schedule.donations,
      component: lazy(() => import('src/views/schedule/UpcomingDonations')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.teams,
      component: lazy(() => import('src/views/Teams')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.viewTeamMember(':id'),
      component: lazy(() => import('src/views/Teams/TeamMember')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.settings.account(':tab'),
      component: lazy(() => import('src/views/Settings/Account.js')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.account(''),
      component: lazy(() => import('src/views/Settings/Account.js')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.business(':tab'),
      component: lazy(() => import('src/views/Settings/Business.js')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.business(''),
      component: lazy(() => import('src/views/Settings/Business.js')),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.settings.documents,
      component: lazy(() =>
        import('src/components/user/account/TeamDocuments')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.settings.tags,
      component: lazy(() => import('src/views/Settings/Tags')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.vehicle,
      component: lazy(() => import('src/views/Vehicle')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.customizeEmail,
      component: lazy(() => import('src/views/CustomizeEmail')),
    },

    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.editRecurringFoodRun(':id'),
      component: lazy(() =>
        import('src/views/Donations/EditRecurringDonations.js')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.editRecurringDonation(':id'),
      component: lazy(() =>
        import('src/views/Donations/EditRecurringDonations.js')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.editDonationSingleInstance(':id'),
      component: lazy(() =>
        import('src/views/Donations/EditSingleInstanceDonation.js')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.cancellationReport,
      component: lazy(() => import('src/views/Donations/CancellationReport')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.editFoodRunSingleInstance(':id'),
      component: lazy(() =>
        import('src/views/FoodRun/EditFoodRunSingleInstance.js')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.viewFoodRun(':id'),
      component: lazy(() => import('src/views/FoodRun/FoodRunDetails.js')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.payment,
      component: lazy(() => import('src/views/Payment')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.allPlans,
      component: lazy(() => import('src/views/Pricing')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.admin.users,
      component: lazy(() => import('src/views/admin/Users')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.admin.orgs,
      component: lazy(() => import('src/views/admin/Organizations')),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.admin.map,
      component: lazy(() => import('src/views/admin/DonorNPOSMap')),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.admin.subscriptions,
      component: lazy(() => import('src/views/admin/Subscriptions')),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.admin.mergeOrgs,
      component: lazy(() => import('src/views/admin/MergeOrganizations')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.admin.createOrg,
      component: lazy(() => import('src/views/admin/CreateOrganization')),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.admin.viewUser(':id'),
      component: lazy(() => import('src/views/admin/User')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.admin.viewOrg(':id'),
      component: lazy(() => import('src/views/admin/Organization')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.trainingEditor,
      component: lazy(() => import('src/views/TrainingEditor')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.admin.executiveUsers,
      component: lazy(() => import('src/views/admin/ExecutiveUsers')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.admin.viewExecutiveUsers(':id'),
      component: lazy(() => import('src/views/admin/ExecutiveUser')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.admin.corporateAccounts,
      component: lazy(() => import('src/views/admin/CorporateAccounts')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.admin.viewCorporateAccount(':id'),
      component: lazy(() => import('src/views/admin/CorporateAccount')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.executive.home,
      component: lazy(() => import('../views/ExecutiveDashboard/Home')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.donations.all,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Donations/All')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.donations.incoming,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Donations/Incoming')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.donations.internal,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Donations/Internal')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.donations.outgoing,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Donations/Outgoing')
      ),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.executive.orgs,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Organizations')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.viewOrg(':id'),
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Organizations')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.createOrg,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/CreateOrganization')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.geozones,
      component: lazy(() => import('../views/ExecutiveDashboard/Geozones')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.corporate.home,
      component: lazy(() => import('src/views/CorporateDashboard/Home')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.donations,
      component: lazy(() => import('src/views/CorporateDashboard/Donations')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.schedule,
      component: lazy(() => import('src/views/CorporateDashboard/Schedule')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.viewDonation(':id'),
      component: lazy(() => import('src/views/CorporateDashboard/Donation')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.npos,
      component: lazy(() => import('src/views/CorporateDashboard/NPOs')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.teams,
      component: lazy(() => import('src/views/CorporateDashboard/Teams')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.viewTeam(':id'),
      component: lazy(() => import('src/views/CorporateDashboard/Team')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.report,
      component: lazy(() => import('src/views/CorporateDashboard/Report')),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.root,
      component: lazy(() => import('src/views/CorporateDashboard/Onboarding')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.create(':profileId').form,
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Create/Form')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.create(':profileId').agreement,
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Create/Agreement')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.create(':profileId').document,
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Create/Document')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.create(':profileId').upload,
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Create/Upload')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.edit.form(':id'),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Edit/Form')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.edit.agreement(':id'),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Edit/Agreement')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.edit.document(':id'),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Edit/Document')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.edit.upload(':id'),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Edit/Upload')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.requests,
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Requests')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.request(':id', ':teamId'),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/Request')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.progress.form(':id', ':teamId'),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/View/Form')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.progress.agreement(
        ':id',
        ':teamId'
      ),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/View/Agreement')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.progress.document(
        ':id',
        ':teamId'
      ),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/View/Document')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.progress.upload(
        ':id',
        ':teamId'
      ),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/View/Upload')
      ),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.complianceProfile.root,
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/ComplianceProfile/All')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.complianceProfile.create,
      component: lazy(() =>
        import(
          'src/views/CorporateDashboard/Onboarding/ComplianceProfile/Create'
        )
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.complianceProfile.view(':id'),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/ComplianceProfile/View')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporate.onboarding.complianceProfile.edit(':id'),
      component: lazy(() =>
        import('src/views/CorporateDashboard/Onboarding/ComplianceProfile/Edit')
      ),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.request(':id', ':corpId'),
      component: lazy(() => import('src/views/CorporateOnboarding/Request')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.progress(':id', ':teamId'),
      component: lazy(() => import('src/views/CorporateOnboarding/Progress')),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.complete.form(':id'),
      component: lazy(() =>
        import('src/views/CorporateOnboarding/Complete/Form')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.complete.agreement(':id'),
      component: lazy(() =>
        import('src/views/CorporateOnboarding/Complete/Agreement')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.complete.document(':id'),
      component: lazy(() =>
        import('src/views/CorporateOnboarding/Complete/Document')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.complete.upload(':id'),
      component: lazy(() =>
        import('src/views/CorporateOnboarding/Complete/Upload')
      ),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.view.form(':id'),
      component: lazy(() => import('src/views/CorporateOnboarding/View/Form')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.view.agreement(':id'),
      component: lazy(() =>
        import('src/views/CorporateOnboarding/View/Agreement')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.view.document(':id'),
      component: lazy(() =>
        import('src/views/CorporateOnboarding/View/Document')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.corporateOnboarding.view.upload(':id'),
      component: lazy(() =>
        import('src/views/CorporateOnboarding/View/Upload')
      ),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.agreement(':id'),
      component: lazy(() => import('src/views/Partnerships/Agreement')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.agreements,
      component: lazy(() => import('src/views/Partnerships/Agreements')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.partnerships,
      component: lazy(() => import('src/views/Partnerships')),
    },

    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.executive.inspections.all,
      component: lazy(() => import('../views/ExecutiveDashboard/Inspections')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.inspections.mine,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Inspections/Mine')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.inspections.incomplete,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Inspections/Incomplete')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.inspections.view(':id'),
      component: lazy(() =>
        import('../views/ExecutiveDashboard/common/DetailView')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.inspections.fro,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Inspections/Forms/FRO')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.inspections.efg,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Inspections/Forms/EFG')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.inspections.reports.fro,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Inspections/Reports/FRO')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.inspections.reports.efg,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Inspections/Reports/EFG')
      ),
    },

    {
      exact: true,
      path: PATH_DASHBOARD.executive.complaints.all,
      component: lazy(() => import('../views/ExecutiveDashboard/Complaints')),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.complaints.mine,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Complaints/Mine')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.complaints.incomplete,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Complaints/Incomplete')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.complaints.view(':id'),
      component: lazy(() =>
        import('../views/ExecutiveDashboard/common/DetailView')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.complaints.form,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Complaints/Forms/Complaint')
      ),
    },
    {
      exact: true,
      path: PATH_DASHBOARD.executive.complaints.report,
      component: lazy(() =>
        import('../views/ExecutiveDashboard/Complaints/Reports/Complaints')
      ),
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default DashboardRoutes;
