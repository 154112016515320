/**
 * Round a moment to the nearest 15 minute interval.
 *
 * @param {moment} time
 * @returns {moment}
 */
export function roundMinutes(time) {
  let intervals = Math.floor(time.minutes() / 15);
  if (time.minutes() % 15 != 0) intervals++;
  if (intervals == 4) {
    time.add(1, 'hours');
    intervals = 0;
  }
  time.minutes(intervals * 15);
  time.seconds(0);
  return time;
}
