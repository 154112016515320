import { useSnackbar } from 'notistack';
import React from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { SOCKET_ORIGIN } from 'src/backend/common';

export const DocumentContext = React.createContext({
  doc: null,
  provider: null,
});

export const DocumentProvider = ({ children, doc = new Y.Doc() }) => {
  const { user } = useAuth();
  const token = window.localStorage.getItem('token');
  const [authenticated, setAuthenticated] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const provider = React.useMemo(() => {
    const provider = new WebsocketProvider(SOCKET_ORIGIN, user.team_id, doc);

    const onConnecting = () => {
      const provider_onmessage = provider.ws.onmessage;
      provider.ws.onmessage = (event) => {
        const { data } = event;
        if (typeof data === 'string') {
          switch (data) {
            case 'Authenticated':
              setAuthenticated(true);
              provider.ws.onmessage = provider_onmessage;
              provider_onopen();
              break;
            case 'Access-Denied':
              provider.disconnect();
              enqueueSnackbar(
                'There was an error authenticating. Please try again later.',
                { variant: 'error' }
              );
              break;
            default:
              break;
          }
        }
      };

      const provider_onopen = provider.ws.onopen;
      provider.ws.onopen = () => {
        provider.ws.send(token);
      };
    };
    onConnecting();

    return provider;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc]);

  return (
    <DocumentContext.Provider
      value={{
        doc,
        provider,
      }}
    >
      {authenticated ? children : <LoadingScreen />}
    </DocumentContext.Provider>
  );
};
