import React, { useState, useEffect, useContext } from 'react';
import { training } from 'src/backend';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const ProgressContext = React.createContext('progress');

export function ProgressProvider({ children }) {
  const [progress, setProgress] = useState();

  return (
    <ProgressContext.Provider value={{ progress, setProgress }}>
      {children}
    </ProgressContext.Provider>
  );
}

export default function useProgess() {
  const { t } = useTranslation('translation');
  const { enqueueSnackbar } = useSnackbar();
  const { progress, setProgress } = useContext(ProgressContext);
  if (!setProgress) {
    throw new Error(
      'You must wrap your component in a `ProgressProvider` to use `useContext`'
    );
  }

  useEffect(() => {
    if (progress) return;

    training
      .getProgress()
      .then((progress) =>
        setProgress({
          ...progress,
          answers: JSON.parse(progress.answers),
          results: JSON.parse(progress.results),
        })
      )
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(t('form.errors.generic'), {
          variant: 'error',
        });
      });
  }, [progress, setProgress, enqueueSnackbar]);

  const updateProgress = (updates) => {
    setProgress({ ...progress, ...updates });
    training.updateProgress({ ...progress, ...updates });
  };

  const isLoading = !progress;
  const answers = progress?.answers ?? {};
  const section = progress?.section ?? 'lesson';

  const setAnswers = (updates) =>
    updateProgress({ answers: { ...answers, ...updates } });

  const setSection = (newSection) =>
    updateProgress({ section: newSection, page: 0 });

  const page = progress?.page;
  const setPage = (newPage) => {
    updateProgress({ page: newPage });
  };

  const results = progress?.results ?? {};
  const finishExam = (newResults) =>
    updateProgress({ results: newResults, section: 'review', page: 0 });
  const restartExam = () =>
    updateProgress({ answers: {}, section: 'lesson', page: 0 });

  const finishProgram = (initials) => {
    updateProgress({ initials });
    training.finishProgram();
  };

  const clearAnswers = () => updateProgress({ answers: {} });

  return {
    isLoading,
    answers,
    setAnswers,
    page,
    setPage,
    section,
    setSection,
    results,
    finishExam,
    restartExam,
    finishProgram,
    clearAnswers,
  };
}
