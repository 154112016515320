import React from 'react';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';

/**
 * Similar to `React.lazy` but this will force-reload the page on a chunk load
 * error. Reloading will happend no more than once every 5 minutes.
 */
export default function lazy(_import) {
  return React.lazy(async () => {
    let lastForceRefresh = window.localStorage.getItem('last-refresh');
    lastForceRefresh = lastForceRefresh && moment(lastForceRefresh);
    const now = moment();

    try {
      const component = await _import();
      return component;
    } catch (error) {
      // Only force-refresh if we have either:
      //   i) never froce refreshed, or
      //  ii) for refreshed over 5 minutes ago
      if (!lastForceRefresh || now.diff(lastForceRefresh, 'minutes') > 5) {
        window.localStorage.setItem(
          'last-refresh',
          now.format('YYYY-MM-DD HH:mm:ss')
        );
        return window.location.reload();
      }

      // Disable the feedback dialog, this error is not from user input
      Sentry.setContext('feedbackDialog', { hide: true });

      // If we shouldn't force-reload, then let the error bubble up.
      throw error;
    }
  });
}
