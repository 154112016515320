// material
import { styled } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';
import { isProduction } from 'src/config';
// components
import Page from '../components/Page';
import { PricingPlanCard } from '../components/pricing';
import { createCheckoutSession } from 'src/backend/subscription';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

export const PLANS = [
  {
    subscription: 'basic',
    icon: '/static/icons/ic_plan_starter.svg',
    price: 19.0,
    item: 'basic',
    priceId: {
      test: 'price_1J2JwiHRNVAUEyHHDhDHJhvZ',
      live: 'price_1JCdyUHRNVAUEyHHQFp7SDwc',
    },
    lists: [
      { text: 'Access to the Careit App', isAvailable: true },
      { text: 'Teams', isAvailable: true },
      { text: 'Dashboard Reporting', isAvailable: true },
      { text: 'Offline Donations', isAvailable: true },
      { text: 'Duplicate & Repeat Donations', isAvailable: false },
      { text: 'Post on behalf of a donor', isAvailable: false },
      {
        text: 'Assign donations to multiple team members and non-profits',
        isAvailable: false,
      },
    ],
    labelAction: 'choose basic',
  },
  {
    subscription: 'food mover',
    icon: '/static/icons/ic_plan_premium.svg',
    price: 250.0,
    item: 'foodMover',
    priceId: {
      live: 'price_1JCdybHRNVAUEyHHHX63HPS3',
      test: 'price_1J2JxOHRNVAUEyHH4krNi4Mc',
    },
    lists: [
      { text: 'Access to the Careit App', isAvailable: true },
      { text: 'Teams', isAvailable: true },
      { text: 'Dashboard Reporting', isAvailable: true },
      { text: 'Offline Donations', isAvailable: true },
      { text: 'Duplicate & Repeat Donations', isAvailable: true },
      { text: 'Post on behalf of a donor', isAvailable: true },
      {
        text: 'Assign donations to multiple team members and non-profits',
        isAvailable: true,
      },
    ],
    labelAction: 'choose food mover',
  },
];

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Pricing() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = ({ item }) => {
    createCheckoutSession(item)
      .then((session) => (window.location.href = session.url))
      .catch(() =>
        enqueueSnackbar('There was an error redirecting you to the checkout.', {
          variant: 'error',
        })
      );
  };

  return (
    <RootStyle title="Pricing | Careit">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {PLANS.map((card) => {
            const priceId = !isProduction
              ? card.priceId.test
              : card.priceId.live;
            return (
              <Grid item xs={12} md={4} key={card.subscription}>
                <PricingPlanCard
                  card={card}
                  selected={user.subscription_price_id === priceId}
                  // index={index}
                  onClick={handleClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </RootStyle>
  );
}
