import { fetchAPI, METHODS, parseBody } from './common';

/**
 * Fetches the notes for a rescue.
 * @param {string} itemID
 * @param {boolean} isRescue
 * @returns string
 */
export async function get(itemId, isRescue) {
  const res = await fetchAPI(
    `/rescue/note/${isRescue ? 1 : 0}/${itemId}`,
    METHODS.get
  );
  const { note } = await parseBody(res);
  return note?.notes;
}

/**
 * Updates the notes for a rescue.
 * @param {string} param0.itemId
 * @param {boolean} param0.isRescue
 * @param {string} param0.value
 * @param {string} param0.id
 */
export async function update({ itemId, isRescue, value, id }) {
  const res = await fetchAPI('/rescue/note', METHODS.put, {
    item_id: itemId,
    is_rescue: isRescue ? 1 : 0,
    notes: value,
    id,
  });
  await parseBody(res);
}

/**
 * Fetches the notes for a rescue.
 * @param {string} itemID
 * @returns array
 */
export async function getAllNotesOfItem(itemId) {
  const res = await fetchAPI(`/rescue/note/all/${itemId}`, METHODS.get);
  const { notes } = await parseBody(res);
  return notes;
}

/**
 * Creates the notes for a rescue.
 * @param {string} param0.itemId
 * @param {boolean} param0.isRescue
 * @param {string} param0.value
 */
export async function create({ itemId, isRescue, value }) {
  const res = await fetchAPI(`/rescue/note`, METHODS.post, {
    item_id: itemId,
    is_rescue: isRescue ? 1 : 0,
    notes: value,
  });
  return await parseBody(res);
}
