import { useEffect, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import useForm, {
  checkboxFormOnChange,
  dateTimeOnChange,
  makeMultiline,
  SelectAddressComponent,
  selectAddressOnChange,
  SelectComponent,
  UploadImagesComponent,
  uploadImagesOnChange,
  RadioGroupComponent,
  DateComponent,
  dateOnChange,
  ToggleButtonComponent,
  TimeComponent,
  timeOnChange,
  foodCategoryOnChange,
  processFoodTypes,
  DayOfTheMonthSelector,
} from 'src/hooks/useForm';
import { useSnackbar } from 'notistack';

import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import * as Yup from 'yup';
import * as backend from 'src/backend';
import moment from 'moment';
import useAuth from './useAuth';
import useOrg from './useOrg';
import useTeamMembers from './useTeamMembers';
import getTimeZoneIdFromCoords from 'src/utils/getTimeZoneIdFromCoords';
import {
  processRecurringIntervalData,
  recomputeEndDate,
} from 'src/utils/recurring';
import { useCheckAction } from 'src/utils/permissions';
import { FOOD_TYPES, useGetVehicleLabels } from 'src/constants';
import { useMeasureFieldUnits } from 'src/components/MeasureField';
import _isFinite from 'lodash/isFinite';
import { Weight, getLocaleUnit } from 'careit';
import { parseUTC } from 'src/utils/timezones';
import { roundMinutes } from 'src/utils/time';
import useCheckCompletedOnboarding from 'src/hooks/useCheckCompletedOnboarding';
import useEventBus from './useEventBus';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Link } from 'react-router-dom';
import useVehicles from './useVehicles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import FoodTypeSelector from 'src/utils/foodTypeSelector';
import useDaysOfTheWeek from './useDaysOfTheWeek';

// ----------------------------------------------------------------------

function parseDonation(donation, user, isRepost) {
  if (!donation) {
    return {};
  }

  const destLocation = JSON.parse(donation.dest_location ?? '{}');
  const donationLocation = donation?.location ?? {};
  const res = {
    title: donation.title,
    description: donation.description,
    foodTypes: _.mapValues(
      _.keyBy(
        JSON.parse(donation.food_weight_object ?? '[]'),
        ({ food_type }) => _.toLower(food_type)
      ),
      ({ estimated_weight, estimated_weight_unit }) => {
        return {
          value:
            estimated_weight === null
              ? null
              : new Weight(estimated_weight, 'lbs')
                  .as(estimated_weight_unit)
                  .valueOf(),
          unit: estimated_weight_unit,
        };
      }
    ),
    location: {
      lat: donationLocation.lat,
      lng: donationLocation.lng,
      formattedAddress: donationLocation.formatted_address,
      name: donationLocation.place,
      streetAddress: donationLocation.street_address,
      city: donationLocation.city,
      province: donationLocation.province,
      country: donationLocation.country,
      zipCode: donationLocation.zip_code,
      placeId: donationLocation.place_id,
      timeZoneId: donationLocation.timezone_id,
    },
    destLocation: {
      lat: destLocation.lat,
      lng: destLocation.lng,
      formattedAddress: destLocation.formatted_address,
      name: destLocation.place,
      streetAddress: destLocation.street_address,
      city: destLocation.city,
      province: destLocation.province,
      country: destLocation.country,
      zipCode: destLocation.zip_code,
      placeId: destLocation.place_id,
      timeZoneId: destLocation.timezone_id,
    },
    images: donation.images,
    deliveryOption: {
      'SELF-DELIVER': 0,
      'RECEIVER-PICKUP': 1,
      'DELIVERY-BY-FOOD-MOVER': 1, // ie. map to RECEIVER-PICKUP
    }[donation.delivery_option],
    receiverTeam: donation.rescue_team ?? 'unassigned',
    driverId: donation.driver_id ?? 'unassigned',
    occurrenceType: isRepost
      ? 'onetime'
      : donation?.is_recurring
      ? 'recurring'
      : 'onetime',
    interval: _.capitalize(donation.recurring_interval),
    startDate: parseUTC(donation.start_date, user),
    endDate: parseUTC(donation.end_date, user),
    startTime: parseUTC(donation.start_date, user),
    endTime: parseUTC(donation.end_date, user),
    dayOfTheMonth: {
      isLastDay: donation.is_last_day_of_the_month,
      customDay: moment(parseUTC(donation.day_of_the_month, user)).date(),
      week: {
        number: donation.no_of_the_week_in_month,
        dayOfTheWeek: donation.day_of_the_week_in_month,
      },
    },
  };

  return res;
}

const useAddDonationSchema = () => {
  const { t } = useTranslation('translation');
  const { WEIGHT_UNITS } = useMeasureFieldUnits();
  const checkCompletedOnboarding = useCheckCompletedOnboarding();
  return (nearbyNPOs) =>
    Yup.object({
      title: Yup.string().required(t('donations.donation-title-required')),
      description: Yup.string().required(t('donations.description-required')),
      foodTypes: Yup.object({
        ...FOOD_TYPES.map(({ value }) => value.toLowerCase()).reduce(
          (foodTypes, foodType) => ({
            ...foodTypes,
            [foodType]: Yup.object({
              value: Yup.number(),
              unit: Yup.string().oneOf(WEIGHT_UNITS.map(({ value }) => value)),
            }),
          }),
          {}
        ),
      }).test(
        'at-least-one-property',
        t('donations.at-least-one-food-type'),
        (value) => Object.entries(processFoodTypes(value)).length > 0
      ),
      location: Yup.object({}).when(['deliveryOption'], {
        is: (deliveryOption) => deliveryOption === 1,
        then: Yup.object({}).required(
          t('donations.donation-location-required')
        ),
        otherwise: Yup.object({}).optional(),
      }),
      destLocation: Yup.object({}).when(['deliveryOption', 'receiverTeam'], {
        is: (deliveryOption, receiverTeam) =>
          deliveryOption === 0 && receiverTeam && receiverTeam !== 'unassigned',
        then: Yup.object({}).required(
          t('donations.donation-destination-location-required')
        ),
        otherwise: Yup.object({}).optional(),
      }),
      images: Yup.array().when(['isFoodMover', 'occurrenceType'], {
        is: (isFoodMover, occurrenceType) =>
          !isFoodMover && occurrenceType === 'onetime',
        then: Yup.array().min(1, t('donations.at-least-one-image')),
        otherwise: Yup.array().optional(),
      }),
      deliveryOption: Yup.number().required(
        t('donations.select-a-delivery-option')
      ),
      receiverTeam: Yup.string()
        .when(['deliveryOption', 'isFoodMover', 'endDate'], {
          is: (deliveryOption, isFoodMover, endDate) =>
            (isFoodMover && deliveryOption === 0) ||
            moment(endDate).isBefore(moment().startOf('day')),
          then: Yup.string()
            .required(t('donations.select-a-receiver'))
            .notOneOf(['unassigned'], t('donations.select-a-receiver')),
          otherwise: Yup.string().optional(),
        })
        .test(
          'completed-onboarding',
          ({ value: receiver }) =>
            checkCompletedOnboarding.buildErrorMessage(nearbyNPOs, receiver),
          checkCompletedOnboarding.validate
        ),
      driverId: Yup.string().when(['deliveryOption', 'receiverTeam'], {
        is: (deliveryOption, receiverTeam) =>
          deliveryOption === 0 && receiverTeam && receiverTeam !== 'unassigned',
        then: Yup.string()
          .required(t('donations.select-a-driver'))
          .notOneOf(['unassigned'], t('donations.select-a-driver')),
        otherwise: Yup.string().optional(),
      }),
      occurrenceType: Yup.string()
        .oneOf(['onetime', 'recurring', 'indefinite'])
        .required(t('donations.select-a-occurrence-type')),
      interval: Yup.string().when('occurrenceType', {
        is: 'recurring',
        then: Yup.string().required(t('donations.select-a-recurring-interval')),
      }),
      startDate: Yup.string().required(t('donations.select-start-date')),
      endDate: Yup.string().when('occurrenceType', {
        is: 'recurring',
        then: Yup.string()
          .required(t('donations.select-end-date'))
          .test(
            'is-date-ranges-correct',
            t('donations.invalid-date-range'),
            (value, context) => {
              const interval = context?.parent.interval.toLowerCase();

              if (
                value &&
                context.parent.startDate &&
                moment(value)
                  .startOf('day')
                  .isBefore(moment(context.parent.startDate).startOf('day'))
              ) {
                return context.createError({
                  path: 'endDate',
                  message: t('donations.end-date-must-be-after-start-date'),
                });
              }

              if (interval === 'weekly') {
                const endDate = moment(value).clone().startOf('day');
                const startDate = moment(context.parent.startDate).startOf(
                  'day'
                );
                if (endDate.diff(startDate, 'days') < 7) {
                  return context.createError({
                    path: 'endDate',
                    message: t('donations.end-date-7-days-after-start-date'),
                  });
                }
              }

              if (interval === 'bi-weekly') {
                const endDate = moment(value).clone().startOf('day');
                const startDate = moment(context.parent.startDate).startOf(
                  'day'
                );
                if (endDate.diff(startDate, 'days') < 14) {
                  return context.createError({
                    path: 'endDate',
                    message: t('donations.end-date-14-days-after-start-date'),
                  });
                }
              }

              return true;
            }
          ),
      }),
      startTime: Yup.date().required(t('donations.select-a-start-time')),
      endTime: Yup.date()
        .required(t('donations.select-a-end-time'))
        .test(
          'is-after-start-time',
          t('donations.end-time-must-be-after-start-time'),
          (value, context) => {
            const endDate = moment(value).clone().startOf('day');
            const endTimeInMinutes = moment(value).diff(endDate, 'minutes');
            const startDate = moment(context.parent.startTime).startOf('day');
            const startTimeInMinues = moment(context.parent.startTime).diff(
              startDate,
              'minutes'
            );
            return endTimeInMinutes > startTimeInMinues;
          }
        ),
      dayOfTheMonth: Yup.object({}).when(['occurrenceType', 'interval'], {
        is: (occurrenceType, interval) =>
          ['indefinite', 'recurring'].includes(occurrenceType) &&
          interval === 'Monthly',
        then: Yup.object()
          .shape({
            isLastDay: Yup.bool(),
            customDay: Yup.number().nullable(),
            week: Yup.object()
              .shape({
                number: Yup.number().nullable(),
                dayOfTheWeek: Yup.number().nullable(),
              })
              .nullable(),
          })
          .test(
            'atLeastOneNonNull',
            t('donations.select-day-of-the-month'),
            function (value) {
              const { isLastDay, customDay, week } = value;
              return (
                isLastDay ||
                customDay ||
                (_isFinite(week.number) && _isFinite(week.dayOfTheWeek))
              );
            }
          )
          .required(t('donations.select-day-of-the-month')),
        otherwise: Yup.object().optional(),
      }),
    }).test({
      test: ({ occurrenceType, interval, ...other }, ctx) => {
        if (
          ['indefinite', 'recurring'].includes(occurrenceType) &&
          ['Weekly', 'Bi-weekly'].includes(interval)
        ) {
          const hasWeekDay = [...'SMTWTFS']
            .map((_, i) => other[`repeat-${i}`])
            .includes(true);
          if (!hasWeekDay) {
            return ctx.createError({
              path: 'dayOfTheWeek',
              message: t('donations.select-at-least-one-day-of-the-week'),
            });
          }
        }
        return true;
      },
    });
};

/**
 * Wrapper around `useForm` implementing a create donation form.
 * @param {Function} invalidate Callback to invalidate donations query due to mutation of donations.
 * @param {object?} initialDonation Initial values for the form.
 * @returns { handleOpen: Function, form: Function }
 */

export const getVehicle = (vehicle) => {
  const vehicleData = useMemo(
    () =>
      vehicle.map((item) => {
        const capacity = item.capacity;
        const processedCapacity =
          capacity.value && capacity.unit
            ? ` (${capacity.value + capacity.unit}) `
            : '';
        const processedData = {
          ...item,
          title: item.name + processedCapacity,
          label: item.name + processedCapacity,
          value: item.id,
        };
        return processedData;
      }),
    [vehicle]
  );
  return vehicleData;
};

export default function useCreateDonation({
  invalidate,
  initialDonation,
  isRepost,
  isCopy,
}) {
  const { t } = useTranslation('translation');
  const AddDonationSchema = useAddDonationSchema();
  const daysOfTheWeek = useDaysOfTheWeek();
  const history = useHistory();
  const { user, isFoodMover } = useAuth();
  const { vehicles = [], invalidate: fetchVehicles } = useVehicles({
    fetchOnMount: false,
  });
  const { getNearByNPOs, nearbyNPOs } = useOrg({
    history: null,
    user,
    isNonProfit: false,
    fetchOnMount: false,
  });
  const emitCloseDonation = useEventBus('donation', 'close');
  const { teamMembers, invalidate: fetchTeamMembers } = useTeamMembers({
    fetchOnMount: false,
  });

  const { weight: preferredUnit } = getLocaleUnit(user);
  const vehicleLabelsWithUnit = useGetVehicleLabels();

  const canCreateRecurring = useCheckAction('recurring-donation');

  const timeZoneId = useMemo(
    () => user.timezone_id || 'America/Los_Angeles',
    [user]
  );

  const defaultState = {
    isFoodMover,
    ...parseDonation(initialDonation, user, isRepost),
  };
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleOpen,
    form,
    state,
    setState,
    setFieldState,
    handleClose,
    open,
  } = useForm({
    noClose: true,
    handleSave: async (data) => {
      const {
        occurrenceType,
        isRecurring,
        interval,
        daysOfTheWeek,
        startDate,
        endDate,
        startTime,
        endTime,
        isDST,
      } = processRecurringIntervalData({
        ...data,
        timezone_id: user.timezone_id,
      });
      const images = [];

      //donationId will be used for image upload and the same donation id should be inserted as
      // id in donation table of one time donations
      const donationId = uuidv4();
      const patternId = uuidv4();
      const s3Folder = isRecurring
        ? `recurring-pattern/${patternId}`
        : `donation/${donationId}`;
      for (let i = 0, e = data.images.length; i < e; i++) {
        // If an image has already been uploaded from a copied donation.
        if (typeof data.images[i] === 'string') {
          images.push(data.images[i]);
          continue;
        }

        const { file } = data.images[i];
        const url = await backend.image.upload(
          file,
          {
            folder: s3Folder,
            filetype: file.type,
          },
          s3Folder
        );
        images.push(url);
      }

      const donationTimeZoneId = await getTimeZoneIdFromCoords(data?.location);
      const destTimeZoneId = await getTimeZoneIdFromCoords(data?.destLocation);

      const newDonation = {
        isFoodRun: false,
        occurrenceType: occurrenceType === 'onetime' ? 'onetime' : 'recurring',
        isRecurring,
        interval,
        dayOfTheMonth: data.dayOfTheMonth,
        daysOfTheWeek,
        startDate,
        endDate: occurrenceType === 'indefinite' ? null : endDate,
        startTime,
        endTime,
        isDST,
        vehicleSize: data.vehicleSize,
        vehicle: data.deliveryOption === 0 ? data.vehicle : null,
        deliveryOption: data.deliveryOption,
        foodWeights: processFoodTypes(data.foodTypes),
        title: data.title,
        description: data.description,
        pickup_address: {
          ...data.location,
          timeZoneId: donationTimeZoneId,
          place: data.location?.name,
        },
        dest_address: {
          ...data.destLocation,
          timeZoneId: destTimeZoneId,
          place: data.destLocation?.name,
        },
        images,
        receiverTeam:
          data.receiverTeam && data.receiverTeam !== 'unassigned'
            ? data.receiverTeam
            : null,
        driverId:
          data.deliveryOption === 0 && data.driverId !== 'unassigned'
            ? data.driverId
            : null,
        timezone_id: user.timezone_id,
        donationId,
        patternId,
      };
      const createNewDonation = () =>
        backend.donations
          .createRecurring(newDonation)
          .then(invalidate)
          .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
      if (isRepost) {
        backend.donations
          .cancelDonation({
            donationId: initialDonation.id,
            teamId: user.team_id,
          })
          .then(() => createNewDonation())
          .then(history.goBack)
          .catch(() =>
            enqueueSnackbar(
              'There was an error while reposting your donation.',
              { variant: 'error' }
            )
          );
      } else {
        createNewDonation();
      }
    },
    form: [
      {
        section: 'stepper',
        vertical: true,
        steps: [
          {
            title: t('donations.donation-details'),
            items: [
              {
                id: 'title',
                name: t('donations.title'),
              },
              {
                id: 'description',
                name: t('donations.description'),
                params: makeMultiline(8, 1000),
              },
              {
                id: 'foodTypes',
                name: t('donations.food-types'),
                component: FoodTypeSelector,
                onChange: foodCategoryOnChange,
                params: { color: 'primary' },
              },
            ],
          },
          {
            title: t('donations.time'),
            items: [
              {
                section: 'horizontal',
                collapseSize: 'xs',
                items: [
                  {
                    id: 'occurrenceType',
                    name: t('donations.occurrence-type'),
                    component: RadioGroupComponent(
                      [
                        { label: t('donations.one-time'), value: 'onetime' },
                        {
                          label: canCreateRecurring
                            ? t('donations.recurring')
                            : t('donations.recurring', { context: 'plan' }),
                          value: 'recurring',
                          disabled: !canCreateRecurring,
                        },
                        {
                          label: canCreateRecurring
                            ? t('donations.indefinite')
                            : t('donations.indefinite', { context: 'plan' }),
                          value: 'indefinite',
                          disabled: !canCreateRecurring,
                        },
                      ],
                      !canCreateRecurring ? (
                        <Box mt={2}>
                          <Link
                            to={PATH_DASHBOARD.allPlans}
                            onClick={emitCloseDonation}
                          >
                            {t('donations.upgrade-to-unlock-recurring')}
                          </Link>
                        </Box>
                      ) : null
                    ),
                    defaultState: 'onetime',
                    params: {
                      fullWidth: true,
                    },
                    mapUpdate: ({ state, id, updates }) => {
                      const updatedState = { ...state, [id]: updates };

                      if (updates === 'onetime') {
                        updatedState.endDate = updatedState.startDate;
                      } else {
                        // Compute new end date based on repeat interval
                        let newEndDate = (updatedState.endDate = moment(
                          updatedState.startDate
                        ));
                        recomputeEndDate(updatedState.interval, newEndDate);
                        updatedState.endDate = new Date(
                          newEndDate.format('YYYY-MM-DD HH:mm:ss')
                        );
                      }

                      return updatedState;
                    },
                  },
                  {
                    id: 'interval',
                    name: t('donations.repeat-every'),
                    condition: (state) =>
                      ['indefinite', 'recurring'].includes(
                        state.occurrenceType
                      ),
                    params: {
                      items: [
                        { value: 'Daily', title: t('donations.daily') },
                        { value: 'Weekly', title: t('donations.weekly') },
                        { value: 'Bi-weekly', title: t('donations.bi-weekly') },
                        { value: 'Monthly', title: t('donations.monthly') },
                      ],
                    },
                    component: SelectComponent,
                    defaultState: 'Daily',
                    mapUpdate: ({ state, id, updates }) => {
                      const updatedState = { ...state, [id]: updates };

                      // Compute new end date based on repeat interval
                      let newEndDate = (updatedState.endDate = moment(
                        updatedState.startDate
                      ));
                      recomputeEndDate(updates, newEndDate);
                      updatedState.endDate = new Date(
                        newEndDate.format('YYYY-MM-DD HH:mm:ss')
                      );

                      return updatedState;
                    },
                  },
                ],
              },
              {
                id: 'dayOfTheMonth',
                name: t('donations.repeat-on'),
                condition: (state) =>
                  ['indefinite', 'recurring'].includes(state.occurrenceType) &&
                  state.interval === 'Monthly',
                defaultState: {
                  isLastDay: false,
                  customDay: null,
                  week: {
                    number: null,
                    dayOfTheWeek: null,
                  },
                },
                component: DayOfTheMonthSelector,
                onChange: dateOnChange,
              },
              {
                section: 'horizontal',
                name: 'dayOfTheWeek',
                id: 'dayOfTheWeek',
                condition: (state) =>
                  ['indefinite', 'recurring'].includes(state.occurrenceType) &&
                  (state.interval === 'Weekly' ||
                    state.interval === 'Bi-weekly'),
                compact: true,
                left: true,
                title: t('donations.repeat-on'),
                items: [...'SMTWTFS'].map((_, i) => ({
                  id: `repeat-${i}`,
                  name: daysOfTheWeek[i],
                  params: { sx: { margin: '5px' } },
                  component: ToggleButtonComponent,
                  onChange: checkboxFormOnChange,
                })),
              },
              {},
              {
                section: 'horizontal',
                collapseSize: 'xs',
                condition: (state) => state.occurrenceType === 'onetime',
                items: [
                  {
                    id: 'startDate',
                    name: t('donations.start-date'),
                    defaultState: new Date(
                      moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm:ss')
                    ),
                    component: DateComponent,
                    onChange: dateTimeOnChange,
                  },
                  {
                    id: 'startTime',
                    name: t('donations.start-time'),
                    defaultState: new Date(
                      roundMinutes(moment())
                        .tz(timeZoneId)
                        .format('YYYY-MM-DD HH:mm:ss')
                    ),
                    component: TimeComponent,
                    onChange: timeOnChange,
                  },
                  {
                    id: 'endDate',
                    name: t('donations.end-date'),
                    condition: (state) => state.occurrenceType !== 'indefinite',
                    defaultState: new Date(
                      moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm:ss')
                    ),
                    component: DateComponent,
                    onChange: dateTimeOnChange,
                    mapState: (state) => ({
                      minDate: state.startDate,
                    }),
                  },
                  {
                    id: 'endTime',
                    name: t('donations.end-time'),
                    defaultState: new Date(
                      roundMinutes(moment())
                        .tz(timeZoneId)
                        .add(5, 'hours')
                        .format('YYYY-MM-DD HH:mm:ss')
                    ),
                    component: TimeComponent,
                    onChange: timeOnChange,
                    mapState: (state) => ({
                      minTime: state.startTime,
                    }),
                  },
                ],
              },
              {
                section: 'horizontal',
                title: t('donations.recurring-interval'),
                name: t('donations.recurring-interval'),
                collapseSize: 'xs',
                condition: (state) =>
                  ['recurring', 'indefinite'].includes(state.occurrenceType),
                items: [
                  {
                    id: 'startDate',
                    name: t('donations.start-date'),
                    defaultState: new Date(
                      moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm:ss')
                    ),
                    component: DateComponent,
                    onChange: dateTimeOnChange,
                    mapState: () => ({
                      minDate: new Date(
                        moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm:ss')
                      ),
                    }),
                  },
                  {
                    id: 'endDate',
                    name: t('donations.end-date'),
                    condition: (state) => state.occurrenceType !== 'indefinite',
                    component: DateComponent,
                    onChange: dateTimeOnChange,
                    mapState: (state) => ({
                      minDate: state.startDate,
                    }),
                  },
                  {
                    id: 'startTime',
                    name: t('donations.start-time'),
                    defaultState: new Date(
                      moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm:ss')
                    ),
                    component: TimeComponent,
                    onChange: timeOnChange,
                  },
                  {
                    id: 'endTime',
                    name: t('donations.end-time'),
                    defaultState: new Date(
                      roundMinutes(moment())
                        .tz(timeZoneId)
                        .add(5, 'hours')
                        .format('YYYY-MM-DD HH:mm:ss')
                    ),
                    component: TimeComponent,
                    onChange: timeOnChange,
                    mapState: (state) => ({
                      minTime: state.startTime,
                    }),
                  },
                ],
              },
            ],
          },

          {
            title: t('donations.donation-and-location-images'),
            items: [
              {
                section: 'horizontal',
                collapseSize: 'xs',
                items: [
                  {
                    id: 'vehicleSize',
                    name: t('donations.vehicle-size'),
                    defaultState: 0,
                    component: SelectComponent,
                    params: {
                      items: vehicleLabelsWithUnit,
                      sx: { paddingRight: 2 },
                    },
                  },

                  {
                    id: 'deliveryOption',
                    name: t('donations.delivery-option'),
                    component: SelectComponent,
                    defaultState: 1,
                    params: {
                      items: [
                        { value: 0, title: t('donations.self-deliver') },
                        { value: 1, title: t('donations.reciever-picks-up') },
                      ],
                    },
                  },
                ],
              },
              {
                id: 'images',
                name: t('donations.donation-images'),
                defaultState: [],
                component: UploadImagesComponent,
                onChange: uploadImagesOnChange,
                params: { displayLabel: true },
              },
              {
                id: 'receiverTeam',
                name: t('donations.assign-receiver'),
                params: {
                  className: 'full-width',
                  fullWidth: false,
                  sx: { width: 'calc(100% - 200px)' },
                  autocomplete: true,
                  items: [
                    {
                      value: 'unassigned',
                      title: (
                        <Typography color="text.secondary">
                          {t('donations.unassigned')}
                        </Typography>
                      ),
                    },
                    ...nearbyNPOs.map((receipient) => ({
                      value: receipient.team_id,
                      title: (
                        <>
                          {receipient.name} &nbsp;
                          <Typography
                            color="text.secondary"
                            variant="overlinee"
                          >
                            {receipient.formatted_address}
                          </Typography>
                        </>
                      ),
                    })),
                  ],
                },
                component: SelectComponent,
                defaultState: 'unassigned',
              },
              {
                id: 'vehicle',
                name: t('donations.vehicle'),
                component: SelectComponent,
                defaultState: null,
                params: {
                  hasClear: true,
                  className: 'full-width',
                  fullWidth: false,
                  sx: { width: 'calc(100% - 200px)' },
                  autocomplete: true,
                  items: getVehicle(vehicles, preferredUnit),
                },
                condition: (state) =>
                  state.deliveryOption === 0 && vehicles.length,
              },
              {
                id: 'driverId',
                name: t('donations.driver'),
                params: {
                  className: 'full-width',
                  fullWidth: false,
                  sx: { width: 'calc(100% - 200px)' },
                  autocomplete: true,
                  items: [
                    {
                      value: 'unassigned',
                      title: (
                        <Typography color="text.secondary">
                          {t('donations.unassigned')}
                        </Typography>
                      ),
                    },
                    ...teamMembers.map((teamMember) => ({
                      value: teamMember.id,
                      title: (
                        <>
                          {teamMember.name} &nbsp;
                          <Typography
                            color="text.secondary"
                            variant="overlinee"
                          >
                            {teamMember.user_formatted_address ||
                              teamMember.email}
                          </Typography>
                        </>
                      ),
                    })),
                  ],
                },
                component: SelectComponent,
                defaultState: 'unassigned',
                condition: (state) =>
                  state.deliveryOption === 0 &&
                  state.receiverTeam &&
                  state.receiverTeam !== 'unassigned',
              },
              {
                id: 'location',
                name: t('donations.donation-location'),
                component: SelectAddressComponent,
                onChange: selectAddressOnChange,
                condition: (state) => state.deliveryOption === 1,
                defaultState: {
                  lat: user.lat,
                  lng: user.lng,
                  formattedAddress: user.formatted_address,
                  name: user.place,
                  streetAddress: user.street_address,
                  city: user.city,
                  province: user.province,
                  country: user.country,
                  zipCode: user.zip_code,
                  placeId: user.place_id,
                  timeZoneId: user.timezone_id,
                },
              },
              {
                id: 'destLocation',
                name: t('donations.destination-location'),
                component: SelectAddressComponent,
                onChange: selectAddressOnChange,
                condition: (state) => state.deliveryOption === 0,
              },
            ],
          },
        ],
      },
    ],
    title: t('donations.create-donation'),
    schema: AddDonationSchema(nearbyNPOs),
    defaultState,
  });
  useEffect(() => {
    if (open) {
      fetchVehicles();
      getNearByNPOs({ lat: user.lat, lng: user.lng, includeSelf: false });
      fetchTeamMembers();
    }
  }, [open, user]);
  useEventBus('donation', 'close', handleClose, []);

  // Update the default state when the initial donation changes (used to
  // maintain initial state for the copy donation feature)
  useEffect(() => {
    setState((state) => ({ ...state, ...defaultState }));
  }, [initialDonation, isRepost, isCopy]);

  useEffect(() => {
    if (state.receiverTeam && state.receiverTeam !== 'unassigned') {
      const selectedTeam =
        nearbyNPOs.find(({ team_id }) => team_id === state.receiverTeam) ?? {};
      setFieldState('destLocation', {
        lat: selectedTeam.lat,
        lng: selectedTeam.lng,
        formattedAddress: selectedTeam.formatted_address,
        name: selectedTeam.place,
        streetAddress: selectedTeam.street_address,
        city: selectedTeam.city,
        province: selectedTeam.province,
        country: selectedTeam.country,
        zipCode: selectedTeam.zip_code,
        placeId: selectedTeam.place_id,
        timeZoneId: selectedTeam.timezone_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.receiverTeam, nearbyNPOs]);

  return { handleOpen, form, open };
}
