import React from 'react';

import { DocumentContext } from './component';

export const useDoc = () => {
  const { doc } = React.useContext(DocumentContext);

  if (doc !== null) {
    return doc;
  } else {
    throw new Error(
      'Could not retrieve a document. Please wrap in a DocumentProvider.'
    );
  }
};

export const useProvider = () => {
  const { provider } = React.useContext(DocumentContext);

  if (provider !== null) {
    return provider;
  } else {
    throw new Error(
      'Could not retrieve a set of providers. Please wrap in a DocumentProvider.'
    );
  }
};
