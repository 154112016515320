import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_AUTH } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';
import useMaybeSwitchTeams from 'src/hooks/useMaybeSwitchTeams';

AuthProtect.propTypes = {
  children: PropTypes.node,
};

export default function AuthProtect({ children }) {
  const {
    isLoading,
    isAuthenticated,
    pendingVerification,
    isOrgModerationEnabled,
  } = useAuth();
  const maybeSwitch = useMaybeSwitchTeams();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (pendingVerification && isOrgModerationEnabled) {
    return <Redirect to={PATH_AUTH.register.pendingVerification} />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  if (maybeSwitch) {
    return maybeSwitch;
  }

  return children;
}
