import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Grid,
} from '@mui/material';
import useGeofences from 'src/hooks/useGeofences';
import GoogleMapGeofences from 'src/components/GoogleMaps/GoogleMapGeofences';
import useEventBus from 'src/hooks/useEventBus';
import { useAddSelectedGeofence } from 'src/hooks/teamGeofences';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  map: {
    width: '100%',
    height: '40vh',
  },
}));

const GeozonesSelectorDialog = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { mutateAsync: addSelectedGeofence, isLoading: loadingAddGeofences } =
    useAddSelectedGeofence();
  const { geofences, activeGeofences, refetchActiveGeofences } = useGeofences();

  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleAddGeofence = async () => {
    try {
      await addSelectedGeofence({
        geofencesIds: value.map((item) => item.id),
      });
      await refetchActiveGeofences();
      enqueueSnackbar('Geozones updated', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    setValue(activeGeofences);
  }, [activeGeofences]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Geozones</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selected geozones will be used to filter donations and organizations.
        </DialogContentText>

        <Autocomplete
          sx={{ mb: 2, mt: 3 }}
          multiple
          id="search-geozone-here"
          options={geofences}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Geozones"
              placeholder="Search geozone here"
            />
          )}
          value={value}
          inputValue={inputValue}
          onChange={(_, newValue) => {
            setValue(newValue);
          }}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          clearText="Clear Active Geofences"
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button
              onClick={() => {
                setValue([]);
              }}
            >
              Clear All
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                if (value.length !== geofences.length) {
                  setValue(geofences);
                }
              }}
            >
              Select All
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ flexDirection: 'column' }}></Box>

        <Box sx={{ mt: 3 }}>
          <GoogleMapGeofences
            className={classes.map}
            mapContainerStyle={{
              width: '100%',
              height: '100%',
              borderRadius: '10px',
            }}
            geofences={value}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={loadingAddGeofences}
        >
          Close
        </Button>
        <Button
          variant="contained"
          onClick={handleAddGeofence}
          disabled={loadingAddGeofences}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function useGeozonesSelectorDialog() {
  const [geozonesOpen, setGeozonesOpen] = useState(false);
  const handleChangeGeozones = () => {
    setGeozonesOpen(true);
  };

  useEventBus('geozones', 'openSelector', handleChangeGeozones, [
    setGeozonesOpen,
  ]);

  return {
    handleChangeGeozones,
    GeozonesSelectorDialog: () => (
      <GeozonesSelectorDialog
        open={geozonesOpen}
        onClose={() => setGeozonesOpen(false)}
      />
    ),
  };
}

export default useGeozonesSelectorDialog;
