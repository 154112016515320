import { useQuery, useMutation } from 'react-query';
import { addSelectedGeofence, getSelectedGeofences } from 'src/backend/team';
import useAuth from './useAuth';

export const useAddSelectedGeofence = (options) => {
  return useMutation(addSelectedGeofence, options);
};

export const useGetSelectedGeofences = (options) => {
  const { user } = useAuth();
  return useQuery(['teamGeofences'], getSelectedGeofences, {
    ...options,
    enabled: !!user?.is_executive ?? false,
  });
};
