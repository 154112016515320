import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)({
  flexGrow: 1,
  height: '100%',
  overflow: 'auto',
});

// ----------------------------------------------------------------------

export default function Scrollbar({ children, sx, ...other }) {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle sx={sx} {...other}>
      {children}
    </RootStyle>
  );
}
