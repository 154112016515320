import PropTypes from 'prop-types';
// @mui
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
// components
import Scrollbar from 'src/components/Scrollbar';
import ChatRoomCollapseButton from './ChatRoomCollapseButton';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const HEIGHT = 60;

ChatRoomGroup.propTypes = {
  isCollapse: PropTypes.bool,
  onCollapse: PropTypes.func,
  participants: PropTypes.array,
};

export default function ChatRoomGroup({
  participants,
  isCollapse,
  onCollapse,
}) {
  const { t } = useTranslation('translation');
  return (
    <>
      <ChatRoomCollapseButton isCollapse={isCollapse} onCollapse={onCollapse}>
        {t('chat.participants', { count: participants.length })}
      </ChatRoomCollapseButton>

      <Box
        sx={{
          height: isCollapse ? HEIGHT * 4 : 0,
          transition: (theme) =>
            theme.transitions.create('height', {
              duration: theme.transitions.duration.shorter,
            }),
        }}
      >
        <Scrollbar>
          {participants.map(({ user }) => (
            <Participant key={user.accountSid} participant={user} />
          ))}
        </Scrollbar>
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

Participant.propTypes = {
  participant: PropTypes.object,
};

function Participant({ participant }) {
  return (
    <ListItem sx={{ height: HEIGHT, px: 2.5 }}>
      <ListItemAvatar>
        <Avatar
          alt={participant.friendlyName}
          src={participant.attributes?.avatar}
        />
      </ListItemAvatar>

      <ListItemText
        primary={participant.friendlyName}
        primaryTypographyProps={{ noWrap: true, variant: 'subtitle2' }}
      />
    </ListItem>
  );
}
