import { Tooltip, IconButton } from '@mui/material';
import { Visibility, OpenInNew } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function useOpenRowDataGrid() {
  const { t } = useTranslation('translation');
  return (getLink) => ({
    field: '__view__',
    width: 100,
    headerName: t('donations.view'),
    sortable: false,
    disableClickEventBubbling: true,
    filterable: false,
    renderCell: (params) => {
      const link = getLink(params);

      if (!link) {
        return null;
      }

      return (
        <>
          <Tooltip title={t('donations.view')}>
            <Link to={link} onClick={(e) => e.stopPropagation()}>
              <IconButton>
                <Visibility />
              </IconButton>
            </Link>
          </Tooltip>

          <Tooltip title={t('donations.view-in-tab')}>
            <Link
              to={link}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              <IconButton>
                <OpenInNew />
              </IconButton>
            </Link>
          </Tooltip>
        </>
      );
    },
  });
}
