import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth, user } from 'src/backend';
// redux
import {
  login,
  register,
  logout,
  setUserData,
  setSession,
  setSuppressionStatus,
} from '../redux/slices/auth';
import { setUserDocument } from '../redux/slices/document';
import moment from 'moment';
import { PLANS } from 'src/views/Pricing';
import { PLAN_ID_SELECTOR } from 'src/utils/permissions';
import useLocales from './useLocales';
import { resetFilterModel } from 'src/redux/slices/pageFilters';
// ----------------------------------------------------------------------

export default function useAuth() {
  const dispatch = useDispatch();
  const {
    user: userData,
    isLoading,
    isAuthenticated,
    isOrgModerationEnabled,
    isEmailSuppressed,
  } = useSelector((state) => state.auth);
  const { onChangeLang } = useLocales();

  const timezoneId = useMemo(
    () =>
      userData?.user_timezone_id ||
      userData?.timezone_id ||
      'America/Los_Angeles',
    [userData]
  );

  useEffect(() => {
    if (userData?.lang) {
      onChangeLang(userData.lang);
    }
  }, [userData?.lang]);

  //format date to user's timezone
  const fDateTimeInUserTimeZone = useCallback(
    (date) => {
      return moment
        .utc(date)
        .tz(timezoneId)
        .format('dddd MMMM Do, YYYY [at] h:mm a');
    },
    [timezoneId]
  );

  const planActive = userData?.subscription_status?.toUpperCase() === 'ACTIVE';
  const currentPlan =
    PLANS.find(
      ({ priceId }) =>
        priceId[PLAN_ID_SELECTOR] === userData?.subscription_price_id
    )?.item ?? 'free';
  const plan = planActive ? currentPlan : 'free';

  return {
    user: userData,
    plan,
    isFoodMover: plan === 'foodMover',
    isLoading,
    isAuthenticated,
    isOrgModerationEnabled,
    isEmailSuppressed,
    fDateTimeInUserTimeZone,
    login: ({ email, password, teamId, then, except }) =>
      dispatch(
        login({
          email,
          password,
          teamId,
          then,
          except,
        })
      ),

    register: (userData, enqueueSnackbar, setData, then) =>
      dispatch(register(userData, enqueueSnackbar, setData, then)),

    logout: () => {
      dispatch(setUserDocument({ document: {} }));
      dispatch(logout());
      dispatch(resetFilterModel());
    },

    resetPassword: auth.resetPasswordOTP,

    updateProfile: async (data) => {
      await user.updateUser(data);
      let userData = await auth.getUserData();
      dispatch(setUserData({ user: userData }));
    },

    updateBusiness: async (data) => {
      await user.updateBusiness(data);
      let userData = await auth.getUserData();
      dispatch(setUserData({ user: userData }));
    },
    invalidateUserData: async () => {
      let userData = await auth.getUserData();
      dispatch(setUserData({ user: userData }));
    },
    verifyEmail: (data) => auth.verifyEmail(data),
    getSuppressionStatus: (data) => {
      const suppressionStatus = auth.getSuppressionStatus(data);
      dispatch(setSuppressionStatus({ isEmailSuppressed: suppressionStatus }));
    },
    resendEmailVerification: (data) => auth.resendEmailVerification(data),
    sendVerificationToUnsuppressEmail: (data) =>
      auth.sendVerificationToUnsuppressEmail(data),
    setUserData: (data) => {
      setSession(data.token, data.refreshToken);
      window.location.reload();
    },
  };
}
