import { Add } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';

const CreateDonationButton = ({ onClick }) => {
  const { user } = useAuth();
  const businessIsActive = !!user?.is_business_visible_to_others;
  const { t } = useTranslation('translation', {
    keyPrefix: 'sidebar',
  });
  return (
    <Tooltip
      disableInteractive
      title={
        !businessIsActive
          ? t('organization-disabled', { context: 'donation' })
          : t('create-donation')
      }
    >
      <span>
        <Button
          disabled={!businessIsActive}
          onClick={onClick}
          startIcon={<Add />}
        >
          {t('create-donation')}
        </Button>
      </span>
    </Tooltip>
  );
};

export default CreateDonationButton;
