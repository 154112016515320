import { createSlice } from '@reduxjs/toolkit';
// utils
import { auth, config } from 'src/backend';
import _noop from 'lodash/noop';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  user: {},
  isOrgModerationEnabled: false,
  isEmailSuppressed: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.isOrgModerationEnabled = action.payload.isOrgModerationEnabled;
      state.isEmailSuppressed = action.payload.isEmailSuppressed;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    },

    setUserData(state, action) {
      state.user = action.payload.user;
    },

    setSuppressionStatus(state, action) {
      state.isEmailSuppressed = action.payload.isEmailSuppressed;
    },
  },
});

// Reducer
export default slice.reducer;

export const setUserData = slice.actions.setUserData;
export const setSuppressionStatus = slice.actions.setSuppressionStatus;

// ----------------------------------------------------------------------

export function updateUserData() {
  return async (dispatch) => {
    const user = await auth.getUserData();
    dispatch(slice.actions.setUserData({ user }));
  };
}

// ----------------------------------------------------------------------

export function setSession(
  accessToken,
  refreshToken,
  { noRevoke = true } = {}
) {
  // Revoke the old refresh token if necessary
  const oldRefreshToken = localStorage.getItem('refresh-token');
  if (oldRefreshToken && refreshToken !== oldRefreshToken && !noRevoke) {
    auth.revokeRefreshToken(oldRefreshToken).catch((err) => {
      console.error(err);
    });
  }

  if (accessToken && refreshToken) {
    localStorage.setItem('token', accessToken);
    localStorage.setItem('refresh-token', refreshToken);
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh-token');
  }
}

// ----------------------------------------------------------------------

export function login({ email, password, teamId, then, except }) {
  return async (dispatch) => {
    try {
      const body = await auth.login(email, password, teamId);

      then();
      const { token, refreshToken, ...user } = body;
      setSession(token, refreshToken);

      const suppressionStatusRes = await auth.getSuppressionStatus(email);
      dispatch(
        setSuppressionStatus({
          isEmailSuppressed: suppressionStatusRes.suppressionStatus,
        })
      );
      dispatch(
        slice.actions.loginSuccess({
          user,
        })
      );
    } catch (e) {
      except(e);
    }
  };
}

// ----------------------------------------------------------------------

export function register(userData, enqueueSnackbar, setData, then = _noop) {
  return async (dispatch) => {
    let body;
    try {
      body = await auth.register(userData);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      return;
    }
    setData({});
    const configRes = await config.getSystemConfig();
    if (configRes?.is_org_moderation_enabled) {
      then(body);
    } else {
      const { token, refreshToken, ...user } = body;
      setSession(token, refreshToken);
      dispatch(slice.actions.registerSuccess({ user }));
      then();
    }
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null, null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const configRes = await config.getSystemConfig();
      const accessToken = window.localStorage.getItem('token');
      const refreshToken = window.localStorage.getItem('refresh-token');

      if (accessToken && refreshToken) {
        setSession(accessToken, refreshToken);

        const userData = await auth.getUserData();
        const suppressionStatusRes = await auth.getSuppressionStatus(
          userData.email
        );
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: userData,
            isOrgModerationEnabled: !!configRes?.is_org_moderation_enabled,
            isEmailSuppressed: suppressionStatusRes.suppressionStatus,
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null,
            isOrgModerationEnabled: !!configRes?.is_org_moderation_enabled,
          })
        );
      }
    } catch (error) {
      window.localStorage.removeItem('token');
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
        })
      );
    }
  };
}
