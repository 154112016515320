/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as Y from 'yjs';
import { useForceUpdate } from './util';
import { useDoc } from './doc';

const useSharedType = (name, constructor) => {
  const doc = useDoc();
  return doc.get(name, constructor);
};

export const useMap = (name) => {
  const map = useSharedType(name, Y.Map);

  const forceUpdate = useForceUpdate();
  React.useEffect(() => {
    map.observe(() => forceUpdate());
  }, []);

  return {
    state: map.toJSON(),
    get: React.useCallback((name) => map.get(name), []),
    set: React.useCallback((name, value) => map.set(name, value), []),
  };
};

export const useArray = (name) => {
  const array = useSharedType(name, Y.Array);

  const forceUpdate = useForceUpdate();
  React.useEffect(() => {
    array.observe(() => forceUpdate());
  }, []);

  return {
    length: array.length,
    state: array.toJSON(),
    get: React.useCallback((index) => array.get(index), []),
    insert: React.useCallback(
      (index, content) => array.insert(index, content),
      []
    ),
    delete: React.useCallback(
      (index, length) => array.delete(index, length),
      []
    ),
    push: React.useCallback((content) => array.push(content), []),
    unshift: React.useCallback((content) => array.unshift(content), []),
    slice: React.useCallback((start, end) => array.slice(start, end), []),
  };
};

export const useText = (name) => useSharedType(name, Y.Text);
