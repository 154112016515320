import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import MoreButton from 'src/components/MoreButton';
import { Language } from '@mui/icons-material';
import useLocales from 'src/hooks/useLocales';
import { LANG_SETTINGS } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { updateUserLang } from 'src/backend/user';

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export function SwitchLanguageButton({ excludeLanguages = [], ...props }) {
  const { user, invalidateUserData } = useAuth();
  const { allLang: langs, onChangeLang } = useLocales();
  const allLang = langs.filter(
    (lang) => !excludeLanguages.includes(lang.value)
  );

  if (!LANG_SETTINGS) {
    return null;
  }

  return (
    <Box
      sx={{ position: 'absolute', right: 8, top: 8, zIndex: 100 }}
      {...props.containerProps}
    >
      <MoreButton
        options={allLang.map(({ label, value }) => ({
          text: label,
          action: async () => {
            onChangeLang(value);
            if (user) {
              updateUserLang({
                lang: value,
              })
                .then(() => {
                  invalidateUserData();
                })
                .catch(() => {});
            }
          },
        }))}
        Icon={Language}
        {...props}
      />
    </Box>
  );
}

export default function AuthLayout({ children }) {
  return (
    <Box sx={{ height: '100%' }}>
      <SwitchLanguageButton />

      {children}
    </Box>
  );
}
