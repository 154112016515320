import { useRef, useState } from 'react';
// @mui
import { Stack, InputBase, IconButton, InputAdornment } from '@mui/material';
// components
import Iconify from '@iconify/react';
import useChatClient from 'src/hooks/useChatClient';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChatMessageInput() {
  const { t } = useTranslation('translation');
  const { activeConversation: conversation } = useChatClient();
  const disabled = !conversation;

  const fileInputRef = useRef(null);
  const [message, setMessage] = useState('');

  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };

  const handleSend = (event) => {
    if (event.key === 'Enter') {
      if (message && conversation) {
        conversation
          .sendMessage(message)
          .then(() => conversation.setAllMessagesRead());
      }
      setMessage('');
    }
  };

  return (
    <>
      <InputBase
        value={message}
        onKeyUp={handleSend}
        onChange={(event) => setMessage(event.target.value)}
        placeholder={t('chat.type-a-message')}
        startAdornment={
          <InputAdornment position="start">
            <IconButton size="small">
              <Iconify icon="eva:smiling-face-fill" />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <Stack direction="row" spacing={1} sx={{ flexShrink: 0, mr: 1.5 }}>
            <IconButton
              disabled={disabled}
              size="small"
              onClick={handleClickAttach}
            >
              <Iconify icon="ic:round-add-photo-alternate" />
            </IconButton>

            <IconButton
              disabled={disabled}
              size="small"
              onClick={handleClickAttach}
            >
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>

            <IconButton disabled={disabled} size="small">
              <Iconify icon="eva:mic-fill" />
            </IconButton>
          </Stack>
        }
        sx={{
          pl: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
    </>
  );
}
