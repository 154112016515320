const { useTranslation } = require('react-i18next');

const useDaysOfTheWeek = () => {
  const { t } = useTranslation('translation');
  return [
    t('donations.day-0'),
    t('donations.day-1'),
    t('donations.day-2'),
    t('donations.day-3'),
    t('donations.day-4'),
    t('donations.day-5'),
    t('donations.day-6'),
  ];
};

export default useDaysOfTheWeek;
