import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from './useAuth';
import useInvites from './useInvites';
import { useTranslation } from 'react-i18next';

const useNotificationsMessages = () => {
  const { t } = useTranslation('translation');

  /**
   * @typedef Prereq
   * @property {string} displayName Name of the action required
   * @property {string} description Details about the prerequisite
   * @property {string?} location Link which directs user to action location
   * @property {() => Promise<void> | void} action Function to be run when this prereq. task has been started
   */

  /** @type {Prereq} */
  const USER_LOCATION = {
    displayName: t('finish-profile.user-location.title'),
    description: t('finish-profile.user-location.description'),
    location: PATH_DASHBOARD.settings.account('general', 'location'),
  };

  /** @type {Prereq} */
  const USER_VERIFY_PHONE = {
    displayName: t('finish-profile.verify-phone.title'),
    description: t('finish-profile.verify-phone.description'),
    location: PATH_DASHBOARD.settings.account('general', 'verify-phone'),
  };

  /** @type {Prereq} */
  const USER_PHONE = {
    displayName: t('finish-profile.set-phone-number.title'),
    description: t('finish-profile.set-phone-number.description'),
    location: PATH_DASHBOARD.settings.account('general', 'update-user'),
  };

  /** @type {Prereq} */
  const INVITATIONS = {
    displayName: t('finish-profile.accept-team-invites.title'),
    description: t('finish-profile.accept-team-invites.description'),
    location: PATH_DASHBOARD.settings.account('invitations'),
  };

  /** @type {Prereq} */
  const ORG_HOURS = {
    displayName: t('finish-profile.set-organization-hours.title'),
    description: t('finish-profile.set-organization-hours.description'),
    location: PATH_DASHBOARD.settings.business('hours'),
  };

  /** @type {Prereq} */
  const ORG_CAPACITY = {
    displayName: t('finish-profile.set-donation-capacity.title'),
    description: t('finish-profile.set-donation-capacity.description'),
    location: PATH_DASHBOARD.settings.business('general', 'capacity'),
  };

  /** @type {(enqueueSnackbar: Function) => Prereq} */
  const DESKTOP_NOTIFICATIONS = (enqueueSnackbar) => ({
    displayName: t('finish-profile.enable-desktop-notifications.title'),
    description: t('finish-profile.enable-desktop-notifications.description'),
    action: () =>
      window.Notification &&
      Notification.requestPermission((result) => {
        if (result === 'granted') {
          enqueueSnackbar(t('finish-profile.notifications-enabled'), {
            variant: 'info',
          });
        }
      }),
  });

  return {
    USER_LOCATION,
    USER_VERIFY_PHONE,
    USER_PHONE,
    INVITATIONS,
    ORG_HOURS,
    ORG_CAPACITY,
    DESKTOP_NOTIFICATIONS,
  };
};

/**
 * Get a list of all tasks which need to be completed by a driver in order to
 * finish signing up.
 *
 * @returns {Prereq[]}
 */
export default function usePrereqs() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const {
    DESKTOP_NOTIFICATIONS,
    INVITATIONS,
    ORG_CAPACITY,
    ORG_HOURS,
    USER_LOCATION,
    USER_PHONE,
    USER_VERIFY_PHONE,
  } = useNotificationsMessages();
  const { invites } = useInvites(user.email);

  const prereqs = [];

  // skip super admins
  if (user.account_type === 'ADMIN') {
    return [];
  }

  if (user.is_non_profit || !(user.is_executive || user.is_corporate)) {
    // for npos and businesses

    if (!user.user_lat && user.is_driver) {
      prereqs.push(USER_LOCATION);
    }

    if (!user.phone) {
      prereqs.push(USER_PHONE);
    } else if (!user.is_phone_verified) {
      prereqs.push(USER_VERIFY_PHONE);
    }

    if (window.Notification && Notification.permission === 'default') {
      prereqs.push(DESKTOP_NOTIFICATIONS(enqueueSnackbar));
    }

    if (invites.length) {
      prereqs.push(INVITATIONS);
    }

    if (user.is_admin) {
      let noHours =
        !Array.isArray(user.hours) ||
        !user.hours.find(
          ({ open_time, close_time }) => open_time || close_time
        );
      if (noHours) {
        prereqs.push(ORG_HOURS);
      }

      if (user.is_non_profit && !user.accepted_food_weights) {
        prereqs.push(ORG_CAPACITY);
      }
    }
  }

  return prereqs;
}
