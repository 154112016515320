import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// material
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// routes
import routes, { renderRoutes } from './routes';
// redux
import { store, persistor } from './redux/store';
// theme
import ThemeProvider from './theme';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import JwtProvider from './components/authentication/JwtProvider';
import { EventBusProvider } from './hooks/useEventBus';
import { GeofenceProvider } from './hooks/useGeofences';
import { LoadScript } from '@react-google-maps/api';
//
import { GOOGLE_API_KEY, ENV, isDevelopment, isProduction } from 'src/config';

import SocketIO from 'src/backend/SocketIO';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import _toLower from 'lodash/toLower';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { PATH_AUTH } from 'src/routes/paths';
import React, { useEffect, useState, useCallback } from 'react';
import es from 'date-fns/locale/es';

import { QueryClientProvider, QueryClient, QueryCache } from 'react-query';
import 'src/locales/i18n';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
});

// ----------------------------------------------------------------------
SocketIO.init();
const history = createBrowserHistory();

// ----------------------------------------------------------------------

if (!isDevelopment) {
  const defaultOptions = {
    dsn: 'https://0eb1568ea93c4679bcee693ea86dff1a@o89831.ingest.sentry.io/5774579',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: _toLower(ENV),
    tracesSampleRate: 1.0,
  };
  const mergedOptions = {
    ...defaultOptions,
    ...(isProduction
      ? {
          beforeSend(event) {
            // Check if the stacktrace exists and has frames
            if (event.exception && event.exception.values) {
              for (const exception of event.exception.values) {
                if (exception.stacktrace && exception.stacktrace.frames) {
                  for (const frame of exception.stacktrace.frames) {
                    // Check if the frame's filename originates from 'https://maps.googleapis.com'
                    if (
                      frame.filename &&
                      frame.filename.startsWith('https://maps.googleapis.com')
                    ) {
                      return null; // Drop the event and don't send to Sentry
                    }
                  }
                }
              }
            }

            if (event.exception && !event.contexts?.feedbackDialog?.hide) {
              Sentry.showReportDialog({ eventId: event.event_id });
            }
            return event;
          },
        }
      : {}),
  };

  Sentry.init(mergedOptions);
}

// ----------------------------------------------------------------------

const baseSettings = {
  id: 'script-loader',
  googleMapsApiKey: GOOGLE_API_KEY,
  language: 'en',
  region: 'EN',
  version: 'weekly',
  libraries: ['drawing', 'visualization', 'places'],
};

function SessionExpiredModal() {
  const { t } = useTranslation('translation');

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    function handleTokenExpiredEvent(event) {
      if (
        !window.location.pathname.includes('login') &&
        window.location.pathname !== '/apps'
      ) {
        setOpenModal(event.detail.isExpired);
      }
    }

    window.addEventListener('token-expired', handleTokenExpiredEvent);
    return () =>
      window.removeEventListener('token-expired', handleTokenExpiredEvent);
  }, []);

  return (
    <Dialog
      open={openModal}
      onClose={() => {
        window.open(PATH_AUTH.login, '_self');
        setOpenModal(false);
      }}
    >
      <DialogTitle>{t('session-expired.title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('session-expired.message')}</Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            window.open(PATH_AUTH.login, '_self');
            setOpenModal(false);
          }}
          variant="outlined"
        >
          {t('session-expired.login')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function OutdatedSnackbar() {
  const { t } = useTranslation('translation');

  const [isOutDated, setOutdated] = useState(
    parseInt(process.env.REACT_APP_VERSION) <
      parseInt(localStorage.getItem('latest-version') ?? '0')
  );

  useEffect(() => {
    const handleVersion = () => {
      setOutdated(
        parseInt(process.env.REACT_APP_VERSION) <
          parseInt(localStorage.getItem('latest-version') ?? '0')
      );
    };

    window.addEventListener('latest-version', handleVersion);
    return () => window.removeEventListener('latest-version', handleVersion);
  }, []);

  const handleRefresh = useCallback(() => window.location.reload(), []);

  if (!isOutDated) {
    return null;
  }

  return (
    <Snackbar
      open={isOutDated}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      message="A new version of careit is available"
      action={
        <Button onClick={handleRefresh}>
          <Typography color={'white'}>{t('refresh')}</Typography>
        </Button>
      }
    ></Snackbar>
  );
}

function MomentLocaleSync() {
  const { i18n } = useTranslation('translation');
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  return null;
}

function App({ lang }) {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <ThemeProvider>
              <RtlLayout>
                <LoadScript {...baseSettings}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={lang == 'es-US' ? es : undefined}
                  >
                    <NotistackProvider>
                      <Router history={history}>
                        <JwtProvider>
                          <EventBusProvider>
                            <GeofenceProvider>
                              <ScrollToTop />
                              <SessionExpiredModal />
                              <OutdatedSnackbar />
                              <MomentLocaleSync />
                              {renderRoutes(routes)}
                            </GeofenceProvider>
                          </EventBusProvider>
                        </JwtProvider>
                      </Router>
                    </NotistackProvider>
                  </LocalizationProvider>
                </LoadScript>
              </RtlLayout>
            </ThemeProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

const AppMemoed = React.memo(App);

export default function AppLocalized() {
  const { i18n } = useTranslation('translation');

  return <AppMemoed lang={i18n?.language} />;
}
