import { fetchAPI, parseBody, assertErrors, METHODS } from './common';
// import qs from 'qs';

/**
 * Get Tags belonging to the current corporate account.
 * @returns Tags[]
 */
export async function getTags() {
  const res = await fetchAPI('/tags', METHODS.get);
  await assertErrors(res);
  const { tags } = await parseBody(res);
  return tags;
}

export async function updateTag(data) {
  const res = await fetchAPI(`/tags/${data.tag.id}`, METHODS.put, data);
  await assertErrors(res);
}

export async function deleteTag(data) {
  const res = await fetchAPI(`/tags/${data.id}`, METHODS.delete);
  await assertErrors(res);
}
