import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Polygon, useGoogleMap } from '@react-google-maps/api';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { grey } from '@mui/material/colors';

// ----------------------------------------------------------------------

function Geofence({ geofence }) {
  return (
    <Polygon
      paths={geofence.points}
      options={{
        fillColor: grey[600],
        fillOpacity: 0.24,
        strokeColor: geofence.strokeColor,
        strokeOpacity: 0.5,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
      }}
    />
  );
}

function EncapsulateGeofences({ geofences }) {
  const map = useGoogleMap();

  useEffect(() => {
    if (!map || !geofences || geofences.length === 0) {
      return;
    }

    const google = window.google;
    const latlng = ({ lat, lng }) => new google.maps.LatLng(lat, lng);

    var latlngbounds = new google.maps.LatLngBounds();
    for (let i = 0; i < geofences.length; i++) {
      latlngbounds.extend(latlng(geofences[i].aabb.min));
      latlngbounds.extend(latlng(geofences[i].aabb.max));
    }

    map.fitBounds(latlngbounds);
  }, [map, geofences]);

  return null;
}

GoogleMapGeofences.propTypes = {
  geofences: PropTypes.array.isRequired,
  className: PropTypes.string,
};

function GoogleMapGeofences({ geofences, className, ...other }) {
  const mapOptions = useMemo(
    () => ({
      center: { lat: 24.886, lng: -70.268 },
      zoom: 3,
      minZoom: 2,
      maxZoom: 16,
      controlSize: 24,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [
          window.google.maps.MapTypeId.ROADMAP,
          window.google.maps.MapTypeId.HYBRID,
          window.google.maps.MapTypeId.SATELLITE,
          window.google.maps.MapTypeId.TERRAIN,
        ],
        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
    }),
    []
  );

  return (
    <div className={className}>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        options={mapOptions}
        {...other}
      >
        <EncapsulateGeofences geofences={geofences} />
        {geofences.map((geofence) => (
          <Geofence
            key={geofence.name}
            id={geofence.name}
            geofence={geofence}
          />
        ))}
      </GoogleMap>
    </div>
  );
}

export default GoogleMapGeofences;
