import { useTranslation } from 'react-i18next';
import * as backend from 'src/backend';

const useCheckCompletedOnboarding = () => {
  const { t } = useTranslation('translation');

  const buildErrorMessage = (npos, receiver) =>
    t('donations.must-complete-onboarding-items', {
      name: npos.find((team) => team.team_id === receiver).name,
    });

  const validate = async (receiver) => {
    if (!receiver || receiver === 'unassigned') {
      return true;
    }
    const requiredItems = await backend.team.getItemsRequiredToDonateTo(
      receiver
    );
    return requiredItems.length === 0;
  };

  return {
    buildErrorMessage,
    validate,
  };
};

export default useCheckCompletedOnboarding;
