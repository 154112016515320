import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Client } from '@twilio/conversations';
import { useSnackbar } from 'notistack';

import { chat } from 'src/backend';
import useAuth from './useAuth';

const ChatClientContext = createContext();

function useChatToken() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [chatToken, setChatToken] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);

  // Only businesses/npos can chat (for now)
  const canChat =
    user &&
    user.account_type === 'TEAM-STAFF' &&
    !(user.is_executive || user.is_corporate);

  useEffect(() => {
    if (valid || !canChat) {
      return;
    }

    setLoading(true);

    if (!user) {
      setLoading(false);
      setValid(true);
      setChatToken(null);
      return;
    }

    chat
      .getAuthToken()
      .then(setChatToken)
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(err.message ?? 'Failed to start a chat session', {
          variant: 'error',
        });
      })
      .finally(() => {
        setValid(true);
        setLoading(false);
      });
  }, [valid, user, enqueueSnackbar, canChat]);

  const invalidate = useCallback(() => setValid(false), []);
  useEffect(invalidate, [user]);

  return {
    chatToken,
    isLoading,
    invalidate,
  };
}

export function ChatClientProvider({ children }) {
  const { user } = useAuth();
  const { chatToken } = useChatToken();
  const [chatOpen, setChatOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [conversations, setConversations] = useState({});
  const [conversationParticipants, setConversationParticipants] = useState({});
  const [activeConversationId, setActiveConversationId] = useState(null);
  const [client, setClient] = useState(null);
  useEffect(() => {
    if (chatToken) {
      setClient(new Client(chatToken));
    } else {
      setClient(null);
      setChatOpen(false);
      setConversations({});
      setConversationParticipants({});
      setActiveConversationId(null);
    }
  }, [chatToken]);

  // setup client hooks
  useEffect(() => {
    if (!client) {
      return;
    }

    client.on('initialized', async () => {
      console.log('Chat initialized');

      let paginator = await client.getSubscribedConversations();
      while (paginator.hasNextPage) {
        paginator = await paginator.nextPage();
      }
    });

    client.on('initFailed', ({ error }) => {
      console.log('Initialization failed: ', error);
    });

    /** @param {import("@twilio/conversations").Conversation} conversation */
    const registerConversation = async (conversation) => {
      setConversations((conversations) => ({
        ...conversations,
        [conversation.sid]: conversation,
      }));

      const updateParticipants = async () => {
        let participants = await conversation.getParticipants();
        participants = await Promise.all(
          participants.map(async (participant) => {
            const user = await participant.getUser();
            return {
              participant,
              user,
            };
          })
        );
        setConversationParticipants((conversationParticipants) => ({
          ...conversationParticipants,
          [conversation.sid]: participants,
        }));
      };
      updateParticipants();

      conversation.on('participantJoined', updateParticipants);
      conversation.on('participantLeft', updateParticipants);
      conversation.on('participantUpdated', updateParticipants);
    };

    client.on('conversationAdded', registerConversation);
    client.on('conversationUpdated', ({ conversation }) =>
      registerConversation(conversation)
    );
    client.on('conversationRemoved', (conversation) => {
      setConversations((conversations) => {
        delete conversations[conversation.sid];
        return conversations;
      });
      setConversationParticipants((conversationParticipants) => {
        delete conversationParticipants[conversation.sid];
        return conversationParticipants;
      });
      setActiveConversationId((sid) => (conversation.sid === sid ? null : sid));
    });
  }, [client]);

  useEffect(() => {
    if (!client) {
      return;
    }

    let handler = null;

    if (window.Notification && Notification.permission === 'granted') {
      handler = (message) => {
        if (chatOpen && message.conversation.sid === activeConversationId) {
          return; // Don't send notifications for open conversations
        }

        if (message.author === `UID${user.id};TID${user.team_id}`) {
          return; // Don't send notifications for messages created by the current user
        }

        const notification = new Notification(
          message.conversation.friendlyName,
          {
            body: message.body,
            icon: '/static/brand/logo_single.png',
            tag: message.sid,
          }
        );

        notification.addEventListener('click', () => {
          setChatOpen(true);
          setDrawerOpen(true);
          setActiveConversationId(message.conversation.sid);
          notification.close();
        });
      };
      client.addListener('messageAdded', handler);
    }

    return () => {
      if (handler) {
        client.removeListener('messageAdded', handler);
      }
    };
  }, [window.Notification?.permission, client, activeConversationId, chatOpen]);

  useTeamConversation({ chatToken });
  if (user?.is_admin) {
    useAdminTeamConversation({ chatToken });
  }

  const unreadMessages = useMemo(() => {
    return Object.values(conversations)
      .map((conversation) => {
        const last = conversation.lastMessage?.index ?? 0;
        const read = conversation.lastReadMessageIndex ?? 0;
        return last - read;
      })
      .reduce((acc, x) => acc + x, 0);
  }, [conversations]);

  const fetchParticipantsOfAConversation = useCallback(
    async (conversationId) => {
      const conversation = await client.getConversationBySid(conversationId);
      const participants = await conversation.getParticipants();
      return participants;
    },
    [client]
  );

  const value = useMemo(
    () => ({
      client,
      conversations,
      conversationParticipants,
      activeConversation:
        activeConversationId && conversations[activeConversationId],
      setActiveConversationId,
      chatOpen,
      setChatOpen,
      drawerOpen,
      setDrawerOpen,
      unreadMessages,
      fetchParticipantsOfAConversation,
    }),
    [
      client,
      conversations,
      conversationParticipants,
      activeConversationId,
      chatOpen,
      unreadMessages,
      fetchParticipantsOfAConversation,
      drawerOpen,
      setDrawerOpen,
      setChatOpen,
    ]
  );

  return (
    <ChatClientContext.Provider value={value}>
      {children}
    </ChatClientContext.Provider>
  );
}

export function useTeamConversation({ chatToken }) {
  const [state, setState] = useState({
    loading: false,
    valid: false,
    cache: null,
    error: null,
  });

  // Only businesses/npos can chat (for now)
  const { user } = useAuth();
  const canChat =
    user &&
    user.account_type === 'TEAM-STAFF' &&
    !(user.is_executive || user.is_corporate);

  useEffect(() => {
    if (state.valid || state.loading || !canChat) {
      return;
    }

    if (chatToken) {
      setState((state) => ({ ...state, loading: true }));
      chat
        .getTeamConversation()
        .then((conversationSid) =>
          setState({
            loading: false,
            valid: true,
            cache: conversationSid,
            error: null,
          })
        )
        .catch((error) => {
          console.error(error);
          setState({
            loading: false,
            valid: true,
            cache: null,
            error,
          });
        });
    }
  }, [state, canChat, chatToken]);

  return state;
}

export function useAdminTeamConversation({ chatToken }) {
  const [state, setState] = useState({
    loading: false,
    valid: false,
    cache: null,
    error: null,
  });

  // Only businesses/npos can chat (for now)
  const { user } = useAuth();
  const canChat =
    user &&
    user.account_type === 'TEAM-STAFF' &&
    !(user.is_executive || user.is_corporate);

  useEffect(() => {
    if (state.valid || state.loading || !canChat) {
      return;
    }

    if (chatToken) {
      setState((state) => ({ ...state, loading: true }));
      chat
        .getAdminTeamConversation()
        .then((conversationSid) =>
          setState({
            loading: false,
            valid: true,
            cache: conversationSid,
            error: null,
          })
        )
        .catch((error) => {
          console.error(error);
          setState({
            loading: false,
            valid: true,
            cache: null,
            error,
          });
        });
    }
  }, [state, canChat, chatToken]);

  return state;
}

export default function useChatClient() {
  const context = useContext(ChatClientContext);
  if (!context) {
    throw new Error(
      'Could not read from the chat client context. Did you forget a <ChatClientProvider />?'
    );
  }

  return context;
}
